import React, { Component, PureComponent } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'
import {
    ResponsiveContainer,
    PieChart, Pie, Sector, Cell,
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,
    Label, Text
} from 'recharts';
import moment from 'moment'
import $ from 'jquery'

import {
    SpinnerModal
} from '../Spinner/SpinnerModal'

import DateFilter from './DateFilter'


import { 
    setReportDateFilter,
    getTasksForStatistics,
    getRequestStatistics,
    getEarningStatistics,
    getUserStatistics
} from '../../actions/reportAction'
import {
    setUserPresence
} from '../../actions/authAction'

import './dashboard.css'

const LINE_MONTHS = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
]

const LINE_WEEKS = [
    'Sun','Mon','Tue','Wed','Thu','Fri','Sat'
]
  
const COLORS = ['#66BB6A', '#FF8E26', '#FF6666', '#29B6F6'];
  
const renderGroupedData = (tickProps) => {
    const { x, y, payload } = tickProps;
    const { value, offset } = payload;
    const date = new Date(value);
    const month = date.getMonth();
    const quarterNo = Math.floor(month / 3) + 1;
    const isMidMonth = month % 3 === 1;
  
    if (month % 3 === 1) {
      return <text x={x + offset} y={y } textAnchor="start">{`Q${quarterNo}`}</text>;
    }
  
    const isLast = month === 11;
  
    if (month % 3 === 0 || isLast) {
      const pathX = Math.floor(isLast ? x + offset * 2 : x) + 0.5;
  
      return <path d={`M${pathX},${y - 4}v${-35}`} stroke="red" />;
    }
    return null;
};

const enumerateDaysBetweenDates = function(startDate, endDate) {
    var now = moment(startDate), dates = [];
    
    while (now.isSameOrBefore(moment(endDate))) {
        dates.push(now.format('MM/DD/YYYY'));
        now.add(1, 'days');
    }
    return dates;
};

const renderCustomToolTip = ({active, label, payload}) => {
    return(
    <div>
      <p> payload[0].label </p>
  {/* // need to add percentage value in tooltip */}
    </div>
    )
}

const CustomizedTaskTypeLabel = () => {
    return (
        <Text
            x={0}
            y={-20}
            dx={-300}
            dy={40}
            textAnchor="start"
            width={180}
            transform="rotate(-90)"
            //scaleToFit={true}
            style={{ fontSize : "12px", marginBotom : "20px", fill : "#CFCDD1" }}
        >            
            Number of tasks
        </Text>
    );
};


const CustomizedTranslatorLabel = () => {
    return (
        <Text
            x={0}
            y={-20}
            dx={-300}
            dy={40}
            textAnchor="start"
            width={180}
            transform="rotate(-90)"
            //scaleToFit={true}
            style={{ fontSize : "12px", marginBotom : "20px", fill : "#CFCDD1" }}
        >            
            Number of translators
        </Text>
    );
};


const CustomizedCustomerLabel = () => {
    return (
        <Text
            x={0}
            y={-20}
            dx={-300}
            dy={40}
            textAnchor="start"
            width={180}
            transform="rotate(-90)"
            //scaleToFit={true}
            style={{ fontSize : "12px", marginBotom : "20px", fill : "#CFCDD1" }}
        >            
            Number of customers
        </Text>
    );
};

const CustomizedEarningLabel = () => {
    return (
        <Text
            x={0}
            y={-20}
            dx={-300}
            dy={40}
            textAnchor="start"
            width={180}
            transform="rotate(-90)"
            //scaleToFit={true}
            style={{ fontSize : "12px", marginBotom : "20px", fill : "#CFCDD1" }}
        >            
            Earnings
        </Text>
    );
};

const CustomizedRequestLabel = () => {
    return (
        <Text
            x={0}
            y={-20}
            dx={-300}
            dy={40}
            textAnchor="start"
            width={180}
            transform="rotate(-90)"
            //scaleToFit={true}
            style={{ fontSize : "12px", marginBotom : "20px", fill : "#CFCDD1" }}
        >            
            Number of requests
        </Text>
    );
};


export class Index extends Component {

    constructor(){

        super();
        this.state = {
            fromYearFilter : moment().year(),
            dateFrom : new Date(moment().subtract(1, 'month')),
            toYearFilter : moment().year(),
            dateTo : new Date(moment()),
            yearFilter : moment().year(),
            dateRangeYearGap : 0,
            currentYear : moment().format("YYYY"),
            currentMonth : moment().format("MMM"),
            currentWeek : moment().week(),
            startOfMonth : moment().startOf('month').format('YYYY-MM-DD hh:mm'),
            endOfMonth : moment().endOf('month').format('YYYY-MM-DD hh:mm'),
            enumeratedDates : [],

            tasksCompleted : [],
            tasksInProgress : [],
            tasksPending : [],
            tasksArchived : [],
            taskCategories : [],
            taskStatusStatisticData : [],

            presentationTasks : [],
            essayTasks : [],
            dissertationTasks : [],
            taskTypeNumberOfDays : moment().daysInMonth(),
            taskTypeData : [],

            translators : [],
            translatorTick : [],
            translatorFilter : "Year",
            translatorYear : moment().year(),
            translatorMonth : "Jan",
            translatorWeek : "Sun",
            translatorFilterYear : [],
            translatorDataYear : [],
            translatorFilterMonth : [],
            translatorFilterWeek : [],
            translatorChartData : [],
            translatorChartIndex : 0,
            translatorStat : [],
            translatorStatWeekGroup : [],
    

            taskTypes : [],
            taskStatTypeFilter : "All",
            showEssayTasks : true,
            showPresentationTasks : true,
            showDissertationTasks : true,
            taskTypeFilter : "Year",
            taskTypeInterval : 30,
            taskTypeActiveYear : "",
            taskTypeActiveMonth : "",
            taskTypeActiveWeek : "",
            taskTypeDataYearIndex : 0,
            taskTypeDataMonthIndex : 0,
            taskTypeDataWeekIndex : 0,
            taskTypeTick : [],
            taskTypeStatData : [],
            taskTypeStatDataDisplay : [],
            isSelectingAll : true,

            customers : [],
            customerFilter : "Year",
            customerActiveYear : "",
            customerActiveMonth : "",
            customerActiveWeek : "",
            customerDataYearIndex : 0,
            customerDataMonthIndex : 0,
            customerDataWeekIndex : 0,
            customerTick : [],
            customerInterval : 30,
            customerStatData : [],
            customerStatDataDisplay : [],
            customerJoined : 0,

            translators : [],
            translatorFilter : "Year",
            translatorActiveYear : "",
            translatorActiveMonth : "",
            translatorActiveWeek : "",
            translatorDataYearIndex : 0,
            translatorDataMonthIndex : 0,
            translatorDataWeekIndex : 0,
            translatorTick : [],
            translatorStatData : [],
            translatorStatDataDisplay : [],
            translatorJoined : 0,
            translatorInterval : 30,

            requests : [],
            requestFilter : "Year",
            requestActiveYear : "",
            requestActiveMonth : "",
            requestActiveWeek : "",
            requestDataYearIndex : 0,
            requestDataMonthIndex : 0,
            requestDataWeekIndex : 0,
            requestTick : [],
            requestStatData : [],
            requestStatDataDisplay : [],
            requestUserTypeFilter  : "customer",
            requestTaskTypeFilter : "essay",
            requestInterval : 30,

            earnings : [],
            earningFilter : "Year",
            earningActiveYear : "",
            earningActiveMonth : "",
            earningActiveWeek : "",
            earningDataYearIndex : 0,
            earningDataMonthIndex : 0,
            earningDataWeekIndex : 0,
            earningTick : [],
            earningStatData : [],
            earningStatDataDisplay : [],
            earningInterval : 30,

            monthTick : [],
            weekTick : ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'],
            yearTick : ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
            isAuthenticated : false

        }

    }

    componentDidMount(){
        const { auth } = this.props;
        const { dateFrom, dateTo } = this.state;

        if(auth.uid){
            this.props.setUserPresence({
                uid : auth.uid
            })
            this.setState({
                isAuthenticated : true
            })

            let dateRange = enumerateDaysBetweenDates(dateFrom, dateTo)

            this.props.setReportDateFilter({
                dateFrom: dateFrom,
                dateTo : dateTo
            })
    
            this.setState({
                enumeratedDates : dateRange
            })
    
            this.props.getTasksForStatistics();
            this.props.getRequestStatistics();
            this.props.getUserStatistics();
        }
    }

    componentDidUpdate(prevProps){

        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true
            })
        }

        if(prevProps.taskList!==this.props.taskList){

            const { taskList } = this.props;
            
            let taskCategories = [];
            taskList.map(data => {
                if(taskCategories.findIndex( d => d.category == data.taskCategory )===-1){
                    taskCategories.push({
                        category : data.taskCategory,
                        selected : true
                    })
                }
            })

            this.setState({
                taskCategories : taskCategories,
            }, ()=> {
                this.createStatusStatChart();
                this.createTaskTypeStatData();
                this.createEarningStatData();
            })
          
        }

        if(prevProps.requestList!==this.props.requestList){
            this.createRequestStatData();
        }

        if(prevProps.userList!==this.props.userList){
            this.createTranslatorStatData();
            this.createCustomerStatData();
        }

    }

    /**
     * TASK TYPE STATISTIC DATA FUNCTIONS
     */
    createTaskTypeStatData = () => {

        const { taskList } = this.props;
        const { dateFrom, dateTo, currentYear, enumeratedDates } = this.state;

        const { taskCategories } = this.state;

        let taskTypeChartData = [];
        
        let isUncheckedAll

        // Loop every year included from the range filter
        enumeratedDates.map( data => {

            let dataYear = "";

            if(taskTypeChartData.findIndex( cd => cd.year===moment(data).format("YYYY"))===-1){
                dataYear = moment(data).format("YYYY");

                let typeFilteredTaskTypeList = taskList.filter( data => moment.unix(data.dateAdded.seconds).format('YYYY')==dataYear)
                let filteredTaskTypeList = [];
                for(let fT=0; fT<typeFilteredTaskTypeList.length; fT++){

                    let catIndex = taskCategories.findIndex( fd => fd.category===typeFilteredTaskTypeList[fT].taskCategory)
                    if(taskCategories[catIndex].selected){
                        filteredTaskTypeList.push(typeFilteredTaskTypeList[fT])
                    }
                }

                let filteredGroup = enumeratedDates.filter( (ed) => moment(ed).format("YYYY") === dataYear);

                taskTypeChartData.push({
                    year : dataYear,
                    year_data : [],
                    month_data : [],
                    week_data : []
                })  

                let thisYearIndex = taskTypeChartData.findIndex( y => y.year === dataYear )
                
                filteredGroup.map( fd => {
                    taskTypeChartData[thisYearIndex].year_data.push({
                        name : fd,
                        date : fd,
                        presentation : filteredTaskTypeList.filter( p => p.taskType==="presentation").filter( p => moment.unix(p.dateAdded.seconds).format("MM/DD/YYYY") === fd ).length,
                        essay : filteredTaskTypeList.filter( p => p.taskType==="essay").filter( p => moment.unix(p.dateAdded.seconds).format("MM/DD/YYYY") === fd ).length,
                        dissertation : filteredTaskTypeList.filter( p => p.taskType==="dissertation").filter( p => moment.unix(p.dateAdded.seconds).format("MM/DD/YYYY") === fd ).length,
                    })

                    let thisMonth = moment(fd).format("MMM");

                    if(taskTypeChartData[thisYearIndex].month_data.findIndex( m => m.month_name === thisMonth )===-1){
                        taskTypeChartData[thisYearIndex].month_data.push({
                            month_name : thisMonth,
                            data : [],
                            day_count : moment(fd).daysInMonth()
                        })
                    }

                    let thisMonthIndex = taskTypeChartData[thisYearIndex].month_data.findIndex( m => m.month_name === thisMonth )

                    taskTypeChartData[thisYearIndex].month_data[thisMonthIndex].data.push({
                        name : fd,
                        date : fd,
                        presentation : filteredTaskTypeList.filter( p => p.taskType==="presentation").filter( p => moment.unix(p.dateAdded.seconds).format("MM/DD/YYYY") === fd ).length,
                        essay : filteredTaskTypeList.filter( p => p.taskType==="essay").filter( p => moment.unix(p.dateAdded.seconds).format("MM/DD/YYYY") === fd ).length,
                        dissertation : filteredTaskTypeList.filter( p => p.taskType==="dissertation").filter( p => moment.unix(p.dateAdded.seconds).format("MM/DD/YYYY") === fd ).length,
                    })

                    let thisWeek = moment(fd).week();
                    if(thisWeek===1 && moment(fd).format("MMM")==="Dec"){
                        thisWeek = 53;
                    }

                    if(taskTypeChartData[thisYearIndex].week_data.findIndex( w => w.week === thisWeek )===-1){
                        taskTypeChartData[thisYearIndex].week_data.push({
                            week : thisWeek,
                            data : [],
                        })
                    }

                    let thisWeekIndex = taskTypeChartData[thisYearIndex].week_data.findIndex( w => w.week === thisWeek )

                    taskTypeChartData[thisYearIndex].week_data[thisWeekIndex].data.push({
                        name : fd,
                        date : fd,
                        presentation : filteredTaskTypeList.filter( p => p.taskType==="presentation").filter( p => moment.unix(p.dateAdded.seconds).format("MM/DD/YYYY") === fd ).length,
                        essay : filteredTaskTypeList.filter( p => p.taskType==="essay").filter( p => moment.unix(p.dateAdded.seconds).format("MM/DD/YYYY") === fd ).length,
                        dissertation : filteredTaskTypeList.filter( p => p.taskType==="dissertation").filter( p => moment.unix(p.dateAdded.seconds).format("MM/DD/YYYY") === fd ).length,
                    })
                   
                })
            }
        })
        
        this.setState({
            taskTypeFilter : "Year",
            taskTypeDataYearIndex : taskTypeChartData.length - 1,
            taskTypeActiveYear : taskTypeChartData[taskTypeChartData.findIndex( y => y.year == currentYear)].year,
            taskTypeStatData : taskTypeChartData,
            taskTypeStatDataDisplay : taskTypeChartData[taskTypeChartData.findIndex( y => y.year == currentYear)].year_data
        }, () => {
            this.animateToRight('#task-stat');
        })

    }

    filterTaskCategory = (filter) => {
        if(filter==="all"){
            const { taskCategories } = this.state;

            let newTaskCategories = [];
            taskCategories.map( data => {
                newTaskCategories.push({
                    category : data.category,
                    selected : true
                })
            })

            this.setState({
                isSelectingAll : true,
                taskCategories : newTaskCategories
            }, () => {
                this.createTaskTypeStatData();
            })
        }else{

            const { taskCategories } = this.state;

            let newTaskCategories = [];
            taskCategories.map( data => {
                newTaskCategories.push({
                    category : data.category,
                    selected : data.category===filter ? !data.selected : data.selected
                })
            })

            this.setState({
                isSelectingAll : false,
                taskCategories : newTaskCategories,
            }, () => {
                this.createTaskTypeStatData();
            })
        }

    }

    selectTaskTypes = (e) => {
        let text = $(e.target).parent('.checkbox-container').text();

        this.setState({
            [`show${text}Tasks`] : e.target.checked,
        })
    }

    filterTaskTypeData = (e) => {

        const { taskTypeStatData, currentYear, currentMonth, currentWeek } = this.state;

        this.setState({
            taskTypeFilter : e.target.value
        })

        if(e.target.value==="Year"){
            this.setState({
                taskTypeDataYearIndex : taskTypeStatData.findIndex( y => y.year == currentYear),
                taskTypeActiveYear : taskTypeStatData[taskTypeStatData.findIndex( y => y.year == currentYear)].year,
                taskTypeStatDataDisplay : taskTypeStatData[taskTypeStatData.findIndex( y => y.year == currentYear)].year_data,
                taskTypeTick : this.state.yearTick,
                taskTypeInterval : 30
            })
        }

        if(e.target.value==="Month"){

            let totalDays = taskTypeStatData[0].month_data[0].day_count;
            let tick = [];

            for(let t=1; t<=totalDays; t++){
                tick.push(t)    
            }

            this.setState({
                taskTypeDataYearIndex : taskTypeStatData.findIndex( y => y.year == currentYear),
                taskTypeDataMonthIndex : taskTypeStatData[taskTypeStatData.findIndex( y => y.year == currentYear)].month_data.findIndex( m => m.month_name===currentMonth),
                taskTypeActiveYear : taskTypeStatData[taskTypeStatData.findIndex( y => y.year == currentYear)].year,
                taskTypeActiveMonth : taskTypeStatData[taskTypeStatData.findIndex( y => y.year == currentYear)].month_data.filter( m => m.month_name===currentMonth )[0].month_name, //taskTypeStatData[0].month_data[0].month_name,
                taskTypeStatDataDisplay : taskTypeStatData[taskTypeStatData.findIndex( y => y.year == currentYear)].month_data.filter( m => m.month_name===currentMonth )[0].data, //taskTypeStatData[0].month_data[0].data,
                taskTypeTick : tick,
                taskTypeInterval : 0
            })

        }

        if(e.target.value==="Week"){

            this.setState({
                taskTypeDataYearIndex : taskTypeStatData.findIndex( y => y.year == currentYear),
                taskTypeDataWeekIndex : taskTypeStatData[taskTypeStatData.findIndex( y => y.year == currentYear)].week_data.findIndex( m => m.week===currentWeek),
                taskTypeActiveYear :taskTypeStatData[taskTypeStatData.findIndex( y => y.year == currentYear)].year,
                taskTypeActiveWeek : taskTypeStatData[taskTypeStatData.findIndex( y => y.year == currentYear)].week_data.filter( m => m.week===currentWeek )[0].week, //taskTypeStatData[0].week_data[0].week,
                taskTypeStatDataDisplay : taskTypeStatData[taskTypeStatData.findIndex( y => y.year == currentYear)].week_data.filter( m => m.week===currentWeek )[0].data, //taskTypeStatData[0].week_data[0].data,
                taskTypeTick : this.state.weekTick,
                taskTypeInterval : 0
            })

        }
    }

    taskTypeTickFormatter = (tick) => {

        const { taskTypeFilter } = this.state;

        if(taskTypeFilter==="Year")
            return moment(new Date(tick)).format("MMM")
    
        if(taskTypeFilter==="Month")
            return moment(new Date(tick)).format("D")
    
        if(taskTypeFilter==="Week")
            return moment(new Date(tick)).format("ddd")
    }
    
    renderTaskTypeInterval = (tick) =>{
        
        const { taskTypeFilter } = this.state;

        if(taskTypeFilter==="Year")
            return 30;
    
        if(taskTypeFilter==="Month")
            return 1;
    
        if(taskTypeFilter==="Week")
            return 1;
    }

    nextPageTaskTypeData = () => {

        const { taskTypeStatData, taskTypeFilter, taskTypeDataYearIndex, taskTypeDataMonthIndex, taskTypeDataWeekIndex } = this.state;

        if(taskTypeFilter==="Year"){

            let newYearIndex = taskTypeDataYearIndex + 1;

            if(taskTypeStatData[newYearIndex]!==undefined){
                this.setState({
                    taskTypeDataYearIndex : newYearIndex,
                    taskTypeActiveYear : taskTypeStatData[newYearIndex].year,
                    taskTypeStatDataDisplay :  taskTypeStatData[newYearIndex].year_data
                })
            }

        }

        if(taskTypeFilter==="Month"){

            let newMonthIndex = taskTypeDataMonthIndex + 1;
            if(newMonthIndex===taskTypeStatData[this.state.taskTypeDataYearIndex].month_data.length){
                
                if(taskTypeStatData[this.state.taskTypeDataYearIndex+1]!==undefined){
                    this.setState({
                        taskTypeActiveYear : taskTypeStatData[this.state.taskTypeDataYearIndex+1].year,
                        taskTypeDataYearIndex : this.state.taskTypeDataYearIndex+1,
                        taskTypeDataMonthIndex : 0
                    }, () => {
    
                        const { taskTypeDataYearIndex, taskTypeDataMonthIndex } = this.state;
    
                        this.setState({
                            taskTypeActiveMonth : taskTypeStatData[taskTypeDataYearIndex].month_data[taskTypeDataMonthIndex].month_name,
                            taskTypeStatDataDisplay :  taskTypeStatData[taskTypeDataYearIndex].month_data[taskTypeDataMonthIndex].data
                        })
                    })
                }
            }else{

                const { taskTypeDataYearIndex } = this.state;

                if(taskTypeStatData[taskTypeDataYearIndex].month_data[newMonthIndex]!==undefined){
                    this.setState({
                        taskTypeDataMonthIndex :newMonthIndex,
                        taskTypeActiveMonth : taskTypeStatData[taskTypeDataYearIndex].month_data[newMonthIndex].month_name,
                        taskTypeStatDataDisplay :  taskTypeStatData[taskTypeDataYearIndex].month_data[newMonthIndex].data
                    })
                }
            }            
        }

        if(taskTypeFilter==="Week"){
          
            let newWeekIndex = taskTypeDataWeekIndex + 1;
            if(newWeekIndex===taskTypeStatData[this.state.taskTypeDataYearIndex].week_data.length){
                if(taskTypeStatData[this.state.taskTypeDataYearIndex+1]!==undefined){
                    this.setState({
                        taskTypeActiveYear : taskTypeStatData[this.state.taskTypeDataYearIndex+1].year,
                        taskTypeDataYearIndex : this.state.taskTypeDataYearIndex+1,
                        taskTypeDataWeekIndex : 0
                    }, () => {
    
                        const { taskTypeDataYearIndex, taskTypeDataWeekIndex } = this.state;
    
                        this.setState({
                            taskTypeActiveWeek : taskTypeStatData[taskTypeDataYearIndex].week_data[taskTypeDataWeekIndex].week,
                            taskTypeStatDataDisplay :  taskTypeStatData[taskTypeDataYearIndex].week_data[taskTypeDataWeekIndex].data
                        })
                    })
                }
            }else{
                const { taskTypeDataYearIndex } = this.state;

                if(taskTypeStatData[taskTypeDataYearIndex].week_data[newWeekIndex]!==undefined){
                    this.setState({
                        taskTypeDataWeekIndex :newWeekIndex,
                        taskTypeActiveWeek : taskTypeStatData[taskTypeDataYearIndex].week_data[newWeekIndex].week,
                        taskTypeStatDataDisplay :  taskTypeStatData[taskTypeDataYearIndex].week_data[newWeekIndex].data
                    })
                }
            }   

        }
    }

    previousPageTaskTypeData = () => {

        const { taskTypeStatData, taskTypeFilter, taskTypeDataYearIndex, taskTypeDataMonthIndex, taskTypeDataWeekIndex } = this.state;

        if(taskTypeFilter==="Year"){
            
            if(taskTypeStatData[this.state.taskTypeDataYearIndex-1]!==undefined){
                this.setState({
                    taskTypeDataYearIndex : this.state.taskTypeDataYearIndex-1,
                    taskTypeActiveYear : taskTypeStatData[this.state.taskTypeDataYearIndex-1].year,
                    taskTypeStatDataDisplay :  taskTypeStatData[this.state.taskTypeDataYearIndex-1].year_data
                })
            }

        }

        if(taskTypeFilter==="Month"){
         
            let newMonthIndex = taskTypeDataMonthIndex - 1;
            
            if(newMonthIndex===-1){
                if(taskTypeStatData[this.state.taskTypeDataYearIndex-1]!==undefined){
                    this.setState({
                        taskTypeActiveYear : taskTypeStatData[this.state.taskTypeDataYearIndex-1].year,
                        taskTypeDataYearIndex : this.state.taskTypeDataYearIndex-1,
                        taskTypeDataMonthIndex : taskTypeStatData[this.state.taskTypeDataYearIndex-1].month_data.length - 1
                    }, () => {
    
                        const { taskTypeDataYearIndex, taskTypeDataMonthIndex } = this.state;
                        this.setState({
                            taskTypeActiveMonth : taskTypeStatData[taskTypeDataYearIndex].month_data[taskTypeDataMonthIndex].month_name,
                            taskTypeStatDataDisplay :  taskTypeStatData[taskTypeDataYearIndex].month_data[taskTypeDataMonthIndex].data
                        })
                    })
                }
            }else{

                const { taskTypeDataYearIndex } = this.state;

                if(taskTypeStatData[taskTypeDataYearIndex].month_data[newMonthIndex]!==undefined){
                    this.setState({
                        taskTypeDataMonthIndex :newMonthIndex,
                        taskTypeActiveMonth : taskTypeStatData[taskTypeDataYearIndex].month_data[newMonthIndex].month_name,
                        taskTypeStatDataDisplay :  taskTypeStatData[taskTypeDataYearIndex].month_data[newMonthIndex].data
                    })
                }
            }  

        }

        if(taskTypeFilter==="Week"){
            
            let newWeekIndex = taskTypeDataWeekIndex - 1;

            if(newWeekIndex===-1){
                
                if(taskTypeStatData[this.state.taskTypeDataYearIndex-1]!==undefined){
                    this.setState({
                        taskTypeActiveYear : taskTypeStatData[this.state.taskTypeDataYearIndex-1].year,
                        taskTypeDataYearIndex : this.state.taskTypeDataYearIndex-1,
                        taskTypeDataWeekIndex : taskTypeStatData[this.state.taskTypeDataYearIndex-1].week_data.length - 1
                    }, () => {
    
                        const { taskTypeDataYearIndex, taskTypeDataWeekIndex } = this.state;
                        
                        this.setState({
                            taskTypeActiveWeek : taskTypeStatData[taskTypeDataYearIndex].week_data[taskTypeDataWeekIndex].week,
                            taskTypeStatDataDisplay :  taskTypeStatData[taskTypeDataYearIndex].week_data[taskTypeDataWeekIndex].data
                        })
                    })
                }
            }else{

                const { taskTypeDataYearIndex } = this.state;

                if(taskTypeStatData[taskTypeDataYearIndex].week_data[newWeekIndex]!==undefined){
                    this.setState({
                        taskTypeDataWeekIndex :newWeekIndex,
                        taskTypeActiveWeek : taskTypeStatData[taskTypeDataYearIndex].week_data[newWeekIndex].week,
                        taskTypeStatDataDisplay :  taskTypeStatData[taskTypeDataYearIndex].week_data[newWeekIndex].data
                    })
                }
            }   

        }

    }

    /**
     * CUSTOMER STATISTIC DATA FUNCTIONS
     */
    createCustomerStatData = () => {
        
        const { userList } = this.props;
        const { fromYearFilter, toYearFilter } = this.state;
        const { dateFrom, dateTo, currentYear, enumeratedDates } = this.state;
        
        let customerChartData = [];
        let customerList =  userList.filter( data => data.type === "customer" );

        // Loop every year included from the range filter
        enumeratedDates.map( data => {

            let dataYear = "";

            if(customerChartData.findIndex( cd => cd.year===moment(data).format("YYYY"))===-1){
                dataYear = moment(data).format("YYYY");

                let filteredCustomerList = customerList.filter( data => moment.unix(data.dateRegistered.seconds).format('YYYY')==dataYear)

                let filteredGroup = enumeratedDates.filter( (ed) => moment(ed).format("YYYY") === dataYear);

                customerChartData.push({
                    year : dataYear,
                    year_data : [],
                    month_data : [],
                    week_data : []
                })  

                let thisYearIndex = customerChartData.findIndex( y => y.year === dataYear )
                
                filteredGroup.map( fd => {
                    customerChartData[thisYearIndex].year_data.push({
                        name : fd,
                        date : fd,
                        count : filteredCustomerList.filter( p => moment.unix(p.dateRegistered.seconds).format("MM/DD/YYYY") === fd ).length
                    })

                    let thisMonth = moment(fd).format("MMM");

                    if(customerChartData[thisYearIndex].month_data.findIndex( m => m.month_name === thisMonth )===-1){
                        customerChartData[thisYearIndex].month_data.push({
                            month_name : thisMonth,
                            data : [],
                            day_count : moment(fd).daysInMonth()
                        })
                    }

                    let thisMonthIndex = customerChartData[thisYearIndex].month_data.findIndex( m => m.month_name === thisMonth )

                    customerChartData[thisYearIndex].month_data[thisMonthIndex].data.push({
                        name : fd,
                        date : fd,
                        count : filteredCustomerList.filter( p => moment.unix(p.dateRegistered.seconds).format("MM/DD/YYYY") === fd ).length
                    })

                    let thisWeek = moment(fd).week();
                    if(thisWeek===1 && moment(fd).format("MMM")==="Dec"){
                        thisWeek = 53;
                    }

                    if(customerChartData[thisYearIndex].week_data.findIndex( w => w.week === thisWeek )===-1){
                        customerChartData[thisYearIndex].week_data.push({
                            week : thisWeek,
                            data : [],
                        })
                    }

                    let thisWeekIndex = customerChartData[thisYearIndex].week_data.findIndex( w => w.week === thisWeek )

                    customerChartData[thisYearIndex].week_data[thisWeekIndex].data.push({
                        name : fd,
                        date : fd,
                        count : filteredCustomerList.filter( p => moment.unix(p.dateRegistered.seconds).format("MM/DD/YYYY") === fd ).length
                    })
                   
                })
            }
        })
        
        let lastYear = moment(dateTo).subtract(1, 'year')

        let customerJoined = 0;

        customerList.map( ( data ) => {
            if(data.dateRegistered.seconds>=lastYear.unix() && data.dateRegistered.seconds<=moment(dateTo).unix()){
                customerJoined = customerJoined + 1;
            }

        })

        this.setState({
            customerJoined : customerJoined,
            customers : customerList,
            customerFilter : "Year",
            customerDataYearIndex : customerChartData.length-1,
            customerActiveYear : customerChartData[customerChartData.findIndex( y => y.year == currentYear)].year,
            customerStatData : customerChartData,
            customerStatDataDisplay : customerChartData[customerChartData.findIndex( y => y.year == currentYear)].year_data
        }, () => {
            this.animateToRight('#customer-stat');
        })

    }

    filterCustomerData = (e) => {

        const { customerStatData, currentYear, currentMonth, currentWeek } = this.state;

        this.setState({
            customerFilter : e.target.value
        })

        if(e.target.value==="Year"){
            this.setState({
                customerDataYearIndex : customerStatData.findIndex( y => y.year == currentYear),
                customerActiveYear : customerStatData[customerStatData.findIndex( y => y.year == currentYear)].year,
                customerStatDataDisplay : customerStatData[customerStatData.findIndex( y => y.year == currentYear)].year_data,
                customerTick : this.state.yearTick,
                customerInterval : 30,
            })
        }

        if(e.target.value==="Month"){

            let totalDays = customerStatData[0].month_data[0].day_count;
            let tick = [];

            for(let t=1; t<=totalDays; t++){
                tick.push(t)    
            }

            this.setState({
                customerDataYearIndex : customerStatData.findIndex( y => y.year == currentYear),
                customerDataMonthIndex : customerStatData[customerStatData.findIndex( y => y.year == currentYear)].month_data.findIndex( m => m.month_name===currentMonth),
                customerActiveYear : customerStatData[customerStatData.findIndex( y => y.year == currentYear)].year,
                customerActiveMonth : customerStatData[customerStatData.findIndex( y => y.year == currentYear)].month_data.filter( m => m.month_name===currentMonth )[0].month_name, //customerStatData[0].month_data[0].month_name,
                customerStatDataDisplay : customerStatData[customerStatData.findIndex( y => y.year == currentYear)].month_data.filter( m => m.month_name===currentMonth )[0].data, //customerStatData[0].month_data[0].data,
                customerTick : tick,
                customerInterval : 1,
            })

        }

        if(e.target.value==="Week"){

            this.setState({
                customerDataYearIndex : customerStatData.findIndex( y => y.year == currentYear),
                customerDataWeekIndex : customerStatData[customerStatData.findIndex( y => y.year == currentYear)].week_data.findIndex( m => m.week===currentWeek),
                customerActiveYear :customerStatData[customerStatData.findIndex( y => y.year == currentYear)].year,
                customerActiveWeek : customerStatData[customerStatData.findIndex( y => y.year == currentYear)].week_data.filter( m => m.week===currentWeek )[0].week, //customerStatData[0].week_data[0].week,
                customerStatDataDisplay : customerStatData[customerStatData.findIndex( y => y.year == currentYear)].week_data.filter( m => m.week===currentWeek )[0].data, //customerStatData[0].week_data[0].data,
                customerTick : this.state.weekTick,
                customerInterval : 1
            })

        }
    }

    customerTickFormatter = (tick) => {

        const { customerFilter } = this.state;

        if(customerFilter==="Year")
            return moment(new Date(tick)).format("MMM")
    
        if(customerFilter==="Month")
            return moment(new Date(tick)).format("D")
    
        if(customerFilter==="Week")
            return moment(new Date(tick)).format("ddd")
    }
    
    renderCustomerInterval = (tick) =>{
        
        const { customerFilter } = this.state;

        if(customerFilter==="Year")
            return 30;
    
        if(customerFilter==="Month")
            return 1;
    
        if(customerFilter==="Week")
            return 1;
    }

    nextPageCustomerData = () => {

        const { customerStatData, customerFilter, customerDataYearIndex, customerDataMonthIndex, customerDataWeekIndex } = this.state;

        if(customerFilter==="Year"){

            let newYearIndex = customerDataYearIndex + 1;

            if(customerStatData[newYearIndex]!==undefined){
                this.setState({
                    customerDataYearIndex : newYearIndex,
                    customerActiveYear : customerStatData[newYearIndex].year,
                    customerStatDataDisplay :  customerStatData[newYearIndex].year_data
                })
            }

        }

        if(customerFilter==="Month"){

            let newMonthIndex = customerDataMonthIndex + 1;
            if(newMonthIndex===customerStatData[this.state.customerDataYearIndex].month_data.length){
                
                if(customerStatData[this.state.customerDataYearIndex+1]!==undefined){
                    this.setState({
                        customerActiveYear : customerStatData[this.state.customerDataYearIndex+1].year,
                        customerDataYearIndex : this.state.customerDataYearIndex+1,
                        customerDataMonthIndex : 0
                    }, () => {
    
                        const { customerDataYearIndex, customerDataMonthIndex } = this.state;
    
                        this.setState({
                            customerActiveMonth : customerStatData[customerDataYearIndex].month_data[customerDataMonthIndex].month_name,
                            customerStatDataDisplay :  customerStatData[customerDataYearIndex].month_data[customerDataMonthIndex].data
                        })
                    })
                }
            }else{

                const { customerDataYearIndex } = this.state;

                if(customerStatData[customerDataYearIndex].month_data[newMonthIndex]!==undefined){
                    this.setState({
                        customerDataMonthIndex :newMonthIndex,
                        customerActiveMonth : customerStatData[customerDataYearIndex].month_data[newMonthIndex].month_name,
                        customerStatDataDisplay :  customerStatData[customerDataYearIndex].month_data[newMonthIndex].data
                    })
                }
            }            
        }

        if(customerFilter==="Week"){
          
            let newWeekIndex = customerDataWeekIndex + 1;
            if(newWeekIndex===customerStatData[this.state.customerDataYearIndex].week_data.length){
                if(customerStatData[this.state.customerDataYearIndex+1]!==undefined){
                    this.setState({
                        customerActiveYear : customerStatData[this.state.customerDataYearIndex+1].year,
                        customerDataYearIndex : this.state.customerDataYearIndex+1,
                        customerDataWeekIndex : 0
                    }, () => {
    
                        const { customerDataYearIndex, customerDataWeekIndex } = this.state;
    
                        this.setState({
                            customerActiveWeek : customerStatData[customerDataYearIndex].week_data[customerDataWeekIndex].week,
                            customerStatDataDisplay :  customerStatData[customerDataYearIndex].week_data[customerDataWeekIndex].data
                        })
                    })
                }
            }else{
                const { customerDataYearIndex } = this.state;

                if(customerStatData[customerDataYearIndex].week_data[newWeekIndex]!==undefined){
                    this.setState({
                        customerDataWeekIndex :newWeekIndex,
                        customerActiveWeek : customerStatData[customerDataYearIndex].week_data[newWeekIndex].week,
                        customerStatDataDisplay :  customerStatData[customerDataYearIndex].week_data[newWeekIndex].data
                    })
                }
            }   

        }
    }

    previousPageCustomerData = () => {

        const { customerStatData, customerFilter, customerDataYearIndex, customerDataMonthIndex, customerDataWeekIndex } = this.state;

        if(customerFilter==="Year"){
            
            if(customerStatData[this.state.customerDataYearIndex-1]!==undefined){
                this.setState({
                    customerDataYearIndex : this.state.customerDataYearIndex-1,
                    customerActiveYear : customerStatData[this.state.customerDataYearIndex-1].year,
                    customerStatDataDisplay :  customerStatData[this.state.customerDataYearIndex-1].year_data
                })
            }

        }

        if(customerFilter==="Month"){
         
            let newMonthIndex = customerDataMonthIndex - 1;
            if(newMonthIndex===-1){
                if(customerStatData[this.state.customerDataYearIndex-1]!==undefined){
                    this.setState({
                        customerActiveYear : customerStatData[this.state.customerDataYearIndex-1].year,
                        customerDataYearIndex : this.state.customerDataYearIndex-1,
                        customerDataMonthIndex : customerStatData[this.state.customerDataYearIndex-1].month_data.length - 1
                    }, () => {
    
                        const { customerDataYearIndex, customerDataMonthIndex } = this.state;
                        this.setState({
                            customerActiveMonth : customerStatData[customerDataYearIndex].month_data[customerDataMonthIndex].month_name,
                            customerStatDataDisplay :  customerStatData[customerDataYearIndex].month_data[customerDataMonthIndex].data
                        })
                    })
                }
            }else{

                const { customerDataYearIndex } = this.state;

                if(customerStatData[customerDataYearIndex].month_data[newMonthIndex]!==undefined){
                    this.setState({
                        customerDataMonthIndex :newMonthIndex,
                        customerActiveMonth : customerStatData[customerDataYearIndex].month_data[newMonthIndex].month_name,
                        customerStatDataDisplay :  customerStatData[customerDataYearIndex].month_data[newMonthIndex].data
                    })
                }
            }  

        }

        if(customerFilter==="Week"){
            
            let newWeekIndex = customerDataWeekIndex - 1;

            if(newWeekIndex===-1){
                
                if(customerStatData[this.state.customerDataYearIndex-1]!==undefined){
                    this.setState({
                        customerActiveYear : customerStatData[this.state.customerDataYearIndex-1].year,
                        customerDataYearIndex : this.state.customerDataYearIndex-1,
                        customerDataWeekIndex : customerStatData[this.state.customerDataYearIndex-1].week_data.length - 1
                    }, () => {
    
                        const { customerDataYearIndex, customerDataWeekIndex } = this.state;
                        
                        this.setState({
                            customerActiveWeek : customerStatData[customerDataYearIndex].week_data[customerDataWeekIndex].week,
                            customerStatDataDisplay :  customerStatData[customerDataYearIndex].week_data[customerDataWeekIndex].data
                        })
                    })
                }
            }else{

                const { customerDataYearIndex } = this.state;

                if(customerStatData[customerDataYearIndex].week_data[newWeekIndex]!==undefined){
                    this.setState({
                        customerDataWeekIndex :newWeekIndex,
                        customerActiveWeek : customerStatData[customerDataYearIndex].week_data[newWeekIndex].week,
                        customerStatDataDisplay :  customerStatData[customerDataYearIndex].week_data[newWeekIndex].data
                    })
                }
            }   

        }

    }

    filterCustomerJoined = (e) => {

        const { customers, dateTo } = this.state;

        if(e.target.value==="Year"){

            let lastYear = moment(dateTo).subtract(1, 'year')

            let customerJoined = 0;

            customers.map( ( data ) => {

                if(data.dateRegistered.seconds>=lastYear.unix() && data.dateRegistered.seconds<=moment(dateTo).unix()){
                    customerJoined = customerJoined + 1;
                }

            })
            
            this.setState({
                customerJoined : customerJoined
            })

        }

        if(e.target.value==="Month"){

            let lastMonth = moment(dateTo).subtract(1, 'month')

            let customerJoined = 0;

            customers.map( ( data ) => {
                if(data.dateRegistered.seconds>=lastMonth.unix() && data.dateRegistered.seconds<=moment(dateTo).unix()){
                    customerJoined = customerJoined + 1;
                }

            })
            
            this.setState({
                customerJoined : customerJoined
            })

        }

        if(e.target.value==="Week"){

            let lastWeek = moment(dateTo).subtract(1, 'week')

            let customerJoined = 0;

            customers.map( ( data ) => {

                if(data.dateRegistered.seconds>=lastWeek.unix() && data.dateRegistered.seconds<=moment(dateTo).unix()){
                    customerJoined = customerJoined + 1;
                }

            })
            
            this.setState({
                customerJoined : customerJoined
            })

        }

    }

    /**
     * TRANSLATOR STATISTIC DATA FUNCTIONS
     */
    createTranslatorStatData = () => {

        const { userList } = this.props;
        const { fromYearFilter, toYearFilter } = this.state;
        const { dateFrom, dateTo, currentYear, enumeratedDates } = this.state;
        
        let translatorChartData = [];
        let translatorList =  userList.filter( data => data.type === "translator" );

        // Loop every year included from the range filter
        enumeratedDates.map( data => {

            let dataYear = "";

            if(translatorChartData.findIndex( cd => cd.year===moment(data).format("YYYY"))===-1){
                dataYear = moment(data).format("YYYY");

                let filteredTranslatorList = translatorList.filter( data => moment.unix(data.dateRegistered.seconds).format('YYYY')==dataYear)

                let filteredGroup = enumeratedDates.filter( (ed) => moment(ed).format("YYYY") === dataYear);

                translatorChartData.push({
                    year : dataYear,
                    year_data : [],
                    month_data : [],
                    week_data : []
                })  

                let thisYearIndex = translatorChartData.findIndex( y => y.year === dataYear )
                
                filteredGroup.map( fd => {
                    translatorChartData[thisYearIndex].year_data.push({
                        name : fd,
                        date : fd,
                        count : filteredTranslatorList.filter( p => moment.unix(p.dateRegistered.seconds).format("MM/DD/YYYY") === fd ).length
                    })

                    let thisMonth = moment(fd).format("MMM");

                    if(translatorChartData[thisYearIndex].month_data.findIndex( m => m.month_name === thisMonth )===-1){
                        translatorChartData[thisYearIndex].month_data.push({
                            month_name : thisMonth,
                            data : [],
                            day_count : moment(fd).daysInMonth()
                        })
                    }

                    let thisMonthIndex = translatorChartData[thisYearIndex].month_data.findIndex( m => m.month_name === thisMonth )

                    translatorChartData[thisYearIndex].month_data[thisMonthIndex].data.push({
                        name : fd,
                        date : fd,
                        count : filteredTranslatorList.filter( p => moment.unix(p.dateRegistered.seconds).format("MM/DD/YYYY") === fd ).length
                    })

                    let thisWeek = moment(fd).week();
                    if(thisWeek===1 && moment(fd).format("MMM")==="Dec"){
                        thisWeek = 53;
                    }

                    if(translatorChartData[thisYearIndex].week_data.findIndex( w => w.week === thisWeek )===-1){
                        translatorChartData[thisYearIndex].week_data.push({
                            week : thisWeek,
                            data : [],
                        })
                    }

                    let thisWeekIndex = translatorChartData[thisYearIndex].week_data.findIndex( w => w.week === thisWeek )

                    translatorChartData[thisYearIndex].week_data[thisWeekIndex].data.push({
                        name : fd,
                        date : fd,
                        count : filteredTranslatorList.filter( p => moment.unix(p.dateRegistered.seconds).format("MM/DD/YYYY") === fd ).length
                    })
                   
                })
            }
        })
        
    
        let lastYear = moment(dateTo).subtract(1, 'year')

        let translatorJoined = 0;

        translatorList.map( ( data ) => {
            if(data.dateRegistered.seconds>=lastYear.unix() && data.dateRegistered.seconds<=lastYear.unix()){
                translatorJoined = translatorJoined + 1;
            }

        })
            
        this.setState({
            translators : translatorList,
            translatorFilter : "Year",
            translatorDataYearIndex : translatorChartData.length-1,
            translatorActiveYear : translatorChartData[translatorChartData.findIndex( y => y.year == currentYear)].year,
            translatorStatData : translatorChartData,
            translatorStatDataDisplay : translatorChartData[translatorChartData.findIndex( y => y.year == currentYear)].year_data,
            translatorJoined : translatorJoined
        }, () =>{
            this.animateToRight('#translator-stat');
        })

    }

    filterTranslatorData = (e) => {

        const { translatorStatData, currentYear, currentMonth, currentWeek } = this.state;

        this.setState({
            translatorFilter : e.target.value
        })

        if(e.target.value==="Year"){
            this.setState({
                translatorDataYearIndex : translatorStatData.findIndex( y => y.year == currentYear),
                translatorActiveYear : translatorStatData[translatorStatData.findIndex( y => y.year == currentYear)].year,
                translatorStatDataDisplay : translatorStatData[translatorStatData.findIndex( y => y.year == currentYear)].year_data,
                translatorTick : this.state.yearTick,
                translatorInterval : 30
            })
        }

        if(e.target.value==="Month"){

            let totalDays = translatorStatData[0].month_data[0].day_count;
            let tick = [];

            for(let t=1; t<=totalDays; t++){
                tick.push(t)    
            }

            this.setState({
                translatorDataYearIndex : translatorStatData.findIndex( y => y.year == currentYear),
                translatorDataMonthIndex : translatorStatData[translatorStatData.findIndex( y => y.year == currentYear)].month_data.findIndex( m => m.month_name===currentMonth),
                translatorActiveYear : translatorStatData[translatorStatData.findIndex( y => y.year == currentYear)].year,
                translatorActiveMonth : translatorStatData[translatorStatData.findIndex( y => y.year == currentYear)].month_data.filter( m => m.month_name===currentMonth )[0].month_name, //translatorStatData[0].month_data[0].month_name,
                translatorStatDataDisplay : translatorStatData[translatorStatData.findIndex( y => y.year == currentYear)].month_data.filter( m => m.month_name===currentMonth )[0].data, //translatorStatData[0].month_data[0].data,
                translatorTick : tick,
                translatorInterval : 1
            })

        }

        if(e.target.value==="Week"){

            this.setState({
                translatorDataYearIndex : translatorStatData.findIndex( y => y.year == currentYear),
                translatorDataWeekIndex : translatorStatData[translatorStatData.findIndex( y => y.year == currentYear)].week_data.findIndex( m => m.week===currentWeek),
                translatorActiveYear :translatorStatData[translatorStatData.findIndex( y => y.year == currentYear)].year,
                translatorActiveWeek : translatorStatData[translatorStatData.findIndex( y => y.year == currentYear)].week_data.filter( m => m.week===currentWeek )[0].week, //translatorStatData[0].week_data[0].week,
                translatorStatDataDisplay : translatorStatData[translatorStatData.findIndex( y => y.year == currentYear)].week_data.filter( m => m.week===currentWeek )[0].data, //translatorStatData[0].week_data[0].data,
                translatorTick : this.state.weekTick,
                translatorInterval : 1
            })

        }
    }

    translatorTickFormatter = (tick) => {

        const { translatorFilter } = this.state;

        if(translatorFilter==="Year")
            return moment(new Date(tick)).format("MMM")
    
        if(translatorFilter==="Month")
            return moment(new Date(tick)).format("D")
    
        if(translatorFilter==="Week")
            return moment(new Date(tick)).format("ddd")
    }
    
    renderTranslatorInterval = (tick) =>{
        
        const { translatorFilter } = this.state;

        if(translatorFilter==="Year")
            return 30;
    
        if(translatorFilter==="Month")
            return 1;
    
        if(translatorFilter==="Week")
            return 1;
    }

    nextPageTranslatorData = () => {

        const { translatorStatData, translatorFilter, translatorDataYearIndex, translatorDataMonthIndex, translatorDataWeekIndex } = this.state;

        if(translatorFilter==="Year"){

            let newYearIndex = translatorDataYearIndex + 1;

            if(translatorStatData[newYearIndex]!==undefined){
                this.setState({
                    translatorDataYearIndex : newYearIndex,
                    translatorActiveYear : translatorStatData[newYearIndex].year,
                    translatorStatDataDisplay :  translatorStatData[newYearIndex].year_data
                })
            }

        }

        if(translatorFilter==="Month"){

            let newMonthIndex = translatorDataMonthIndex + 1;
            if(newMonthIndex===translatorStatData[this.state.translatorDataYearIndex].month_data.length){
                
                if(translatorStatData[this.state.translatorDataYearIndex+1]!==undefined){
                    this.setState({
                        translatorActiveYear : translatorStatData[this.state.translatorDataYearIndex+1].year,
                        translatorDataYearIndex : this.state.translatorDataYearIndex+1,
                        translatorDataMonthIndex : 0
                    }, () => {
    
                        const { translatorDataYearIndex, translatorDataMonthIndex } = this.state;
    
                        this.setState({
                            translatorActiveMonth : translatorStatData[translatorDataYearIndex].month_data[translatorDataMonthIndex].month_name,
                            translatorStatDataDisplay :  translatorStatData[translatorDataYearIndex].month_data[translatorDataMonthIndex].data
                        })
                    })
                }
            }else{

                const { translatorDataYearIndex } = this.state;

                if(translatorStatData[translatorDataYearIndex].month_data[newMonthIndex]!==undefined){
                    this.setState({
                        translatorDataMonthIndex :newMonthIndex,
                        translatorActiveMonth : translatorStatData[translatorDataYearIndex].month_data[newMonthIndex].month_name,
                        translatorStatDataDisplay :  translatorStatData[translatorDataYearIndex].month_data[newMonthIndex].data
                    })
                }
            }            
        }

        if(translatorFilter==="Week"){
          
            let newWeekIndex = translatorDataWeekIndex + 1;
            if(newWeekIndex===translatorStatData[this.state.translatorDataYearIndex].week_data.length){
                if(translatorStatData[this.state.translatorDataYearIndex+1]!==undefined){
                    this.setState({
                        translatorActiveYear : translatorStatData[this.state.translatorDataYearIndex+1].year,
                        translatorDataYearIndex : this.state.translatorDataYearIndex+1,
                        translatorDataWeekIndex : 0
                    }, () => {
    
                        const { translatorDataYearIndex, translatorDataWeekIndex } = this.state;
    
                        this.setState({
                            translatorActiveWeek : translatorStatData[translatorDataYearIndex].week_data[translatorDataWeekIndex].week,
                            translatorStatDataDisplay :  translatorStatData[translatorDataYearIndex].week_data[translatorDataWeekIndex].data
                        })
                    })
                }
            }else{
                const { translatorDataYearIndex } = this.state;

                if(translatorStatData[translatorDataYearIndex].week_data[newWeekIndex]!==undefined){
                    this.setState({
                        translatorDataWeekIndex :newWeekIndex,
                        translatorActiveWeek : translatorStatData[translatorDataYearIndex].week_data[newWeekIndex].week,
                        translatorStatDataDisplay :  translatorStatData[translatorDataYearIndex].week_data[newWeekIndex].data
                    })
                }
            }   

        }
    }

    previousPageTranslatorData = () => {

        const { translatorStatData, translatorFilter, translatorDataYearIndex, translatorDataMonthIndex, translatorDataWeekIndex } = this.state;

        if(translatorFilter==="Year"){
            
            if(translatorStatData[this.state.translatorDataYearIndex-1]!==undefined){
                this.setState({
                    translatorDataYearIndex : this.state.translatorDataYearIndex-1,
                    translatorActiveYear : translatorStatData[this.state.translatorDataYearIndex-1].year,
                    translatorStatDataDisplay :  translatorStatData[this.state.translatorDataYearIndex-1].year_data
                })
            }

        }

        if(translatorFilter==="Month"){
         
            let newMonthIndex = translatorDataMonthIndex - 1;
            if(newMonthIndex===-1){
                if(translatorStatData[this.state.translatorDataYearIndex-1]!==undefined){
                    this.setState({
                        translatorActiveYear : translatorStatData[this.state.translatorDataYearIndex-1].year,
                        translatorDataYearIndex : this.state.translatorDataYearIndex-1,
                        translatorDataMonthIndex : translatorStatData[this.state.translatorDataYearIndex-1].month_data.length - 1
                    }, () => {
    
                        const { translatorDataYearIndex, translatorDataMonthIndex } = this.state;
                        this.setState({
                            translatorActiveMonth : translatorStatData[translatorDataYearIndex].month_data[translatorDataMonthIndex].month_name,
                            translatorStatDataDisplay :  translatorStatData[translatorDataYearIndex].month_data[translatorDataMonthIndex].data
                        })
                    })
                }
            }else{

                const { translatorDataYearIndex } = this.state;

                if(translatorStatData[translatorDataYearIndex].month_data[newMonthIndex]!==undefined){
                    this.setState({
                        translatorDataMonthIndex :newMonthIndex,
                        translatorActiveMonth : translatorStatData[translatorDataYearIndex].month_data[newMonthIndex].month_name,
                        translatorStatDataDisplay :  translatorStatData[translatorDataYearIndex].month_data[newMonthIndex].data
                    })
                }
            }  

        }

        if(translatorFilter==="Week"){
            
            let newWeekIndex = translatorDataWeekIndex - 1;

            if(newWeekIndex===-1){
                
                if(translatorStatData[this.state.translatorDataYearIndex-1]!==undefined){
                    this.setState({
                        translatorActiveYear : translatorStatData[this.state.translatorDataYearIndex-1].year,
                        translatorDataYearIndex : this.state.translatorDataYearIndex-1,
                        translatorDataWeekIndex : translatorStatData[this.state.translatorDataYearIndex-1].week_data.length - 1
                    }, () => {
    
                        const { translatorDataYearIndex, translatorDataWeekIndex } = this.state;
                        
                        this.setState({
                            translatorActiveWeek : translatorStatData[translatorDataYearIndex].week_data[translatorDataWeekIndex].week,
                            translatorStatDataDisplay :  translatorStatData[translatorDataYearIndex].week_data[translatorDataWeekIndex].data
                        })
                    })
                }
            }else{

                const { translatorDataYearIndex } = this.state;

                if(translatorStatData[translatorDataYearIndex].week_data[newWeekIndex]!==undefined){
                    this.setState({
                        translatorDataWeekIndex :newWeekIndex,
                        translatorActiveWeek : translatorStatData[translatorDataYearIndex].week_data[newWeekIndex].week,
                        translatorStatDataDisplay :  translatorStatData[translatorDataYearIndex].week_data[newWeekIndex].data
                    })
                }
            }   

        }

    }

    filterTranslatorJoined = (e) => {

        const { translators, dateTo } = this.state;

        if(e.target.value==="Year"){

            let lastYear = moment(dateTo).subtract(1, 'year')

            let translatorJoined = 0;

            translators.map( ( data ) => {
                if(data.dateRegistered.seconds>=lastYear.unix() && data.dateRegistered.seconds<=moment(dateTo).unix()){
                    translatorJoined = translatorJoined + 1;
                }
            })
            
            this.setState({
                translatorJoined : translatorJoined
            })

        }

        if(e.target.value==="Month"){

            let lastMonth = moment(dateTo).subtract(1, 'month')

            let translatorJoined = 0;

            translators.map( ( data ) => {
                if(data.dateRegistered.seconds>=lastMonth.unix() && data.dateRegistered.seconds<=moment(dateTo).unix()){
                    translatorJoined = translatorJoined + 1;
                }

            })
            
            this.setState({
                translatorJoined : translatorJoined
            })

        }

        if(e.target.value==="Week"){

            let lastWeek = moment(dateTo).subtract(1, 'week')

            let translatorJoined = 0;

            translators.map( ( data ) => {

                if(data.dateRegistered.seconds>=lastWeek.unix() && data.dateRegistered.seconds<=moment(dateTo).unix()){
                    translatorJoined = translatorJoined + 1;
                }

            })
            
            this.setState({
                translatorJoined : translatorJoined
            })

        }

    }

    /**
     * REQUEST STATISTIC DATA FUNCTIONS
     */
    createRequestStatData = (userType="customer", taskType="essay") => {
    
        const { requestList } = this.props;
        const { dateFrom, dateTo, currentYear, enumeratedDates } = this.state;

        const { dateRangeYearGap } = this.state;
        
        let startingYear = moment(dateFrom).year();
        let requestChartData = [];

        let filteredRequestList = [];

        requestList.map( ( data ) => {
            data.escalators.map( eData => {
                filteredRequestList.push({
                    user_type : eData.user.type,
                    task_type : data.task.taskType,
                    dateAdded : data.escalation.dateAdded
                })
            })
        })

        // Loop every year included from the range filter
        enumeratedDates.map( data => {

            let dataYear = "";

            if(requestChartData.findIndex( cd => cd.year===moment(data).format("YYYY"))===-1){
                dataYear = moment(data).format("YYYY");

                let filteredRequests = filteredRequestList.filter(data=> data.user_type===userType).filter(data=>data.task_type===taskType).filter( data => moment.unix(data.dateAdded.seconds).format('YYYY')==currentYear)

                let filteredGroup = enumeratedDates.filter( (ed) => moment(ed).format("YYYY") === dataYear);

                requestChartData.push({
                    year : dataYear,
                    year_data : [],
                    month_data : [],
                    week_data : []
                })  

                let thisYearIndex = requestChartData.findIndex( y => y.year === dataYear )
                
                filteredGroup.map( fd => {
                    requestChartData[thisYearIndex].year_data.push({
                        name : fd,
                        date : fd,
                        count : filteredRequests.filter( p => moment.unix(p.dateAdded.seconds).format("MM/DD/YYYY") === fd ).length
                    })

                    let thisMonth = moment(fd).format("MMM");

                    if(requestChartData[thisYearIndex].month_data.findIndex( m => m.month_name === thisMonth )===-1){
                        requestChartData[thisYearIndex].month_data.push({
                            month_name : thisMonth,
                            data : [],
                            day_count : moment(fd).daysInMonth()
                        })
                    }

                    let thisMonthIndex = requestChartData[thisYearIndex].month_data.findIndex( m => m.month_name === thisMonth )

                    requestChartData[thisYearIndex].month_data[thisMonthIndex].data.push({
                        name : fd,
                        date : fd,
                        count : filteredRequests.filter( p => moment.unix(p.dateAdded.seconds).format("MM/DD/YYYY") === fd ).length
                    })

                    let thisWeek = moment(fd).week();
                    if(thisWeek===1 && moment(fd).format("MMM")==="Dec"){
                        thisWeek = 53;
                    }

                    if(requestChartData[thisYearIndex].week_data.findIndex( w => w.week === thisWeek )===-1){
                        requestChartData[thisYearIndex].week_data.push({
                            week : thisWeek,
                            data : [],
                        })
                    }

                    let thisWeekIndex = requestChartData[thisYearIndex].week_data.findIndex( w => w.week === thisWeek )

                    requestChartData[thisYearIndex].week_data[thisWeekIndex].data.push({
                        name : fd,
                        date : fd,
                        count : filteredRequests.filter( p => moment.unix(p.dateAdded.seconds).format("MM/DD/YYYY") === fd ).length
                    })
                   
                })
            }
        })
        
        
        this.setState({
            requestFilter : "Year",
            requestTick : this.state.yearTick,
            requestDataYearIndex : requestChartData.length-1,
            requestActiveYear : requestChartData[requestChartData.findIndex( y => y.year == currentYear)].year,
            requestStatData : requestChartData,
            requestStatDataDisplay : requestChartData[requestChartData.findIndex( y => y.year == currentYear)].year_data
        }, () =>{
            this.animateToRight('#requests-stat');
        })

    }

    filterRequestData = (e) => {

        const { requestStatData, currentYear, currentMonth, currentWeek } = this.state;

        this.setState({
            requestFilter : e.target.value
        })

        if(e.target.value==="Year"){
            this.setState({
                requestDataYearIndex : requestStatData.findIndex( y => y.year == currentYear),
                requestActiveYear : requestStatData[requestStatData.findIndex( y => y.year == currentYear)].year,
                requestStatDataDisplay : requestStatData[requestStatData.findIndex( y => y.year == currentYear)].year_data,
                requestTick : this.state.yearTick,
                requestInterval : 30,
            })
        }

        if(e.target.value==="Month"){

            let totalDays = requestStatData[0].month_data[0].day_count;
            let tick = [];

            for(let t=1; t<=totalDays; t++){
                tick.push(t)    
            }

            this.setState({
                requestDataYearIndex : requestStatData.findIndex( y => y.year == currentYear),
                requestDataMonthIndex : requestStatData[requestStatData.findIndex( y => y.year == currentYear)].month_data.findIndex( m => m.month_name===currentMonth),
                requestActiveYear : requestStatData[requestStatData.findIndex( y => y.year == currentYear)].year,
                requestActiveMonth : requestStatData[requestStatData.findIndex( y => y.year == currentYear)].month_data.filter( m => m.month_name===currentMonth )[0].month_name, //requestStatData[0].month_data[0].month_name,
                requestStatDataDisplay : requestStatData[requestStatData.findIndex( y => y.year == currentYear)].month_data.filter( m => m.month_name===currentMonth )[0].data, //requestStatData[0].month_data[0].data,
                requestTick : tick,
                requestInterval : 1,
            })

        }

        if(e.target.value==="Week"){

            this.setState({
                requestDataYearIndex : requestStatData.findIndex( y => y.year == currentYear),
                requestDataWeekIndex : requestStatData[requestStatData.findIndex( y => y.year == currentYear)].week_data.findIndex( m => m.week===currentWeek),
                requestActiveYear :requestStatData[requestStatData.findIndex( y => y.year == currentYear)].year,
                requestActiveWeek : requestStatData[requestStatData.findIndex( y => y.year == currentYear)].week_data.filter( m => m.week===currentWeek )[0].week, //requestStatData[0].week_data[0].week,
                requestStatDataDisplay : requestStatData[requestStatData.findIndex( y => y.year == currentYear)].week_data.filter( m => m.week===currentWeek )[0].data, //requestStatData[0].week_data[0].data,
                requestTick : this.state.weekTick,
                requestInterval : 1,
            })

        }
    }

    requestTickFormatter = (tick) => {

        const { requestFilter } = this.state;

        if(requestFilter==="Year")
            return moment(new Date(tick)).format("MMM")
    
        if(requestFilter==="Month")
            return moment(new Date(tick)).format("D")
    
        if(requestFilter==="Week")
            return moment(new Date(tick)).format("ddd")
    }

    nextPageRequestData = () => {

        const { requestStatData, requestFilter, requestDataYearIndex, requestDataMonthIndex, requestDataWeekIndex } = this.state;

        if(requestFilter==="Year"){

            let newYearIndex = requestDataYearIndex + 1;

            if(requestStatData[newYearIndex]!==undefined){
                this.setState({
                    requestDataYearIndex : newYearIndex,
                    requestActiveYear : requestStatData[newYearIndex].year,
                    requestStatDataDisplay :  requestStatData[newYearIndex].year_data
                })
            }

        }

        if(requestFilter==="Month"){

            let newMonthIndex = requestDataMonthIndex + 1;
            if(newMonthIndex===requestStatData[this.state.requestDataYearIndex].month_data.length){
                
                if(requestStatData[this.state.requestDataYearIndex+1]!==undefined){
                    this.setState({
                        requestActiveYear : requestStatData[this.state.requestDataYearIndex+1].year,
                        requestDataYearIndex : this.state.requestDataYearIndex+1,
                        requestDataMonthIndex : 0
                    }, () => {
    
                        const { requestDataYearIndex, requestDataMonthIndex } = this.state;
    
                        this.setState({
                            requestActiveMonth : requestStatData[requestDataYearIndex].month_data[requestDataMonthIndex].month_name,
                            requestStatDataDisplay :  requestStatData[requestDataYearIndex].month_data[requestDataMonthIndex].data
                        })
                    })
                }
            }else{

                const { requestDataYearIndex } = this.state;

                if(requestStatData[requestDataYearIndex].month_data[newMonthIndex]!==undefined){
                    this.setState({
                        requestDataMonthIndex :newMonthIndex,
                        requestActiveMonth : requestStatData[requestDataYearIndex].month_data[newMonthIndex].month_name,
                        requestStatDataDisplay :  requestStatData[requestDataYearIndex].month_data[newMonthIndex].data
                    })
                }
            }            
        }

        if(requestFilter==="Week"){
          
            let newWeekIndex = requestDataWeekIndex + 1;
            if(newWeekIndex===requestStatData[this.state.requestDataYearIndex].week_data.length){
                if(requestStatData[this.state.requestDataYearIndex+1]!==undefined){
                    this.setState({
                        requestActiveYear : requestStatData[this.state.requestDataYearIndex+1].year,
                        requestDataYearIndex : this.state.requestDataYearIndex+1,
                        requestDataWeekIndex : 0
                    }, () => {
    
                        const { requestDataYearIndex, requestDataWeekIndex } = this.state;
    
                        this.setState({
                            requestActiveWeek : requestStatData[requestDataYearIndex].week_data[requestDataWeekIndex].week,
                            requestStatDataDisplay :  requestStatData[requestDataYearIndex].week_data[requestDataWeekIndex].data
                        })
                    })
                }
            }else{
                const { requestDataYearIndex } = this.state;

                if(requestStatData[requestDataYearIndex].week_data[newWeekIndex]!==undefined){
                    this.setState({
                        requestDataWeekIndex :newWeekIndex,
                        requestActiveWeek : requestStatData[requestDataYearIndex].week_data[newWeekIndex].week,
                        requestStatDataDisplay :  requestStatData[requestDataYearIndex].week_data[newWeekIndex].data
                    })
                }
            }   

        }
    }

    previousPageRequestData = () => {

        const { requestStatData, requestFilter, requestDataYearIndex, requestDataMonthIndex, requestDataWeekIndex } = this.state;

        if(requestFilter==="Year"){
            
            if(requestStatData[this.state.requestDataYearIndex-1]!==undefined){
                this.setState({
                    requestDataYearIndex : this.state.requestDataYearIndex-1,
                    requestActiveYear : requestStatData[this.state.requestDataYearIndex-1].year,
                    requestStatDataDisplay :  requestStatData[this.state.requestDataYearIndex-1].year_data
                })
            }

        }

        if(requestFilter==="Month"){
         
            let newMonthIndex = requestDataMonthIndex - 1;
            if(newMonthIndex===-1){
                if(requestStatData[this.state.requestDataYearIndex-1]!==undefined){
                    this.setState({
                        requestActiveYear : requestStatData[this.state.requestDataYearIndex-1].year,
                        requestDataYearIndex : this.state.requestDataYearIndex-1,
                        requestDataMonthIndex : requestStatData[this.state.requestDataYearIndex-1].month_data.length - 1
                    }, () => {
    
                        const { requestDataYearIndex, requestDataMonthIndex } = this.state;
                        this.setState({
                            requestActiveMonth : requestStatData[requestDataYearIndex].month_data[requestDataMonthIndex].month_name,
                            requestStatDataDisplay :  requestStatData[requestDataYearIndex].month_data[requestDataMonthIndex].data
                        })
                    })
                }
            }else{

                const { requestDataYearIndex } = this.state;

                if(requestStatData[requestDataYearIndex].month_data[newMonthIndex]!==undefined){
                    this.setState({
                        requestDataMonthIndex :newMonthIndex,
                        requestActiveMonth : requestStatData[requestDataYearIndex].month_data[newMonthIndex].month_name,
                        requestStatDataDisplay :  requestStatData[requestDataYearIndex].month_data[newMonthIndex].data
                    })
                }
            }  

        }

        if(requestFilter==="Week"){
            
            let newWeekIndex = requestDataWeekIndex - 1;

            if(newWeekIndex===-1){
                
                if(requestStatData[this.state.requestDataYearIndex-1]!==undefined){
                    this.setState({
                        requestActiveYear : requestStatData[this.state.requestDataYearIndex-1].year,
                        requestDataYearIndex : this.state.requestDataYearIndex-1,
                        requestDataWeekIndex : requestStatData[this.state.requestDataYearIndex-1].week_data.length - 1
                    }, () => {
    
                        const { requestDataYearIndex, requestDataWeekIndex } = this.state;
                        
                        this.setState({
                            requestActiveWeek : requestStatData[requestDataYearIndex].week_data[requestDataWeekIndex].week,
                            requestStatDataDisplay :  requestStatData[requestDataYearIndex].week_data[requestDataWeekIndex].data
                        })
                    })
                }
            }else{

                const { requestDataYearIndex } = this.state;

                if(requestStatData[requestDataYearIndex].week_data[newWeekIndex]!==undefined){
                    this.setState({
                        requestDataWeekIndex :newWeekIndex,
                        requestActiveWeek : requestStatData[requestDataYearIndex].week_data[newWeekIndex].week,
                        requestStatDataDisplay :  requestStatData[requestDataYearIndex].week_data[newWeekIndex].data
                    })
                }
            }   

        }

    }

    filterRequestUserType = (e) => {

        this.setState({
            requestUserTypeFilter : e.target.value
        }, () => {

            const { requestTaskTypeFilter, requestUserTypeFilter } = this.state;
            this.createRequestStatData(requestUserTypeFilter, requestTaskTypeFilter)

        })

    } 

    filterRequestTaskType = (e) => {

        this.setState({
            requestTaskTypeFilter : e.target.value
        }, () => {

            const { requestTaskTypeFilter, requestUserTypeFilter } = this.state;
            this.createRequestStatData(requestUserTypeFilter, requestTaskTypeFilter)

        })

    }   


    /**
     * EARNINGS STATISTIC DATA FUNCTIONS
     */
    createEarningStatData = () => {

        const { taskList } = this.props;

        const { dateFrom, dateTo, currentYear, enumeratedDates } = this.state;

        const { dateRangeYearGap } = this.state;
        
        let startingYear = moment(dateFrom).year();
        let earningChartData = [];
        let filteredEarningList = [];

        taskList.map( ( data ) => {
            if(data.isPaidToTranslator){
                filteredEarningList.push({
                    dateAdded : data.datePaid,
                    amount : data.translatorEarnings
                })
            }
        })

        // Loop every year included from the range filter
        enumeratedDates.map( data => {

            let dataYear = "";

            if(earningChartData.findIndex( cd => cd.year===moment(data).format("YYYY"))===-1){
                dataYear = moment(data).format("YYYY");

                let filteredEarnings = filteredEarningList.filter( data => moment.unix(data.dateAdded.seconds).format('YYYY')==dataYear)

                let filteredGroup = enumeratedDates.filter( (ed) => moment(ed).format("YYYY") === dataYear);

                earningChartData.push({
                    year : dataYear,
                    year_data : [],
                    month_data : [],
                    week_data : []
                })  

                let thisYearIndex = earningChartData.findIndex( y => y.year === dataYear )
                
                filteredGroup.map( fd => {

                    let filteredForAmount = filteredEarnings.filter( p => moment.unix(p.dateAdded.seconds).format("MM/DD/YYYY") === fd )
                    let total = 0;
                    filteredForAmount.map( (data) => {
                        total = total + data.amount
                    })
                    earningChartData[thisYearIndex].year_data.push({
                        name : fd,
                        date : fd,
                        amount : parseFloat(total.toFixed(2))
                    })

                    let thisMonth = moment(fd).format("MMM");

                    if(earningChartData[thisYearIndex].month_data.findIndex( m => m.month_name === thisMonth )===-1){
                        earningChartData[thisYearIndex].month_data.push({
                            month_name : thisMonth,
                            data : [],
                            day_count : moment(fd).daysInMonth()
                        })
                    }

                    let thisMonthIndex = earningChartData[thisYearIndex].month_data.findIndex( m => m.month_name === thisMonth )

                  
                
                    earningChartData[thisYearIndex].month_data[thisMonthIndex].data.push({
                        name : fd,
                        date : fd,
                        amount : parseFloat(total.toFixed(2))
                    })

                    let thisWeek = moment(fd).week();
                    if(thisWeek===1 && moment(fd).format("MMM")==="Dec"){
                        thisWeek = 53;
                    }

                    if(earningChartData[thisYearIndex].week_data.findIndex( w => w.week === thisWeek )===-1){
                        earningChartData[thisYearIndex].week_data.push({
                            week : thisWeek,
                            data : [],
                        })
                    }

                    let thisWeekIndex = earningChartData[thisYearIndex].week_data.findIndex( w => w.week === thisWeek )
                    
                    earningChartData[thisYearIndex].week_data[thisWeekIndex].data.push({
                        name : fd,
                        date : fd,
                        amount : parseFloat(total.toFixed(2))
                    })
                   
                })
            }
        })
        
        this.setState({
            earningFilter : "Year",
            earningTick : this.state.yearTick,
            earningDataYearIndex : earningChartData.length-1,
            earningActiveYear : earningChartData[earningChartData.findIndex( y => y.year == currentYear)].year,
            earningStatData : earningChartData,
            earningStatDataDisplay : earningChartData[earningChartData.findIndex( y => y.year == currentYear)].year_data
        }, () =>{
            this.animateToRight('#earnings-stat');
        })
    }

    filterEarningData = (e) => {

        const { earningStatData, currentYear, currentMonth, currentWeek } = this.state;

        this.setState({
            earningFilter : e.target.value
        })

        if(e.target.value==="Year"){
            this.setState({
                earningDataYearIndex : earningStatData.findIndex( y => y.year == currentYear),
                earningActiveYear : earningStatData[earningStatData.findIndex( y => y.year == currentYear)].year,
                earningStatDataDisplay : earningStatData[earningStatData.findIndex( y => y.year == currentYear)].year_data,
                earningTick : this.state.yearTick,
                earningInterval : 30,
            })
        }

        if(e.target.value==="Month"){

            let totalDays = earningStatData[0].month_data[0].day_count;
            let tick = [];

            for(let t=1; t<=totalDays; t++){
                tick.push(t)    
            }

            this.setState({
                earningDataYearIndex : earningStatData.findIndex( y => y.year == currentYear),
                earningDataMonthIndex : earningStatData[earningStatData.findIndex( y => y.year == currentYear)].month_data.findIndex( m => m.month_name===currentMonth),
                earningActiveYear : earningStatData[earningStatData.findIndex( y => y.year == currentYear)].year,
                earningActiveMonth : earningStatData[earningStatData.findIndex( y => y.year == currentYear)].month_data.filter( m => m.month_name===currentMonth )[0].month_name, //earningStatData[0].month_data[0].month_name,
                earningStatDataDisplay : earningStatData[earningStatData.findIndex( y => y.year == currentYear)].month_data.filter( m => m.month_name===currentMonth )[0].data, //earningStatData[0].month_data[0].data,
                earningTick : tick,
                earningInterval : 1,
            })

        }

        if(e.target.value==="Week"){

            this.setState({
                earningDataYearIndex : earningStatData.findIndex( y => y.year == currentYear),
                earningDataWeekIndex : earningStatData[earningStatData.findIndex( y => y.year == currentYear)].week_data.findIndex( m => m.week===currentWeek),
                earningActiveYear :earningStatData[earningStatData.findIndex( y => y.year == currentYear)].year,
                earningActiveWeek : earningStatData[earningStatData.findIndex( y => y.year == currentYear)].week_data.filter( m => m.week===currentWeek )[0].week, //earningStatData[0].week_data[0].week,
                earningStatDataDisplay : earningStatData[earningStatData.findIndex( y => y.year == currentYear)].week_data.filter( m => m.week===currentWeek )[0].data, //earningStatData[0].week_data[0].data,
                earningTick : this.state.weekTick,
                earningInterval : 1,
            })

        }
    }

    earningTickFormatter = (tick) => {

        const { earningFilter } = this.state;

        if(earningFilter==="Year")
            return moment(new Date(tick)).format("MMM")
    
        if(earningFilter==="Month")
            return moment(new Date(tick)).format("D")
    
        if(earningFilter==="Week")
            return moment(new Date(tick)).format("ddd")
    }

    nextPageEarningData = () => {

        const { earningStatData, earningFilter, earningDataYearIndex, earningDataMonthIndex, earningDataWeekIndex } = this.state;

        if(earningFilter==="Year"){

            let newYearIndex = earningDataYearIndex + 1;

            if(earningStatData[newYearIndex]!==undefined){
                this.setState({
                    earningDataYearIndex : newYearIndex,
                    earningActiveYear : earningStatData[newYearIndex].year,
                    earningStatDataDisplay :  earningStatData[newYearIndex].year_data
                })
            }

        }

        if(earningFilter==="Month"){

            let newMonthIndex = earningDataMonthIndex + 1;
            if(newMonthIndex===earningStatData[this.state.earningDataYearIndex].month_data.length){
                
                if(earningStatData[this.state.earningDataYearIndex+1]!==undefined){
                    this.setState({
                        earningActiveYear : earningStatData[this.state.earningDataYearIndex+1].year,
                        earningDataYearIndex : this.state.earningDataYearIndex+1,
                        earningDataMonthIndex : 0
                    }, () => {
    
                        const { earningDataYearIndex, earningDataMonthIndex } = this.state;
    
                        this.setState({
                            earningActiveMonth : earningStatData[earningDataYearIndex].month_data[earningDataMonthIndex].month_name,
                            earningStatDataDisplay :  earningStatData[earningDataYearIndex].month_data[earningDataMonthIndex].data
                        })
                    })
                }
            }else{

                const { earningDataYearIndex } = this.state;

                if(earningStatData[earningDataYearIndex].month_data[newMonthIndex]!==undefined){
                    this.setState({
                        earningDataMonthIndex :newMonthIndex,
                        earningActiveMonth : earningStatData[earningDataYearIndex].month_data[newMonthIndex].month_name,
                        earningStatDataDisplay :  earningStatData[earningDataYearIndex].month_data[newMonthIndex].data
                    })
                }
            }            
        }

        if(earningFilter==="Week"){
          
            let newWeekIndex = earningDataWeekIndex + 1;
            if(newWeekIndex===earningStatData[this.state.earningDataYearIndex].week_data.length){
                if(earningStatData[this.state.earningDataYearIndex+1]!==undefined){
                    this.setState({
                        earningActiveYear : earningStatData[this.state.earningDataYearIndex+1].year,
                        earningDataYearIndex : this.state.earningDataYearIndex+1,
                        earningDataWeekIndex : 0
                    }, () => {
    
                        const { earningDataYearIndex, earningDataWeekIndex } = this.state;
    
                        this.setState({
                            earningActiveWeek : earningStatData[earningDataYearIndex].week_data[earningDataWeekIndex].week,
                            earningStatDataDisplay :  earningStatData[earningDataYearIndex].week_data[earningDataWeekIndex].data
                        })
                    })
                }
            }else{
                const { earningDataYearIndex } = this.state;

                if(earningStatData[earningDataYearIndex].week_data[newWeekIndex]!==undefined){
                    this.setState({
                        earningDataWeekIndex :newWeekIndex,
                        earningActiveWeek : earningStatData[earningDataYearIndex].week_data[newWeekIndex].week,
                        earningStatDataDisplay :  earningStatData[earningDataYearIndex].week_data[newWeekIndex].data
                    })
                }
            }   

        }
    }

    previousPageEarningData = () => {

        const { earningStatData, earningFilter, earningDataYearIndex, earningDataMonthIndex, earningDataWeekIndex } = this.state;

        if(earningFilter==="Year"){
            
            if(earningStatData[this.state.earningDataYearIndex-1]!==undefined){
                this.setState({
                    earningDataYearIndex : this.state.earningDataYearIndex-1,
                    earningActiveYear : earningStatData[this.state.earningDataYearIndex-1].year,
                    earningStatDataDisplay :  earningStatData[this.state.earningDataYearIndex-1].year_data
                })
            }

        }

        if(earningFilter==="Month"){
         
            let newMonthIndex = earningDataMonthIndex - 1;
            if(newMonthIndex===-1){
                if(earningStatData[this.state.earningDataYearIndex-1]!==undefined){
                    this.setState({
                        earningActiveYear : earningStatData[this.state.earningDataYearIndex-1].year,
                        earningDataYearIndex : this.state.earningDataYearIndex-1,
                        earningDataMonthIndex : earningStatData[this.state.earningDataYearIndex-1].month_data.length - 1
                    }, () => {
    
                        const { earningDataYearIndex, earningDataMonthIndex } = this.state;
                        this.setState({
                            earningActiveMonth : earningStatData[earningDataYearIndex].month_data[earningDataMonthIndex].month_name,
                            earningStatDataDisplay :  earningStatData[earningDataYearIndex].month_data[earningDataMonthIndex].data
                        })
                    })
                }
            }else{

                const { earningDataYearIndex } = this.state;

                if(earningStatData[earningDataYearIndex].month_data[newMonthIndex]!==undefined){
                    this.setState({
                        earningDataMonthIndex :newMonthIndex,
                        earningActiveMonth : earningStatData[earningDataYearIndex].month_data[newMonthIndex].month_name,
                        earningStatDataDisplay :  earningStatData[earningDataYearIndex].month_data[newMonthIndex].data
                    })
                }
            }  

        }

        if(earningFilter==="Week"){
            
            let newWeekIndex = earningDataWeekIndex - 1;

            if(newWeekIndex===-1){
                
                if(earningStatData[this.state.earningDataYearIndex-1]!==undefined){
                    this.setState({
                        earningActiveYear : earningStatData[this.state.earningDataYearIndex-1].year,
                        earningDataYearIndex : this.state.earningDataYearIndex-1,
                        earningDataWeekIndex : earningStatData[this.state.earningDataYearIndex-1].week_data.length - 1
                    }, () => {
    
                        const { earningDataYearIndex, earningDataWeekIndex } = this.state;
                        
                        this.setState({
                            earningActiveWeek : earningStatData[earningDataYearIndex].week_data[earningDataWeekIndex].week,
                            earningStatDataDisplay :  earningStatData[earningDataYearIndex].week_data[earningDataWeekIndex].data
                        })
                    })
                }
            }else{

                const { earningDataYearIndex } = this.state;

                if(earningStatData[earningDataYearIndex].week_data[newWeekIndex]!==undefined){
                    this.setState({
                        earningDataWeekIndex :newWeekIndex,
                        earningActiveWeek : earningStatData[earningDataYearIndex].week_data[newWeekIndex].week,
                        earningStatDataDisplay :  earningStatData[earningDataYearIndex].week_data[newWeekIndex].data
                    })
                }
            }   

        }

    }


    /**
     * TASK STATUS FUNCTIONS
     */

    createStatusStatChart = () => {

        const { taskList } = this.props;

        let statCompleted = taskList.filter( data => data.status === "completed" && !data.isArchived )
        let statInprogress = taskList.filter( data => data.status === "inProgress" || data.status==="forReview" && !data.isArchived )
        let statPending = taskList.filter( data => data.status === "new" && !data.isArchived )
        let statArchived = taskList.filter( data => data.isArchived )

        let newTaskStatusStatisticData = [];
        newTaskStatusStatisticData.push({
            name : 'Completed',
            value : parseFloat(((statCompleted.length/taskList.length) * 100).toFixed(2))
        })
        newTaskStatusStatisticData.push({
            name : 'Completed',
            value : parseFloat(((statInprogress.length/taskList.length) * 100).toFixed(2))
        })
        newTaskStatusStatisticData.push({
            name : 'Completed',
            value : parseFloat(((statPending.length/taskList.length) * 100).toFixed(2))
        })
        newTaskStatusStatisticData.push({
            name : 'Completed',
            value : parseFloat(((statArchived.length/taskList.length) * 100).toFixed(2))
        })

        this.setState({
            tasksCompleted : statCompleted,
            tasksInProgress : statInprogress,
            tasksPending : statPending,
            tasksArchived : statArchived,
            taskStatusStatisticData : newTaskStatusStatisticData
        })
    }

    
    /**
     * DATE RANGE FILTER FUNCTIONS
     */
    onDateFromChange = (date) => {
        if(date!==undefined){
            this.setState({
                dateFrom : date
            })
        }
    }

    onDateToChange = (date) =>{
        if(date!==undefined){
            this.setState({
                dateTo : date
            })
        }
    }

    applyPeriod = (period) => {

        let previousDays = moment().subtract(period, 'days')

        this.setState({
            dateFrom : new Date(previousDays)
        })

    }

    applyFilter = () => {
        const { dateFrom, dateTo } = this.state;

        let fromYear = moment(dateFrom).year();
        let toYear = moment(dateTo).year();

        this.setState({
            dateRangeYearGap : toYear - fromYear
        })

        if(dateFrom!==null && dateTo!==null){
            this.props.setReportDateFilter({
                dateFrom: dateFrom,
                dateTo : dateTo
            })
            let dateRange = enumerateDaysBetweenDates(dateFrom, dateTo)

            this.setState({
                enumeratedDates : dateRange
            } , () => {
                this.props.getTasksForStatistics();
                this.props.getRequestStatistics();
                this.props.getUserStatistics();
                this.props.getEarningStatistics();
            })
            
        }

        if(dateFrom===null && dateTo===null){
            this.props.resetOutcomeReportsFilter();
            let dateRange = enumerateDaysBetweenDates(dateFrom, dateTo)

            this.setState({
                enumeratedDates : dateRange
            } , () => {
                this.props.getTasksForStatistics();
                this.props.getRequestStatistics();
                this.props.getUserStatistics();
                this.props.getEarningStatistics();
            })
        }
    }

    animateToRight(el) {
        var scrollWidth = $(el).get(0).scrollWidth;
        var clientWidth = $(el).get(0).clientWidth;
        $(el).animate({ scrollLeft : scrollWidth - clientWidth})
    }

    render () {

        const { taskList, auth, isLoggingOut, userProfile } = this.props;

        const { taskStatusStatisticData, tasksCompleted, tasksInProgress, tasksPending, tasksArchived } = this.state; 
        
        const { translatorJoined, translatorStat, translatorChartData, translatorChartIndex, translatorStatWeekGroup } = this.state;
        const { translatorFilterYear, translatorFilterMonth, translatorFilterWeek } = this.state;

        const { taskTypeInterval, taskTypeFilter, taskTypeStatDataDisplay, taskTypeTick, showEssayTasks, showPresentationTasks, showDissertationTasks, isSelectingAll, taskCategories, taskTypeActiveYear, taskTypeActiveMonth, taskTypeActiveWeek } = this.state;
        
        const { customerInterval, customerJoined, customers, customerStatDataDisplay, customerTick, customerFilter, customerActiveYear, customerActiveMonth, customerActiveWeek } = this.state;
        const { translatorInterval, translators, translatorStatDataDisplay, translatorTick, translatorFilter, translatorActiveYear, translatorActiveMonth, translatorActiveWeek } = this.state;
        const { requestInterval, requestStatDataDisplay, requestTick, requestUserTypeFilter, requestTaskTypeFilter, requestFilter, requestActiveYear, requestActiveMonth, requestActiveWeek } = this.state;
        const { earningInterval, earningStatDataDisplay, earningTick, earningFilter, earningActiveYear, earningActiveMonth, earningActiveWeek } = this.state;

        const { yearTick } = this.state;

        const { dateFrom, dateTo } = this.state;
        const { dateRangeYearGap } = this.state;

        const { isAuthenticate } = this.state;

        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }

        if(!auth.uid){
            return <Redirect to="/" />
        }


        return (
            <div className="dashboard">
                <div style={{ display : "flex" }}>
                    <p style={{ maxWidth : "30%", flex : "1" }} className="module-header">Dashboard</p>
                    <DateFilter applyFilter={this.applyFilter} applyPeriod={this.applyPeriod}  onDateToChange={this.onDateToChange} onDateFromChange={this.onDateFromChange} dateTo={dateTo} dateFrom={dateFrom} />
                </div>
                <div className="stat-row">
                    <div className="stat-box" id="status-task-stat">
                        <p className="stat-box-header">Statistic status</p>
                        <div className="stat-chart-area" style={{ position : "relative"}}>
                            <div className="task-stat-total">
                                <span className="task-stat-total-header">{ taskList.length }</span>
                                <span className="task-stat-total-sub-header">Total</span>
                            </div>
                            <div style={{ width: '95%', height: 400 }}>
                                <ResponsiveContainer>
                                    <PieChart height={400}>
                                        <Pie
                                            data={taskStatusStatisticData}
                                            innerRadius={130}
                                            outerRadius={150}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                        <Tooltip content={renderCustomToolTip}/>
                                        {
                                            taskStatusStatisticData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                        }
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className="stat-percentage">
                            <div className="stat-percentage-details">
                                <span className="stat-percentage-text"><img src={require('../../images/icon-completed.png')} style={{ width: "10px", height : "10px", marginRight : "10px" }}/>Completed</span>
                                <span className="stat-percentage-value">{ taskList && ((tasksCompleted.length/taskList.length) * 100).toFixed(2)}%</span>
                            </div>
                            <div className="stat-percentage-details">
                                <span className="stat-percentage-text"><img src={require('../../images/icon-inprogress.png')} style={{ width: "10px", height : "10px", marginRight : "10px" }}/>In Progress</span>
                                <span className="stat-percentage-value">{ taskList && ((tasksInProgress.length/taskList.length) * 100).toFixed(2)}%</span>
                            </div>
                            <div className="stat-percentage-details">
                                <span className="stat-percentage-text"><img src={require('../../images/icon-archived.png')} style={{ width: "10px", height : "10px", marginRight : "10px" }}/>Pending</span>
                                <span className="stat-percentage-value">{ taskList && ((tasksPending.length/taskList.length) * 100).toFixed(2)}%</span>
                            </div>
                            <div className="stat-percentage-details">
                                <span className="stat-percentage-text"><img src={require('../../images/icon-review.png')} style={{ width: "10px", height : "10px", marginRight : "10px" }}/>Archive</span>
                                <span className="stat-percentage-value">{ taskList && ((tasksArchived.length/taskList.length) * 100).toFixed(2)}%</span>
                            </div>
                        </div>
                    </div>
                    <div className="stat-box" id="status-task-stat">
                        <div style={{ display : "flex" }}>
                            <p className="stat-box-header">Task's statistic</p>
                            <div style={{ flex : "1", display : "flex" }}>
                                <label className="checkbox-container dashboard-checkbox-container">Presentation
                                    <input checked={showPresentationTasks} onClick={this.selectTaskTypes} name="task_type" className="task-type" type="checkbox"/>
                                    <span className="checkmark presentation-checkmark"></span>
                                </label>
                                <label className="checkbox-container dashboard-checkbox-container">Essay
                                    <input checked={showEssayTasks} onClick={this.selectTaskTypes} name="task_type" className="task-type" type="checkbox"/>
                                    <span className="checkmark essay-checkmark"></span>
                                </label>
                                <label className="checkbox-container dashboard-checkbox-container">Dissertation
                                    <input checked={showDissertationTasks} onClick={this.selectTaskTypes} name="task_type" className="task-type" type="checkbox"/>
                                    <span className="checkmark dis-checkmark"></span>
                                </label>
                            </div>
                            <div className="form-group" style={{ float : "right" }}>
                                <select value={taskTypeFilter} onChange={this.filterTaskTypeData} placeholder="" class="custom-select form-control" id="tick" name="tick">
                                    <option value="Year">Year</option>
                                    <option value="Month">Month</option>
                                    <option value="Week">Week</option>
                                </select>
                            </div>
                        </div>
                        <div style={{ overflowX : "auto", width: "90%" }}>
                            <ul className="type-filters">
                                <li onClick={ () => this.filterTaskCategory('all')} style={{ marginRight: "15px" }}><span style={ isSelectingAll ? { background : '#7AB7B6', color : "white"} : {} } className="type-filter">All</span></li>
                                {
                                    taskCategories.map( (data, i) => (
                                        <li onClick={ () => this.filterTaskCategory(data.category)} style={{ marginRight: "10px", fontSize : '9px' }}><span style={ data.selected ? { background : '#7AB7B6', color : "white"} : {} } className="type-filter">{data.category}</span></li>
                                    ))
                                }
                            </ul>
                        </div>
                        {
                            taskTypeFilter==="Year" && <p className="year-label" style={{ textAlign : "center" }}>{taskTypeActiveYear}</p>
                        }
                        {
                            taskTypeFilter==="Month" && <div>
                                <span className="year-label">{taskTypeActiveYear}</span>
                                <span className="non-year-label">{taskTypeActiveMonth}</span>
                            </div>
                        }
                        {
                            taskTypeFilter==="Week" && <div>
                                <span className="year-label">{taskTypeActiveYear}</span>
                                <span className="non-year-label">Week {taskTypeActiveWeek}</span>
                            </div>
                        }
                        <div className="stat-chart-area">
                            <div className="button-container"><button onClick={this.previousPageTaskTypeData}>{"<"}</button></div>
                            <div id="task-stat" style={{ width: '600px', height: 450, overflowX : "auto", overflowY : "hidden" }}>
                                <AreaChart
                                    width={2000}
                                    height={400}
                                    data={taskTypeStatDataDisplay}
                                    margin={{
                                    top: 50, right: 30, left: 0, bottom: 0,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis tickFormatter={this.taskTypeTickFormatter} allowDuplicatedCategory={false} interval={taskTypeInterval} tick={{ fontSize : 12}} dataKey="name" ticks={taskTypeTick}/>
                                    <YAxis label={<CustomizedTaskTypeLabel/>} type="number" domain={[0, 'dataMax + 10']}/>                                        
                                    <Tooltip 
                                        formatter={function(count, name) {
                                            return `${count}`;
                                        }}

                                        labelFormatter={function(date) {
                                            return `${date}`;
                                        }}
                                    
                                    />
                                    { showPresentationTasks && <Area type="monotone" dataKey="presentation" stackId="1"   stroke="#22C9C7" fill="#C7F9F8"  />}
                                    { showEssayTasks && <Area type="monotone" dataKey="essay" stackId="1" stroke="#D4236D" fill="#FBC9DE"/>}
                                    { showDissertationTasks &&  <Area type="monotone" dataKey="dissertation" stackId="1" stroke="#5D31AF" fill="#E3D9F5" />}
                                </AreaChart>
                            </div>
                            <div className="button-container"><button onClick={this.nextPageTaskTypeData}>{">"}</button></div>
                        </div>
                    </div>
                </div>
                <div className="stat-row">
                    <div className="stat-box" id="status-task-stat">
                        <div style={{ display : "flex" }}>
                            <p className="stat-box-header" style={{ flex: "1" }}>Translator's statistic</p>
                            <div className="form-group" style={{ float : "right" }}>
                                <select value={translatorFilter} onChange={this.filterTranslatorData} placeholder="" class="custom-select form-control" id="tick" name="tick">
                                    <option value="Year">Year</option>
                                    <option value="Month">Month</option>
                                    <option value="Week">Week</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            {
                                translatorFilter==="Year" && <p className="year-label" style={{ textAlign : "center" }}>{translatorActiveYear}</p>
                            }
                            {
                                translatorFilter==="Month" && <div>
                                    <span className="year-label">{translatorActiveYear}</span>
                                    <span className="non-year-label">{translatorActiveMonth}</span>
                                </div>
                            }
                            {
                                translatorFilter==="Week" && <div>
                                    <span className="year-label">{translatorActiveYear}</span>
                                    <span className="non-year-label">Week {translatorActiveWeek}</span>
                                </div>
                            }
                        </div>
                        <div className="stat-chart-area">
                            <div className="button-container"><button onClick={this.previousPageTranslatorData}>{"<"}</button></div>
                            <div id="translator-stat" style={{ width: '600px', height: 450, overflowX : "auto", overflowY : "hidden" }}>
                                <AreaChart
                                    width={800}
                                    height={400}
                                    data={translatorStatDataDisplay}
                                    margin={{
                                    top: 50, right: 30, left: 0, bottom: 0,
                                    }}         
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis tickFormatter={this.translatorTickFormatter} allowDuplicatedCategory={false} interval={translatorInterval} tick={{ fontSize : 12}} dataKey="name" ticks={translatorTick}/>
                                    <YAxis label={<CustomizedTranslatorLabel/>} dataKey="count" type="number" domain={[0, 'dataMax + 10']} />
                                    <Tooltip />
                                    <Area type="monotone" dataKey="count" stackId="1" stroke="#29B6F6" fill="#29B6F6" />
                                </AreaChart>
                            </div>
                            <div className="button-container"><button onClick={this.nextPageTranslatorData}>{">"}</button></div>
                        </div>
                    </div>
                    <div className="stat-box" id="status-task-stat">
                        <div style={{ display : "flex" }}>
                            <p className="stat-box-header" style={{ flex: "1" }}>Customer's statistic</p>
                            <div className="form-group" style={{ float : "right" }}>
                                <select value={customerFilter} onChange={this.filterCustomerData} placeholder="" class="custom-select form-control" id="tick" name="tick">
                                    <option value="Year">Year</option>
                                    <option value="Month">Month</option>
                                    <option value="Week">Week</option>
                                </select>
                            </div>
                        </div>
                        <div>
                        {
                            customerFilter==="Year" && <p className="year-label" style={{ textAlign : "center" }}>{customerActiveYear}</p>
                        }
                        {
                            customerFilter==="Month" && <div>
                                <span className="year-label">{customerActiveYear}</span>
                                <span className="non-year-label">{customerActiveMonth}</span>
                            </div>
                        }
                        {
                            customerFilter==="Week" && <div>
                                <span className="year-label">{customerActiveYear}</span>
                                <span className="non-year-label">Week {customerActiveWeek}</span>
                            </div>
                        }
                        </div>
                        <div className="stat-chart-area">
                            <div className="button-container"><button onClick={this.previousPageCustomerData}>{"<"}</button></div>
                            <div id="customer-stat" style={{ width: '600px', height: 450, overflowX : "auto", overflowY : "hidden" }}>
                                <AreaChart
                                    width={800}
                                    height={400}
                                    data={customerStatDataDisplay}
                                    margin={{
                                    top: 50, right: 30, left: 0, bottom: 0,
                                    }} 
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis tickFormatter={this.customerTickFormatter} allowDuplicatedCategory={false} interval={customerInterval} tick={{ fontSize : 12}} dataKey="name" ticks={customerTick}/>
                                    <YAxis label={<CustomizedCustomerLabel/>} dataKey="count" type="number" domain={[0, 'dataMax + 10']}  />
                                    <Tooltip />
                                    <Area type="monotone" dataKey="count" stackId="1" stroke="#29B6F6" fill="#29B6F6" />
                                </AreaChart>
                                  
                            </div>
                            <div className="button-container"><button onClick={this.nextPageCustomerData}>{">"}</button></div>
                        </div>
                    </div>
                </div>
                <div className="stat-row">
                    <div className="stat-box" id="status-task-stat">
                        <div style={{ display : "flex" }}>
                            <p className="stat-box-header" style={{ flex: "1" }}>How many translators joined</p>
                            <div className="form-group" style={{ float : "right" }}>
                                <select onChange={this.filterTranslatorJoined} placeholder="" class="custom-select form-control" id="tick" name="tick">
                                    <option value="Year">Last year</option>
                                    <option value="Month">Last month</option>
                                    <option value="Week">Last week</option>
                                </select>
                            </div>
                        </div>
                        <p className="joined-label">{ `+ ${translatorJoined}` }</p>
                    </div>
                    <div className="stat-box" id="status-task-stat">
                        <div style={{ display : "flex" }}>
                            <p className="stat-box-header" style={{ flex: "1" }}>How many customers joined</p>
                            <div className="form-group" style={{ float : "right" }}>
                                <select onChange={this.filterCustomerJoined} placeholder="" class="custom-select form-control" id="tick" name="tick">
                                    <option value="Year">Last year</option>
                                    <option value="Month">Last month</option>
                                    <option value="Week">Last week</option>
                                </select>
                            </div>
                        </div>
                        <p className="joined-label">{ `+ ${customerJoined}` }</p>
                    </div>
                </div>
                <div className="stat-row">
                    <div className="stat-box" id="status-task-stat">
                        <div style={{ display : "flex" }}>
                            <p className="stat-box-header" style={{ flex: "1" }}>Earning's statistic</p>
                            <div className="form-group" style={{ float : "right" }}>
                                <select value={earningFilter} onChange={this.filterEarningData} placeholder="" class="custom-select form-control" id="tick" name="tick">
                                    <option value="Year">Year</option>
                                    <option value="Month">Month</option>
                                    <option value="Week">Week</option>
                                </select>
                            </div>
                        </div>
                        <div>
                        {
                            earningFilter==="Year" && <p className="year-label" style={{ textAlign : "center" }}>{earningActiveYear}</p>
                        }
                        {
                            earningFilter==="Month" && <div>
                                <span className="year-label">{earningActiveYear}</span>
                                <span className="non-year-label">{earningActiveMonth}</span>
                            </div>
                        }
                        {
                            earningFilter==="Week" && <div>
                                <span className="year-label">{earningActiveYear}</span>
                                <span className="non-year-label">Week {earningActiveWeek}</span>
                            </div>
                        }
                        </div>
                        <div className="stat-chart-area">
                        <div className="button-container"><button onClick={this.previousPageEarningData}>{"<"}</button></div>
                            <div id="earnings-stat"  style={{ width: '600px', height: 450, overflowX : "auto", overflowY : "hidden" }}>
                                <AreaChart
                                    width={800}
                                    height={400}
                                    data={earningStatDataDisplay}
                                    margin={{
                                    top: 50, right: 30, left: 30, bottom: 0,
                                    }} 
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis tickFormatter={this.earningTickFormatter} allowDuplicatedCategory={false} interval={earningInterval} tick={{ fontSize : 12}} dataKey="name" ticks={earningTick}/>
                                    <YAxis label={<CustomizedEarningLabel/>} dataKey="amount" type="number" domain={[0, 'dataMax + 100']}  />
                                    <Tooltip 
                                        formatter={function(amount) {
                                            return `$${amount.toFixed(2)}`;
                                        }}
                                    />
                                    <Area type="monotone" dataKey="amount" stackId="1" stroke="#29B6F6" fill="#29B6F6" />
                                </AreaChart>
                            </div>
                            <div className="button-container"><button onClick={this.nextPageEarningData}>{">"}</button></div>
                        </div>
                    </div>
                    <div className="stat-box" id="status-task-stat">
                        <div style={{ display : "flex" }}>
                            <p className="stat-box-header" style={{ flex: "1" }}>Request's statistic</p>
                            <div className="form-group" style={{ float : "right", width : 110, marginLeft: 5 }}>
                                <select value={requestUserTypeFilter} onChange={this.filterRequestUserType} placeholder="" class="custom-select form-control" id="tick" name="tick">
                                    <option value="customer">Customer</option>
                                    <option value="translator">Translator</option>
                                    <option value="accountManager">Account manager</option>
                                </select>
                            </div>
                            <div className="form-group" style={{ float : "right", width : 110, marginLeft: 5 }}>
                                <select value={requestTaskTypeFilter} onChange={this.filterRequestTaskType} placeholder="" class="custom-select form-control" id="tick" name="tick">
                                    <option value="essay">essay</option>
                                    <option value="presentation">presentation</option>
                                    <option value="dissertation">dissertation</option>
                                </select>
                            </div>
                            <div className="form-group" style={{ float : "right", width : 110, marginLeft: 5 }}>
                                <select value={requestFilter} onChange={this.filterRequestData} placeholder="" class="custom-select form-control" id="tick" name="tick">
                                    <option value="Year">Year</option>
                                    <option value="Month">Month</option>
                                    <option value="Week">Week</option>
                                </select>
                            </div>
                        </div>
                        <div>
                        {
                            requestFilter==="Year" && <p className="year-label" style={{ textAlign : "center" }}>{requestActiveYear}</p>
                        }
                        {
                            requestFilter==="Month" && <div>
                                <span className="year-label">{requestActiveYear}</span>
                                <span className="non-year-label">{requestActiveMonth}</span>
                            </div>
                        }
                        {
                            requestFilter==="Week" && <div>
                                <span className="year-label">{requestActiveYear}</span>
                                <span className="non-year-label">Week {requestActiveWeek}</span>
                            </div>
                        }
                        </div>
                        <div className="stat-chart-area">
                            <div className="button-container"><button onClick={this.previousPageRequestData}>{"<"}</button></div>
                            <div id="requests-stat"  style={{ width: '600px', height: 450, overflowX : "auto", overflowY : "hidden" }}>
                                <AreaChart
                                    width={800}
                                    height={400}
                                    data={requestStatDataDisplay}
                                    margin={{
                                    top: 50, right: 30, left: 0, bottom: 0,
                                    }} 
                                    
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis tickFormatter={this.requestTickFormatter} allowDuplicatedCategory={false} interval={requestInterval} tick={{ fontSize : 12}} dataKey="name" ticks={requestTick}/>
                                    <YAxis label={<CustomizedRequestLabel/>} dataKey="count" type="number" domain={[0, 'dataMax + 10']}  />
                                    <Tooltip />
                                    <Area type="monotone" dataKey="count" stackId="1" stroke="#29B6F6" fill="#29B6F6" />
                                </AreaChart>
                            </div>
                            <div className="button-container"><button onClick={this.nextPageRequestData}>{">"}</button></div>
                        </div>
                    </div>
                </div>
                {
                    isLoggingOut && 
                    <SpinnerModal
                        isOpened={isLoggingOut}
                    />
                }
            </div>
            
        )
        
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        taskList : state.report.taskList,
        isLoggingOut : state.auth.isLoggingOut,
        isGettingTaskStat : state.report.isGettingTaskStat,
        userList : state.report.userList,
        requestList : state.report.requestList,
        earningList : state.report.earningList,
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, {
        setReportDateFilter,
        getTasksForStatistics,
        getRequestStatistics,        
        getUserStatistics,
        getEarningStatistics,
        setUserPresence
    })
)(Index)

class CustomizedAxisTick extends Component {
    render() {
      const { x, y, payload } = this.props;
  
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={-20}
            dy={16}
            textAnchor="end"
            fill="#666"
            className="customized-axis-tick-text"
          >
            {payload.value}
          </text>
        </g>
      );
    }
  }

