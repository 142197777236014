import React, { Component } from 'react'
import './modal-translator-item.css'
import moment from 'moment'

const renderRating = (count) => {

    let rating = [];

    for(let i=0; i<count; i++){
        rating.push(<img src={require('../../../../images/icon-rating.png')}/>)
    }

    return rating;

}

export class Item extends Component {

    openConfirmationModal = (translator) =>{
        const { task } = this.props;

        const translatorName = translator.fullName.split(",");

        this.props.openConfirmationModal({
            name : translatorName[0] + " " + translatorName[1],
            translator : translator,
            task : task.title
        })
    }
    

    render(){

        const { translator, task } = this.props;
        const translatorName = translator.fullName.split(",");
        let lastActiveAt = translator.hasOwnProperty('lastActiveAt') ? moment.unix(translator.lastActiveAt._seconds).fromNow() : "offline" ;

        return (
            <div className="rm-translator-item">
                <div className="rm-translator-main-details">
                    <div style={{ position: "relative"}}>
                        <img className="display-photo" src={translator.imageUrl===""?require('../../../../images/default-user.jpg'):translator.imageUrl}/>
                        <img style={{ width: "12px", height : "12px", position : "absolute", top : "50px", left : "10px" }} src={ translator.presence==="offline" ? require('../../../../images/icon-offline.png') : require('../../../../images/icon-completed.png')}/>
                    </div>
                    <div className="main-details">
                        <span className="tr-name">{translatorName[1]+' '+translatorName[0]}</span>
                        <span className={` ${translator.presence==="online" ? "tr-presence-online" : "tr-presence-offline"}`}>{translator.presence==="online"? "Online" : lastActiveAt}</span>
                        <span className="tr-rating">{renderRating(Math.trunc(translator.rating))}</span>
                    </div>
                </div>
                <div className="rm-translator-sub-details">
                    <div style={{ flex : "1" }}>
                        <span className="tr-label">
                            Specialty
                        </span>
                        <span className="tr-value">
                            {translator.specialty}
                        </span>
                    </div>
                    <div style={{ flex : "1" }}>
                        <span className="tr-label">
                            Status
                        </span>
                        <span className="tr-value">
                            {translator.status}
                        </span>
                    </div>
                    <div style={{ flex : "1" }}>
                        <span className="tr-label">
                            Experience
                        </span>
                        <span className="tr-value">
                            {translator.yearsOfExperience}
                        </span>
                    </div>
                </div>
                <div className="rm-task-description">
                    <p>{translator.aboutYourself}</p>
                </div>
                <button onClick={ () => this.openConfirmationModal(translator)} className="op-btn">INVITE TRANSLATOR</button>
            </div>
        )
    }

}

export default Item;