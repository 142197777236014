import React, { Component } from 'react';
import $ from 'jquery';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import { 
    getEscalations,
    loadMoreEscalations,
    resolveEscalation,
    clearEscalationsRef
} from '../../../actions/escalationAction'

import { 
    updateOperatorRequestsResolved
} from '../../../actions/authAction'

import TaskList from './List'
import TaskDetailsModal from '../Modals/TaskDetails'
import SpinnerModal from '../../Spinner/SpinnerModal';

export class Index extends Component {

    constructor(){
        super();
        this.state= {
            isTaskDetailsModalActive : false,
            selectedEscalation : null,
            selectedTask : null,
            activeTab : "customer",
            isProcessing : false,
            isAuthenticated : false
        }
    }

    componentDidMount(){
       
        const { auth , userProfile } = this.props;

        if(auth.uid){
            this.loadEscalations();
        }

        if(userProfile.hasOwnProperty('fullName')){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    scrollToBottom = () => {
        const scrollHeight = $('html')[0].scrollHeight;
        const height = $('html')[0].clientHeight;
        const maxScrollTop = scrollHeight - height;
        $('html')[0].scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    loadEscalations = () =>{
        this.props.getEscalations();
    }

    loadMoreEscalations = () => {
        this.props.loadMoreEscalations();
    }

    openTaskDetailsModal = (data, activeTab = "customer") => {
        
        this.setState({
            isTaskDetailsModalActive : true,
            selectedEscalation : {...data},
            selectedTask : {...data.task},
            activeTab : activeTab
        })
    }

    closeTaskDetailsModal = () => {
        this.setState({
            isTaskDetailsModalActive : false,
            selectedEscalation : null,
            selectedTask : null
        })
    }

    openChat = (userType, recipientUid, task=null) => {

        const { selectedTask } = this.state;

        this.props.history.push({
            pathname : "/chat",
            state : {
                tab : userType,
                task : task ? task : selectedTask,
                recipient : recipientUid
            }
        })
    }

    resolveRequest = (data) => {

        this.setState({
            isProcessing : true
        }, async () => {

            const { userProfile } = this.props;

            await this.props.resolveEscalation({
                uid : data.escalation,
                isResolved : true,
                operator : userProfile.uid
            })

            setTimeout( () => {
                this.setState({
                    isProcessing : false
                }, () => {
                    this.loadEscalations();
                    this.props.updateOperatorRequestsResolved();
                })
            }, 800)
        })

    }

    render() {

        const { isProcessing, isAuthenticated, isTaskDetailsModalActive, selectedTask, selectedEscalation, activeTab } = this.state;
        const { isLoggingOut, auth, escalations, isGettingEscalations, isLoadingMoreEscalations, userProfile } = this.props;

        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }
        
        if(!auth.uid && !auth.emailVerified){
            return <Redirect to="/" />
        }else{
            if(isAuthenticated){
                if(userProfile.type!=="operator"){
                    return "NOT ALLOWED";
                }
            }
        }

        return (
            <div className="div-holder">
                {
                    isAuthenticated &&
                    <div className="div-holder">
                        <p className="module-header">Tasks List</p>
                        {
                            isGettingEscalations ?
                            <div className="d-flex justify-content-center">
                                <div style={{ height: "5rem", width: "5rem" }} className="custom-spinner-border spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            escalations.length!== 0 ?
                            <TaskList
                                resolveRequest={this.resolveRequest}
                                isLoadingMoreEscalations={isLoadingMoreEscalations}
                                openChat={this.openChat}
                                task = {selectedTask}
                                openTaskDetailsModal={this.openTaskDetailsModal}
                                escalations={escalations}
                                loadMoreEscalations={this.loadMoreEscalations}
                            />
                            : ""
                            
                        }
                        {
                            isTaskDetailsModalActive && 
                            <TaskDetailsModal 
                                openChat={this.openChat}
                                activeTab={activeTab}
                                task = {selectedTask}
                                escalation={selectedEscalation}
                                closeTaskDetailsModal={this.closeTaskDetailsModal}
                                isOpened={isTaskDetailsModalActive}
                            />
                        }
                        {
                            isLoggingOut && 
                            <SpinnerModal
                                isOpened={isLoggingOut}
                            />
                        }
                        {
                            isProcessing && 
                            <SpinnerModal
                                isOpened={isProcessing}
                            />
                        }
                    </div>
                }
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        escalations : state.escalation.escalations,
        isGettingEscalations : state.escalation.isGettingEscalations,
        isLoadingMoreEscalations : state.escalation.isLoadingMoreEscalations,
        isLoggingOut : state.auth.isLoggingOut
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, {   
        getEscalations,
        loadMoreEscalations,
        resolveEscalation,
        updateOperatorRequestsResolved
    })
)(Index)