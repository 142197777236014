import React, { Component } from 'react';
import $ from 'jquery';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SearchInput from '../SearchInput';
import { Redirect, withRouter, Link } from 'react-router-dom'
import { getAllCustomers, updateUser, blockCustomer } from '../../../actions/userAction'

import SpinnerModal from '../../Spinner/SpinnerModal'
import SUList from './SUList'
import BlockCustomerModal from './Modals/BlockCustomer'
import MessageModal from '../../Modals/Message'

import './customers.css'

export class Index extends Component{

    constructor(){
        super();
        this.state = {
            search : ``,
            facetFilters : [
                ["type:customer"]
            ],
            isProcessing : false,
            searchString : "",
            pageNumber : 0,
            selectedCustomer : null,
            isBlockCustomerModalActive : false,
            isMessageModalActive : false
        }
    }

    componentDidMount(){
        const { auth } = this.props;
        
        if(auth.uid){
            
            const { search , facetFilters } = this.state;

            this.loadCustomers({
                search : search,
                facetFilters : facetFilters
            });
          
        }
    }

    loadCustomers = (data) => {

        data.sortBy = "fullName";
        this.props.getAllCustomers(data);

    }

    searchCustomers = (data) => {

        this.setState({
            search : data
        }, () => {

            const { search, facetFilters } = this.state;

            this.loadCustomers({
                search : search,
                facetFilters : facetFilters
            });
        })
    }

    loadMore = () => {

        const { customersCurrentPage, customersPageCount } = this.props;
        const { search, facetFilters } = this.state;

        let newPageNumber = customersCurrentPage + 1;

        if(newPageNumber <= customersPageCount ){
            this.loadCustomers({
                search : search,
                facetFilters : facetFilters,
                page : newPageNumber
            })   
        }
    }

    openChat = (recipient, task) => {
        this.props.history.push({
            pathname : "/chat",
            state : {
                tab : "customer",
                task : task,
                recipient : recipient
            }
        })
    }

    openBlockCustomerModal = (customer) => {
        this.setState({
            selectedCustomer : customer,
            isBlockCustomerModalActive : true
        })
    }

    closeBlockCustomerModal = () => {
        this.setState({
            selectedCustomer : null,
            isBlockCustomerModalActive : false
        })
    }

    blockCustomer = (data) => {

        const { selectedCustomer } = this.state;

        this.setState({
            isProcessing : true
        }, () => {

            this.props.blockCustomer({
                uid : selectedCustomer.uid,
                status : "disabled",
                disabledReason : data.disabledReason
            })

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isBlockCustomerModalActive : false,
                    isMessageModalActive : true,
                    selectedCustomer : null
                })
            }, 3000)

        })
    }

    openMessageModal = () => {
        this.setState({
            isMessageModalActive : true
        })
    }

    closeMessageModal = () => {
        this.setState({
            isMessageModalActive : false
        }, () => {
            const { search , facetFilters } = this.state;

            this.loadCustomers({
                search : search,
                facetFilters : facetFilters
            });
        })
    }

    render(){

        const { isProcessing } = this.state;
        const { isLoggingOut, customers, auth, userProfile } = this.props;

        const { isGettingCustomers, isGettingMoreCustomers, customersTotalRecord } = this.props;
        const { isBlockCustomerModalActive, selectedCustomer } = this.state;

        const { isMessageModalActive } = this.state;

        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }

        if(!auth.uid && !auth.emailVerified){
            return <Redirect to="/" />
        }

        return (
            <div>
                <div style={{ display : "flex"}}>
                    <div style={{ flex : "1", textAlign : "left" }}>
                        <p className="module-header">Customers List</p>
                    </div>
                    <div style={{ display : "flex", float : "right" }}>
                        <SearchInput searchUsers={this.searchCustomers} />
                    </div>
                </div>
                {
                    userProfile.type==="superuser" &&
                    <div>
                        {
                            isGettingCustomers ?
                            <div className="d-flex justify-content-center">
                                <div style={{ height: "5rem", width: "5rem" }} className="custom-spinner-border spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            customersTotalRecord!== 0 ?
                            <SUList 
                                loadMore={this.loadMore}
                                isGettingMoreCustomers={isGettingMoreCustomers}
                                customers={customers}
                                openBlockCustomerModal={this.openBlockCustomerModal}
                            /> : ""
                        }
                    </div>
                }
                {
                    isProcessing &&
                    <SpinnerModal
                        isOpened={isProcessing}
                    />
                }
                {
                    isLoggingOut &&
                    <SpinnerModal
                        isOpened={isLoggingOut}
                    />
                }
                {
                    isBlockCustomerModalActive &&
                    <BlockCustomerModal 
                        isOpened={isBlockCustomerModalActive}
                        customer={selectedCustomer}
                        closeBlockCustomerModal={this.closeBlockCustomerModal}
                        blockCustomer={this.blockCustomer}
                    />
                }
                {
                    isMessageModalActive &&
                    <MessageModal 
                        isOpened={isMessageModalActive}
                        closeMessageModal={this.closeMessageModal}
                        header={""}
                        subHeader={"Customer has been blocked."}
                    />
                }
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        isGettingCustomers : state.user.isGettingCustomers,
        isGettingMoreCustomers : state.user.isGettingMoreCustomers,
        customers : state.user.customers,
        customersTotalRecord : state.user.customersTotalRecord,
        customersFilteredRecord : state.user.customersFilteredRecord,
        customersCurrentPage : state.user.customersCurrentPage,
        customersPageCount : state.user.customersPageCount,
        isLoggingOut : state.auth.isLoggingOut
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, {   
        getAllCustomers,
        updateUser,
        blockCustomer
    })
)(Index)
