import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

import authReducer from './authReducer';
import taskReducer from './taskReducer';
import conversationReducer from './conversationReducer';
import escalationReducer from './escalationReducer'
import appReducer from './appReducer';
import userReducer from './userReducer';
import reportReducer from './reportReducer'
import adminConfigReducer from './adminConfigReducer'
import notificationReducer from './notificationReducer'

export default combineReducers({
    auth : authReducer,
    task : taskReducer,
    conversation : conversationReducer,
    escalation : escalationReducer,
    adminConfig : adminConfigReducer,
    app: appReducer,
    report : reportReducer,
    user : userReducer,
    notification : notificationReducer,
    firestore : firestoreReducer,
    firebase : firebaseReducer
})