import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import { getArchivedTasks, loadMoreArchivedTasks } from '../../../actions/taskAction'
import { setUserPresence } from '../../../actions/authAction'
import TaskList from './List';
import TaskItem from './Item';
import '../task.css';
import SpinnerModal from '../../Spinner/SpinnerModal'

export class Index extends Component {

    constructor(){
        super();
        this.state = {
            isEmpty : false,
            isProcessing : false,
            isAuthenticated : false
        }
    }
    
    componentDidMount(){

        const { auth, userProfile } = this.props;

        if(auth.uid){

            this.props.setUserPresence({
                uid : auth.uid
            })
            this.loadTasks();
        }

        if(userProfile.hasOwnProperty('fullName')){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    loadTasks = async () => {
        this.props.getArchivedTasks();
    }

    loadMoreArchivedTasks = () => {
        this.props.loadMoreArchivedTasks();
    }

    render() {

        const { isLoadingMoreTasks, isLoggingOut, auth, tasks, isGettingTasks, userProfile } = this.props;
        const { isAuthenticated, isProcessing } = this.state;
        
        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }
        
        if(!auth.uid){
            return <Redirect to="/" />
        }else{
            if(isAuthenticated){
                if(userProfile.type!=="superuser"){
                    if(!auth.emailVerified){
                        return <Redirect to="/" />
                    }
                }
            }
        }

        return (
            <div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <p className="module-header">Archive</p>
                    </div>
                </div>
                <div className="">
                    {
                        isGettingTasks ?
                        <div className="d-flex justify-content-center">
                            <div style={{ height: "5rem", width: "5rem" }} className="custom-spinner-border spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        :
                        tasks.length!==0 ?  
                        <TaskList  
                            loadMoreArchivedTasks={this.loadMoreArchivedTasks} 
                            isLoadingMoreTasks={isLoadingMoreTasks}
                            tasks={tasks}
                            /> : ""
                    }
                </div>
                {
                    isProcessing && 
                    <SpinnerModal
                        isOpened={isProcessing}
                    />
                }
                {
                    isLoggingOut && 
                    <SpinnerModal
                        isOpened={isLoggingOut}
                    />
                }
            </div>
        )
        
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        tasks : state.task.archivedTasks,
        isGettingTasks : state.task.isGettingTasks,
        isLoadingMoreTasks : state.task.isLoadingMoreTasks,
        isLoggingOut : state.auth.isLoggingOut
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { getArchivedTasks, loadMoreArchivedTasks, setUserPresence })
)(Index)

