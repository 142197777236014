import React, { Component } from 'react'
import ReactDOM from 'react-dom';

import Item from './Item';

export class List extends Component {

    scrollToBottom = () => {
        const { newTaskList } = this.refs;
        const scrollHeight = newTaskList.scrollHeight;
        const height = newTaskList.clientHeight;
        const maxScrollTop = scrollHeight - height;
        ReactDOM.findDOMNode(newTaskList).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    render(){

        const { isLoadingMoreTasks, tasks } = this.props;
        return (
            
            <div className="" ref="newTaskList">
                {
                    tasks.map( (data, i) => 
                        <Item openChat={this.props.openChat} openCompleteTaskModal={this.props.openCompleteTaskModal} openEscalateTaskModal={this.props.openEscalateTaskModal} openDeclineTaskModal={this.props.openDeclineTaskModal} openHireTranslatorModal={this.props.openHireTranslatorModal} key={data.uid} task={data}/> 
                    )
                }

                <div className="text-center">
                    {
                        isLoadingMoreTasks ? <div style={{ paddingTop : "15px" }}>
                            <div class="custom-get-more-spinner spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div> : <div className="text-center" style={{ marginTop : "15px" }}>
                            <button onClick={this.props.loadMoreNewTasks} className="load-more-button">LOAD MORE</button>
                        </div>
                    }
                    
                </div>
            </div>

           

        )

    }

}

export default List;
