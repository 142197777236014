import React, { Component } from 'react'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import moment from 'moment';

export class SearchedMessage extends Component {

    constructor(){
        super();
    }

    render(){
        
        const { searched } = this.props;

        let imageUrl = searched.senderImage === "" ? require('../../images/default-user.jpg') : searched.senderImage
        let conversationClass = '';

        return (
            <div onClick={ () => this.props.getAndSetActiveConversation(searched.conversation) } className={` chat-item ${conversationClass} `}>
                <div className="chat-item-body">
                    <img className="display-photo" src={imageUrl}/>
                    <div className="details">
                        <span className="username">{searched.senderName}</span>
                        <span className="highlighted-text-result">{ ReactHtmlParser(searched._snippetResult.text.value) }</span>
                        <span className="chat-date" style={{ textTransform : "capitalize"}}>{moment.unix(searched.dateAdded._seconds).format("M.DD.YY")}</span>
                    </div>
                </div>
            </div>
        )

    }

}

export default SearchedMessage