import React, { Component } from 'react'
import ReactDOM from 'react-dom';

import Item from './Item';

export class List extends Component {

    scrollToBottom = () => {
        const { translatorList } = this.refs;
        const scrollHeight = translatorList.scrollHeight;
        const height = translatorList.clientHeight;
        const maxScrollTop = scrollHeight - height;
        ReactDOM.findDOMNode(translatorList).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    render(){

        const { translators, selectedTask } = this.props;

        return (
            
            <div className="" ref="translatorList">
                {
                    translators.map( (data, i) => <Item openFireTranslatorModal={this.props.openFireTranslatorModal} openChat={this.props.openChat} openConfirmationModal={this.props.openConfirmationModal} hireTranslator={this.props.hireTranslator} key={data.uid} task={selectedTask} translator={data}/> )
                }

                <div className="text-center">
                    <button onClick={this.props.loadMore} className="load-more-button">LOAD MORE</button>
                </div>
            </div>

           

        )

    }

}

export default List;
