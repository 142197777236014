import React, { Component } from 'react';
import $ from 'jquery';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link, useParams } from 'react-router-dom'
import {
    checkInviteToken
} from '../../../actions/userAction';
import {
    signUpUser
} from '../../../actions/authAction';

import SpinnerModal from '../../Spinner/SpinnerModal';
import MessageModal from '../../Modals/Message';

export class InviteSignup extends Component {

    constructor(){
        super();
        this.state = {
           inviteStatus : null,
           fullName : "",
           firstName : "",
           lastName : "",
           emailAddress : "",
           password : "",
           userType : "",
           isProcessing : false,
           isSignupSuccessful : false,
           imageFile : null,
           imageFileDisplay : null
        }
    }

    componentDidMount(){
        const { match: { params } } = this.props;
        
        this.checkToken(params.token);
    }

    checkToken = async (token) => {
        let result = await this.props.checkInviteToken({
            token : token
        });
        
        if(result.code==401){
            this.setState({
                inviteStatus : 401
            })
        }

        if(result.code==405){
            this.setState({
                inviteStatus : 405
            })
        }

        if(result.code==200){
            this.setState({
                inviteStatus : 200,
                emailAddress : result.data.recipient,
                userType : result.data.userType
            })

        }

    }

    onChange = (e) => {

        if(e.target.name==="firstName"){

            $('#firstName').removeClass('error-input');
            $('#firstName').removeClass('success-input');
            $('#firstName').next('.error-input-message').text('');

            let regExp = /[0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\u0660-\u0669]+/g;
            if(e.target.value.trim().length===0){
                return
            }

            if(regExp.test(e.target.value) && e.target.value.trim()!==""){
                $('#firstName').addClass('error-input');
                $('#firstName').next('.error-input-message').text(`Name should not contain any numbers or special characters.`);
                this.setState({
                    firstName : ""
                })
            }else{

                $('#firstName').removeClass('error-input');
                $('#firstName').next('.error-input-message').text('');
                this.setState({
                    firstName : e.target.value
                })
            }
        }

        if(e.target.name==="lastName"){

            $('#lastName').removeClass('error-input');
            $('#lastName').removeClass('success-input');
            $('#lastName').next('.error-input-message').text('');

            let regExp = /[0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\u0660-\u0669]+/g;
            if(e.target.value.trim().length===0){
                return
            }

            if(regExp.test(e.target.value) && e.target.value.trim()!==""){
                $('#lastName').addClass('error-input');
                $('#lastName').next('.error-input-message').text(`Name should not contain any numbers or special characters`);
                this.setState({
                    lastName : ""
                })
            }else{

                $('#lastName').removeClass('error-input');
                $('#lastName').next('.error-input-message').text('');
                this.setState({
                    lastName : e.target.value
                })
            }
        }

        // if(e.target.name==="fullName"){
        //     $('#fullName').removeClass('error-input');
        //     $('#fullName').removeClass('success-input');
        //     $('#fullName').next('.error-input-message').text('');

        //     let regExp = /^[\w]*,\s[\w\s]*$/i;
           

        //     if(!regExp.test(e.target.value) && e.target.value!==""){
        //         $('#fullName').addClass('error-input');
        //         $('#fullName').next('.error-input-message').text( `Name should be on the following format, Last Name, First Name` );
        //         this.setState({
        //             fullName : ""
        //         })
        //     }else{
        //         $('#fullName').removeClass('error-input');
        //         $('#fullName').next('.error-input-message').text('');
        //         this.setState({
        //             fullName : e.target.value
        //         })
        //     }
        // }

        if(e.target.name==="password"){

            let regExp = /^[a-zA-Z0-9\u0660-\u0669]+$/i;
            $('#password').removeClass('error-input');
            $('#password').removeClass('success-input');
            $('#password').next('span').removeClass('error-input-message');
            $('#password').next('span').removeClass('success-input-message');
            $('#password').next('span').text('');

            if(!regExp.test(e.target.value)){

                $('#password').addClass('error-input');
                $('#password').next('span').addClass('error-input-message');
                $('#password').next('span').text('Password should not contain any symbols or spaces.');
                
            }else{

                if(e.target.value.length<6){
                    $('#password').addClass('error-input');
                    $('#password').next('span').addClass('error-input-message');
                    $('#password').next('span').text('Password is too short - minimum length is 6 characters.');
                }else{
                    $('#password').addClass('success-input')
                    $('#password').next('span').addClass('success-input-message');
                    $('#password').next('span').text('Your password is valid');
                }  
            }

            this.setState({
                password : e.target.value
            })
        }

    }

    signupNewAdmin = () => {

        let errCount = 0;
        const { firstName, lastName, password, imageFile } = this.state;

        $('#firstName').removeClass("error-input");
        if(firstName.trim()===""){
            errCount = errCount + 1;
            $('#firstName').addClass("error-input")
        }

        $('#lastName').removeClass("error-input");
        if(lastName.trim()===""){
            errCount = errCount + 1;
            $('#lastName').addClass("error-input")
        }

        $('#password').removeClass("error-input");
        if(password.trim()===""){
            errCount = errCount + 1;
            $('#password').addClass("error-input")
        }

        $('#new_photo').next('.error-input-message').text(``);
        if(imageFile===null){
            errCount = errCount + 1;
            $('#new_photo').next('.error-input-message').text(`Please upload a display photo.`);
        }

        if(errCount==0){

            this.setState({
                isProcessing : true
            }, () => {
    
                const { firstName, lastName, fullName, emailAddress, password, userType } = this.state;
                
                let wholeName = `${lastName}, ${firstName}`;
    
                this.props.signUpUser({
                    fullName : wholeName,
                    emailAddress : emailAddress,
                    password : password,
                    userType : userType,
                    imageFile : imageFile
                }).then( (result) => {
                    if(result.code=="success"){
                        this.setState({
                            isProcessing : false,
                            isSignupSuccessful : true
                        })
                    }
                })
    
            })
        }
    }

    closeMessageModal = () => {

        this.props.history.push('/')

    }

    openFileUpload = (e) => {
        e.preventDefault();
        $('#new_photo').click();
    }

    onFileChange = (e) => {
        
        let file = e.target.files;

        if(e===null){
            $('.image-placeholder').removeClass('border-0');
        }else{
            this.setState({
                imageFileDisplay : URL.createObjectURL(file[0]),
                imageFile : file[0]
            }, () => {
                $('.image-placeholder').addClass('border-0');
            })
        }
    }

    render(){
        
        const { inviteStatus, fullName, password, emailAddress } = this.state;

        const { isProcessing, isSignupSuccessful } = this.state;

        if(inviteStatus==401){
            return (
                <p>YOUR TOKEN IS INVALID.</p>
            )
        }

        if(inviteStatus==405){
            return (
                <p>YOUR TOKEN IS EXPIRED.</p>
            )
        }

        if(inviteStatus==200){
            return (
                <div style={{ width: "50%", margin : "0 auto" }}>
                    <div className="row">
                        <div className="col">
                            <div className="invite-box-display-photo">
                                <div className="image-placeholder">
                                    <img style={{ width : "200px", height : "200px" }} src={ this.state.imageFileDisplay === null ? require('../../../images/default-user.jpg') : this.state.imageFileDisplay}/>
                                </div>
                                <input  accept="image/*" onChange={this.onFileChange} type="file" name="new_photo" id="new_photo" style={{ display : "none" }} />
                                <span className="block align-left error-input-message"></span>
                                <button onClick={this.openFileUpload}>UPLOAD PHOTO</button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label className="align-left custom-label block">First name</label>
                                <input onChange={this.onChange} id="firstName" name="firstName" style={{ width: "100%" }} className="custom-input" type="text" placeholder="Enter here"/>
                                <span className="align-left block error-input-message"></span>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label className="align-left custom-label block">Last name</label>
                                <input onChange={this.onChange} id="lastName" name="lastName" style={{ width: "100%" }} className="custom-input" type="text" placeholder="Enter here"/>
                                <span className="align-left block error-input-message"></span>
                            </div>
                        </div>
                    </div>
                    {/* <div className="form-group">
                        <label className="custom-label block">Full name</label>
                        <input onChange={this.onChange} id="fullName" name="fullName" style={{ width: "100%" }} className={`custom-input`} type="text" placeholder="Enter here"/>
                        <span class="error-input-message"></span>
                    </div> */}

                    <div className="form-group">
                        <label className="custom-label block">Email</label>
                        <input disabled value={emailAddress} name="emailAddress" style={{ width: "100%" }} className={`custom-input`} type="text" placeholder="Enter here"/>
                        <span class="error-input-message"></span>
                    </div>
                    <div className="form-group">
                        <label className="custom-label block">Password</label>
                        <input onChange={this.onChange} id="password" name="password" style={{ width: "100%" }} className={`custom-input`} type="password" placeholder="Enter here"/>
                        <span class="error-input-message"></span>
                    </div>
                    <button className="custom-btn" style={{ width: "100%", marginTop : '22px' }} onClick={this.signupNewAdmin}>SIGN UP</button>
                    {
                        isProcessing && 
                        <SpinnerModal 
                            isOpened={isProcessing}
                        />
                    }
                    {
                        isSignupSuccessful &&
                        <MessageModal
                            header={"Sign up successfully!"}
                            subHeader={"Please confirm your email address and login your account. Please check your spam folder for the verification email as well. Thank you!"}
                            closeMessageModal={this.closeMessageModal}
                            isOpened={isSignupSuccessful}
                        />
                    }
                </div>
            )
        }

        if(inviteStatus==null){
            return (
                ""
            )
        }

    }


}

const mapStateToProps = state => {
    return {
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, {
        checkInviteToken,
        signUpUser
    })
)(InviteSignup)