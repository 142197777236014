import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'
import { getTranslators, clearTranslators } from '../../../actions/translatorAction';
import { getAllTranslators } from '../../../actions/userAction';
import TranslatorList from './Translator/List';
import ConfirmationModal from './Confirmation'
import SpinnerModal from '../../Spinner/SpinnerModal';
import SearchInput from './Translator/SearchInput';
import './modal.css'

import {
    addModal,
    popModal
} from '../../../actions/appAction';

export class HireTranslator extends Component {

    constructor(){
        super();
        this.state = {
            confirmationHeader : "",
            activeTab : "best-rating",
            search : ``,
            facetFilters : [
                ["type:translator"],
                ["status:available"]
            ],
            hitsPerPage : 5,
            selectedTranslator : null,
            isProcessing : false,
            modalZ : 1050
        }
    }


    componentDidMount(){

        const { isOpened, task } = this.props;
        const { activeTab } = this.state;

        if(isOpened){
            $('#hireTranslator').modal({backdrop:'static', keyboard:false});
            $('#hireTranslator').modal('show');
            this.props.addModal('right-modal')
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("ht-bd");

            const { search, facetFilters } = this.state;

            this.loadTranslators({
                search : search,
                facetFilters : facetFilters
            });

        }

    }


    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="right-modal");
            if(modalInstance!==undefined){
                $('.ht-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }


    componentWillUnmount(){
        this.props.clearTranslators();
        $('#hireTranslator').modal('hide');
        this.props.popModal()
    }

    loadTranslators = (data) => {
        const { hitsPerPage, activeTab } = this.state;

        let loadData = {
            ...data,
            hitsPerPage : hitsPerPage
        }

        if(activeTab==="all-translators"){
            loadData.sortBy = "fullName"
        }else if(activeTab==="most-suitable"){
            loadData.sortBy = "rating"
        }else{
            loadData.sortBy = "rating"
        }

        this.props.getAllTranslators(loadData);

    }

    searchTranslators = (data) => {

        this.setState({
            search : data
        }, () => {

            const { search, facetFilters } = this.state;

            this.loadTranslators({
                search : search,
                facetFilters : facetFilters
            });
        })

        
    }

    loadMore = () => {

        const { translatorsCurrentPage, translatorsPageCount } = this.props;
        const { search, facetFilters } = this.state;

        let newPageNumber = translatorsCurrentPage + 1;

        if(newPageNumber <= translatorsPageCount ){
            this.loadTranslators({
                search : search,
                facetFilters : facetFilters,
                page : newPageNumber
            })   
        }

    }

    filterTranslators = (filter,reset) => {
        
        const { task } = this.props;

        if(filter==="best-rating"){
            let newFacetFitlers = [
                ["type:translator"],
                ["status:available"]
            ]

            this.setState({
                activeTab : "best-rating",
                facetFilters : newFacetFitlers
            }, () => {

                const { search, facetFilters } = this.state;

                this.loadTranslators({
                    search : search,
                    facetFilters : facetFilters
                });
            })
        }

        if(filter==="most-suitable"){

            let newFacetFitlers = [
                ["type:translator"],
                ["status:available"],
                [`specialty:${task.taskCategory}`]
            ]

            this.setState({
                activeTab : "most-suitable",
                facetFilters : newFacetFitlers
            }, () => {

                const { search, facetFilters } = this.state;

                this.loadTranslators({
                    search : search,
                    facetFilters : facetFilters
                });
            })
        }

        if(filter==="all-translators"){
            let newFacetFitlers = [
                ["type:translator"],
                ["status:available"]
            ]

            this.setState({
                activeTab : "all-translators",
                facetFilters : newFacetFitlers
            }, () => {

                const { search, facetFilters } = this.state;

                this.loadTranslators({
                    search : search,
                    facetFilters : facetFilters
                });
            })
        }

    }

    render(){

        const { translators, task } = this.props;
        const { modalZ, isProcessing, activeTab } = this.state;

        const seeAll = {
            fontSize : "14px",
            fontWeight : "bold",
            textDecoration : "none",
            color : "#03A5A4"
        }

        return (
            <div>
                <div style={{ zIndex : `${modalZ}` }} className="modal right-modal fade" id="hireTranslator" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div style={{ display : "flex", width : "100%" }}>
                                    <div style={{ flex :"1" }}>
                                        <p className="right-modal-header"> <img onClick={this.props.closeHireTranslatorModal} style={{ cursor: "pointer", width: "10px", height : "15px", marginRight: "15px"}} src={require('../../../images/back.png')}/>Select the translator</p>
                                    </div>
                                    <div style={{ textAlign : "right" }}>
                                        <Link style={seeAll} to={{
                                            pathname: '/users/translators',
                                            state: { task }
                                        }}>SEE ALL</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body">
                                <ul className="nav nav-tabs nav-fill nav-header" role="tablist">
                                    <li onClick={ () => this.filterTranslators('best-rating',true)} className="nav-item" >
                                        <a className={`nav-link ${activeTab==="best-rating" ? "active" : ""}`} id="best-rating-tab" data-toggle="tab" href="#best-rating" role="tab" aria-controls="best-rating" aria-selected={ activeTab === "best-rating" }>Best Rating</a>
                                    </li>
                                    <li onClick={ () => this.filterTranslators('most-suitable',true)} className="nav-item">
                                        <a className={`nav-link ${activeTab==="most-suitable" ? "active" : ""}`} id="most-suitable-tab" data-toggle="tab" href="#most-suitable" role="tab" aria-controls="most-suitable" aria-selected={ activeTab === "most-suitable" }>Most suitable</a>
                                    </li>
                                    <li onClick={ () => this.filterTranslators('all-translators',true)} id="li-all-translators"  className="nav-item">
                                        <a className={`nav-link ${activeTab==="all-translators" ? "active" : ""}`} id="all-translators-tab" data-toggle="tab" href="#all-translators" role="tab" aria-controls="all-translators" aria-selected={ activeTab === "all-translators" }>All translators</a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent" style={{ position :"relative", minHeight : "100%" }}>
                                    <div style={{ padding: "30px", textAlign : "left" }} className={`tab-pane fade show ${activeTab==="best-rating" ? "active" : ""}`} id="best-rating" role="tabpanel" aria-labelledby="best-rating-tab">
                                        <SearchInput searchTranslators={this.searchTranslators} />
                                        {
                                            
                                            ( translators &&  translators.length!==0 ) &&
                                            
                                            <TranslatorList
                                                openConfirmationModal={this.props.openConfirmationModal}
                                                hireTranslator={this.props.hireTranslator}
                                                loadMore={this.loadMore}
                                                task={task}
                                                translators={translators}
                                            />
                                        }
                                    </div>
                                    <div style={{ padding: "30px", textAlign : "left" }} className={`tab-pane fade show ${activeTab==="most-suitable" ? "active" : ""}`} id="most-suitable" role="tabpanel" aria-labelledby="most-suitable-tab">
                                        <SearchInput searchTranslators={this.searchTranslators} />
                                        {
                                            ( translators &&  translators.length!==0 ) &&
                                            <TranslatorList
                                                openConfirmationModal={this.props.openConfirmationModal}
                                                hireTranslator={this.props.hireTranslator}
                                                loadMore={this.loadMore}
                                                task={task}
                                                translators={translators}
                                            />
                                        }
                                    </div>
                                    <div style={{ padding: "30px", textAlign : "left" }} className={`tab-pane fade show ${activeTab==="all-translators" ? "active" : ""}`} id="all-translators" role="tabpanel" aria-labelledby="all-translators-tab">
                                        <SearchInput searchTranslators={this.searchTranslators} />
                                        {
                                            ( translators &&  translators.length!==0 ) &&
                                            <TranslatorList
                                                openConfirmationModal={this.props.openConfirmationModal}
                                                hireTranslator={this.props.hireTranslator}
                                                loadMore={this.loadMore}
                                                task={task}
                                                translators={translators}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                {
                    isProcessing &&
                    <SpinnerModal 
                        isOpened={isProcessing}
                    />
                }
            </div>   
        )
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        translators : state.user.translators,
        translatorsTotalRecord : state.user.translatorsTotalRecord,
        translatorsFilteredRecord : state.user.translatorsFilteredRecord,
        translatorsCurrentPage : state.user.translatorsCurrentPage,
        translatorsPageCount : state.user.translatorsPageCount
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { addModal, popModal, getAllTranslators, clearTranslators })
)(HireTranslator)