import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import $ from 'jquery';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import {
    addModal,
    popModal
} from '../../../actions/appAction'

export class ViewEmailVerification extends Component {
    
    constructor(){
        super();
        this.state = {
            modalZ : 1050,
            currentLanguage : "en"
        }
    }

    componentDidMount(){
       
        const { isOpened } = this.props;
        
        if(isOpened){
            $('#viewEmailVerificationModal').modal({backdrop:'static', keyboard:false});
            $('#viewEmailVerificationModal').modal('show');
            if(this.props.addModal){
                this.props.addModal('view-email-verification-modal')
            }
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("ve-bd");
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="view-email-verification-modal");
            if(modalInstance!==undefined){
                $('.ve-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }

    componentWillUnmount(){
        $('#viewEmailVerificationModal').modal('hide');
        if(this.props.popModal){
            this.props.popModal()
        }
        $('body').removeClass('modal-open');
    }

    setEn = (e) =>{
        $(e.target).attr('class','btn btn-xs btn-success');
        $("#btn-ar").attr('class','btn btn-xs');
        this.setState({
            currentLanguage : "en"
        })
    }

    setAr = (e) =>{
        $(e.target).attr('class','btn btn-xs btn-success');
        $("#btn-en").attr('class','btn btn-xs');
        this.setState({
            currentLanguage : "ar"
        })
    }

    render(){

        const { opened, header, body, btn, link } = this.props;
        const { modalZ , currentLanguage} = this.state;
        return (
            <div style={{ zIndex : `${modalZ}` }} className="view-email-verification-modal modal fade" id="viewEmailVerificationModal" tabIndex="-1" role="dialog" aria-labelledby="viewEmailVerificationModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div class="btn-group btn-toggle"> 
                            <button id="btn-en" onClick={this.setEn} className="btn btn-xs btn-success">EN</button>
                            <button id="btn-ar" onClick={this.setAr} className="btn btn-xs">AR</button>
                        </div>
                        <div className="custom-modal-header modal-header">
                            <button onClick={this.props.closeViewVerificationModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span style={{ fontWeight: "300", fontSize: "70px" }} aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div style={{ textAlign : "center" }}>
                                <img style={{ width: "50%", marginBottom : "30px"}} src={require('../../../images/tarjemle-logo.png')}/>
                            </div>
                            <p style={{ textAlign : currentLanguage === "ar" ?  "right" : "left" }} className="verification-header">{ header[currentLanguage] }</p>
                            <p style={{ textAlign : currentLanguage === "ar" ?  "right" : "left" }} className="verification-body">{ body[currentLanguage] }</p>
                            <a style={{ cursor : "pointer" }} className="btn-link ok">{ btn[currentLanguage] }</a>
                        </div>
                       
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(ViewEmailVerification)