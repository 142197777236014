import {
    RELOAD_NOTIFICATIONS,
    REMOVE_NOTIFICATION,
    SET_TO_VIEWED,
    SET_TO_VIEWED_SUCCESS,
    SET_TO_VIEWED_ERROR,
} from '../actions/types'

const initState = {
    notifications : []
}

const appReducer = (state = initState, action) => {

    switch(action.type){
        case RELOAD_NOTIFICATIONS:
            return {
                ...state,
                notifications : [...state.notifications, action.payload.notification]
            }
        case REMOVE_NOTIFICATION: {

            state.notifications.pop();

            return {
                ...state,
                notifications : [...state.notifications]
            }
        }
        default:
            return state;
    }

}

export default appReducer