import React, { Component } from 'react'
import BeautyStars from 'beauty-stars';

const specialties = [
    "Accounting & Consulting",
    "Admin Support",
    "Customer Service",
    "Data Science and Analytics",
    "Design & Creative",
    "Engineering and Architecture",
    "IT & Engineering",
    "Legal",
    "Sales & Marketing",
    "Translation",
    "Web, Mobile, & Software Development",
    "Writing",
    "Medical"
]

const yearsOfExperience = [
    "Less than 1 year",
    "1-3 years",
    "3-5 years",
    "More than 5 years"
]

const status = [
    "available",
    "notAvailable"
]


export class Filter extends Component {


    
    componentDidMount(){
        document.addEventListener('mousedown',this.outOfBoundsClick, false);
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown',this.outOfBoundsClick, false);
    }

    outOfBoundsClick = (e) => {
        if(this.filter_node.contains(e.target)){
            return
        }
        this.props.toggleFilter();
    }

    render() {

        const { selectedTask, rating, specFilter, statFilter, expFilter } = this.props;
        
        return (
            <div className="translator-floating-filter" ref={filter_node=>this.filter_node = filter_node}>
                <div className="filters">
                    <div className="filter-options" style={{ marginLeft: "0 !important"}}>
                        <span className="filter-options-header">Specialty</span>
                        <div>
                            <ul className="filter-option-list">
                                {
                                    specialties.map( (data, i) => (
                                        i<=7 && <li>
                                            <label data-filter="specialty" className="checkbox-container">{data}
                                                <input checked={specFilter.indexOf(`specialty:${data}`)!==-1 ? true : false } name="filter" onClick={this.props.selectFilter} className="filter" type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="filter-options">
                        <span className="filter-options-header">&nbsp;</span>
                        <div>
                            <ul className="filter-option-list">
                                {
                                    specialties.map( (data, i) => (
                                        i>=8 && <li>
                                            <label data-filter="specialty" className="checkbox-container">{data}
                                                <input checked={specFilter.indexOf(`specialty:${data}`)!==-1 ? true : false } name="filter" onClick={this.props.selectFilter} className="filter" type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="filters">
                    <div className="filter-options">
                        <span className="filter-options-header">Experience</span>
                        <div>
                            <ul className="filter-option-list">
                                {
                                    yearsOfExperience.map( (data, i) => (
                                        <li>
                                            <label data-filter="exp" className="checkbox-container">{data}
                                                <input checked={expFilter.indexOf(`yearsOfExperience:${data}`)!==-1 ? true : false } name="filter" onClick={this.props.selectFilter} className="filter" type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="nonflex-filters">
                    <div className="filter-options">
                        <span className="filter-options-header">Status</span>
                        {
                            selectedTask===null && 
                            <div>
                                <ul className="filter-option-list">
                                    {
                                        status.map( (data, i) => (
                                            <li>
                                                <label data-filter="status" className="checkbox-container">{data==="available" ? "Available" : "Not Available"}
                                                    <input checked={statFilter.indexOf(`status:${data}`)!==-1 ? true : false } name="filter" onClick={this.props.selectFilter} className="filter" type="checkbox"/>
                                                    <span className="checkmark"></span>
                                                </label>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        }
                        {
                            selectedTask!==null && 
                            <div>
                                <ul className="filter-option-list">
                                    <li>
                                        <label data-filter="status" className="checkbox-container">Available
                                            <input checked={statFilter.indexOf(`status:available`)!==-1 ? true : false } name="filter" onClick={this.props.selectFilter} className="filter" type="checkbox"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label data-filter="status" className="checkbox-container">Not Available
                                            <input disabled={true} checked={statFilter.indexOf(`status:notAvailable`)!==-1 ? true : false } name="filter" onClick={this.props.selectFilter} className="filter" type="checkbox"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                    <div className="filter-options" style={{ marginTop : "40px" }}>
                        <span className="filter-options-header">Rating</span>
                        <div>
                            <BeautyStars
                                editable={true}
                                value={rating}
                                inactiveColor="#f3f3f3"
                                activeColor="#22C9C7"
                                size="20px"
                                onChange={ (rating) => this.props.setRatingFilter(rating)}
                            />
                        </div>
                    </div>
                </div>
            </div>  
        )
    }
}

export default Filter