import React, { Component } from 'react'
import $ from 'jquery';

export class Reauthenticate extends Component {
    
    constructor(){
        super();
        this.state = {
            modalZ : 1050
        }
    }

    componentDidMount(){
        const { isOpened } = this.props;
        
        if(isOpened){
            $('#reAuthModal').modal({backdrop:'static', keyboard:false});
            $('#reAuthModal').modal('show');
            if(this.props.addModal){
                this.props.addModal('reauth-modal')
            }
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("ra-bd");
        }
    }
    

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="reauth-modal");
            if(modalInstance!==undefined){
                $('.ra-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }

    componentWillUnmount(){
        $('#reAuthModal').modal('hide');
        if(this.props.popModal){
            this.props.popModal()
        }
        $('body').removeClass('modal-open');
    }

    render(){

        const { modalZ } = this.state;
        const { authError, errorMessage } = this.props;

        let emailErrorMessage = "";
        let emailErrorClass = "";
        let passwordErrorClass = "";
        let passwordErrorMessage = "";

        if(authError!==null && authError!==undefined){
            const { code } = authError;

            if(code!==null && code!==undefined){
                if(code==="auth/invalid-email"){
                    emailErrorMessage = "Incorrect email format";
                    emailErrorClass = "error-input"
                }
    
                if(code==="auth/user-not-found"){
                    emailErrorMessage = "No account found. Please verify your email address";
                    emailErrorClass = "error-input"
                }
    
                if(code==="auth/wrong-password"){
                    passwordErrorMessage = "Incorrect password. Please try again";
                    passwordErrorClass = "error-input"
                }
    
                if(code==="auth/unverified-email"){
                    emailErrorMessage = authError.message
                    emailErrorClass = "error-input"
                }
    
            }
        }

        if(errorMessage!==""){
            emailErrorClass = "error-input"
            passwordErrorClass = "error-input"
        }

        return (
            <div style={{ zIndex : `${modalZ}` }} className="reauth-modal modal fade" id="reAuthModal" tabIndex="-1" role="dialog" aria-labelledby="reAuthModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="custom-modal-header modal-header">
                            <button onClick={this.props.closeReAuthModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span style={{ fontWeight: "300", fontSize: "70px" }} aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="text-header">We need to make sure it's you</p>
                            <p className="text-sub-header">Please enter your email and password to re-authenticate</p>
                            <div style={{ textAlign : "left" }} className="form-group">
                                <label className="custom-label block">Log in</label>
                                <input onChange={this.props.authChange} name="authEmail" style={{ width: "100%" }} className={`custom-input ${emailErrorClass}`} type="text" placeholder="Enter here"/>
                                <span class="error-input-message">{ emailErrorMessage }</span>
                            </div>
                            <div style={{ textAlign : "left" }} className="form-group">
                                <label className="custom-label block">Password</label>
                                <input onChange={this.props.authChange} name="authPassword" style={{ width: "100%" }} className={`custom-input ${passwordErrorClass}`} type="password" placeholder="Enter here"/>
                                <span class="error-input-message">{ passwordErrorMessage }</span>
                            </div>
                            <span class="error-input-message">{ errorMessage }</span>
                            <div className="text-center">
                                <button onClick={this.props.authSubmit} style={{ width: "100%" , border :"none", background : "#22C9C7", borderRadius : "8px", padding: "16px 20px", fontSize : "16px", fontWeight : "bold", textTransform : "uppercase", textAlign : "center", color : "#FAFAFA", marginTop : "30px" }}>AUTHENTICATE</button>    
                            </div>   
                        </div>
                       
                    </div>
                </div>
            </div>
        )
    }
}


export default Reauthenticate