import React, { Component } from 'react';
import $ from 'jquery';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SearchInput from '../SearchInput';
import { Redirect, withRouter, Link } from 'react-router-dom'
import { getAllCandidates, updateUser, deleteCandidate } from '../../../actions/userAction'

import CandidateList from './List';
import DeclineCandidateModal from './Modals/DeclineCandidate'
import AcceptCandidateModal from './Modals/AcceptCandidate'
import SpinnerModal from '../../Spinner/SpinnerModal'
import sgMail from '@sendgrid/mail';
import MessageModal from '../../Modals/Message'

import './candidates.css'

sgMail.setApiKey('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');

export class Index extends Component{

    constructor(){
        super();
        this.state = {
            selectedCandidate : null,
            search : ``,
            facetFilters : [
                ["status:pending"],
                ["type:translator"]
            ],
            isProcessing : false,
            searchString : "",
            pageNumber : 0,
            isDeclineCandidateModalActive : false,
            isAcceptCandidateModalActive : false,
            isMessageModalActive : false,
            messageHeader : "",
            messageSubHeader : ""
        }
    }

    componentDidMount(){
        const { auth } = this.props;
        
        if(auth.uid){
            const { search , facetFilters } = this.state;

            this.loadCandidates({
                search : search,
                facetFilters : facetFilters
            });
        }
    }

    loadCandidates = (data) => {

        data.sortBy = "fullName";
        this.props.getAllCandidates(data);

    }

    searchCandidates = (data) => {

        this.setState({
            search : data
        }, () => {

            const { search, facetFilters } = this.state;

            this.loadCandidates({
                search : search,
                facetFilters : facetFilters
            });
        })
    }

    loadMore = () => {

        const { translatorsCurrentPage, translatorsPageCount } = this.props;
        const { search, facetFilters } = this.state;

        let newPageNumber = translatorsCurrentPage + 1;

        if(newPageNumber <= translatorsPageCount ){
            this.loadCandidates({
                search : search,
                facetFilters : facetFilters,
                page : newPageNumber
            })   
        }
    }

    openDeclineCandidateModal = (candidate) => {
        this.setState({
            selectedCandidate : candidate,
            isDeclineCandidateModalActive : true
        })
    }

    closeDeclineCandidateModal = () => {
        this.setState({
            selectedCandidate : null,
            isDeclineCandidateModalActive : false
        })
    }

    openAcceptCandidateModal = (candidate) => {
        this.setState({
            selectedCandidate : candidate,
            isAcceptCandidateModalActive : true
        })
    }

    closeAcceptCandidateModal = () => {
        this.setState({
            selectedCandidate : null,
            isAcceptCandidateModalActive : false
        })
    }

    confirm = () => {
        
        const { selectedCandidate } = this.state;

        this.setState({
            isProcessing : true
        }, () => {

            this.props.deleteCandidate(selectedCandidate)

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isDeclineCandidateModalActive : false,
                    selectedCandidate : null,
                    isMessageModalActive : true,
                    messageHeader : "",
                    messageSubHeader : "Candidate has been declined."
                })
            }, 3000)

        })

    }

    acceptCandidate = () => {

        const { selectedCandidate } = this.state;

        this.setState({
            isProcessing : true
        }, () => {

            this.props.updateUser({
                uid : selectedCandidate.uid,
                status : "available"
            })

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isAcceptCandidateModalActive : false,
                    selectedCandidate : null,
                    isMessageModalActive : true,
                    messageHeader : "",
                    messageSubHeader : "Candidate has been accepted."
                })
            }, 3000)

        })

    }

    closeMessageModal = () => {
        this.setState({
            isMessageModalActive : false,
            messageHeader : "",
            messageSubHeader : ""
        }, () => {
            const { search , facetFilters } = this.state;

            this.loadCandidates({
                search : search,
                facetFilters : facetFilters
            });
        })
    }

    render(){

        const { isProcessing, isDeclineCandidateModalActive, isAcceptCandidateModalActive, selectedCandidate } = this.state;
        const { isLoggingOut, candidates, auth, userProfile } = this.props;

        const { isMessageModalActive, messageHeader, messageSubHeader } = this.state;

        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }

        if(!auth.uid && !auth.emailVerified){
            return <Redirect to="/" />
        }

        return (
            <div>
                <div style={{ display : "flex"}}>
                    <div style={{ flex : "1", textAlign : "left" }}>
                        <p className="module-header">Candidates List</p>
                    </div>
                    <div style={{ display : "flex", float : "right" }}>
                        <SearchInput searchUsers={this.searchCandidates} />
                    </div>
                </div>
                <div className="">
                    {
                        candidates.length!==0 ?  
                        <CandidateList  
                            loadMore={this.loadMore} 
                            candidates={candidates}
                            openDeclineCandidateModal={this.openDeclineCandidateModal}
                            openAcceptCandidateModal={this.openAcceptCandidateModal}
                            /> : 
                        ""
                    }
                </div>
                {
                    isProcessing &&
                    <SpinnerModal
                        isOpened={isProcessing}
                    />
                }
                {
                    isLoggingOut &&
                    <SpinnerModal
                        isOpened={isLoggingOut}
                    />
                }
                {
                    isDeclineCandidateModalActive &&
                    <DeclineCandidateModal
                        isOpened={isDeclineCandidateModalActive}
                        btnYes={"OK"}
                        btnCancel={"CANCEL"}
                        header={"Decline Candidate"}
                        confirm={this.confirm}
                        candidate={selectedCandidate}
                        closeDeclineCandidateModal={this.closeDeclineCandidateModal}
                    />
                }
                {
                    isAcceptCandidateModalActive &&
                    <AcceptCandidateModal
                        isOpened={isAcceptCandidateModalActive}
                        candidate={selectedCandidate}
                        acceptCandidate={this.acceptCandidate}
                        closeAcceptCandidateModal={this.closeAcceptCandidateModal}
                    />
                }
                {
                    isMessageModalActive &&
                    <MessageModal 
                        isOpened={isMessageModalActive}
                        closeMessageModal={this.closeMessageModal}
                        header={messageHeader}
                        subHeader={messageSubHeader}
                    />
                }
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        candidates : state.user.candidates,
        candidatesTotalRecord : state.user.candidatesTotalRecord,
        candidatesFilteredRecord : state.user.candidatesFilteredRecord,
        candidatesCurrentPage : state.user.candidatesCurrentPage,
        candidatesPageCount : state.user.candidatesPageCount,
        isLoggingOut : state.auth.isLoggingOut
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, {   
        getAllCandidates,
        updateUser,
        deleteCandidate,
    })
)(Index)
