import {
    LOGOUT,
    LOGOUT_SUCCESS,
    VALIDATE_EMAIL_ERROR,
    VALIDATE_EMAIL_SUCCESS,
    SIGN_UP_SUCCESS,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR
} from './types'

import axios from 'axios'

const permittedUsers = [
    "superuser",
    "operator",
    "accountManager"
]

export const signInUser = (email, password, remember_me = false) =>  (dispatch, getState, { getFirebase, getFirestore }) => {

    return new Promise( (resolve, reject) => {

        const firebase = getFirebase();
        const firestore = getFirestore();

        if(!remember_me){
            firebase.auth().setPersistence('session').then( () => {
                firebase.auth().signInWithEmailAndPassword(
                    email,
                    password
                ).then( (user) => {
        
                    firestore.collection('users').where('uid','==',firebase.auth().currentUser.uid).get()
                    .then( async (querySnapShots) =>{
        
                        let userData = querySnapShots.docs[0].data();
        
                        if(permittedUsers.indexOf(userData.type)===-1){
                            resolve({
                                code : "auth/error",
                                message : ""
                            })
                            firebase.auth().signOut().then(() =>{
                                dispatch({
                                    type : "LOGOUT_SUCCESS"
                                })
                            })
                        }else{
                            if(userData.type==="accountManager" || userData.type==="operator"){
                                if(!user.user.emailVerified){
        
                                    //firebase.auth().currentUser.sendEmailVerification();
        
                                    let requestBody = {
                                        userUid : userData.uid,
                                        email : userData.emailAddress
                                    }
                                    
                                    //await axios.post('https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/send_email_verification', requestBody)
                                    
                                    var sendEmailVerification = firebase.functions().httpsCallable('sendEmailVerification');
    
                                    sendEmailVerification(requestBody).then( (result) => {
                                    }).catch( error => {
                                        console.log(error);
                                    })
                                    
                                   
                                    firebase.auth().signOut().then(() =>{
                                       
                                        dispatch({
                                            type : "LOGIN_ERROR",
                                            payload : {
                                                error : {
                                                    code : "auth/unverified-email",
                                                    message : "This account is not yet confirmed. Please verify your email address"
                                                }
                                            }
                                        })

                                        resolve({
                                            code : "auth/unverified-email",
                                            message : "This account is not yet confirmed. Please verify your email address"
                                        })
                                        
                                    })
                                }else{
                                    dispatch({
                                        type : "LOGIN_SUCCESS",
                                        payload : {
                                            userData
                                        }
                                    })
                                    
                                    localStorage.setItem('rememberMe', remember_me);

                                    resolve({
                                        code : "auth/success",
                                        message : "",
                                        data : {...userData}
                                    })
                                }
                            }else if(userData.type==="superuser"){
                                dispatch({
                                    type : "LOGIN_SUCCESS",
                                    payload : {
                                        userData
                                    }
                                })

                                localStorage.setItem('rememberMe', remember_me);

                                resolve({
                                    code : "auth/success",
                                    message : "",
                                    data : {...userData}
                                })
                            }else{
                                resolve({
                                    code : "auth/error",
                                    message : ""
                                })
                                firebase.auth().signOut().then(() =>{
                                    dispatch({
                                        type : "LOGOUT_SUCCESS"
                                    })
                                })
                            }
                        }
                    })
                }).catch( error => {
                    dispatch({
                        type : "LOGIN_ERROR",
                        payload : {
                            error : {
                                code : error.code,
                                message : error.message
                            }
                        }
                    })
        
                    resolve({
                        code : "auth/error",
                        message : ""
                    })
                    
                })
            })
        }else{
            firebase.auth().setPersistence('local').then( () => {
                firebase.auth().signInWithEmailAndPassword(
                    email,
                    password
                ).then( (user) => {
        
                    firestore.collection('users').where('uid','==',firebase.auth().currentUser.uid).get()
                    .then( async (querySnapShots) =>{
        
                        let userData = querySnapShots.docs[0].data();
        
                        if(permittedUsers.indexOf(userData.type)===-1){
                            resolve({
                                code : "auth/error",
                                message : ""
                            })
                            firebase.auth().signOut().then(() =>{
                                dispatch({
                                    type : "LOGOUT_SUCCESS"
                                })
                            })
                        }else{
                            if(userData.type==="accountManager" || userData.type==="operator"){
                                if(!user.user.emailVerified){
        
                                    //firebase.auth().currentUser.sendEmailVerification();
        
                                    let requestBody = {
                                        userUid : userData.uid,
                                        email : userData.emailAddress
                                    }
                                    
                                    //await axios.post('https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/send_email_verification', requestBody)
                                    var sendEmailVerification = firebase.functions().httpsCallable('sendEmailVerification');
    
                                    sendEmailVerification(requestBody).then( (result) => {
                                    }).catch( error => {
                                        console.log(error);
                                    })
                                    
                                   
                                    firebase.auth().signOut().then(() =>{
                                        dispatch({
                                            type : "LOGIN_ERROR",
                                            payload : {
                                                error : {
                                                    code : "auth/unverified-email",
                                                    message : "This account is not yet confirmed. Please verify your email address"
                                                }
                                            }
                                        })

                                        resolve({
                                            code : "auth/unverified-email",
                                            message : "This account is not yet confirmed. Please verify your email address"
                                        })
                                    })
                                }else{
                                    dispatch({
                                        type : "LOGIN_SUCCESS",
                                        payload : {
                                            userData
                                        }
                                    })
                                    
                                    localStorage.setItem('rememberMe', remember_me);

                                    resolve({
                                        code : "auth/success",
                                        message : "",
                                        data : {...userData}
                                    })
                                }
                            }else if(userData.type==="superuser"){
                                dispatch({
                                    type : "LOGIN_SUCCESS",
                                    payload : {
                                        userData
                                    }
                                })

                                localStorage.setItem('rememberMe', remember_me);

                                resolve({
                                    code : "auth/success",
                                    message : "",
                                    data : {...userData}
                                })
                            }else{
                                resolve({
                                    code : "auth/error",
                                    message : ""
                                })
                                firebase.auth().signOut().then(() =>{
                                    dispatch({
                                        type : "LOGOUT_SUCCESS"
                                    })
                                })
                            }
                        }
                    })
                }).catch( error => {
        
                    dispatch({
                        type : "LOGIN_ERROR",
                        payload : {
                            error : {
                                code : error.code,
                                message : error.message
                            }
                        }
                    })
        
                    resolve({
                        code : "auth/error",
                        message : ""
                    })
                    
                })
            })
        }
    })
}

export const loggingOutUser = () => (dispatch) => {

    dispatch({
        type : LOGOUT
    })

}

// export const signOutUser = () => {
//     return (dispatch, getState, { getFirebase }) => {
//         const firebase = getFirebase();

//         firebase.auth().signOut().then(() =>{
//             dispatch({
//                 type : LOGOUT_SUCCESS
//             })
//         })
//     }
// }

export const signOutUser = () => (dispatch, getState, { getFirebase }) => {
    dispatch({
        type : LOGOUT
    })

    const firebase = getFirebase();
    const oldRealTimeDb = firebase.database();
    const onlineRef = oldRealTimeDb.ref('.info/connected');

    onlineRef.on('value', snapshot => {
    
        oldRealTimeDb
        .ref(`/presence/${firebase.auth().currentUser.uid}`)
        .set('offline') // The value to be set for this key when the client disconnects
        .then(() => {
            firebase.auth().signOut().then(() =>{
                dispatch({
                    type : LOGOUT_SUCCESS
                })
                localStorage.removeItem("rememberMe");
                // axios.post('https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/signout-user').then(()=>{
                // })
            })
        }).catch(error => {
            console.log(error)
        });
    
    });
}

export const updateUserLastOpenedNewTasksDate = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : "UPDATE_USER"
    })


    const firestore = getFirestore();
    const firebase = getFirebase();

    let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid);

    firestore.runTransaction( t => {
        return t.get(userRef)
                .then( doc => {
                    t.update(userRef, {
                        lastOpenedNewTasksDate : firestore.Timestamp.now()
                    })
                    return Promise.resolve(true)
                })
    }).then( (result) => {
        dispatch({
            type : "UPDATE_USER_SUCCESS"
        })
    }).catch( error => {
        console.log(error)
        dispatch({
            type : "UPDATE_USER_ERROR"
        })
    })
}

export const updateUserLastOpenedConversationDate = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : "UPDATE_USER"
    })


    const firestore = getFirestore();
    const firebase = getFirebase();

    let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid);

    firestore.runTransaction( t => {
        return t.get(userRef)
                .then( doc => {
                    t.update(userRef, {
                        lastOpenedConversationDate : firestore.Timestamp.now()
                    })
                    return Promise.resolve(true)
                })
    }).then( (result) => {
        dispatch({
            type : "UPDATE_USER_SUCCESS"
        })
    }).catch( error => {
        console.log(error)
        dispatch({
            type : "UPDATE_USER_ERROR"
        })
    })
}


export const updateCurrentUser = (data) => ( dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : "UPDATE_USER"
    })

    const firestore = getFirestore();
    const firebase = getFirebase();

    let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid);

    firestore.runTransaction( t => {
        return t.get(userRef)
                .then( doc => {
                    t.update(userRef, data)
                    return Promise.resolve(true)
                })
    }).then( (result) => {
        dispatch({
            type : "UPDATE_USER_SUCCESS"
        })
    }).catch( error => {
        console.log(error)
        dispatch({
            type : "UPDATE_USER_ERROR"
        })
    })


}

export const updateUserEmail = (data) => async (dispatch, getState, { getFirestore, getFirebase }) => {

    return new Promise ( (resolve) => {
        const firebase = getFirebase();

        let user = firebase.auth().currentUser;

        user.updateEmail(data.email).then( async function() {
            
            //await firebase.auth().currentUser.sendEmailVerification()

            let requestBody = {
                userUid : user.id,
                email : user.emailAddress
            }
            
            //await axios.post('https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/send_email_verification', requestBody)
            var sendEmailVerification = firebase.functions().httpsCallable('sendEmailVerification');
    
            sendEmailVerification(requestBody).then( (result) => {
            }).catch( error => {
                console.log(error);
            })
            
            resolve({
                code : 200
            })

        }).catch(function(error) {
            console.log(error)
            resolve({
                code : 201
            })
        });
    })


}

export const updateUserPassword = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    return new Promise ( resolve => {

        const firebase = getFirebase();

        let user = firebase.auth().currentUser;

        user.updatePassword(data.password).then(function() {
            resolve({
                code : 200
            })
        }).catch(function(error) {
            console.log(error)
            resolve({
                code : 201,
                message : error.message
            })
        });

    })

}

export const reAuthenticateUser = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    return new Promise ( resolve => {
        const firebase = getFirebase();

        let user = firebase.auth().currentUser;
        let credential = firebase.auth.EmailAuthProvider.credential(data.email, data.password);
        // Prompt the user to re-provide their sign-in credentials

        user.reauthenticateWithCredential(credential).then(function() {
            resolve({
                code : 200
            })
        }).catch(function(error) {
            resolve({
                code : 500,
                message : error.message
            })
        });
    })

}

export const uploadUserImage = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    return new Promise ( (resolve, reject) => {

        const firebase = getFirebase();
        const firestore = getFirestore();
        let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid);

        if(data.file!==null){
            firebase.storage().ref(`users/${userRef.id}/profilePictures/${data.file.name}`).put(data.file)
            .on('state_changed', (snapshot) => {
            },
            (error) =>{
                console.log(error);
            },
            () => {
                // complete function .... 
                firebase.storage().ref(`users/${userRef.id}/profilePictures`).child(data.file.name).getDownloadURL().then( url => {
                    
                    firestore.runTransaction( t => {
        
                        return t.get(userRef)
                            .then( doc => {
                                t.update(userRef, {imageUrl : url});
                                resolve({
                                    code : 200,
                                    url : url
                                })
                            });
                
                    }).then( result => {
                    }).catch( error => {
                        console.log(error)
                        resolve({
                            code : 500
                        })
                        throw(error);
                    });
                })
            });
        }
    })

}

export const setUserPresence = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore =  getFirestore();
    const firebase = getFirebase();
    const oldRealTimeDb = firebase.database();
    
    const usersRef = firestore.collection('users'); // Get a reference to the Users collection;
    const onlineRef = oldRealTimeDb.ref('.info/connected'); // Get a reference to the list of connections

    onlineRef.on('value', snapshot => {
    
        oldRealTimeDb
        .ref(`/presence/${data.uid}`)
        .onDisconnect() // Set up the disconnect hook
        .set('offline') // The value to be set for this key when the client disconnects
        .then(() => {
           
            // Set the Firestore User's online status to true
            usersRef
            .doc(data.uid)
            .set({
                presence: "online",
            }, { merge: true});  

            // Let's also create a key in our real-time database
            // The value is set to 'online'
            oldRealTimeDb.ref(`/presence/${data.uid}`).set('online');

        }).catch(error => {
            console.log(error)
        });
    
    });

    // const firestore = getFirestore();
    // const firebase = getFirebase();

    // let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid)
    // let userFdbRef = firebase.database().ref(`presence/${firebase.auth().currentUser.uid}`)
    
    // let offlineForFirestore = {
    //     presence : "offline",
    // }

    // let onlineForFirestore = {
    //     presence : "online",
    // }

    // firebase.database().ref('.info/connected').on('value', function (snapshot){
    //     if(snapshot.val()===false){
    //         return;
    //     }
    // })

    // userFdbRef.onDisconnect().set("offline").then( function () {
    //     userFdbRef.set("online")

    //     userRef.set({
    //         ...onlineForFirestore
    //     }, { merge : true })
    // })

}

export const checkAuth = () => (dispatch, getState, { getFirestore , getFirebase }) => { 
}

export const checkForDuplicateEmail = (email) => (dispatch, getState, {getFirebase, getFirestore} ) => {

    return new Promise( async (resolve) => {

        const firestore = getFirestore();
            
        firestore.collection('users').where('emailAddress','==',email).get()
            .then( (querySnapShots) => {
                if(querySnapShots.docs.length>0){

                    dispatch({
                        type : VALIDATE_EMAIL_ERROR,
                        payload : {
                            code : "auth/duplicate-email",
                            message : "This email has already been used. Please use a different email address."
                        }
                    })

                    resolve({
                        code : "auth/duplicate-email",
                        message : "This email has already been used. Please use a different email address."
                    })
                }else{

                    dispatch({
                        type : VALIDATE_EMAIL_SUCCESS
                    })

                    resolve({
                        code : "success",
                        message : "Email is available"
                    })

                }
            })
    })

}

export const signUpUser = (data) => (dispatch, getState, {getFirebase, getFirestore}) => {
    
    return new Promise((resolve, reject) => {

        const firebase = getFirebase();
        const firestore = getFirestore();

        let userData = {
            fullName : data.fullName,
            type : data.userType,
            presence : "offline",
            loginType : "emailAddress",
            emailAddress : data.emailAddress,
            country : "",
            phoneNumber : "",
            imageUrl : "",
            isDeleted : false,
            dateRegistered : firestore.Timestamp.now()
        }

        if(data.userType==="operator"){
            userData.numberOfRequestsResolved = 0;
        }

        if(data.userType==="accountManager"){
            userData.numberOfTasksAssigned = 0;
            userData.rating = 0;
        }
        
        firebase.auth().createUserWithEmailAndPassword(
            data.emailAddress,
            data.password
        ).then((response) => {
            return firestore.collection('users').doc(response.user.uid).set({...userData, uid : response.user.uid})
        }).then( async ()=>{
            let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid);

            //await firebase.auth().currentUser.sendEmailVerification()

            if(data.imageFile!==null){
                firebase.storage().ref(`users/${userRef.id}/profilePictures/${data.imageFile.name}`).put(data.imageFile)
                .on('state_changed', (snapshot) => {
                },
                (error) =>{
                    console.log(error);
                },
                () => {
                    // complete function .... 
                    firebase.storage().ref(`users/${userRef.id}/profilePictures`).child(data.imageFile.name).getDownloadURL().then( url => {
                        
                        firestore.runTransaction( t => {
            
                            return t.get(userRef)
                            .then( doc => {
                                t.update(userRef, {imageUrl : url});
                                return Promise.resolve(url)
                            });

                        }).then( async(result) => {

                            let userValue;
                            let userValueRef = firestore.collection('users').doc(userRef.id);
                            await userValueRef.get().then( docU => {
                                userValue = docU.data()
                            })

                            let suRef = firestore.collection('users').where('type','==','superuser');
                            suRef.get().then( querySnapshots => {

                                querySnapshots.forEach( doc => {

                                    let memberData = []
                                    let memberTypes = {}
                                    let members = []
                    
                                    memberData = {
                                        [doc.data().uid] : {
                                            fullName : doc.data().fullName,
                                            imageUrl : doc.data().imageUrl !== undefined ? doc.data().imageUrl : "",
                                            userType : doc.data().type
                                        },
                                        [userValue.uid] : {
                                            fullName : userValue.fullName,
                                            imageUrl : result,
                                            userType : userValue.type
                                        }
                                    }
                    
                                    members = [
                                        doc.data().uid,
                                        userValue.uid
                                    ]
                        
                                    memberTypes = {
                                        [userValue.type] : true,
                                        superuser : true
                                    }
                                    
                                    let conversationRef = firestore.collection('conversations').doc();

                                    conversationRef.set({
                                        uid : conversationRef.id,
                                        members : members,
                                        memberData : memberData,
                                        memberTypes : memberTypes,
                                        lastMessageSentText : "",
                                        lastMessageSentBy : "",
                                        lastMessageSentByUserType : "",
                                        task : "",
                                        taskTitle : "",
                                        initiatedBy : doc.data().uid,
                                        lastMessageSentAt : firestore.Timestamp.now()
                                    }, { merge: true});

                                })
                            });

                        }).catch( error => {
                            console.log(error)
                            throw(error);
                        });
                    })
                });
            }

            let requestBody = {
                userUid : firebase.auth().currentUser.uid,
                email : data.emailAddress
            }
            
            //await firebase.auth().currentUser.sendEmailVerification()
            //axios.post('https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/send_email_verification', requestBody)
            var sendEmailVerification = firebase.functions().httpsCallable('sendEmailVerification');
    
            sendEmailVerification(requestBody).then( (result) => {
            }).catch( error => {
                console.log(error);
            })
           
            await firebase.auth().currentUser.updateProfile({
                displayName : data.fullName,
            })

            let inviteRef = firestore.collection('inviteCodes').where('recipient','==',data.emailAddress);

            await inviteRef.get().then( async (querySnapShots) => {

                let updateInviteCode = firestore.collection('inviteCodes').doc(querySnapShots.docs[0].data().uid);

                await updateInviteCode.set({
                    dateExpired : firestore.Timestamp.now()
                }, { merge : true })

            })

            firebase.auth().signOut();
          
            resolve({
                code : "success"
            })

            dispatch({
                type : SIGN_UP_SUCCESS
            })

          
        }).catch( error => {
            console.log(error)
        })

    })

}

export const updateAccountManagerTasksAssigned = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : UPDATE_USER
    })

    return new Promise ( (resolve) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid);

        firestore.runTransaction( t => {
            return t.get(userRef)
                    .then( doc => {

                        let count = doc.data().numberOfTasksAssigned;
                        count = count + 1;

                        t.update(userRef, { numberOfTasksAssigned : count })
                        return Promise.resolve(true)
                    })
        }).then( (result) => {
            dispatch({
                type : UPDATE_USER_SUCCESS
            })
            
            resolve({
                code : 200
            })
        }).catch( error => {
            console.log(error)
            dispatch({
                type : UPDATE_USER_ERROR
            })

            resolve({
                code : 500
            })
        })


    })

}

export const updateOperatorRequestsResolved = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : UPDATE_USER
    })

    return new Promise ( (resolve) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid);

        firestore.runTransaction( t => {
            return t.get(userRef)
                    .then( doc => {

                        let count = doc.data().numberOfRequestsResolved;
                        count = count + 1;

                        t.update(userRef, { numberOfRequestsResolved : count })
                        return Promise.resolve(true)
                    })
        }).then( (result) => {
            dispatch({
                type : UPDATE_USER_SUCCESS
            })
            
            resolve({
                code : 200
            })
        }).catch( error => {
            console.log(error)
            dispatch({
                type : UPDATE_USER_ERROR
            })

            resolve({
                code : 500
            })
        })


    })

}


export const testCallable = () => (dispatch, getState, { getFirebase, getFirestore }) => {

    return new Promise ( (resolve) => {
        const firebase = getFirebase();

        var testCallable = firebase.functions().httpsCallable('testCallable');

        testCallable({ data : "test" }).then( (result) => {
            resolve(result)
        }).catch( error => {
            console.log(error);
        })
    })

}