import React, { Component } from 'react';
import $ from 'jquery';
import _ from 'lodash/'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'
import {
    sendAdminInvite
} from '../../../actions/userAction';
import {
    checkForDuplicateEmail
} from '../../../actions/authAction';

import SpinnerModal from '../../Spinner/SpinnerModal'
import MessageModal from '../../Modals/Message';

export class Index extends Component {

    constructor(){
        super();
        this.state = {
            activeTab : "operator",
            tabName : "Operator",
            isAuthenticated : false,
            isProcessing : false,
            fullName : "",
            firstName : "",
            lastName : "",
            emailAddress : "",
            isMessageModalActive : false
        }

        this.checkForEmailDuplication = _.debounce(this.checkForEmailDuplication,500);
    }

    componentDidMount(){

        const { auth , userProfile } = this.props;

        if(auth.uid){
            this.setState({
                isAuthenticated : true
            })
        }

        if(userProfile.hasOwnProperty('fullName')){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    changeTab = (tab) => {
        this.setState({
            activeTab : tab,
            tabName : tab==="operator" ? "Operator" : "Account Manager"
        })
    }

    onChange = (e) => {
        if(e.target.name==="emailAddress"){
            let regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;
            let emailVal = e.target.value;

            $('#emailAddress').removeClass('error-input');
            $('#emailAddress').removeClass('success-input');
            $('#emailAddress').next('.error-input-message').text('');
            $('#emailAddress').next('.success-input-message').text('');
            $('#emailAddress').next().next().text('');

            if(!regExp.test(emailVal)){
                $('#emailAddress').addClass('error-input');
                $('#emailAddress').next('.error-input-message').text( 'Incorrect email format. Please try again.');
                this.setState({
                    emailAddress : ""
                })
            }else{

                if(emailVal!==""){  
                    this.checkForEmailDuplication(emailVal);                    
                }else{
                    this.setState({
                        emailAddress : ""
                    })
                }
            }
        }


        if(e.target.name==="firstName"){

            $('#firstName').removeClass('error-input');
            $('#firstName').removeClass('success-input');
            $('#firstName').next('.error-input-message').text('');

            let regExp = /^[A-Za-z\s]+$/;
            if(e.target.value.trim().length===0){
                return
            }

            if(!regExp.test(e.target.value) && e.target.value!==""){
                $('#firstName').addClass('error-input');
                $('#firstName').next('.error-input-message').text(`Name should not contain any numbers or special characters.`);
                this.setState({
                    firstName : ""
                })
            }else{

                $('#firstName').removeClass('error-input');
                $('#firstName').next('.error-input-message').text('');
                this.setState({
                    firstName : e.target.value
                })
            }
        }


        if(e.target.name==="lastName"){

            $('#lastName').removeClass('error-input');
            $('#lastName').removeClass('success-input');
            $('#lastName').next('.error-input-message').text('');

            let regExp = /^[A-Za-z\s]+$/;
            if(e.target.value.trim().length===0){
                return
            }

            if(!regExp.test(e.target.value) && e.target.value!==""){
                $('#lastName').addClass('error-input');
                $('#lastName').next('.error-input-message').text(`Name should not contain any numbers or special characters`);
                this.setState({
                    lastName : ""
                })
            }else{

                $('#lastName').removeClass('error-input');
                $('#lastName').next('.error-input-message').text('');
                this.setState({
                    lastName : e.target.value
                })
            }
        }
        // }else{
        //     this.setState({
        //         [e.target.name] : e.target.value
        //     })
        // }


       
    }

    sendInvitation = (lang = 'en') => {

        const { firstName, lastName, fullName, emailAddress, activeTab } = this.state;

        let errCount = 0;

        $('#firstName').removeClass("error-input");
        if(firstName.trim()===""){
            errCount = errCount + 1;
            $('#firstName').addClass("error-input")
        }

        $('#lastName').removeClass("error-input");
        if(lastName.trim()===""){
            errCount = errCount + 1;
            $('#lastName').addClass("error-input")
        }

        $('#emailAddress').removeClass("error-input");
        if(emailAddress.trim()===""){
            errCount = errCount + 1;
            $('#emailAddress').addClass("error-input")
        }

        if(errCount==0){

            this.setState({
                isProcessing : true
            }, () => {
                
                const { firstName, lastName } = this.state;

                let wholeName = `${lastName}, ${firstName}`

                this.props.sendAdminInvite({
                    userType : activeTab,
                    userCode : activeTab == "operator" ? "op" : "am",
                    fullName : wholeName,
                    emailAddress : emailAddress,
                    lang : lang
                }).then( (result) => {

                    this.setState({
                        isProcessing : false,
                        isMessageModalActive : true,
                        emailAddress : "",
                        fullName : "",
                        firstName : "",
                        lastName : ""
                    }, () => {
                        $('#firstName').val("")
                        $('#lastName').val("")
                        $('#emailAddress').val("")
                    })
                })
            })
        }
    }

    openMessageModal = () => {
        this.setState({
            isMessageModalActive : true
        })
    }

    closeMessageModal = () => {
        this.setState({
            isMessageModalActive : false
        })
    }

    checkForEmailDuplication = async (email) => {
      
        let x = await this.props.checkForDuplicateEmail(email);
        if(x.code==="success"){
            $('#emailAddress').removeClass('error-input');
            $('#emailAddress').removeClass('success-input');
            $('#emailAddress').next('.error-input-message').text('');
            $('#emailAddress').next('.success-input-message').text('');
            $('#emailAddress').next().next().text('');
            $('#emailAddress').addClass('success-input');
            $('#emailAddress').next().next().text(x.message);

            this.setState({
                emailAddress : email
            })
        }else{
            $('#emailAddress').removeClass('error-input');
            $('#emailAddress').removeClass('success-input');
            $('#emailAddress').next('.error-input-message').text('');
            $('#emailAddress').next('.success-input-message').text('');
            $('#emailAddress').next().next().text('');
            $('#emailAddress').addClass('error-input');
            $('#emailAddress').next('.error-input-message').text(x.message);
            this.setState({
                emailAddress : ""
            })
        }
    }

    render(){

        const { activeTab, tabName, isProcessing, isAuthenticated } = this.state;
        const { isLoggingOut, auth, userProfile } = this.props;

        const { fullName, emailAddress } = this.state;
        const { isMessageModalActive } = this.state;

        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }
        
        if(!auth.uid && !auth.emailVerified){
            return <Redirect to="/" />
        }else{
            if(isAuthenticated){
                if(userProfile.type!=="superuser"){
                    return "NOT ALLOWED";
                }
            }
        }

        return (
            <div style={{ width: "50%", margin : "0 auto" }}>
                <p style={{ fontSize : "40px", color : "#414042", marginLeft: "32px", marginBottom : "32px" }}>Invite an {tabName}</p>
                <ul className="nav nav-tabs nav-fill nav-header" role="tablist" style={{ marginTop : "5px !important", marginRight : "40px", marginLeft : "40px", marginTop : "32px" }}>
                    <li onClick={ () => this.changeTab('operator') } className="nav-item" >
                        <a className={`nav-link ${activeTab==="operator" ? "active" : ""}`} id="operator-tab" data-toggle="tab" href="#operator" role="tab" aria-controls="operator" aria-selected={ activeTab === "operator" }>Operator</a>
                    </li>
                    <li onClick={ () => this.changeTab('accountManager') } className="nav-item">
                        <a className={`nav-link ${activeTab==="accountManager" ? "active" : ""}`} id="accountManager-tab" data-toggle="tab" href="#accountManager" role="tab" aria-controls="accountManager" aria-selected={ activeTab === "accountManager" }>Account Manager</a>
                    </li>
                </ul>
                <div style={{ padding : "32px" }}>
                    <p style={{ fontSize : "16px", color : "#414042", marginBottom : "32px" }}>Fill in the fields for adding an { activeTab==="operator" ?  "operator" : "account manager"} to the system. An invitation letter and password will be sent by mail</p>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label className="align-left custom-label block">First name</label>
                                <input onChange={this.onChange} id="firstName" name="firstName" style={{ width: "100%" }} className="custom-input" type="text" placeholder="Enter here"/>
                                <span className="align-left block error-input-message"></span>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label className="align-left custom-label block">Last name</label>
                                <input onChange={this.onChange} id="lastName" name="lastName" style={{ width: "100%" }} className="custom-input" type="text" placeholder="Enter here"/>
                                <span className="align-left block error-input-message"></span>
                            </div>
                        </div>
                    </div>
                    {/* <div className="form-group">
                        <label className="custom-label block">Full name</label>
                        <input onChange={this.onChange} id="fullName" name="fullName" style={{ width: "100%" }} className={`custom-input`} type="text" placeholder="Enter here"/>
                        <span class="error-input-message"></span>
                    </div> */}
                    <div className="form-group">
                        <label className="custom-label block">Email</label>
                        <input onChange={this.onChange} id="emailAddress" name="emailAddress" style={{ width: "100%" }} className={`custom-input`} type="text" placeholder="Enter here"/>
                        <span class="error-input-message"></span>
                    </div>
                    
                    <div style={{ display : "flex" }}>
                        <div style={{ flex : "1" }}>
                            <button className="custom-btn" style={{ width: "90%", marginTop : '22px' }} onClick={ () => this.sendInvitation('ar')}>SEND IN ARABIC</button>
                        </div>
                        <div style={{ flex : "1", textAlign : "right" }}>
                            <button className="custom-btn" style={{ width: "90%", marginTop : '22px' }} onClick={ () => this.sendInvitation('en')}>SEND IN ENGLISH</button>
                        </div>
                    </div>
                </div>
                {
                    isLoggingOut && 
                    <SpinnerModal
                        isOpened={isLoggingOut}
                    />
                }
                {
                    isProcessing && 
                    <SpinnerModal
                        isOpened={isProcessing}
                    />
                }
                {
                    isMessageModalActive &&
                    <MessageModal 
                        isOpened={isMessageModalActive}
                        closeMessageModal={this.closeMessageModal}
                        header={""}
                        subHeader={"User has been invited."}
                    />
                }
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        isLoggingOut : state.auth.isLoggingOut
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, {
        sendAdminInvite,
        checkForDuplicateEmail
    })
)(Index)