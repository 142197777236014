import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import moment from 'moment'

const renderRating = (count) => {

    let rating = [];
    
    let ratingCt =0;

    for(let i=0; i<count; i++){
        rating.push(<img style={{ width: "15px", height : "15px" }} src={require('../../../images/icon-rating.png')}/>)
        ratingCt++;
    }
    
    for(let i=ratingCt; i<5; i++){
        rating.push(<img style={{ width: "15px", height : "15px" }} src={require('../../../images/icon-rating-disabled.png')}/>)
    }

    return rating;

}

const renderName = (fullName) => {

    let firstName = fullName.split(",")[1].trim();
    let lastName = fullName.split(",")[0];

    return `${firstName} ${lastName}`

}

export class List extends Component {

    scrollToBottom = () => {
        const { taskList } = this.refs;
        const scrollHeight = taskList.scrollHeight;
        const height = taskList.clientHeight;
        const maxScrollTop = scrollHeight - height;
        ReactDOM.findDOMNode(taskList).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    render(){

        const { isGettingMoreTranslators, translators } = this.props;

        return (
        
            <div className="div-holder">
                <div className="translator-table-div">
                    <table>
                        <thead>
                            <tr>
                                <th>TRANSLATOR</th>
                                <th>RATING</th>
                                <th style={{ textAlign : "center" }}>HOW MANY TASKS</th>
                                <th>DATE JOINED</th>
                                <th>SPECIALTY</th>
                                <th>EARNINGS</th>
                                <th style={{ width: "70px" }}>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            translators.map( (data, i) => (
                                <tr>
                                    <td><img style={{ width: "36px", height : "36px", borderRadius : "100%", marginRight : "15px" }} src={ data.imageUrl !== "" ? data.imageUrl : require('../../../images/default-user.jpg')}/>{ renderName(data.fullName) }</td>
                                    <td>{ renderRating(data.rating) }</td>
                                    <td style={{ textAlign : "center" }}>{ data.numberOfAssignedTasks }</td>
                                    <td>{ moment.unix(data.dateRegistered._seconds).format('MMMM DD, YYYY') }</td>
                                    <td>{ data.specialty }</td>
                                    <td>${ data.hasOwnProperty('amountEarned') ? data.amountEarned.toFixed(2) : 0 }</td>
                                    <td style={{ position : "relative" }}>
                                        <div class="btn-group" style={{ position : "absolute", top : "10px", right : "15px" }}>
                                            <button type="button" class="btn btn-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            . . .
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right custom-dropdown-right">
                                            {/* <button class="dropdown-item custom-dropdown-item" type="button">Hire Translator</button> */}
                                            { data.status!=="pending" && <button onClick={ () => this.props.openFireTranslatorModal(data) } class="dropdown-item custom-dropdown-item" type="button">Fire Translator</button> }
                                            { data.status==="pending" && <button onClick={ () => this.props.openAcceptCandidateModal(data) } class="dropdown-item custom-dropdown-item" type="button">Hire Translator</button> }
                                            { data.status==="pending" && <button onClick={ () => this.props.openDeclineCandidateModal(data) } class="dropdown-item custom-dropdown-item" type="button">Decline Translator</button> }
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
                {
                    (translators.length!==0) &&
                    isGettingMoreTranslators ? <div style={{ paddingTop : "15px" }}>
                        <div class="custom-get-more-spinner spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div> : <div className="text-center" style={{ marginTop : "15px" }}>
                        <button onClick={this.props.loadMore} className="load-more-button">LOAD MORE</button>
                    </div>
                }
            </div>
           
        )

    }

}

export default List;
