import React, { Component } from 'react';
import $ from 'jquery';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SearchInput from '../SearchInput';
import { Redirect, withRouter, Link } from 'react-router-dom'
import { getAllOperators, fireUser } from '../../../actions/userAction'

import SpinnerModal from '../../Spinner/SpinnerModal'
import SUList from './SUList'
import FireOperatorModal from './Modals/FireOperator'
import MessageModal from '../../Modals/Message'

import './operators.css'

export class Index extends Component{

    constructor(){
        super();
        this.state = {
            search : ``,
            facetFilters : [
                ["type:operator"],
                ["status:- disabled"]
            ],
            isProcessing : false,
            searchString : "",
            pageNumber : 0,
            selectedOperator : null,
            isFireOperatorModalActive : false,
            showLoadMore : true,
            isMessageModalActive : false
        }
    }

    componentDidMount(){
        const { auth } = this.props;
        
        if(auth.uid){
            
            const { search , facetFilters } = this.state;

            this.loadOperators({
                search : search,
                facetFilters : facetFilters
            });
          
        }
    }

    loadOperators = (data) => {

        data.sortBy = "fullName";
        this.props.getAllOperators(data);

    }

    searchOperators = (data) => {

        this.setState({
            search : data
        }, () => {

            const { search, facetFilters } = this.state;

            this.loadOperators({
                search : search,
                facetFilters : facetFilters
            });
        })
    }

    loadMore = () => {

        const { operatorsCurrentPage, operatorsPageCount } = this.props;
        const { search, facetFilters } = this.state;

        let newPageNumber = operatorsCurrentPage + 1;

        if(newPageNumber <= operatorsPageCount ){
            this.loadOperators({
                search : search,
                facetFilters : facetFilters,
                page : newPageNumber
            })   
        }
    }

    openChat = (recipient) => {
        this.props.history.push({
            pathname : "/chat",
            state : {
                tab : "operator",
                task : null,
                recipient : recipient
            }
        })
    }

    openFireOperatorModal = (customer) => {
        this.setState({
            selectedOperator : customer,
            isFireOperatorModalActive : true
        })
    }

    closeFireOperatorModal = () => {
        this.setState({
            selectedOperator : null,
            isFireOperatorModalActive : false
        })
    }

    fireOperator = (data) => {

        const { selectedOperator } = this.state;

        this.setState({
            isProcessing : true
        }, () => {

            this.props.fireUser({
                uid : selectedOperator.uid,
                status : "disabled",
                disabledReason : data.disabledReason
            })

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isFireOperatorModalActive : false,
                    isMessageModalActive : true,
                    selectedOperator : null
                })
            }, 3000)

        })

    }

    openMessageModal = () => {
        this.setState({
            isMessageModalActive : true
        })
    }

    closeMessageModal = () => {
        this.setState({
            isMessageModalActive : false
        }, () => {
            const { search , facetFilters } = this.state;

            this.loadOperators({
                search : search,
                facetFilters : facetFilters
            });
        })
    }

    render(){

        const { isProcessing } = this.state;
        const { isLoggingOut, operators, auth, userProfile } = this.props;

        const { operatorsTotalRecord, isGettingMoreOperators, isGettingOperators } = this.props;
        const { isFireOperatorModalActive, selectedOperator, showLoadMore } = this.state;

        const { isMessageModalActive } = this.state;

        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }
        
        if(!auth.uid && !auth.emailVerified){
            return <Redirect to="/" />
        }

        return (
            <div>
                <div style={{ display : "flex"}}>
                    <div style={{ flex : "1", textAlign : "left" }}>
                        <p className="module-header">Operators List</p>
                    </div>
                    <div style={{ display : "flex", float : "right" }}>
                        <SearchInput searchUsers={this.searchOperators} />
                    </div>
                </div>
                {
                    userProfile.type==="superuser" &&
                    <div>
                        {
                            isGettingOperators ?
                            <div className="d-flex justify-content-center">
                                <div style={{ height: "5rem", width: "5rem" }} className="custom-spinner-border spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            operatorsTotalRecord!== 0 ?
                            <SUList 
                                openChat={this.openChat}
                                loadMore={this.loadMore}
                                showLoadMore={showLoadMore}
                                isGettingMoreOperators={isGettingMoreOperators}
                                operators={operators}
                                openFireOperatorModal={this.openFireOperatorModal}
                            /> : ""
                        }
                    </div>
                }
                {
                    isProcessing &&
                    <SpinnerModal
                        isOpened={isProcessing}
                    />
                }
                {
                    isLoggingOut &&
                    <SpinnerModal
                        isOpened={isLoggingOut}
                    />
                }
                {
                    isFireOperatorModalActive &&
                    <FireOperatorModal 
                        isOpened={isFireOperatorModalActive}
                        operator={selectedOperator}
                        closeFireOperatorModal={this.closeFireOperatorModal}
                        fireOperator={this.fireOperator}
                    />
                }
                {
                    isMessageModalActive &&
                    <MessageModal 
                        isOpened={isMessageModalActive}
                        closeMessageModal={this.closeMessageModal}
                        header={""}
                        subHeader={"Operator has been fired."}
                    />
                }
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        isGettingOperators : state.user.isGettingOperators,
        isGettingMoreOperators : state.user.isGettingMoreOperators,
        operators : state.user.operators,
        operatorsTotalRecord : state.user.operatorsTotalRecord,
        operatorsFilteredRecord : state.user.operatorsFilteredRecord,
        operatorsCurrentPage : state.user.operatorsCurrentPage,
        operatorsPageCount : state.user.operatorsPageCount,
        isLoggingOut : state.auth.isLoggingOut
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, {   
        getAllOperators,
        fireUser
    })
)(Index)
