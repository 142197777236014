import algoliasearch from 'algoliasearch'
import moment from 'moment';
import exactMath from 'exact-math'

import {
    GET_USER_TRANSLATORS,
    GET_USER_TRANSLATORS_SUCCESS,
    GET_USER_TRANSLATORS_PAGINATE,
    GET_USER_TRANSLATORS_PAGINATE_SUCCESS,
    GET_USER_CUSTOMERS,
    GET_USER_CUSTOMERS_SUCCESS,
    GET_USER_CUSTOMERS_PAGINATE,
    GET_USER_CUSTOMERS_PAGINATE_SUCCESS,
    GET_USER_CANDIDATES,
    GET_USER_CANDIDATES_SUCCESS,
    GET_USER_CANDIDATES_ERROR,
    GET_USER_CANDIDATES_PAGINATE,
    GET_USER_CANDIDATES_PAGINATE_SUCCESS,
    GET_USER_CANDIDATES_PAGINATE_ERROR,
    GET_USER_ACCOUNT_MANAGERS,
    GET_USER_ACCOUNT_MANAGERS_SUCCESS,
    GET_USER_ACCOUNT_MANAGERS_ERROR,
    GET_USER_ACCOUNT_MANAGERS_PAGINATE,
    GET_USER_ACCOUNT_MANAGERS_PAGINATE_SUCCESS,
    GET_USER_ACCOUNT_MANAGERS_PAGINATE_ERROR,
    GET_USER_OPERATORS,
    GET_USER_OPERATORS_SUCCESS,
    GET_USER_OPERATORS_ERROR,
    GET_USER_OPERATORS_PAGINATE,
    GET_USER_OPERATORS_PAGINATE_SUCCESS,
    GET_USER_OPERATORS_PAGINATE_ERROR,
    BLOCK_CUSTOMER,
    BLOCK_CUSTOMER_SUCCESS,
    BLOCK_CUSTOMER_ERROR,
    FIRE_USER,
    FIRE_USER_SUCCESS,
    FIRE_USER_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    GET_EMAIL_VERIFICATION_CONTENT,
    GET_EMAIL_VERIFICATION_CONTENT_SUCCESS,
    GET_EMAIL_VERIFICATION_CONTENT_ERROR,
} from './types'

const client = algoliasearch('QZDWQC5AMN','c8973aeead0de12842cdf80db955436a',{
    timeouts: {
      connect: 1000,
      read: 2 * 1000,
      write: 30 * 1000
    }
})

export const getAllUser = () => ( dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();

    firestore.collection('users').get()
            .then( querySnapshots => {

                let users = [];
                
                querySnapshots.forEach( doc => {
                    users.push(doc.data())
                })
            })

}

export const getAllCustomers = (data) => ( dispatch, getState, { getFirestore, getFirebase }) => {

    if(data !==undefined && !data.hasOwnProperty('page')){
        dispatch({
            type : GET_USER_CUSTOMERS
        })
    }else{
        dispatch({
            type : GET_USER_CUSTOMERS_PAGINATE
        })
    }

    let searchSettings = {
        query : data.search,
        facetFilters : data.facetFilters
    }
    
    if(data!==undefined && data.hasOwnProperty('page')){
        searchSettings.page  = data.page
    }

    if(data!==undefined && data.hasOwnProperty('hitsPerPage')){
        searchSettings.hitsPerPage = data.hitsPerPage;
    }

    let indexName = process.env.REACT_APP_ENV=="production " ? "prod_USERS" : "dev_USERS";
    let rankSettings = {};

    if(data!==undefined && data.hasOwnProperty('sortBy')){
        if(data.sortBy==="rating"){
            indexName = process.env.REACT_APP_ENV=="production " ? "prod_USERS_RATING_DESC" : "dev_USERS_RATING_DESC";
            rankSettings = {
                ranking : [
                    "desc(rating)",
                    "asc(firstName)",
                    "typo",
                    "geo",
                    "words",
                    "filters",
                    "proximity",
                    "attribute",
                    "exact",
                    "custom"
                ]
            }
        }
        if(data.sortBy==="fullName"){
            indexName = process.env.REACT_APP_ENV=="production " ? "prod_USERS_FULLNAME_DESC" : "dev_USERS_FULLNAME_DESC";
            rankSettings = {
                ranking : [
                    "asc(firstName)",
                    "typo",
                    "geo",
                    "words",
                    "filters",
                    "proximity",
                    "attribute",
                    "exact",
                    "custom"
                ]
            }
        }
    }

    const customersIndex = client.initIndex(indexName);
    customersIndex.setSettings(rankSettings)

    customersIndex.search(searchSettings, async (error, res) => {
        if( error ){
            console.log(error)
            throw(error)
        }
        
        const firestore = getFirestore();
        // let result = res;

        // for(let r=0; r<result.hits.length; r++){
        //     let taskRef = await firestore.collection('tasks').where('customer','==',result.hits[r].uid).get();
        //     result.hits[r].tasksCreated = taskRef.docs.length

        //     let totalSpent = 0;

        //     taskRef.forEach( doc => {
        //         totalSpent = totalSpent + (doc.data().price - doc.data().remainingAmount)
        //     })

        //     result.hits[r].spent = totalSpent;
        // }

        if(data !==undefined && data.hasOwnProperty('page')){
            dispatch({
                type : GET_USER_CUSTOMERS_PAGINATE_SUCCESS,
                payload : {
                    data : res
                }
            })
        }else{
            dispatch({
                type : GET_USER_CUSTOMERS_SUCCESS,
                payload : {
                    data : res
                }
            })
        }
    })
}

export const getAllAccountManagers = (data) => ( dispatch, getState, { getFirestore, getFirebase }) => {

    if(data !==undefined && !data.hasOwnProperty('page')){
        dispatch({
            type : GET_USER_ACCOUNT_MANAGERS
        })
    }else{
        dispatch({
            type : GET_USER_ACCOUNT_MANAGERS_PAGINATE
        })
    }

    let searchSettings = {
        query : data.search,
        facetFilters : data.facetFilters
    }
    
    if(data!==undefined && data.hasOwnProperty('page')){
        searchSettings.page  = data.page
    }

    if(data!==undefined && data.hasOwnProperty('hitsPerPage')){
        searchSettings.hitsPerPage = data.hitsPerPage;
    }

    let indexName = process.env.REACT_APP_ENV=="production " ? "prod_USERS" : "dev_USERS";
    let rankSettings = {};

    if(data!==undefined && data.hasOwnProperty('sortBy')){
        if(data.sortBy==="rating"){
            indexName =  process.env.REACT_APP_ENV=="production " ? "prod_USERS_RATING_DESC" : "dev_USERS_RATING_DESC";
            rankSettings = {
                ranking : [
                    "desc(rating)",
                    "asc(firstName)",
                    "typo",
                    "geo",
                    "words",
                    "filters",
                    "proximity",
                    "attribute",
                    "exact",
                    "custom"
                ]
            }
        }
        if(data.sortBy==="fullName"){
            indexName = process.env.REACT_APP_ENV=="production " ? "prod_USERS_FULLNAME_DESC" : "dev_USERS_FULLNAME_DESC";
            rankSettings = {
                ranking : [
                    "asc(firstName)",
                    "typo",
                    "geo",
                    "words",
                    "filters",
                    "proximity",
                    "attribute",
                    "exact",
                    "custom"
                ]
            }
        }
    }

    const accountManagersIndex = client.initIndex(indexName);
    accountManagersIndex.setSettings(rankSettings)

    accountManagersIndex.search(searchSettings, async (error, res) => {
        if( error ){
            console.log(error)
            throw(error)
        }

        const firestore = getFirestore();
        const firebase = getFirebase();

        // let result = res;

        // for(let r=0; r<result.hits.length; r++){
        //     let taskRef = await firestore.collection('tasks').where('accountManager','==',result.hits[r].uid).get();
        //     result.hits[r].tasksAssigned = taskRef.docs.length
        // }
      
        if(data !==undefined && data.hasOwnProperty('page')){
            dispatch({
                type : GET_USER_ACCOUNT_MANAGERS_PAGINATE_SUCCESS,
                payload : {
                    data : res
                }
            })
        }else{
            dispatch({
                type : GET_USER_ACCOUNT_MANAGERS_SUCCESS,
                payload : {
                    data : res
                }
            })
        }
    })
}

export const getAllOperators = (data) => ( dispatch, getState, { getFirestore, getFirebase }) => {

    if(data !==undefined && !data.hasOwnProperty('page')){
        dispatch({
            type : GET_USER_OPERATORS
        })
    }else{
        dispatch({
            type : GET_USER_OPERATORS_PAGINATE
        })
    }

    let searchSettings = {
        query : data.search,
        facetFilters : data.facetFilters
    }
    
    if(data!==undefined && data.hasOwnProperty('page')){
        searchSettings.page  = data.page
    }

    if(data!==undefined && data.hasOwnProperty('hitsPerPage')){
        searchSettings.hitsPerPage = data.hitsPerPage;
    }

    let indexName = process.env.REACT_APP_ENV=="production " ? "prod_USERS" : "dev_USERS";
    let rankSettings = {};

    if(data!==undefined && data.hasOwnProperty('sortBy')){
        if(data.sortBy==="rating"){
            indexName = process.env.REACT_APP_ENV=="production " ?  "prod_USERS_RATING_DESC" : "dev_USERS_RATING_DESC"
            rankSettings = {
                ranking : [
                    "desc(rating)",
                    "asc(firstName)",
                    "typo",
                    "geo",
                    "words",
                    "filters",
                    "proximity",
                    "attribute",
                    "exact",
                    "custom"
                ]
            }
        }
        if(data.sortBy==="fullName"){
            indexName = process.env.REACT_APP_ENV=="production " ?  "prod_USERS_FULLNAME_DESC" :  "dev_USERS_FULLNAME_DESC"
            rankSettings = {
                ranking : [
                    "asc(firstName)",
                    "typo",
                    "geo",
                    "words",
                    "filters",
                    "proximity",
                    "attribute",
                    "exact",
                    "custom"
                ]
            }
        }
    }

    const operatorsIndex = client.initIndex(indexName);
    operatorsIndex.setSettings(rankSettings)

    operatorsIndex.search(searchSettings, async (error, res) => {
        if( error ){
            console.log(error)
            throw(error)
        }

        let result = res;

        // const firestore = getFirestore();
        // const firebase = getFirebase();

        // for(let r=0; r<result.hits.length; r++){
        //     let escalationRef = await firestore.collection('escalations').where('operator','==',result.hits[r].uid).get();
        //     result.hits[r].solvedEscalations = escalationRef.docs.length
        // }
      
        if(data !==undefined && data.hasOwnProperty('page')){
            dispatch({
                type : GET_USER_OPERATORS_PAGINATE_SUCCESS,
                payload : {
                    data : res
                }
            })
        }else{
            dispatch({
                type : GET_USER_OPERATORS_SUCCESS,
                payload : {
                    data : res
                }
            })
        }
    })
}

export const getAllTranslators = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    if(data !==undefined && !data.hasOwnProperty('page')){
        dispatch({
            type : GET_USER_TRANSLATORS
        })
    }else{
        dispatch({
            type : GET_USER_TRANSLATORS_PAGINATE
        })
    }

    let searchSettings = {
        query : data.search,
        facetFilters : data.facetFilters
    }
    
    if(data!==undefined && data.hasOwnProperty('page')){
        searchSettings.page  = data.page
    }

    if(data!==undefined && data.hasOwnProperty('hitsPerPage')){
        searchSettings.hitsPerPage = data.hitsPerPage;
    }

    let indexName = process.env.REACT_APP_ENV=="production " ? "prod_USERS" : "dev_USERS";
    let rankSettings = {};

    if(data!==undefined && data.hasOwnProperty('sortBy')){
        if(data.sortBy==="rating"){
            indexName = process.env.REACT_APP_ENV=="production " ? "prod_USERS_RATING_DESC" : "dev_USERS_RATING_DESC";
            rankSettings = {
                ranking : [
                    "desc(rating)",
                    "asc(firstName)",
                    "typo",
                    "geo",
                    "words",
                    "filters",
                    "proximity",
                    "attribute",
                    "exact",
                    "custom"
                ]
            }
        }
        if(data.sortBy==="fullName"){
            indexName = process.env.REACT_APP_ENV=="production " ? "prod_USERS_FULLNAME_DESC" : "dev_USERS_FULLNAME_DESC";
            rankSettings = {
                ranking : [
                    "asc(firstName)",
                    "typo",
                    "geo",
                    "words",
                    "filters",
                    "proximity",
                    "attribute",
                    "exact",
                    "custom"
                ]
            }
        }
    }

    const translatorsIndex = client.initIndex(indexName);
    translatorsIndex.setSettings(rankSettings)

    translatorsIndex.search(searchSettings, async (error, res) => {
        if( error ){
            console.log(error)
            throw(error)
        }

        const firestore = getFirestore();

        // let result = res;

        // for(let r=0; r<result.hits.length; r++){
        //     let taskRef = await firestore.collection('tasks').where('assignedTranslator','==',result.hits[r].uid).get();
        //     result.hits[r].tasksAssigned = taskRef.docs.length

        //     let totalEarnings = 0;

        //     taskRef.forEach( doc => {
        //         totalEarnings = totalEarnings + doc.data().price
        //     })

        //     result.hits[r].earnings = totalEarnings;
        // }
        
        
        if(data !==undefined && data.hasOwnProperty('page')){
            dispatch({
                type : GET_USER_TRANSLATORS_PAGINATE_SUCCESS,
                payload : {
                    data : res
                }
            })
        }else{
            dispatch({
                type : GET_USER_TRANSLATORS_SUCCESS,
                payload : {
                    data : res
                }
            })
        }
    })
}

export const updateUser = (data) => ( dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : "UPDATE_USER"
    })

    const firestore = getFirestore();

    let userRef = firestore.collection('users').doc(data.uid);
    
    firestore.runTransaction( t => {
        return t.get(userRef)
                .then( doc => {
                    t.update(userRef, data)
                    return Promise.resolve(true)
                })
    }).then( (result) => {
        dispatch({
            type : "UPDATE_USER_SUCCESS"
        })
    }).catch( error => {
        console.log(error)
        dispatch({
            type : "UPDATE_USER_ERROR"
        })
    })


}

export const getAllCandidates = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {
    if(data !==undefined && !data.hasOwnProperty('page')){
        dispatch({
            type : GET_USER_CANDIDATES
        })
    }else{
        dispatch({
            type : GET_USER_CANDIDATES_PAGINATE
        })
    }

    let searchSettings = {
        query : data.search,
        facetFilters : data.facetFilters
    }
    
    if(data!==undefined && data.hasOwnProperty('page')){
        searchSettings.page  = data.page
    }

    if(data!==undefined && data.hasOwnProperty('hitsPerPage')){
        searchSettings.hitsPerPage = data.hitsPerPage;
    }

    let indexName = process.env.REACT_APP_ENV=="production " ? "prod_USERS" : "dev_USERS";
    let rankSettings = {};

    if(data!==undefined && data.hasOwnProperty('sortBy')){
        
        if(data.sortBy==="fullName"){
            indexName = process.env.REACT_APP_ENV=="production " ? "prod_USERS_FULLNAME_DESC" : "dev_USERS_FULLNAME_DESC";
            rankSettings = {
                ranking : [
                    "asc(firstName)",
                    "typo",
                    "geo",
                    "words",
                    "filters",
                    "proximity",
                    "attribute",
                    "exact",
                    "custom"
                ]
            }
        }
    }

    const candidatesIndex = client.initIndex(indexName);
    candidatesIndex.setSettings(rankSettings)

    candidatesIndex.search(searchSettings, (error, res) => {
        if( error ){
            console.log(error)
            throw(error)
        }
        
        if(data !==undefined && data.hasOwnProperty('page')){
            dispatch({
                type : GET_USER_CANDIDATES_PAGINATE_SUCCESS,
                payload : {
                    data : res
                }
            })
        }else{
            dispatch({
                type : GET_USER_CANDIDATES_SUCCESS,
                payload : {
                    data : res
                }
            })
        }
    })
}

export const deleteCandidate = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    return new Promise ( (resolve) => {

        firestore.collection('users').doc(data.uid).delete().then( () => {
            resolve({
                code : 204
            })
        }).catch( error => {
            console.log(error)
            resolve({
                code: 500
            })
        })

    })
} 

export const blockCustomer = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    return new Promise ( (resolve) => {

        dispatch({
            type : BLOCK_CUSTOMER
        })
    
        const firestore = getFirestore();
        const firebase = getFirebase();
    
        let userRef = firestore.collection('users').doc(data.uid);
        
        firestore.runTransaction( t => {
            return t.get(userRef)
                    .then( doc => {
                        t.update(userRef, data)
                        return Promise.resolve(true)
                    })
        }).then( (result) => {

            var blockCustomer = firebase.functions().httpsCallable('blockCustomer');

            blockCustomer(data).then( (result) => {
                if(result.data==200){
                    dispatch({
                        type : BLOCK_CUSTOMER_SUCCESS
                    })
                }
            }).catch( error => {
                console.log(error);
                dispatch({
                    type : BLOCK_CUSTOMER_ERROR
                })
            })
        }).catch( error => {
            console.log(error)
            dispatch({
                type : BLOCK_CUSTOMER_ERROR
            })
        })
    })
}

export const fireUser = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    return new Promise ( (resolve) => {

        dispatch({
            type : FIRE_USER
        })
    
        const firestore = getFirestore();
        const firebase = getFirebase();
    
        let userRef = firestore.collection('users').doc(data.uid);
        
        firestore.runTransaction( t => {
            return t.get(userRef)
                    .then( doc => {
                        t.update(userRef, data)
                        return Promise.resolve(true)
                    })
        }).then( (result) => {

            var fireUser = firebase.functions().httpsCallable('fireUser');

            fireUser(data).then( (result) => {
                if(result.data==200){
                    dispatch({
                        type : FIRE_USER_SUCCESS
                    })
                }
            }).catch( error => {
                console.log(error);
                dispatch({
                    type : FIRE_USER_ERROR
                })
            })
        }).catch( error => {
            console.log(error)
            dispatch({
                type : FIRE_USER_ERROR
            })
        })
    })
}

export const sendAdminInvite = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {
    
    return new Promise ( (resolve) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        var sendAdminInvite = firebase.functions().httpsCallable('sendAdminInvite');
        const uuidv1 = require('uuid/v1');
        let inviteToken = uuidv1();

        const invite_content = {
            "ar" : {
                "op" : {
                    "greeting"  : "لقد تمت دعوتك لتصبح مشغل في نظام ترجملي .فضلا اضغط على الرابط أدناه",
                    "link" : "تسجيل كمشغل ترجملي"
                },
                "am" : {
                    "greeting"  : "لقد تمت دعوتك لتصبح مدير حساب في نظام ترجملي.فضلا اضغط على الرابط أدناه",
                    "link" : "تسجيل كمدير حساب ترجملي"
                }
            },
            "en" : {
                "op" : {
                    "greeting" : "You have been invited to be an operator of Tarjemle. Please click the link below.",
                    "link" : "Tarjemle operator sign up"
                },
                "am" : {
                    "greeting" : "You have been invited to be an account manager of Tarjemle. Please click the link below.",
                    "link" : "Tarjemle account manager sign up"
                }
            }
        }

        const ADMIN_URL = process.env.REACT_APP_ENV=="production " ? process.env.REACT_APP_PROD_ADMIN_URL : process.env.REACT_APP_DEV_ADMIN_URL;

        let inviteData = {
            emailAddress : data.emailAddress,
            subject : data.userType==="operator" ? "Operator Invite" : "Account Manager Invite",
            html : `<div style="text-align :  ${data.lang==="ar" ? `right` : `left`}">
                                
                <p style="direction : ${data.lang==="ar" ? `rtl` : `ltr`}"><br><br>${invite_content[data.lang][data.userCode].greeting}<br><br></p>
                <a style="direction : ${data.lang==="ar" ? `rtl` : `ltr`} cursor: pointer" target="_blank" rel="noindex ,nofollow" href=${ADMIN_URL}/invite/${inviteToken}>${invite_content[data.lang][data.userCode].link}</a>

            </div>`
        }

        sendAdminInvite(inviteData).then( (result) => {

            if(result.data.code==200){

                let docRef = firestore.collection('inviteCodes').doc();

                let inviteCode = {};

                inviteCode.uid = docRef.id;
                inviteCode.token = inviteToken;
                inviteCode.recipient = inviteData.emailAddress;
                inviteCode.dateExpired = new Date(moment().add(24,'hours'));
                inviteCode.userType = data.userType

        
                docRef.set({
                    ...inviteCode
                }, { merge : true}).then( () =>{
                    resolve({
                        code : 200
                    })
                })

            }
        }).catch( error => {
            console.log(error);
        })
    })

}

export const checkInviteToken = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();

    return new Promise ( resolve => {

        let inviteRef = firestore.collection('inviteCodes').where('token','==',data.token);

        inviteRef.get().then( querySnapshot => {
            
            if(typeof querySnapshot.docs[0] == "undefined"){
                resolve({
                    code : 401
                })
            }else{

                if(moment().unix()>querySnapshot.docs[0].data().dateExpired.seconds){
                    resolve({
                        code : 405
                    })
                }else{
                    resolve({
                        code : 200,
                        data : querySnapshot.docs[0].data()
                    })
                }

            }
        }).catch( error => {
            console.log(error);
        })

    })
}

export const updateAll = () => (dispatch, getState, { getFirestore, getFirebase } ) => {

    const firestore = getFirestore();

    firestore.collection('users').get().then( querySnapshots => {
        
        querySnapshots.forEach( doc => {
            if(doc.data().uid!==undefined){
                let userRef = firestore.collection('users').doc(doc.data().uid)

                firestore.runTransaction( t => {
                    return t.get(userRef)
                            .then( doc => {

                                let data= {};

                                if(doc.data().type==="translator"){
                                    data = {
                                        numberOfAssignedTasks : 0,
                                        amountEarned : 0
                                    }
                                }

                                if(doc.data().type==="customer"){
                                    data = {
                                        numberOfTasksCreated : 0,
                                        amountSpent : 0
                                    }
                                }

                                if(doc.data().type==="operator"){
                                    data = {
                                        numberOfRequestsResolved : 0
                                    }
                                }

                                if(doc.data().type==="accountManager"){
                                    data = {
                                        numberOfTasksAssigned : 0
                                    }
                                }

                                t.update(userRef, data)
                                return Promise.resolve(true)
                            })
                }).then( (result) => {
                    dispatch({
                        type : "UPDATE_USER_SUCCESS"
                    })
                }).catch( error => {
                    console.log(error)
                    dispatch({
                        type : "UPDATE_USER_ERROR"
                    })
                })
            }

        })

    })


}

export const updateTranslatorAvailability = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : UPDATE_USER
    })

    return new Promise ( async (resolve) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let taskCount = 0;
        let tasksRef = firestore.collection('tasks').where('assignedTranslator','==',data.translator)
                                                    .where('status','in',['inProgress','forReview']);

        await tasksRef.get().then( querySnapshots => {
            querySnapshots.forEach( doc => {
                taskCount = taskCount + 1
            })
        })

        let userRef = firestore.collection('users').doc(data.translator);

            let taskRef = firestore.collection('tasks').doc(data.taskUid);
            let taskDetails;

            await taskRef.get()
                .then( doc => {
                    taskDetails = doc.data()
                })

            firestore.runTransaction( t => {
                return t.get(userRef)
                        .then( doc => {    
                            // let newAmountEarned = taskDetails.translatorEarnings;
                            // let translatorCompletedTasks = doc.data().numberOfTasksCompleted + 1;

                            // newAmountEarned = exactMath.add(doc.data().amountEarned, newAmountEarned)

                            t.update(userRef, { status : taskCount<=1 ? "available" : "notAvailable" })
                            return Promise.resolve(true)
                        })
            }).then( (result) => {
                dispatch({
                    type : UPDATE_USER_SUCCESS
                })
                
                resolve({
                    code : 200
                })
            }).catch( error => {
                console.log(error)
                dispatch({
                    type : UPDATE_USER_ERROR
                })
    
                resolve({
                    code : 500
                })
            })
    })
}

export const getEmailVerificationContents = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    return new Promise ( (resolve ) => {

        const firestore = getFirestore();

        firestore.collection('emailVerificationContent').get().then( querySnapshots => {

            if(querySnapshots.docs.length===0){
                resolve({
                    code : 404,
                    data : null
                })
            }else{
                resolve({
                    code : 200,
                    data : querySnapshots.docs[0].data()
                })
            }
            
        }).catch( error => {

            resolve({
                code : 500,
                message : error
            })
        })
    })
}

export const saveEmailVerificationContents = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    return new Promise ( (resolve ) => {

        const firestore = getFirestore();

        firestore.collection('emailVerificationContent').get().then( querySnapshots => {
            if(querySnapshots.docs.length===0){
                resolve({
                    code : 404,
                    data : null
                })
            }else{
                let contentRef = firestore.collection('emailVerificationContent').doc(querySnapshots.docs[0].data().uid)

                contentRef.set({
                    body : data.body
                }, { merge : true }).then( () => {
                    resolve({
                        code : 200,
                        data : querySnapshots.docs[0].data()
                    })
                }).catch( error => {
                    resolve({
                        code : 500,
                        message : error
                    })
                })
            }
            
        }).catch( error => {

            resolve({
                code : 500,
                message : error
            })
        })
    })    

}