import React, { Component } from 'react'
import moment from 'moment';
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'
import {
    getTaskCustomer,
    getTaskTranslator,
    getTaskTransactions
} from '../../../actions/taskAction'

import {
    addModal,
    popModal
} from '../../../actions/appAction';

const renderRating = (count) => {

    let rating = [];

    for(let i=0; i<count; i++){
        rating.push(<img src={require('../../../images/icon-rating.png')}/>)
    }

    return rating;

}

const renderEscalatorName = (name) => {

    let fullName = name.split(",")

    return fullName[1] + " " + fullName[0]

}

const renderUserType = (type) => {

    if(type==="accountManager"){
        return "Account Manager";
    }

    if(type==="translator"){
        return "Translator"
    }

    if(type==="customer"){
        return "Customer"
    }

}

export class TaskDetails extends Component{

    constructor(){
        super();
        this.state = {
            selectedCustomer : null,
            selectedTranslator : null,
            taskTransactions : [],
            modalZ : 1050
        }

    }

    async componentDidMount(){

        const { isOpened, task, activeTab, escalation } = this.props;
        
        if(isOpened){
            
            $('#taskDetails').modal({backdrop:'static', keyboard:false});
            $('#taskDetails').modal('show');
            this.props.addModal('right-modal')
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("td-bd");

            if(activeTab==="customer"){
                const { selectedCustomer } = this.state;
                if(selectedCustomer===null){
                    let result = await this.props.getTaskCustomer({
                        customer : task.customer
                    });
    
                    if(result.code===200){
                        this.setState({
                            selectedCustomer : result.customer
                        }, () => {
                            this.checkTaskTransactions()
                        })
                    }else{
                        // DO SOMETHING HERE
                    }
                }
            }

            if(activeTab==="translator"){

            }
        }
    }

     componentWillUnmount(){
        $('#taskDetails').modal('hide');
        $('body').removeClass('modal-open')
        this.props.popModal()
    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="right-modal");
            if(modalInstance!==undefined){
                $('.td-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }

        }
    }

    checkTaskTransactions = async () => {

        const { task } = this.props;

        await this.props.getTaskTransactions(task.uid).then( (result) => {
            if(result.code==200){
                this.setState({
                    taskTransactions : [...result.data.transactions]
                })
            }

        })

    }

    changeTab = async (tab) => {
        
        const { task } = this.props;

        if(tab==="customer"){
            const { selectedCustomer } = this.state;

            if(selectedCustomer===null){
                let result = await this.props.getTaskCustomer({
                    customer : task.customer
                });

                if(result.code===200){
                    this.setState({
                        selectedCustomer : result.customer
                    }, () => {
                        this.checkTaskTransactions()
                    })
                }else{
                    // DO SOMETHING HERE
                }
            }
        }

        if(tab==="translator"){
            const { selectedTranslator } = this.state;

            if(selectedTranslator===null){

                if(task.assignedTranslator !== undefined && task.assignedTranslator !== ""){
                    let result = await this.props.getTaskTranslator({
                        translator : (task.hasOwnProperty('assignedTranslator') && task.assignedTranslator !== "") ? task.assignedTranslator : ""
                    });
    
                    if(result.code===200){
                        this.setState({
                            selectedTranslator : result.translator
                        })
                    }else{
                        // DO SOMETHING HERE
                    }
                }else{
                }

               
            }
        }   

    }

    render() {

        const { activeTab, task, escalation, userProfile } = this.props;
        const { selectedCustomer, taskTransactions, selectedTranslator, modalZ } = this.state;

        let customerName;
        let translatorName;
        let lastActiveAt;

        if(selectedCustomer!==null){
            customerName = selectedCustomer.fullName.split(",");
        }

        if(selectedTranslator!==null){
            translatorName = selectedTranslator.fullName.split(",");
            lastActiveAt = selectedTranslator.hasOwnProperty('lastActiveAt') ? moment.unix(selectedTranslator.lastActiveAt.seconds).fromNow() : "offline" ;
        }

        return(
            <div style={{ zIndex : `${modalZ}` }} className="modal right-modal fade" id="taskDetails" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">

                        <div className="modal-header">
                            <p onClick={this.props.closeTaskDetailsModal}><img style={{ cursor: "pointer", width: "10px", height : "15px", marginRight: "15px"}} src={require('../../../images/back.png')}/>Task details</p>
                        </div>

                        <div  className="modal-body" style={{ overflowY : "auto" }}>
                            <ul className="nav nav-tabs nav-fill nav-header" role="tablist" style={{ marginTop : "5px !important", marginRight : "40px", marginLeft : "40px", marginTop : "32px" }}>
                                <li onClick={ () => this.changeTab('customer') } className="nav-item" >
                                    <a className={`nav-link ${activeTab==="customer" ? "active" : ""}`} id="customer-tab" data-toggle="tab" href="#customer" role="tab" aria-controls="customer" aria-selected={ activeTab === "customer" }>Customer</a>
                                </li>
                                <li onClick={ () => this.changeTab('translator') } className="nav-item">
                                    <a className={`nav-link ${activeTab==="translator" ? "active" : ""}`} id="translator-tab" data-toggle="tab" href="#translator" role="tab" aria-controls="translator" aria-selected={ activeTab === "translator" }>Translator</a>
                                </li>
                                {
                                    userProfile.type==="superuser" && <li id="li-description" onClick={ () => this.changeTab('description') } className="nav-item">
                                        <a className={`nav-link ${activeTab==="description" ? "active" : ""}`} id="description-tab" data-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected={ activeTab === "description" }>Description</a>
                                    </li>   
                                } 
                                {
                                    userProfile.type==="operator" && <li id="li-reason" onClick={ () => this.changeTab('reason') } className="nav-item">
                                        <a className={`nav-link ${activeTab==="reason" ? "active" : ""}`} id="reason-tab" data-toggle="tab" href="#reason" role="tab" aria-controls="reason" aria-selected={ activeTab === "reason" }>Request reason</a>
                                    </li>   
                                }                           
                            </ul>
                            <div className="task-details tab-content" id="myTabContent" style={{ position :"relative" }}>
                                <div style={{ padding : "30px", textAlign : "left" }} className={`tab-pane fade show ${activeTab==="customer" ? "active" : ""}`} id="customer" role="tabpanel" aria-labelledby="customer-tab">
                                    <p className="bold-text">Customer</p>
                                    <div style={{ position : "relative", display : "flex", paddingBottom : "32px", borderBottom : "1px solid #DFDFE0" }}>
                                        
                                        {
                                            userProfile.type!=="operator" ?
                                            <div style={{  }}>
                                                {
                                                    selectedCustomer!==null && <span className="rm-customer-name"><img src={ selectedCustomer.imageUrl !== "" ? selectedCustomer.imageUrl : require('../../../images/default-user.jpg') }/> { customerName[1] + " " + customerName[0]}</span>
                                                }
                                            </div>
                                            :
                                            <div style={{ position: "relative", width: "100%", paddingBottom : "20px" }}>
                                                {
                                                    selectedCustomer!== null && 
                                                    <div>
                                                        <img style={{ width: "80px", height : "80px", borderRadius : "100%", position : "absolute", left : "0", top : "0" }} src={ selectedCustomer.imageUrl !== "" ? selectedCustomer.imageUrl : require('../../../images/default-user.jpg') }/>
                                                        <span  style={{ display: "block", marginLeft: "100px", marginTop : "15px" }} className="op-rm-customer-name"> { customerName[1] + " " + customerName[0]}</span>
                                                        <span style={{ display : "block", marginLeft: "100px", fontSize : "12px", color : (selectedCustomer.presence==="online" ? "#66BB6A" : "#676A6C"), textTransform : "capitalize" }}>{selectedCustomer.presence}</span>
                                                        <button onClick={ () => this.props.openChat(selectedCustomer.type, selectedCustomer.uid)} style={{ background : "#22C9C7", borderRadius : "8px", padding: "12px 16px", border : "none", fontSize : "14px", position : "absolute", top : "10px", right : "15px", color : "#fcfcfc" }}>OPEN CHAT</button>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        
                                        
                                    </div>
                                    {
                                        taskTransactions.length!==0 &&
                                        <div style={{ marginTop : "15px" }}>
                                            {
                                                ( task && task.transactions.length===1 && task.remainingAmount === 0 ) && 
                                                <div>
                                                    <p className="bold-text">All amount paid : {moment.unix(taskTransactions[0].dateAdded.seconds).format("M.DD.YYYY")} </p>
                                                </div>
                                            }
                                            {
                                                ( task && task.transactions.length===1 && task.remainingAmount !== 0 ) && 
                                                <div>
                                                    <p className="bold-text">First payment: {moment.unix(taskTransactions[0].dateAdded.seconds).format("M.DD.YYYY")} </p>
                                                    <div style={{ display : "flex" }}>
                                                        <p style={{ fontSize : "18px", color : "#676A6C" }}>Prepayed 30%</p>
                                                        <p style={{ flex: "1", textAlign : "right", fontSize : "20px", color : "#464646", fontWeight : "600" }}>${taskTransactions[0].amount.toFixed(2)}</p>
                                                    </div>
                                                    <div style={{ display : "flex", marginTop : "15px" }}>
                                                        <p className="bold-text">Customer need to pay</p>
                                                        <p style={{ flex: "1", textAlign : "right", fontSize : "32px", color : "#464646", fontWeight : "600" }}>${task.remainingAmount.toFixed(2)}</p>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                ( task && task.transactions.length===2 && task.remainingAmount === 0 ) && 
                                                <div>
                                                    <p className="bold-text">First payment: {moment.unix(taskTransactions[0].dateAdded.seconds).format("M.DD.YYYY")} </p>
                                                    <div style={{ display : "flex" }}>
                                                        <p style={{ fontSize : "18px", color : "#676A6C" }}>Prepayed 30%</p>
                                                        <p style={{ flex: "1", textAlign : "right", fontSize : "20px", color : "#464646", fontWeight : "600" }}>${taskTransactions[0].amount.toFixed(2)}</p>
                                                    </div>
                                                    <p className="bold-text">Last payment: {moment.unix(taskTransactions[1].dateAdded.seconds).format("M.DD.YYYY")} </p>
                                                    <div style={{ display : "flex", marginTop : "15px" }}>
                                                        <p className="bold-text">Paid amount</p>
                                                        <p style={{ flex: "1", textAlign : "right", fontSize : "20px", color : "#464646", fontWeight : "600" }}>${taskTransactions[1].amount.toFixed(2)}</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <div style={{ padding : "30px", textAlign : "left" }} className={`tab-pane fade show ${activeTab==="translator" ? "active" : ""}`} id="translator" role="tabpanel" aria-labelledby="translator-tab">
                                    {
                                        selectedTranslator !== null &&
                                        <div className="rm-translator-item">
                                            <div className="rm-translator-main-details">
                                                <div style={{ position: "relative"}}>
                                                    <img className="display-photo" src={selectedTranslator.imageUrl===""?require('../../../images/default-user.jpg'):selectedTranslator.imageUrl}/>
                                                    <img style={{ width: "12px", height : "12px", position : "absolute", top : "50px", left : "40px" }} src={ selectedTranslator.presence==="offline" ? require('../../../images/icon-offline.png') : require('../../../images/icon-completed.png')}/>
                                                </div>
                                                {
                                                    userProfile.type==="operator" && <button onClick={ () => this.props.openChat(selectedTranslator.type, selectedTranslator.uid)}  style={{ background : "#22C9C7", borderRadius : "8px", padding: "12px 16px", border : "none", fontSize : "14px", position : "absolute", top : "10px", right : "15px", color : "#fcfcfc" }}>OPEN CHAT</button>

                                                }
                                                <div className="main-details">
                                                    <span className="tr-name">{translatorName[1]+' '+translatorName[0]}</span>
                                                    <span className={` ${selectedTranslator.presence==="online" ? "tr-presence-online" : "tr-presence-offline"}`}>{selectedTranslator.presence==="online"? "Online" : lastActiveAt}</span>
                                                    <span className="tr-rating">{renderRating(Math.trunc(selectedTranslator.rating))}</span>
                                                </div>
                                            </div>
                                            <div className="rm-translator-sub-details">
                                                <div style={{ flex : "1" }}>
                                                    <span className="tr-label">
                                                        Specialty
                                                    </span>
                                                    <span className="tr-value">
                                                        {selectedTranslator.specialty}
                                                    </span>
                                                </div>
                                                <div style={{ flex : "1" }}>
                                                    <span className="tr-label">
                                                        Status
                                                    </span>
                                                    <span className="tr-value">
                                                        {selectedTranslator.status}
                                                    </span>
                                                </div>
                                                <div style={{ flex : "1" }}>
                                                    <span className="tr-label">
                                                        Experience
                                                    </span>
                                                    <span className="tr-value">
                                                        {selectedTranslator.yearsOfExperience}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="rm-task-description">
                                                <p>{selectedTranslator.aboutYourself}</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div style={{ padding : "30px", textAlign : "left" }} className={`tab-pane fade show ${activeTab==="description" ? "active" : ""}`} id="description" role="tabpanel" aria-labelledby="description-tab">
                                    <p className="bold-text" style={{ marginBottom: "10px" }}>Description</p>
                                    <p className="task-description"><span style={{ color : "#7e7e7e" }}>Description: &nbsp;</span>{ task.detailedInstructions}</p>
                                </div>
                                <div style={{ padding : "30px", textAlign : "left" }} className={`tab-pane fade show ${activeTab==="reason" ? "active" : ""}`} id="reason" role="tabpanel" aria-labelledby="reason-tab">
                                    {
                                        escalation && 
                                        <div>
                                            {
                                                escalation.escalators.map( (data, i )=> (
                                                    <div className="escalation-container">
                                                        
                                                        <div className="escalation-details">

                                                            <img onClick={ () => this.props.openChat(data.user.type, data.user.uid)} className="message-icon" src={ require('../../../images/icon-messages.png')}/>
                                                            <div className="escalator-details">
                                                                <img className="display-photo" src={ data.user.imageUrl !== "" ? data.user.imageUrl : require('../../../images/default-user.jpg') }/>
                                                                <span className="escalator-name">{renderEscalatorName(data.user.fullName)}</span>
                                                                <span className="escalator-type">{renderUserType(data.user.type)}</span>
                                                            </div>
                                                            <div className="escalations">
                                                                <p className="bold-text">{ data.escalationReason }</p>
                                                                <p className="esc-letter">{ data.escalationLetter }</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { 
        getTaskCustomer,
        getTaskTranslator,
        getTaskTransactions,
        addModal,
        popModal
    })
)(TaskDetails)