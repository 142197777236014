import React, { Component } from 'react'
import $ from 'jquery'
import moment from 'moment'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import {
    addModal,
    popModal
} from '../../../actions/appAction';

const taskTypeColor = (type) => {
    switch(type){
        case "presentation":
            return { background: "#EA5895" }
        case "essay":
            return { background : "#7E57C2" }
        default:
            return { background : "#29B6F6" }
    }
}

export class CompleteTask extends Component {

    constructor(){
        super();
        this.state = {
            modalZ : 1050
        }
    }


    componentDidMount(){

        const { isOpened } = this.props;
        
        if(isOpened){
            $('#completeTaskModal').modal({backdrop:'static', keyboard:false});
            $('#completeTaskModal').modal('show');
            this.props.addModal('complete-task-modal')
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("cmt-bd");
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="complete-task-modal");
            if(modalInstance!==undefined){
                $('.cmt-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }


    componentWillUnmount(){
        $('#completeTaskModal').modal('hide');
        this.props.popModal()
    }

    completeTask = () => {
        this.props.completeTask()
    }

    render(){
        const { modalZ } = this.state;
        const { task, translator } = this.props;

        let dateAdded = task.dateAdded.seconds === undefined ? task.dateAdded._seconds : task.dateAdded.seconds;
        let deadline = task.deadline.seconds === undefined ? task.deadline._seconds : task.deadline.seconds;
       
        return (
            <div style={{ zIndex : `${modalZ}` }} className="complete-task-modal modal fade modal-opaque" id="completeTaskModal" tabIndex="-1" role="dialog" aria-labelledby="forgotPasswordModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="custom-modal-header modal-header">
                            <button onClick={this.props.closeCompleteTaskModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span style={{ fontWeight: "300", fontSize: "70px" }} aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="text-header">Complete task</p>
                            <div className="hired-translator-details task-item">
                                <div style={{ display : "flex" }}>
                                    <div style={{ flex : "1" }}><span style={taskTypeColor(task.taskType)} className="task-type">{task.taskType}</span></div>
                                    <div style={{ textAlign: "right" }}><span style={{ fontSize : "14px", color: "#676A6C" }}>Order#:<span style={{ fontSize: ".80rem", color: "black" }}>{task.orderID}</span></span></div>
                                </div>
                                <p style={{ marginTop: "16px" }} className="task-title">{task.title}</p>
                                <div style={{ display : "flex", marginTop : "24px" }}>
                                    <div style={{ marginRight : "24px"}}>
                                        <span className="field-label">Task created</span>
                                        <span className="field-value">{ moment.unix(dateAdded).format("Y/M/DD")}</span>
                                    </div>
                                    <div style={{ flex : "1"}}>
                                        <span className="field-label">Deadline</span>
                                        <span className="field-value">{ moment.unix(deadline).format("Y/M/DD")}</span>
                                    </div>
                                    <div style={{ flex : "1"}}>
                                        <span className="field-label">Translator</span>
                                        <span className="field-value">{translator}</span>
                                    </div>
                                </div>
                                <div style={{ display : "flex", marginTop : "24px" }}>
                                    <div style={{ marginRight : "24px"}}>
                                        <span className="field-label">Words number</span>
                                        <span className="field-value">{task.numberOfWords}</span>
                                    </div>
                                    <div style={{ flex : "1"}}>
                                        <span className="field-label">Days to deadline</span>
                                        <span className="field-value">{ moment.unix(deadline).diff(moment(new Date()), 'days')}</span>
                                    </div>
                                </div>   
                            </div>
                            
                            <div className="text-center" style={{ display : "flex", marginTop : "56px" }}>
                                <button onClick={this.completeTask} className="btn-chat">COMPLETE TASK</button>    
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(CompleteTask)