import React, { Component } from 'react';
import $ from 'jquery';
import _ from 'lodash/'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'
import {
    getEmailVerificationContents,
    saveEmailVerificationContents
} from '../../../actions/userAction';


import SpinnerModal from '../../Spinner/SpinnerModal'
import MessageModal from '../../Modals/Message';
import ViewEmailVerificationModal from './Modals/ViewEmailVerification'

export class CustomEmailVerification extends Component {

    constructor(){
        super();
        this.state = {
            isAuthenticated : false,
            isProcessing : false,
            verificationText : "",
            verificationTitle : "Verify your Email for Tarjemle",
            isViewEmailVerificationModalActive : false
        }
    }

    componentDidMount(){

        const { auth , userProfile } = this.props;

        if(auth.uid){
            
            this.setState({
                isAuthenticated : true
            }, async () => {
                let result = await this.props.getEmailVerificationContents();
                if(result.code===200){
                    this.setState({
                        verificationText : result.data.body
                    })
                }
            })
        }

        if(userProfile.hasOwnProperty('fullName')){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    onChange = (e) => {
        this.setState({
            verificationText : e.target.value
        })
    }

    openViewVerificationModal = () => {
        this.setState({
            isViewEmailVerificationModalActive : true
        })
    }

    closeViewVerificationModal = () => {
        this.setState({
            isViewEmailVerificationModalActive : false
        })
    }

    saveContent = () => {
        const { verificationText } = this.state;
        if(verificationText.trim()!==""){
            this.setState({
                isProcessing : true
            }, async () => {
                let result = await this.props.saveEmailVerificationContents({
                    body : verificationText
                })

                setTimeout(() => {
                    this.setState({
                        isProcessing : false
                    })
                }, 800)
               
            })
        }
    }

    render(){

        const { isProcessing, isAuthenticated } = this.state;
        const { isLoggingOut, auth, userProfile } = this.props;

        const { isViewEmailVerificationModalActive, verificationTitle, verificationText } = this.state;

        const saveBtn = {
            width: "110px",
            background: "#22C9C7",
            borderRadius: "8px",
            border: "none",
            textAlign: "center",
            textDecoration: "none",
            color:" white",
            padding: "12px 10px",
            height : "50px",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "14px",
            lineHeight: "16px",
            textAlign: "center",
            textTransform: "uppercase",
            marginLeft: "10px"
        }

        const viewBtn = {
            background: "#F5F4F6",
            borderRadius: "8px",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "14px",
            lineHeight: "16px",
            textAlign: "center",
            textTransform: "uppercase",
            color: "#22C9C7",
            padding: "16px 20px",
            border: "none",
            height: "50px",
        }

        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }
        
        if(!auth.uid && !auth.emailVerified){
            return <Redirect to="/" />
        }else{
            if(isAuthenticated){
                if(userProfile.type!=="superuser"){
                    return "NOT ALLOWED";
                }
            }
        }

        return (
            <div className="div-holder">
                <div style={{ display : "flex"}}>
                    <div style={{ flex : "1", textAlign : "left" }}>
                        <p className="module-header">Custom Email Verification</p>
                    </div>
                    <div style={{ display : "flex", float : "right" }}>
                        <button  onClick={this.openViewVerificationModal} style={viewBtn}>View Email</button>
                        <button onClick={this.saveContent} style={saveBtn}>Save</button>
                    </div>
                </div>
                <div>
                    <div className="form-group">
                        <label className="custom-create-task-label block">Body of the email</label>
                        <textarea value={verificationText} onChange={this.onChange} style={{ whiteSpace : "pre-line", resize : "none", width: "100%" }} name="emailBody" id="emailBody" rows={10} placeholder="Enter body of the email here" className="custom-input"></textarea>
                    </div>
                </div>
                {
                    isViewEmailVerificationModalActive &&
                    <ViewEmailVerificationModal
                        isOpened={isViewEmailVerificationModalActive}
                        closeViewVerificationModal={this.closeViewVerificationModal}
                        header={verificationTitle}
                        body={verificationText}
                    />
                }
                {
                    isLoggingOut && 
                    <SpinnerModal
                        isOpened={isLoggingOut}
                    />
                }
                {
                    isProcessing && 
                    <SpinnerModal
                        isOpened={isProcessing}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        isLoggingOut : state.auth.isLoggingOut
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, {
        getEmailVerificationContents,
        saveEmailVerificationContents
    })
)(CustomEmailVerification)