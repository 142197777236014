import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import moment from 'moment';

import '../task.css'

const renderPaymentStatus = (status) => {
    switch(status){
        case "prepaid" :
            return "Unpaid"
        case "fullyPaid" :
            return "Paid"
        default:
            return "Unpaid"
    }
}

const taskTypeColor = (type) => {
    switch(type){
        case "presentation":
            return { background: "#EA5895" }
        case "essay":
            return { background : "#7E57C2" }
        default:
            return { background : "#29B6F6" }
    }
}

const renderAdditionalServices = (services) => {

    let servicesList = [];

    
    if(services!==undefined){

        if(services.length==1){
            services.map( (data) => {

                if(data==="diamond"){
                    servicesList.push(<span style={{ fontSize : "12px", marginRight: "10px" }}><img style={{ width: "30px", height: "30px" }} src={require('../../../images/icon-diamond.png')}/>&nbsp;&nbsp;&nbsp;High priority task</span>)
                }
        
                if(data==="express"){
                    servicesList.push(<span style={{ fontSize : "12px", marginRight: "10px" }}><img style={{ width: "30px", height: "30px" }} src={require('../../../images/icon-express.png')}/>&nbsp;&nbsp;&nbsp;Express service</span>)
                }
            })
        }else{
            services.map( (data) => {

                if(data==="diamond"){
                    servicesList.push(<span style={{ fontSize : "12px", marginRight: "10px" }}><img style={{ width: "30px", height: "30px" }} src={require('../../../images/icon-diamond.png')}/></span>)
                }
        
                if(data==="express"){
                    servicesList.push(<span style={{ fontSize : "12px", marginRight: "10px" }}><img style={{ width: "30px", height: "30px" }} src={require('../../../images/icon-express.png')}/></span>)
                }
            })
        }
    }
    
    return servicesList;
}

export class Item extends Component {

    openPauseTaskModal = (task) => {
        this.props.openPauseTaskModal(task);
    }

    openStopTaskModal = (task) => {
        this.props.openStopTaskModal(task);
    }

    openEscalateTaskModal = (task) => {
        this.props.openEscalateTaskModal(task);
    }

    openTaskDetailsModal = (task) => {
        this.props.openTaskDetailsModal(task);
    }

    openChat = (userType, recipient, task) => {
        this.props.openChat(userType, recipient, task)
    }

    render(){

        const { task } = this.props;

        let dateAdded = task.dateAdded.seconds === undefined ? task.dateAdded._seconds : task.dateAdded.seconds;
        let deadline = task.deadline.seconds === undefined ? task.deadline._seconds : task.deadline.seconds;
       
        return (
            <div className="task-item">
                <div className="row">
                    <div style={{ borderRight: "1px solid #E9E9EA"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">

                        <div className="row pl-2">
                            <div className="col">
                                <p className="task-status" style={{ textAlign : "left" }}><span style={{ marginRight: "10px" }}><img style={{ width: "10px", height: "10px" }} src={ task.paymentStatus==="fullyPaid" ? require('../../../images/icon-paid.png') : require('../../../images/icon-unpaid.png')}/></span>{renderPaymentStatus(task.paymentStatus)}</p>
                            </div>
                            
                        </div>
                        <div className="row pl-2">
                            <div className="col">
                                <span className="field-label">Task created</span>
                                <span className="field-value">{ moment.unix(dateAdded).format("Y/M/DD")}</span>
                            </div>
                            <div className="col">
                                <span className="field-label">Deadline</span>
                                <span className="field-value">{ moment.unix(deadline).format("Y/M/DD")}</span>
                            </div>
                        </div>
                        <div className="row pl-2 mt-4">
                            <div className="col">
                                <span className="field-label">Words number</span>
                                <span className="field-value">{task.numberOfWords}</span>
                            </div>
                            <div className="col">
                                <span className="field-label">Days to deadline</span>
                                <span className="field-value">{ moment.unix(deadline).diff(moment(new Date()), 'days')}</span>
                            </div>
                        </div>

                    </div>
                    <div style={{ borderRight: "1px solid #E9E9EA", position : "relative"}}  className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="row pl-2">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <span style={taskTypeColor(task.taskType)} className="task-type">{task.taskType}</span>
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                <p className="task-title">{task.title}</p>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right">
                            <span style={{ fontSize : "13px", color: "#676A6C" }}>Order#:<span style={{ fontSize: ".80rem", color: "black" }}>{task.orderID}</span></span>
                            </div>
                        </div>
                        <div className="row pl-2">
                            <div className="col">
                                <p className="task-description"><span style={{ color : "#7e7e7e" }}>Description: &nbsp;</span>{task.detailedInstructions}</p>
                            </div>
                        </div>
                        <div style={{ display : "flex", marginTop : "15px" }}>
                            <div style={{ flex : "1" }}>
                                <p className="task-payed">{ task.paymentStatus!=="unpaid" ? `Payed: $ ${parseFloat(task.price - task.remainingAmount).toFixed(2)}` :""}</p>
                            </div>
                            <div style={{ flex : "1", marginLeft : "30px", float : "right", textAlign : "right" }}>
                                { renderAdditionalServices(task.additionalServices) }
                            </div>
                        </div>
                    </div>
                    <div style={{ position :"relative" }} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <div class="btn-group" style={{ position : "absolute", top : "-7px", right : "15px" }}>
                            <button type="button" class="btn btn-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            . . .
                            </button>
                            <div class="dropdown-menu dropdown-menu-right custom-dropdown-right">
                            <button onClick={ () => this.openChat('customer', task.customer, task) } class="dropdown-item custom-dropdown-item" type="button">Start the chat</button>
                            <button onClick={ () => this.props.openDeclineTaskModal(task)} class="dropdown-item custom-dropdown-item" type="button">Decline task</button>
                            <button onClick={ () => this.props.openEscalateTaskModal(task)} class="dropdown-item custom-dropdown-item" type="button">Problem? Get help</button>
                            </div>
                        </div>
                        <span style={{ fontSize:"20px", color : "#464646" }}>Price</span>
                        <p className="task-remaining-amount">${ parseFloat(task.price).toFixed(2) }</p>
                        <button onClick={ () => this.props.openHireTranslatorModal(task)} className="task-give-feedback">SELECT THE TRANSLATOR</button>
                    </div>
                </div>


            </div>
        )

    }

}

export default Item;
