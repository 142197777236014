import algoliasearch from 'algoliasearch'
import exactMath from 'exact-math'

import {
    GET_NEW_TASKS,
    GET_NEW_TASKS_SUCCESS,
    GET_NEW_TASKS_ERROR,
    LOAD_MORE_NEW_TASKS,
    LOAD_MORE_NEW_TASKS_SUCCESS,
    LOAD_MORE_NEW_TASKS_ERROR,
    GET_INPROGRESS_TASKS,
    GET_INPROGRESS_TASKS_SUCCESS,
    GET_INPROGRESS_TASKS_ERROR,
    LOAD_MORE_INPROGRESS_TASKS,
    LOAD_MORE_INPROGRESS_TASKS_SUCCESS,
    LOAD_MORE_INPROGRESS_TASKS_ERROR,
    GET_ARCHIVED_TASKS,
    GET_ARCHIVED_TASKS_SUCCESS,
    GET_ARCHIVED_TASKS_ERROR,
    LOAD_MORE_ARCHIVED_TASKS,
    LOAD_MORE_ARCHIVED_TASKS_SUCCESS,
    LOAD_MORE_ARCHIVED_TASKS_ERROR,
    GET_ALL_TASKS,
    GET_ALL_TASKS_SUCCESS,
    GET_ALL_TASKS_ERROR,
    LOAD_MORE_TASKS,
    LOAD_MORE_TASKS_SUCCESS,
    LOAD_MORE_TASKS_ERROR,
    GET_TASK_CUSTOMER,
    GET_TASK_CUSTOMER_SUCCESS,
    GET_TASK_CUSTOMER_ERROR,
    GET_TASK_TRANSLATOR,
    GET_TASK_TRANSLATOR_SUCCESS,
    GET_TASK_TRANSLATOR_ERROR,
    GET_TASK_TRANSACTIONS,
    GET_TASK_TRANSACTIONS_SUCCESS,
    GET_TASK_TRANSACTIONS_ERROR,
    UPDATE_TASK,
    UPDATE_TASK_ERROR,
    UPDATE_TASK_SUCCESS,
    CLEAR_NEW_TASKS_COUNT
} from './types'

const client = algoliasearch('QZDWQC5AMN','c8973aeead0de12842cdf80db955436a',{
    timeouts: {
      connect: 1000,
      read: 2 * 1000,
      write: 30 * 1000
    }
})

export const getAllTasks = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {
    
    if(data !==undefined && !data.hasOwnProperty('page')){
        dispatch({
            type : GET_ALL_TASKS
        })
    }else{
        dispatch({
            type : LOAD_MORE_TASKS
        })
    }

    let searchSettings = {
        query : data.search,
        facetFilters : data.facetFilters,
        filters : data.filters
    }
    
    if(data!==undefined && data.hasOwnProperty('page')){
        searchSettings.page  = data.page
    }

    if(data!==undefined && data.hasOwnProperty('hitsPerPage')){
        searchSettings.hitsPerPage = data.hitsPerPage;
    }

    let indexName = process.env.REACT_APP_ENV=="production " ? "prod_TASKS" : "dev_TASKS";
    let rankSettings = {};

    if(data!==undefined && data.hasOwnProperty('sortBy')){
        if(data.sortBy==="dateAdded"){
            indexName = process.env.REACT_APP_ENV=="production " ? "prod_TASKS_DATEADDED_DESC" : "dev_TASKS_DATEADDED_DESC";
            rankSettings = {
                ranking : [
                    "desc(dateAdded._seconds)",
                    "typo",
                    "geo",
                    "words",
                    "filters",
                    "proximity",
                    "attribute",
                    "exact",
                    "custom"
                ]
            }
        }
    }

    const tasksIndex = client.initIndex(indexName);
    tasksIndex.setSettings(rankSettings)

    if(data.facetFilters.length===0){
        if(data !==undefined && data.hasOwnProperty('page')){
            dispatch({
                type : LOAD_MORE_TASKS_SUCCESS,
                payload : {
                    data : []
                }
            })
        }else{
            dispatch({
                type : GET_ALL_TASKS_SUCCESS,
                payload : {
                    data : []
                }
            })
        }
    }else{
        tasksIndex.search(searchSettings, (error, res) => {
            if( error ){
                console.log(error)
                throw(error)
            }
            
            if(data !==undefined && data.hasOwnProperty('page')){
                dispatch({
                    type : LOAD_MORE_TASKS_SUCCESS,
                    payload : {
                        data : res
                    }
                })
            }else{
                dispatch({
                    type : GET_ALL_TASKS_SUCCESS,
                    payload : {
                        data : res
                    }
                })
            }
        })
    }
    

}

export const watchAllTasks = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

}

export const getNewTasks = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    const taskState = getState().task;
    const stateNewTasks = taskState.newTasks;
    
    dispatch({
        type : GET_NEW_TASKS
    })
    
    let tasksRef = firestore.collection('tasks')
                                 .where('isDeleted','==',false)
                                 .where('isArchived','==',false)
                                 .where('status','==','new')
                                 .where('paymentStatus','in',['fullyPaid','prepaid'])

    if(taskState.typeFilter!=="all"){
        tasksRef = tasksRef.where('taskType','==',taskState.typeFilter);
    }

    tasksRef.orderBy('dateAdded','desc').limit(3).get()
        .then( (querySnapShots) => {

            let taskList = [];
            let taskRefList = [];

            querySnapShots.forEach( doc => {
                taskList.push(doc.data())
                taskRefList.push(doc);
            })

            dispatch({
                type : GET_NEW_TASKS_SUCCESS,
                payload : {
                    tasks : taskList,
                    tasksRef : taskRefList
                }
            })

        }).catch(error=>{
            console.log(error)

            dispatch({
                type : GET_NEW_TASKS_ERROR
            })
        })
}

export const loadMoreNewTasks = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : LOAD_MORE_NEW_TASKS
    })

    const firestore = getFirestore();
    const taskState = getState().task;

    const newTasks = taskState.newTasks;
    const newTasksRef = taskState.newTasksRef;
    const lastRef = newTasksRef.length === 0 ? null : newTasksRef[newTasksRef.length-1];

    if(lastRef!==null){

        let tasksRef = firestore.collection('tasks')
                                     .where('isDeleted','==',false)
                                     .where('isArchived','==',false)
                                     .where('status','==','new')
                                     .where('paymentStatus','in',['fullyPaid','prepaid'])
                                     
        if(taskState.typeFilter!=="all"){
            tasksRef = tasksRef.where('taskType','==',taskState.typeFilter);
        }

        tasksRef = tasksRef.orderBy('dateAdded','desc')

        tasksRef.startAfter(lastRef).limit(3).get()
            .then( (querySnapShots) => {

                let taskList = [];
                let taskRefList = [];

                querySnapShots.forEach( doc => {
                    if(newTasks.findIndex( f => f.uid===doc.data().uid)===-1){
                        taskList.push(doc.data())
                        taskRefList.push(doc);
                    }
                })

                dispatch({
                    type : LOAD_MORE_NEW_TASKS_SUCCESS,
                    payload : {
                        tasks : taskList,
                        tasksRef : taskRefList
                    }
                })

            }).catch(error=>{
                console.log(error)

                dispatch({
                    type : LOAD_MORE_NEW_TASKS_ERROR
                })
            })

    }

}

export const filterTasks = (type) => (dispatch) => {
    dispatch({
        type : "FILTER_TASKS",
        payload : {
            type
        }
    })
}

export const clearNewTasksCount = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : CLEAR_NEW_TASKS_COUNT
    })

}

export const watchNewTasksCount = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    const user = getState().firebase.profile;

    let openedNewTasksData = user.hasOwnProperty('lastOpenedNewTasksDate') ? user.lastOpenedNewTasksDate : firestore.Timestamp.now()

    const unsubscribeWatchTaskCountListener = firestore.collection('tasks')
        .where('dateAdded','>=',openedNewTasksData)
        .orderBy('dateAdded','desc')
        .onSnapshot( querySnapshot => {
            querySnapshot.docChanges().forEach( change => {
                if(change.type==='modified'){
                   
                    dispatch({
                        type : "RELOAD_NEW_TASKS",
                        payload : {
                            task : change.doc.data(),
                            taskRef : change.doc
                        }
                    })
                }
            })
        })

    return unsubscribeWatchTaskCountListener;
}

export const watchNewTasks = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();

    const unsubscribeWatchTaskListener = firestore.collection('tasks')
        .orderBy('dateAdded','desc')
        .onSnapshot( querySnapshot => {
            querySnapshot.docChanges().forEach( change => {
                if(change.type==="modified"){
                   
                    dispatch({
                        type : "RELOAD_NEW_TASK",
                        payload : {
                            task : change.doc.data(),
                            taskRef : change.doc
                        }
                    })
                }
            })
        })
    
        return unsubscribeWatchTaskListener;
}

export const getInProgressTasks = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    const taskState = getState().task;
    const stateInProgressTasks = taskState.inProgressTasks;

    dispatch({
        type : GET_INPROGRESS_TASKS
    })

    let tasksRef = firestore.collection('tasks')
                    .where('isDeleted','==',false)
                    .where('isArchived','==',false)
                    .where('status','in',['inProgress','forReview'])
                    .where('accountManager','==',firebase.auth().currentUser.uid)

    if(taskState.typeFilter!=="all"){
        tasksRef = tasksRef.where('taskType','==',taskState.typeFilter);
    }

    tasksRef.orderBy('dateAdded','desc').limit(3).get()
        .then( (querySnapShots) => {

            let taskList = [];
            let taskRefList = [];

            querySnapShots.forEach( doc => {
                taskList.push(doc.data())
                taskRefList.push(doc);
            })

            dispatch({
                type : GET_INPROGRESS_TASKS_SUCCESS,
                payload : {
                    tasks : taskList,
                    tasksRef : taskRefList
                }
            })

        }).catch(error=>{
            dispatch({
                type : GET_INPROGRESS_TASKS_ERROR
            })
        })
}

export const loadMoreInProgressTasks = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : LOAD_MORE_INPROGRESS_TASKS
    })

    const firestore = getFirestore();
    const firebase = getFirebase();
    const taskState = getState().task;

    const inProgressTasks = taskState.inProgressTasks;
    const inProgressTasksRef = taskState.inProgressTasksRef;
    const lastRef = inProgressTasksRef.length === 0 ? null : inProgressTasksRef[inProgressTasksRef.length-1];
    if(lastRef!==null){

        let tasksRef = firestore.collection('tasks')
                        .where('isDeleted','==',false)
                        .where('isArchived','==',false)
                        .where('status','in',['inProgress','forReview'])
                        .where('accountManager','==',firebase.auth().currentUser.uid)

        if(taskState.typeFilter!=="all"){
            tasksRef = tasksRef.where('taskType','==',taskState.typeFilter);
        }

        tasksRef = tasksRef.orderBy('dateAdded','desc')

        tasksRef.startAfter(lastRef).limit(3).get()
            .then( (querySnapShots) => {

                let taskList = [];
                let taskRefList = [];

                querySnapShots.forEach( doc => {
                    if(inProgressTasks.findIndex( f => f.uid===doc.data().uid)===-1){
                        taskList.push(doc.data())
                        taskRefList.push(doc);
                    }
                })

                dispatch({
                    type : LOAD_MORE_INPROGRESS_TASKS_SUCCESS,
                    payload : {
                        tasks : taskList,
                        tasksRef : taskRefList
                    }
                })

            }).catch( error => {
                console.log(error)

                dispatch({
                    type : LOAD_MORE_INPROGRESS_TASKS_ERROR
                })
            })

    }

}

export const watchInProgressTasks = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    const unsubscribeWatchInProgressTasks = firestore.collection('tasks')
        .where('accountManager','==',firebase.auth().currentUser.uid)
        .orderBy('dateAdded','desc')
        .onSnapshot( querySnapshot => {
            querySnapshot.docChanges().forEach( change => {
                if(change.type==="modified"){
                    dispatch({
                        type : "RELOAD_INPROGRESS_TASK",
                        payload : {
                            task : change.doc.data(),
                            taskRef : change.doc
                        }
                    })
                }
            })
        })
    
        return unsubscribeWatchInProgressTasks;
}

export const clearLastRef = () => (dispatch, getState) => {
    dispatch({
        type : "CLEAR_LAST_REF"
    })
}

export const getArchivedTasks = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    const taskState = getState().task;
    const stateArchivedTasks = taskState.archivedTasks;

    dispatch({
        type : GET_ARCHIVED_TASKS
    })

    let tasksRef = firestore.collection('tasks')
            .where('status','in',['stopped','paused','declined','completed'])
            .where('isArchived','==',true)

    tasksRef.orderBy('dateAdded','desc').limit(3).get()
        .then( (querySnapShots) => {

            let taskList = [];
            let taskRefList = [];

            querySnapShots.forEach( doc => {
                taskList.push(doc.data())
                taskRefList.push(doc);
            })

            dispatch({
                type : GET_ARCHIVED_TASKS_SUCCESS,
                payload : {
                    tasks : taskList,
                    tasksRef : taskRefList
                }
            })

        })
}

export const loadMoreArchivedTasks = () => async (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : LOAD_MORE_ARCHIVED_TASKS
    })

    const firestore = getFirestore();
    const taskState = getState().task;

    const archivedTasks = taskState.archivedTasks;
    const archivedTasksRef = taskState.archivedTasksRef;
    const lastRef = archivedTasksRef.length === 0 ? null : archivedTasksRef[archivedTasksRef.length-1];

    if(lastRef!==null){

        let tasksRef = firestore.collection('tasks')
                .where('status','in',['stopped','paused','declined','completed'])
                .where('isArchived','==',true)

        tasksRef = tasksRef.orderBy('dateAdded','desc')

        tasksRef.startAfter(lastRef).limit(3).get()
            .then( (querySnapShots) => {

                let taskList = [];
                let taskRefList = [];

                querySnapShots.forEach( doc => {
                    if(archivedTasks.findIndex( f => f.uid===doc.data().uid)===-1){
                        taskList.push(doc.data())
                        taskRefList.push(doc);
                    }
                })

                dispatch({
                    type : LOAD_MORE_ARCHIVED_TASKS_SUCCESS,
                    payload : {
                        tasks : taskList,
                        tasksRef : taskRefList
                    }
                })

            }).catch(error=>{
                console.log(error)

                dispatch({
                    type : LOAD_MORE_ARCHIVED_TASKS_ERROR
                })
            })

    }

}

export const updateTask = (data) => async (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : "UPDATE_TASK"
    })

    const firestore = getFirestore();

    let taskRef = firestore.collection('tasks').doc(data.uid);

    firestore.runTransaction( t => {
        return t.get(taskRef)
                .then( doc => {
                    t.update(taskRef, data)
                    return Promise.resolve(true)
                })
    }).then( async (result) => {
        dispatch({
            type : "UPDATE_TASK_SUCCESS"
        })

        if(data.status=="completed"){

            let taskCount = 0;
            let tasksRef = firestore.collection('tasks').where('assignedTranslator','==',data.assignedTranslator)
                                                        .where('status','in',['inProgress','forReview']);

            await tasksRef.get().then( querySnapshots => {
                querySnapshots.forEach( doc => {
                    taskCount = taskCount + 1
                })
            })

            let userRef = firestore.collection('users').doc(data.assignedTranslator);

            let taskRef = firestore.collection('tasks').doc(data.uid);
            let taskDetails;

            await taskRef.get()
                .then( doc => {
                    taskDetails = doc.data()
                })

            firestore.runTransaction( t => {
                return t.get(userRef)
                        .then( doc => {    
                            let newAmountEarned = taskDetails.translatorEarnings;
                            let translatorCompletedTasks = doc.data().numberOfTasksCompleted + 1;

                            newAmountEarned = exactMath.add(doc.data().amountEarned, newAmountEarned)

                            t.update(userRef, { numberOfTasksCompleted : translatorCompletedTasks, status : taskCount==1 ? "available" : "notAvailable", amountEarned : newAmountEarned })
                            return Promise.resolve(true)
                        })
            }).then( (result) => {
            }).catch( error => {
            })

        }

    }).catch( error => {
        console.log(error)
        dispatch({
            type : "UPDATE_TASK_ERROR"
        })
    })
}

export const declineAssignedTask = (data) => async (dispatch, getState, { getFirestore, getFirebase }) => {
    
    dispatch({
        type : "UPDATE_TASK"
    })
    
    return new Promise ( (resolve) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let taskRef = firestore.collection('tasks').doc(data.task.uid);

        firestore.runTransaction( t => {
            return t.get(taskRef)
                    .then( doc => {

                        let newInvitedTranslators = [...doc.data().invitedTranslators];
                        let userIndex = newInvitedTranslators.indexOf(data.translator.uid)

                        newInvitedTranslators.splice(userIndex, 1);

                        t.update(taskRef, {
                            invitedTranslators : newInvitedTranslators
                        })                
                    })
        }).then( (result) => {
            
            dispatch({
                type : "UPDATE_TASK_SUCCESS"
            })

            taskRef = firestore.collection('tasks').doc(data.task.uid);

            taskRef.get().then( querySnapshot => {
                resolve({
                    data : querySnapshot.data(),
                    code : 200
                })
               
            })

           
        }).catch( error => {
            console.log(error)
            dispatch({
                type : "UPDATE_TASK_ERROR"
            })
            
            resolve({
                code : 500
            })
        })

    })
}

export const declineTask = (data) => async (dispatch, getState, { getFirestore, getFirebase }) => {
    
    dispatch({
        type : "UPDATE_TASK"
    })
    
    return new Promise ( (resolve) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let taskRef = firestore.collection('tasks').doc(data.uid);

        firestore.runTransaction( t => {
            return t.get(taskRef)
                    .then( doc => {

                        let declineReason = {};

                        if(doc.data().hasOwnProperty('declineReason')){
                            declineReason = {
                                ...doc.data().declineReason,
                                [firebase.auth().currentUser.uid] : data.declineReason
                            }
                        }else{
                            declineReason = {
                                [firebase.auth().currentUser.uid] : data.declineReason
                            }
                        }

                        t.update(taskRef, {
                            ...data,
                            declineReason : declineReason,
                        })                
                    })
        }).then( (result) => {
            
            dispatch({
                type : "UPDATE_TASK_SUCCESS"
            })

            taskRef = firestore.collection('tasks').doc(data.uid);

            taskRef.get().then( querySnapshot => {
                resolve({
                    data : querySnapshot.data(),
                    code : 200
                })
               
            })

           
        }).catch( error => {
            console.log(error)
            dispatch({
                type : "UPDATE_TASK_ERROR"
            })
            
            resolve({
                code : 500
            })
        })

    })
}

export const hireTranslator = (data) => async (dispatch, getState, { getFirestore, getFirebase }) => {
    
    return new Promise ( (resolve) => {

        const firestore = getFirestore();
        const firebase = getFirebase();
        
        let taskRef = firestore.collection('tasks').doc(data.uid);

        taskRef.get().then( querySnapshot => {
            if(querySnapshot.data()!== undefined){

                if(querySnapshot.data().assignedTranslator!=="" && querySnapshot.data().hasOwnProperty('assignedTranslator')){
                    resolve({
                        code : 1062,
                        message : "This task has already been assigned to another translator."
                    })
                }else{

                    dispatch({
                        type : UPDATE_TASK
                    })

                    firestore.runTransaction( t => {
                        return t.get(taskRef)
                                .then( doc => {
            
                                    let newInvitedTranslators;
                                    let isAlreadyInvited = false;
            
                                    if(doc.data().hasOwnProperty('invitedTranslators')){
                                        newInvitedTranslators = doc.data().invitedTranslators;
                                    }else{
                                        newInvitedTranslators = []
                                    }
            
                                    if(newInvitedTranslators.indexOf(data.assignedTranslator)===-1){
                                        newInvitedTranslators.push(data.assignedTranslator);
                                    }else{
                                        isAlreadyInvited = true
                                    }
            
                                    t.update(taskRef, {
                                        invitedTranslators : [...newInvitedTranslators],
                                        accountManager : data.accountManager
                                    })

                                    let requestBody = {
                                        userUid : data.assignedTranslator,
                                        taskTitle : doc.data().title
                                    }
                                    
                                    
                                    var assignTask = firebase.functions().httpsCallable('assignTask');
    
                                    assignTask(requestBody).then( (result) => {
                                    }).catch( error => {
                                        console.log(error);
                                    })

                                    return Promise.resolve(isAlreadyInvited)
                                })
                    }).then( (result) => {
                        dispatch({
                            type : UPDATE_TASK_SUCCESS
                        })
            
                        taskRef = firestore.collection('tasks').doc(data.uid);
                            taskRef.get().then( querySnapshot => {
                                resolve({
                                    code : 200,
                                    message : "translator/hired",
                                    data : querySnapshot.data()
                                })
                            })
                    }).catch( error => {
                        console.log(error)
                        dispatch({
                            type : UPDATE_TASK_ERROR
                        })

                        resolve({
                            code : 500,
                            message : error
                        })
                    })

                }

            }
        })
    })
}

export const getTaskCustomer = (data) => async (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : GET_TASK_CUSTOMER
    })

    return new Promise ( async (resolve, reject) =>{

        const firestore = getFirestore();
        const firebase = getFirebase();

        let userRef = firestore.collection('users').doc(data.customer);

        let userData = null;

        await userRef.get()
                     .then( querySnapshot => {

                        dispatch({
                            type : GET_TASK_CUSTOMER_SUCCESS
                        })

                        if(querySnapshot.data() !== undefined){
                            userData = {...querySnapshot.data()}
                            resolve({
                                code: 200,
                                customer : {...userData}
                            })
                        }else{
                            resolve({
                                code: 400,
                                customer : null
                            })
                        }                       
                     }).catch( error => {
                        console.log(error)
                        dispatch({
                            type : GET_TASK_CUSTOMER_ERROR
                        })

                        reject({
                            code : 500,
                            message : error
                        })
                     })


    })

}

export const getTaskTranslator = (data) => async (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : GET_TASK_TRANSLATOR
    })

    return new Promise ( async (resolve, reject) =>{

        const firestore = getFirestore();
        const firebase = getFirebase();

        let userRef = firestore.collection('users').doc(data.translator);

        let userData = null;

        await userRef.get()
                     .then( querySnapshot => {

                        dispatch({
                            type : GET_TASK_TRANSLATOR_SUCCESS
                        })

                        if(querySnapshot.data() !== undefined){
                            userData = {...querySnapshot.data()}
                            resolve({
                                code: 200,
                                translator : {...userData}
                            })
                        }else{
                            resolve({
                                code: 400,
                                translator : null
                            })
                        }                       
                     }).catch( error => {
                        console.log(error)
                        dispatch({
                            type : GET_TASK_TRANSLATOR_ERROR
                        })

                        reject({
                            code : 500,
                            message : error
                        })
                     })


    })

}

export const getTaskTransactions = (taskUid) => (dispatch, getState, { getFirebase, getFirestore }) => {

    dispatch({
        type : GET_TASK_TRANSACTIONS
    })

    return new Promise( (resolve, reject) => {

        const firestore = getFirestore();

        let taskTransactions = [];

        let taskTransactionsRef = firestore.collection('transactions')
                                        .where('task','==',taskUid)
                                        .orderBy('dateAdded','asc')
        
        taskTransactionsRef.get().then( (querySnapshots ) => {

            querySnapshots.forEach( (doc) => {
                taskTransactions.push(doc.data())
            })

            resolve({
                code : 200,
                data : {
                    transactions : [...taskTransactions]
                }
            })

            dispatch({
                type : GET_TASK_TRANSACTIONS_SUCCESS
            })

        }).catch( error => {
            console.log(error)
            dispatch({
                type : GET_TASK_TRANSACTIONS_ERROR
            })
        })

    })

}

export const getTaskDetails = (taskUid) => (dispatch, getState, { getFirebase, getFirestore }) => {

    return new Promise( (resolve, reject) => {

        const firestore = getFirestore();

        firestore.collection('tasks').doc(taskUid)
            .get().then( querySnapshot => {

                resolve(querySnapshot.data())

            })
            .catch( error => {
                console.log(error)
                throw(error)
            })


    })

}

export const setEscalation = (data) => async (dispatch, getState, { getFirebase, getFirestore }) => {

    dispatch({
        type : "CHECK_ESCALATION"
    })

    return new Promise( async (resolve, reject) => {

        const firestore = getFirestore();
        const firebase = getFirebase();
    
        let escalationRef = firestore.collection('escalations').where('task','==',data.taskUid);
        let escalation = null;
        let escalationId = null;

        await escalationRef.get().then( (querySnapshots) => {

            querySnapshots.forEach( (doc) => {
                escalation = doc.data()
            })

        }).catch( error => {
            console.log(error)
            dispatch({
                type : "CHECK_ESCALATION_ERROR"
            })
            resolve({
                code : 500,
                message : error
            })
        })

        dispatch({
            type : "CHECK_ESCALATION_SUCCESS"
        })
        
        if(escalation===null){

            escalationRef = firestore.collection('escalations').doc();
            escalationId = escalationRef.id;

        }else{

            escalationId = escalation.uid
            escalationRef = firestore.collection('escalations').doc(escalation.uid);

        }

        

        let key = firebase.auth().currentUser.uid;
        let escalationLetter = {
            [key] : data.escalationLetter
        }
        let escalationReason = {
            [key] : data.escalationReason
        }
        let escalatedBy = {
            [key] : true
        }
        
        escalationRef.set({
            isResolved : false,
            dateAdded : firestore.Timestamp.now(),
            uid : escalationId,
            task : data.taskUid,
            operator : data.operator,
            escalationReason : escalation === null ? {...escalationReason} : {...escalation.escalationReason, ...escalationReason},
            escalationLetter : escalation === null ? {...escalationLetter} : {...escalation.escalationLetter, ...escalationLetter},
            escalatedBy : escalation === null ?  {...escalatedBy} : {...escalation.escalatedBy, ...escalatedBy}
        }, { merge : true }).then( () => {

            dispatch({
                type : "SET_ESCALATION_SUCCESS"
            })

            resolve({
                code : 200,
                message : "success"
            })
        })




    })

}
