import {
    GET_USER_TRANSLATORS,
    GET_USER_TRANSLATORS_SUCCESS,
    GET_USER_TRANSLATORS_PAGINATE,
    GET_USER_TRANSLATORS_PAGINATE_SUCCESS,
    GET_USER_CUSTOMERS,
    GET_USER_CUSTOMERS_SUCCESS,
    GET_USER_CUSTOMERS_PAGINATE,
    GET_USER_CUSTOMERS_PAGINATE_SUCCESS,
    GET_USER_CANDIDATES,
    GET_USER_CANDIDATES_SUCCESS,
    GET_USER_CANDIDATES_PAGINATE,
    GET_USER_CANDIDATES_PAGINATE_SUCCESS,

    GET_USER_ACCOUNT_MANAGERS,
    GET_USER_ACCOUNT_MANAGERS_SUCCESS,
    GET_USER_ACCOUNT_MANAGERS_PAGINATE,
    GET_USER_ACCOUNT_MANAGERS_PAGINATE_SUCCESS,

    GET_USER_OPERATORS,
    GET_USER_OPERATORS_SUCCESS,
    GET_USER_OPERATORS_PAGINATE,
    GET_USER_OPERATORS_PAGINATE_SUCCESS,
} from '../actions/types'

const initState ={
    isGettingTranslators : false,
    isGettingMoreTranslators : false,
    translators : [],
    translatorsTotalRecord : 0,
    translatorsFilteredRecord : 0,
    translatorsCurrentPage : 0,
    translatorsPageCount : 0,

    isGettingCustomers : false,
    isGettingMoreCustomers : false,
    customers : [],
    customersTotalRecord : 0,
    customersFilteredRecord : 0,
    customersCurrentPage : 0,
    customersPageCount : 0,

    isGettingCandidates : false,
    isGettingMoreCandidates : false,
    candidates : [],
    candidatesTotalRecord : 0,
    candidatesFilteredRecord : 0,
    candidatesCurrentPage : 0,
    candidatesPageCount : 0,

    isGettingAccountManagers : false,
    isGettingMoreAccountManagers : false,
    accountManagers : [],
    accountManagersTotalRecord : 0,
    accountManagersFilteredRecord : 0,
    accountManagersCurrentPage : 0,
    accountManagersPageCount : 0,

    isGettingOperators : false,
    isGettingMoreOperators : false,
    operators : [],
    operatorsTotalRecord : 0,
    operatorsFilteredRecord : 0,
    operatorsCurrentPage : 0,
    operatorsPageCount : 0,

}

const userReducer = (state = initState, action) => {
    switch(action.type){
        case GET_USER_TRANSLATORS:
            return {
                ...state,
                isGettingTranslators : true
            }
        case GET_USER_TRANSLATORS_SUCCESS:
            return {
                ...state,
                isGettingTranslators : false,
                translators : [...action.payload.data.hits],
                translatorsTotalRecord : action.payload.data.nbHits,
                translatorsFilteredRecord : action.payload.data.hits.length,
                translatorsCurrentPage : action.payload.data.page,
                translatorsPageCount : action.payload.data.nbPages
            }
        case GET_USER_TRANSLATORS_PAGINATE:
            return{
                ...state,
                isGettingMoreTranslators : true
            }
        case GET_USER_TRANSLATORS_PAGINATE_SUCCESS:
            return {
                ...state,
                isGettingMoreTranslators : false,
                translators : [...state.translators, ...action.payload.data.hits],
                translatorsTotalRecord : action.payload.data.nbHits,
                translatorsFilteredRecord : action.payload.data.hits.length,
                translatorsCurrentPage : action.payload.data.page,
                translatorsPageCount : action.payload.data.nbPages
            }

        case GET_USER_CUSTOMERS:
            return {
                ...state,
                isGettingCustomers : true
            }
        case GET_USER_CUSTOMERS_SUCCESS:
            return {
                ...state,
                isGettingCustomers : false,
                customers : [...action.payload.data.hits],
                customersTotalRecord : action.payload.data.nbHits,
                customersFilteredRecord : action.payload.data.hits.length,
                customersCurrentPage : action.payload.data.page,
                customersPageCount : action.payload.data.nbPages
            }
        case GET_USER_CUSTOMERS_PAGINATE:
            return{
                ...state,
                isGettingMoreCustomers : true
            }
        case GET_USER_CUSTOMERS_PAGINATE_SUCCESS:
            return {
                ...state,
                isGettingMoreCustomers : false,
                customers : [...state.customers, ...action.payload.data.hits],
                customersTotalRecord : action.payload.data.nbHits,
                customersFilteredRecord : action.payload.data.hits.length,
                customersCurrentPage : action.payload.data.page,
                customersPageCount : action.payload.data.nbPages
            }
        
        case GET_USER_CANDIDATES:
            return {
                ...state,
                isGettingCandidates : true
            }
        case GET_USER_CANDIDATES_SUCCESS:
            return {
                ...state,
                isGettingCandidates : false,
                candidates : [...action.payload.data.hits],
                candidatesTotalRecord : action.payload.data.nbHits,
                candidatesFilteredRecord : action.payload.data.hits.length,
                candidatesCurrentPage : action.payload.data.page,
                candidatesPageCount : action.payload.data.nbPages
            }
        case GET_USER_CANDIDATES_PAGINATE:
            return{
                ...state,
                isGettingMoreCandidates : true
            }
        case GET_USER_CANDIDATES_PAGINATE_SUCCESS:
            return {
                ...state,
                isGettingMoreCandidates : false,
                candidates : [...state.candidates, ...action.payload.data.hits],
                candidatesTotalRecord : action.payload.data.nbHits,
                candidatesFilteredRecord : action.payload.data.hits.length,
                candidatesCurrentPage : action.payload.data.page,
                candidatesPageCount : action.payload.data.nbPages
            }

        case GET_USER_ACCOUNT_MANAGERS:
            return {
                ...state,
                isGettingAccountManagers : true
            }
        case GET_USER_ACCOUNT_MANAGERS_SUCCESS:
            return {
                ...state,
                isGettingAccountManagers : false,
                accountManagers : [...action.payload.data.hits],
                accountManagersTotalRecord : action.payload.data.nbHits,
                accountManagersFilteredRecord : action.payload.data.hits.length,
                accountManagersCurrentPage : action.payload.data.page,
                accountManagersPageCount : action.payload.data.nbPages
            }
        case GET_USER_ACCOUNT_MANAGERS_PAGINATE:
            return{
                ...state,
                isGettingMoreAccountManagers : true
            }
        case GET_USER_ACCOUNT_MANAGERS_PAGINATE_SUCCESS:
            return {
                ...state,
                isGettingMoreAccountManagers : false,
                accountManagers : [...state.accountManagers, ...action.payload.data.hits],
                accountManagersTotalRecord : action.payload.data.nbHits,
                accountManagersFilteredRecord : action.payload.data.hits.length,
                accountManagersCurrentPage : action.payload.data.page,
                accountManagersPageCount : action.payload.data.nbPages
            }

        case GET_USER_OPERATORS:
            return {
                ...state,
                isGettingOperators : true
            }
        case GET_USER_OPERATORS_SUCCESS:
            return {
                ...state,
                isGettingOperators : false,
                operators : [...action.payload.data.hits],
                operatorsTotalRecord : action.payload.data.nbHits,
                operatorsFilteredRecord : action.payload.data.hits.length,
                operatorsCurrentPage : action.payload.data.page,
                operatorsPageCount : action.payload.data.nbPages
            }
        case GET_USER_OPERATORS_PAGINATE:
            return{
                ...state,
                isGettingMoreOperators : true
            }
        case GET_USER_OPERATORS_PAGINATE_SUCCESS:
            return {
                ...state,
                isGettingMoreOperators : false,
                operators : [...state.operators, ...action.payload.data.hits],
                operatorsTotalRecord : action.payload.data.nbHits,
                operatorsFilteredRecord : action.payload.data.hits.length,
                operatorsCurrentPage : action.payload.data.page,
                operatorsPageCount : action.payload.data.nbPages
            }

        default : 
            return state;
    }
}

export default userReducer;