import {
    GET_ADMIN_CONFIG,
    GET_ADMIN_CONFIG_ERROR,
    GET_ADMIN_CONFIG_SUCCESS,
    LOGOUT_SUCCESS
} from '../actions/types'

const initState = {
    adminConfig : {}
}

const adminConfigReducer = (state = initState, action) => {

    switch(action.type){
        case GET_ADMIN_CONFIG_SUCCESS:
            return {
                ...state,
                adminConfig : action.payload.data
            } 
        case LOGOUT_SUCCESS:
            return initState;
        default:
            return state;
    }

}

export default adminConfigReducer