import {

    SET_REPORT_DATE_FILTER,
    GET_TASK_STATISTICS,
    GET_TASK_STATISTICS_SUCCESS,
    GET_TASK_STATISTICS_ERROR,
    GET_USER_STATISTICS,
    GET_USER_STATISTICS_SUCCESS,
    GET_USER_STATISTICS_ERROR,
    GET_REQUESTS_STATISTICS,
    GET_REQUESTS_STATISTICS_SUCCESS,
    GET_REQUESTS_STATISTICS_ERROR,
    GET_EARNINGS_STATISTICS,
    GET_EARNINGS_STATISTICS_SUCCESS,
    GET_EARNINGS_STATISTICS_ERROR,
    GET_TASK_STATUS_STATISTICS,
    GET_TASK_STATUS_STATISTICS_SUCCESS,
    GET_TASK_STATUS_STATISTICS_ERROR,
    GET_TASK_TYPE_STATISTICS,
    GET_TASK_TYPE_STATISTICS_SUCCESS,
    GET_TASK_TYPE_STATISTICS_ERROR,

    GET_TRANSLATOR_STATISTICS,
    GET_TRANSLATOR_STATISTICS_SUCCESS,
    GET_TRANSLATOR_STATISTICS_ERROR
} from '../actions/types'

const initState = {
    isGettingTaskStat : false,
    taskList : [],
    isGettingUserStat : false,
    userList : [],
    isGettingEarningStat : false,
    earningList : [],
    isGettingRequestStat : false,
    requestList : [],

    isGettingTaskStatusStat : false,
    taskStatusStat : [],
    isGettingUserStat : false,
    userStat : [],
    isGettingTaskTypeStat : false,
    taskTypeStat : [],
    isGettingTranslatorStat : false,
    translatorStat : [],
    isGettingTranslatorCountStat : false,
    translatorCountStat : 0,
    reportDateFilter : null
}

const reportReducer = (state = initState, action) => {
    switch(action.type){
        case SET_REPORT_DATE_FILTER:
            return{
                ...state,
                reportDateFilter : {
                    dateFrom : action.payload.dateFrom,
                    dateTo : action.payload.dateTo
                }
            }
        case GET_TASK_STATISTICS:
            return {
                ...state,
                isGettingTaskStat : true,
            }
        case GET_TASK_STATISTICS_SUCCESS:
            return {
                ...state,
                isGettingTaskStat : false,
                taskList : [...action.payload.taskList]
            }
        case GET_TASK_STATISTICS_ERROR:
            return {
                ...state,
                isGettingTaskStat : false,
                taskList : []
            }

        case GET_REQUESTS_STATISTICS:
            return {
                ...state,
            }
        case GET_REQUESTS_STATISTICS_SUCCESS:
            return {
                ...state,
                requestList : [...action.payload.escalations]
            }
        case GET_REQUESTS_STATISTICS_ERROR:
            return {
                ...state,
                requestList : []
            }

        case GET_EARNINGS_STATISTICS:
            return {
                ...state,
            }
        case GET_EARNINGS_STATISTICS_SUCCESS:
            return {
                ...state,
                earningList : [...action.payload.earningList]
            }
        case GET_EARNINGS_STATISTICS_ERROR:
            return {
                ...state,
                earningList : []
            }
        case GET_TASK_STATUS_STATISTICS:
            return {
                ...state,
                isGettingTaskStatusStat : true
            }
        case GET_TASK_STATUS_STATISTICS_SUCCESS:
            return {
                ...state,
                isGettingTaskStatusStat : false,
                taskStatusStat : [...action.payload.taskStatusList]
            }
        case GET_TASK_STATUS_STATISTICS_ERROR:
            return {
                ...state,
                isGettingTaskStatusStat : false,
                taskStatusStat : []
            }
        case GET_TASK_TYPE_STATISTICS:
            return {
                ...state,
                isGettingTaskTypeStat : true
            }
        case GET_TASK_TYPE_STATISTICS_SUCCESS:
            return {
                ...state,
                isGettingTaskTypeStat : false,
                taskTypeStat : [...action.payload.taskTypeList]
            }
        case GET_TASK_TYPE_STATISTICS_ERROR:
            return {
                ...state,
                isGettingTaskTypeStat : false,
                taskTypeStat : []
            }
        case GET_USER_STATISTICS:
            return {
                ...state,
                isGettingUserStat : true
            }
        
        case GET_USER_STATISTICS_SUCCESS:
            return {
                ...state,
                isGettingUserStat : false,
                userList : [...action.payload.userList]
            }
        case GET_USER_STATISTICS_ERROR:
            return {
                ...state,
                isGettingUserStat : false,
                userList : []
            }
        case GET_TRANSLATOR_STATISTICS:
            return {
                ...state,
                isGettingTranslatorStat : true
            }
        
        case GET_TRANSLATOR_STATISTICS_SUCCESS:
            return {
                ...state,
                isGettingTranslatorStat : false,
                translatorStat : [...action.payload.translatorList]
            }
        case GET_TRANSLATOR_STATISTICS_ERROR:
            return {
                ...state,
                isGettingTranslatorStat : false,
                translatorStat : []
            }
        default:
            return state;
    }

}

export default reportReducer