export const getTranslators = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : "GET_TRANSLATORS"
    })

    if(data.reset){
        dispatch({
            type : "CLEAR_TRANSLATORS"
        })
    }

    const firestore = getFirestore();

    const translatorLastRef = getState().translator.translatorLastRef;

    let translatorsRef = firestore.collection('users')
                .where('type','==','translator')
                .where('status','==','available')

    if(data.filter==="best-rating"){

        if(translatorLastRef!==null){
            
            translatorsRef = translatorsRef.orderBy('rating','desc')

            translatorsRef.startAfter(translatorLastRef).limit(5).get()
                .then ( querySnapshots => {
                    let translatorList = [];
                    let translatorRefList = [];

                    querySnapshots.forEach( doc => {
                        translatorList.push(doc.data())
                        translatorRefList.push(doc);
                    })
    
                    dispatch({
                        type : "GET_TRANSLATORS_SUCCESS_PAGINATE",
                        payload : {
                            translators : translatorList,
                            translatorsRef : translatorRefList
                        }
                    })

                })

        }else{

            translatorsRef.orderBy('rating','desc').limit(5).get()
            .then( (querySnapShots) => {

                let translatorList = [];
                let translatorRefList = [];

                querySnapShots.forEach( doc => {
                    translatorList.push(doc.data())
                    translatorRefList.push(doc);
                })

                dispatch({
                    type : "GET_TRANSLATORS_SUCCESS",
                    payload : {
                        translators : translatorList,
                        translatorsRef : translatorRefList
                    }
                })

            })

        }

    }

    if(data.filter==="all-translators"){

        if(translatorLastRef!==null){
            
            translatorsRef = translatorsRef.orderBy('fullName','asc')

            translatorsRef.startAfter(translatorLastRef).limit(5).get()
                .then ( querySnapshots => {
                    let translatorList = [];
                    let translatorRefList = [];

                    querySnapshots.forEach( doc => {
                        translatorList.push(doc.data())
                        translatorRefList.push(doc);
                    })
    
                    dispatch({
                        type : "GET_TRANSLATORS_SUCCESS_PAGINATE",
                        payload : {
                            translators : translatorList,
                            translatorsRef : translatorRefList
                        }
                    })

                })

        }else{

            translatorsRef.orderBy('fullName','asc').limit(5).get()
            .then( (querySnapShots) => {

                let translatorList = [];
                let translatorRefList = [];

                querySnapShots.forEach( doc => {
                    translatorList.push(doc.data())
                    translatorRefList.push(doc);
                })

                dispatch({
                    type : "GET_TRANSLATORS_SUCCESS",
                    payload : {
                        translators : translatorList,
                        translatorsRef : translatorRefList
                    }
                })

            })

        }

    }

    if(data.filter==="most-suitable"){

        translatorsRef = translatorsRef.where('specialty','==',data.taskCategory)

        if(translatorLastRef!==null){
            
            translatorsRef = translatorsRef.orderBy('rating','desc')

            translatorsRef.startAfter(translatorLastRef).limit(5).get()
                .then ( querySnapshots => {
                    let translatorList = [];
                    let translatorRefList = [];

                    querySnapshots.forEach( doc => {
                        translatorList.push(doc.data())
                        translatorRefList.push(doc);
                    })
    
                    dispatch({
                        type : "GET_TRANSLATORS_SUCCESS_PAGINATE",
                        payload : {
                            translators : translatorList,
                            translatorsRef : translatorRefList
                        }
                    })

                })

        }else{

            translatorsRef.orderBy('rating','desc').limit(5).get()
            .then( (querySnapShots) => {

                let translatorList = [];
                let translatorRefList = [];

                querySnapShots.forEach( doc => {
                    translatorList.push(doc.data())
                    translatorRefList.push(doc);
                })

                dispatch({
                    type : "GET_TRANSLATORS_SUCCESS",
                    payload : {
                        translators : translatorList,
                        translatorsRef : translatorRefList
                    }
                })

            })

        }

    }
                


}

export const clearTranslators = () => (dispatch) => {
    dispatch({
        type : "CLEAR_TRANSLATORS"
    })
}