import{
    LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR
} from '../actions/types'

const initState = {
    isEmailAvailable : true,
    isSignUpSuccess : false,
    authError : null,
    userProfile : {},
    isLoggingOut : false,
    isAuthenticated : false
}

const authReducer = ( state = initState , action ) => {
    switch(action.type){
        case "VALIDATE_EMAIL_ERROR":
            return {
                ...state,
                isEmailAvailable : false
            }
        case "VALIDATE_EMAIL_SUCCESS":
            return {
                ...state,
                isEmailAvailable : true
            }
        case "SIGN_UP_SUCCESS":
            return {
                ...state,
                isSignUpSuccess : true
            }
        case "SIGN_UP_ERROR":
            return {
                ...state,
                isSignUpSuccess : false
            }
        case "LOGIN_ERROR":
            return {
                ...state,
                authError : action.payload.error
            }
        case "LOGIN_SUCCESS":
            return {
                authError : null,
                userProfile : {...action.payload.userData}
            }
        case LOGOUT:
            return{
                ...state,
                isLoggingOut : true
            }
        case LOGOUT_ERROR:
            return{
                ...state,
                isLoggingOut : false,
            }
        case LOGOUT_SUCCESS:
            return initState;
        default:
            return state;
    }
}

export default authReducer;