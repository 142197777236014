import {
    GET_ESCALATIONS,
    GET_ESCALATIONS_SUCCESS,
    GET_ESCALATIONS_ERROR,
    GET_ESCALATIONS_PAGINATE_SUCCESS,
    CLEAR_ESCALATIONS_REF,
    LOAD_MORE_ESCALATIONS,
    LOAD_MORE_ESCALATIONS_SUCCESS,
    LOAD_MORE_ESCALATIONS_ERROR
} from './types'

export const setEscalation = (data) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
        type : "CHECK_ESCALATION"
    })

    return new Promise( async (resolve, reject) => {

        const firestore = getFirestore();
        const firebase = getFirebase();
    
        let escalationRef = firestore.collection('escalations').where('task','==',data.taskUid);
        let escalation = null;
        let escalationId = null;

        await escalationRef.get().then( (querySnapshots) => {

            querySnapshots.forEach( (doc) => {
                escalation = doc.data()
            })

        }).catch( error => {
            console.log(error)
            dispatch({
                type : "CHECK_ESCALATION_ERROR"
            })
            resolve({
                code : 500,
                message : error
            })
        })

        dispatch({
            type : "CHECK_ESCALATION_SUCCESS"
        })
        
        if(escalation===null){

            escalationRef = firestore.collection('escalations').doc();
            escalationId = escalationRef.id;

        }else{

            escalationId = escalation.uid
            escalationRef = firestore.collection('escalations').doc(escalation.uid);

        }

        let key = firebase.auth().currentUser.uid;
        let escalationLetter = {
            [key] : data.escalationLetter
        }
        let escalationReason = {
            [key] : data.escalationReason
        }
        let escalatedBy = {
            [key] : true
        }
        
        escalationRef.set({
            isResolved : false,
            dateAdded : firestore.Timestamp.now(),
            uid : escalationId,
            task : data.taskUid,
            operator : data.operator,
            escalationReason : escalation === null ? {...escalationReason} : {...escalation.escalationReason, ...escalationReason},
            escalationLetter : escalation === null ? {...escalationLetter} : {...escalation.escalationLetter, ...escalationLetter},
            escalatedBy : escalation === null ?  {...escalatedBy} : {...escalation.escalatedBy, ...escalatedBy}
        }, { merge : true }).then( () => {

            dispatch({
                type : "SET_ESCALATION_SUCCESS"
            })

            resolve({
                code : 200,
                message : "success"
            })
        })
    })
}

export const getEscalations = (data) => async (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();

    const escalationState = getState().escalation;
    const stateEscalations = escalationState.escalations;
    
    dispatch({
        type : GET_ESCALATIONS
    })

    let escalationsRef = firestore.collection('escalations')
                            .where('isResolved','==',false)

    escalationsRef.orderBy('dateAdded','desc').limit(10).get()
        .then( async (querySnapshots) => {

    let escalationList = [];
    let escalationRefList = [];

    for(let a=0; a<querySnapshots.docs.length; a++){
        let taskDetails;

        let taskRef = firestore.collection('tasks').doc(querySnapshots.docs[a].data().task);

        await taskRef.get()
            .then( async (tQuerySnapshot) => {
                taskDetails = tQuerySnapshot.data()
                
                let escalatorList = [];

                Object.keys(querySnapshots.docs[a].data().escalatedBy).map( async (data) => {

                    let userRef = firestore.collection('users').doc(data)
                    
                    await userRef.get()
                        .then( (uQuerySnapshot) => {
                            escalatorList.push({
                                user : uQuerySnapshot.data(),
                                escalationReason : querySnapshots.docs[a].data().escalationReason[data],
                                escalationLetter : querySnapshots.docs[a].data().escalationLetter[data]
                            })
                        })
                })

                escalationList.push({
                    escalation : querySnapshots.docs[a].data().uid,
                    task : taskDetails,
                    escalators : escalatorList
                })

                escalationRefList.push(querySnapshots.docs[a]);
            })
    }
    
    dispatch({
        type : GET_ESCALATIONS_SUCCESS,
        payload : {
            escalations : escalationList,
            escalationsRef : escalationRefList
        }
    })

})

}

export const loadMoreEscalations = (data) => async (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : LOAD_MORE_ESCALATIONS
    })

    const firestore = getFirestore();
    const escalationState = getState().escalation;

    const escalationRefs = escalationState.escalationsRef;
    const lastRef = escalationRefs.length === 0 ? null : escalationRefs[escalationRefs.length-1]

    let escalationsRef = firestore.collection('escalations')
                            .where('isResolved','==',false)

    if(lastRef!==null){
       
        escalationsRef.orderBy('dateAdded','desc').startAfter(lastRef).limit(5).get()
            .then( async (querySnapshots) => {

                let escalationList = [];
                let escalationRefList = [];

                for(let a=0; a<querySnapshots.docs.length; a++){
                    let taskDetails;
    
                    let taskRef = firestore.collection('tasks').doc(querySnapshots.docs[a].data().task);
    
                    await taskRef.get()
                        .then( async (tQuerySnapshot) => {
                            taskDetails = tQuerySnapshot.data()
                            
                            let escalatorList = [];

                            Object.keys(querySnapshots.docs[a].data().escalatedBy).map( async (data) => {
    
                                let userRef = firestore.collection('users').doc(data)
                                
                                await userRef.get()
                                    .then( (uQuerySnapshot) => {
                                        escalatorList.push({
                                            user : uQuerySnapshot.data(),
                                            escalationReason : querySnapshots.docs[a].data().escalationReason[data],
                                            escalationLetter : querySnapshots.docs[a].data().escalationLetter[data]
                                        })
                                    })
                            })

                            escalationList.push({
                                task : taskDetails,
                                escalators : escalatorList
                            })

                            escalationRefList.push(querySnapshots.docs[a]);
                        })
                }

                dispatch({
                    type : LOAD_MORE_ESCALATIONS_SUCCESS,
                    payload : {
                        escalations : escalationList,
                        escalationsRef : escalationRefList
                    }
                })

            }).catch( error => {
                console.log(error)

                dispatch({
                    type : LOAD_MORE_ESCALATIONS_ERROR
                })
            })

    }

}

export const watchEscalations = (data) => async (dispatch, getState, { getFirestore, getFirebase }) => {

}

export const resolveEscalation = (data) => async (dispatch, getState, { getFirestore, getFirebase }) => {

    return new Promise ( async (resolve) => {

        const firestore = getFirestore();

        let escalationRef = firestore.collection('escalations').doc(data.uid)

        await escalationRef.set(data, { merge : true });

        resolve(true)

    })
    
}

export const clearEscalationsRef = () => (dispatch) => {
    dispatch({
        type : CLEAR_ESCALATIONS_REF
    })
}
