import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, Link, withRouter } from 'react-router-dom';
import $ from 'jquery';
import _ from 'lodash/'
import './profile.css'

import {
    updateCurrentUser,
    uploadUserImage,
    checkForDuplicateEmail,
    updateUserEmail,
    updateUserPassword,
    signOutUser,
    reAuthenticateUser
} from '../../actions/authAction';

import {
    addModal,
    popModal
} from '../../actions/appAction';

import {
    Message
} from '../Modals/Message'
import {
    Reauthenticate
} from './Modals/Reauthenticate'
import {
    NewPassword
} from './Modals/NewPassword'
import {
    SpinnerModal
} from '../Spinner/SpinnerModal'

const countryList = require('country-list');
const countryCodes = require('country-codes-list')
const myCountryCodesObject = countryCodes.customList('countryNameEn','+{countryCallingCode}')

const acceptedFileTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg"
]

export class Index extends Component {

    constructor(){
        super();
        this.state = {
            isAuthenticated : false,
            displayPhoto : "",
            phoneCode : "",
            profileData : {},
            changedProfileData : {},
            changeCount : 0,
            isChanged : false,
            errorCount : 0,
            isFullnameValid : true,
            isFullnameChanged : false,
            isMessageModalActive : false,
            messageModalHeader : "",
            messageModalSubHeader : "",
            isReauthenticateModalActive : false,
            isPasswordChangeModalActive : false,
            isEmailValid : true,
            isEmailChanged : false,
            isCountryChanged : false,
            isCountryValid : true,
            isPhoneNumberChanged : false,
            isPhoneNumberValid : true,
            isProcessing : false,
            
            authEmail : "",
            authPassword : "",
            isLoggingIn : false,
            errorMessage : "",
            reAuthFor : "",
            newAuthPassword : "",
            passwordError : false,
            passwordConfirmError : false
        }

        this.checkForEmailDuplication = _.debounce(this.checkForEmailDuplication,500);
    }

    componentDidMount(){
        
        const { auth, userProfile } = this.props;

        if(userProfile.hasOwnProperty('fullName')){
            
            let profileData = {
                fullName : userProfile.fullName,
                country : userProfile.country,
                emailAddress : userProfile.emailAddress,
                phoneNumber : userProfile.phoneNumber
            }

            this.setState({
                isAuthenticated : true,
                phoneCode : myCountryCodesObject[userProfile.country],
                profileData : profileData,
                changedProfileData : profileData
            })
        }

    }

    componentDidUpdate(prevProps){

        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true,
                phoneCode : myCountryCodesObject[this.props.userProfile.country],
            })
        }

    }

    closeMessageModal = () => {
        this.props.signOutUser();
    }

    /**
     * PASSWORD FUNCTIONS
     */
    onPasswordClick = () => {
        this.setState({
            isPasswordChangeModalActive : true,
            reAuthFor : "password"
        })
    }

    onPasswordChange = async (e) => {

        let regExp = /^[a-zA-Z0-9]+$/i;
        $('#newAuthPassword').removeClass('error-input');
        $('#newAuthPassword').next('span').text('');

        if(e.target.value.length===0){
            return
        }

        if(!regExp.test(e.target.value)){
            $('#newAuthPassword').addClass('error-input');
            $('#newAuthPassword').next('span').text('Password should not contain any symbols or spaces.');
            this.setState({
                passwordError : true
            })
        }else{
            if(e.target.value.length<6 || e.target.value.length>8){
                $('#newAuthPassword').addClass('error-input');
                $('#newAuthPassword').next('span').text('Password should be 6-8 characters long');
                this.setState({
                    passwordError : true
                })
            }else{
                $('#newAuthPassword').removeClass('error-input')
                $('#newAuthPassword').next('span').text('');
                this.setState({
                    newAuthPassword : e.target.value,
                    passwordError : false
                })
            }  
        }
    }

    onPasswordConfirmChange = async (e) => {

        $('#authPasswordConfirm').removeClass('error-input');
        $('#authPasswordConfirm').next('span').text('');

        const { newAuthPassword } = this.state;

        if(e.target.value!==newAuthPassword){
            $('#authPasswordConfirm').addClass('error-input');
            $('#authPasswordConfirm').next('span').text(`Passwords don't match`);
            this.setState({
                passwordConfirmError : true
            })
        }else{
            $('#authPasswordConfirm').removeClass('error-input');
            $('#authPasswordConfirm').next('span').text();
            this.setState({
                passwordConfirmError : false
            })
        }
    }
    
    onSavePasswordChange = async () => {

        const { passwordError, passwordConfirmError } = this.state;

        if( !passwordError && !passwordConfirmError ){
            this.setState({
                isProcessing : true
            }, async () => {
                const { newAuthPassword } = this.state;
                
                let result = await this.props.updateUserPassword({
                    password : newAuthPassword
                })
    
                if(result.code===201){
                    setTimeout( () => {
                        this.setState({
                            isReauthenticateModalActive : true,
                            reAuthFor : "password",
                            isProcessing : false
                        })
                    }, 500)
                }else{
                    setTimeout ( () => {
                        this.setState({
                            isMessageModalActive : true,
                            messageModalHeader : "Password updated successfully!",
                            isPasswordChangeModalActive : false,
                            isProcessing : false
                        })
                    }, 500)
                }
            })
        }

    }

    closeNewPasswordModal = () => {
        this.setState({
            isPasswordChangeModalActive : false
        })
    }

    /**
     * EMAIL FUNCTIONS
     */
    onEmailChange = async (e) => {
        const { changedProfileData,  } = this.state;
        const { userProfile } = this.props;
      
        let cData = changedProfileData;
        cData[e.target.name] = e.target.value;

        let regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;
        let emailVal = e.target.value;

        if(!regExp.test(emailVal)){
            $('#emailAddress').addClass('error-input');
            $('#emailAddress').parent('div').next('.error-input-message').text('Invalid email format');
            this.setState({
                isEmailValid : false
            })
        }else{
            if(userProfile.emailAddress!==emailVal){
                await this.checkForEmailDuplication(emailVal);  
            }else{
                $('#emailAddress').removeClass('error-input');
                $('#emailAddress').parent('div').next('.error-input-message').text('');
                this.setState({
                    isEmailValid : true,
                    isEmailChanged : false
                })
            }
        }

    }

    checkForEmailDuplication = async (email) => {
        let x = await this.props.checkForDuplicateEmail(email);
        if(x.code==="success"){
            $('#emailAddress').removeClass('error-input');
            $('#emailAddress').next('.error-input-message').text('');
            this.setState({
                isEmailValid : true,
                isEmailChanged : true
            })
        }else{
            $('#emailAddress').addClass('error-input');
            $('#emailAddress').next('.error-input-message').text(x.message);
            this.setState({
                isEmailValid : false,
                isEmailChanged : true
            })
        }
    }

    saveEmail = async () => {

        this.setState({
            isProcessing : true
        }, async () => {
            const { changedProfileData } = this.state;

            let result = await this.props.updateUserEmail({
                email : changedProfileData.emailAddress
            })
    
            if(result.code===201){
                setTimeout( () => {
                    this.setState({
                        isReauthenticateModalActive : true,
                        reAuthFor : "email",
                        isProcessing : false
                    })
                }, 500)
            }else{
    
                await this.props.updateCurrentUser({
                    emailAddress : changedProfileData.emailAddress
                })
    
                setTimeout( () => {
                    this.setState({
                        isMessageModalActive : true,
                        messageModalHeader : "Email updated successfully!",
                        messageModalSubHeader : "Please confirm you email address and re-login your account. Please check your spam folder for the verification email as well. Thank you!",
                        isProcessing : false
                    })
                }, 500)
            }   
        })
       
    }

    /**
     * FULL NAME FUNCTIONS
     */
    onFullNameChange = async (e) => {

        const { changedProfileData } = this.state;
        const { userProfile } = this.props;

        if(e.target.name==="fullName"){
            let regExp = /^[\w]*,\s[\w\s]*$/i;

            if(!regExp.test(e.target.value)){
                $('#fullName').addClass('error-input');
                $('#fullName').parent('div').next('.error-input-message').text( `Name should be on the following format, Last Name, First Name` );
                this.setState({
                    isFullnameValid : false
                })
            }else{
                $('#fullName').removeClass('error-input');
                $('#fullName').parent('div').next('.error-input-message').text( `` );
                if(userProfile.fullName.trim()!==e.target.value.trim()){
                   
                    let cData = changedProfileData;
                    cData[e.target.name] = e.target.value;

                    this.setState({
                        isFullnameValid : true,
                        isFullnameChanged : true,
                        changedProfileData : {...cData}
                    })

                }else{
                    this.setState({
                        isFullnameValid : true,
                        isFullnameChanged : false
                    })
                }
            }
        }

    }

    saveFullname = () => {

        const { changedProfileData } = this.state;
    
        this.setState({
            isProcessing : true
        }, async () => {

            await this.props.updateCurrentUser({
                fullName : changedProfileData.fullName
            })

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isFullnameChanged : false
                })
            }, 500)

        })

    }

    /**
     * COUNTRY FUNCTIONS
     */
    onCountryChange = (e) => {
        const { userProfile } = this.props;
        const { changedProfileData } = this.state;

        if(e.target.value!==""){
            if(userProfile.country!==e.target.value){

                let cData = changedProfileData;
                cData[e.target.name] = e.target.value;

                this.setState({
                    isCountryChanged : true,
                    isCountryValid : true,
                    phoneCode : myCountryCodesObject[e.target.value],
                    changedProfileData : {...cData}
                })
            }else{
                this.setState({
                    isCountryChanged : false
                })
            }
        }else{
            this.setState({
                isCountryValid : false
            })
        }
    }

    saveCountry = async (e) => {

        this.setState({
            isProcessing : true
        }, async () => {

            const { changedProfileData }  = this.state;

            await this.props.updateCurrentUser({
                country : changedProfileData.country
            })

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isCountryValid : true,
                    isCountryChanged : false
                })
            }, 500)

        })

    }

    /**
     * PHONE NUMBER FUNCTIONS
     */

    onPhoneNumberFocus = (e) => {
        e.target.select();
    }

    onPhoneNumberChange = (e) => {

        const { changedProfileData } = this.state;
        const { userProfile } = this.props;

        let regExp = /^[0-9\b]+$/;

        if(!regExp.test(e.target.value)){
            $('#phoneNumber').addClass('error-input');
            $('#phoneNumber').parent('div').next('.error-input-message').text( `Invalid phone number format` );
            this.setState({
                isPhoneNumberValid : false
            })
        }else{
            
            const { phoneCode } = this.state;

            if(e.target.value.indexOf(phoneCode)!=-1){
                $('#phoneNumber').addClass('error-input');
                $('#phoneNumber').parent('div').next('.error-input-message').text( `Invalid phone number format` );
                this.setState({
                    isPhoneNumberValid : false
                })
            }else{
                $('#phoneNumber').removeClass('error-input');
                $('#phoneNumber').parent('div').next('.error-input-message').text( `` );
                if(userProfile.phoneNumber!==e.target.value){
                    
                    let cData = changedProfileData;
                    cData[e.target.name] = e.target.value;

                    this.setState({
                        isPhoneNumberChanged : true,
                        isPhoneNumberValid : true,
                        changedProfileData : {...cData}
                    })

                }else{
                    this.setState({
                        isPhoneNumberValid : true,
                        isPhoneNumberChanged : false
                    })
                }
            }
        }

    }

    savePhoneNumber = async () => {

        this.setState({
            isProcessing : true
        }, async () => {

            const { changedProfileData, phoneCode }  = this.state;

            await this.props.updateCurrentUser({
                phoneNumber : phoneCode + "" + changedProfileData.phoneNumber
            })

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isPhoneNumberChanged : false,
                    isPhoneNumberValid : true
                })
            }, 500)

        })

    }
    
    /**
     * PHOTO UPLOAD FUNCTIONS
     */
    openPhotoUpload = (e) => {
        e.preventDefault();
        $('#new_photo').click();
    }

    onFileChange = async (e) => {
        
        let newPhoto = e.target.files.length!== 0 ? e.target.files[0] : null;

        if(newPhoto!==null){

            let newPhotoFileType = newPhoto.type;

            if(acceptedFileTypes.indexOf(newPhotoFileType)!==-1){
                let result = await this.props.uploadUserImage({
                    file : newPhoto
                })

                if(result.code===200){
                    this.setState({
                        displayPhoto : result.url
                    })
                }
                $('#new_photo').next('span').text('');
            }else{
                $('#new_photo').next('span').text('File type not supported');
            }
        }
    }

    /**
     * RE AUTHENTICATION FUNCTIONS
     */
    authChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    authSubmit = (e) => {
        e.preventDefault();
        const { authEmail, authPassword, changedProfileData, reAuthFor } = this.state;
        const { userProfile } = this.props;

        if(authEmail==="" && authPassword===""){
            this.setState({
                errorMessage : "Please complete all fields to continue."
            })
        }else{
            if(reAuthFor==="password"){
                this.setState({
                    isProcessing : true,
                    errorMessage : ""
                }, async () => {
                    let result = await this.props.reAuthenticateUser({
                        email : authEmail,
                        password : authPassword
                    })

                    if(result.code===200){
                        this.setState({
                            isReauthenticateModalActive : false,
                            isPasswordChangeModalActive : false,
                            authEmail : "",
                            authPassword : "",
                            isLoggingIn : false,
                            errorMessage : "",
                            reAuthFor : "",
                            isProcessing : false,
                            isMessageModalActive : true,
                            isPasswordChangeModalActive : false,
                        })
                    }else{
                        this.setState({
                            isProcessing : false,
                            errorMessage : result.message
                        })
                    }
                })
            }

            if(reAuthFor==="email"){
                this.setState({
                    isProcessing : true
                }, async () => {
                    let result = await this.props.reAuthenticateUser({
                        email : authEmail,
                        password : authPassword
                    })

                    if(result.code===200){
                        this.setState({
                            isReauthenticateModalActive : false,
                            authEmail : "",
                            authPassword : "",
                            isLoggingIn : false,
                            errorMessage : "",
                            reAuthFor : "",
                            isProcessing : false,
                            isMessageModalActive : true,
                            isPasswordChangeModalActive : false,
                        })
                    }else{
                        this.setState({
                            isProcessing : false,
                            errorMessage : result.message
                        })
                    }
                })
            }
        }
    }

    closeReAuthModal = () => {
        this.setState({
            isReauthenticateModalActive : false,
            reAuthFor : ""
        })
    }

    render() {
        
        const { auth, userProfile, isLoggingOut } = this.props;
        const { isFullnameChanged, isFullnameValid } = this.state;
        const { isCountryChanged, isCountryValid } = this.state;
        const { isPhoneNumberChanged, isPhoneNumberValid } = this.state;
        const { isEmailChanged, isEmailValid } = this.state;
        const { isPasswordChangeModalActive, errorMessage, isProcessing, isReauthenticateModalActive, isMessageModalActive, messageModalHeader, messageModalSubHeader, isAuthenticated, displayPhoto, phoneCode } = this.state;

        let imageUrl = userProfile.hasOwnProperty('imageUrl') ? userProfile.imageUrl === "" ? require('../../images/default-user.jpg') : userProfile.imageUrl : require('../../images/default-user.jpg')

        if(displayPhoto!==""){
            imageUrl = displayPhoto
        }

        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }

        if(!auth.uid){
            return <Redirect to="/" />
        }

        return (
            <div className="div-holder">
                {
                    isAuthenticated &&
                    <div className="div-holder">
                        <div className="profile-box">
                            <div className="display-photo">
                                <img src={imageUrl}/>
                                <input onChange={this.onFileChange} type="file" name="new_photo" id="new_photo" style={{ display : "none" }} />
                                <span style={{ fontSize : ".65rem" }} className="error-input-message"></span>
                                <button onClick={this.openPhotoUpload}>REPLACE</button>
                            </div>
                            <div className="user-data">
                                <div style={{ flex : '1' }}>
                                    <div style={{ marginBottom : "20px" }} className="form-group">
                                        <label className="custom-label block">Name</label>
                                        <div style={{ display : "flex" }}>
                                            <input onChange={this.onFullNameChange} defaultValue={userProfile.hasOwnProperty('fullName') ? userProfile.fullName : ""} id="fullName" name="fullName" style={{ width: "100%" }} className="custom-input" type="text" placeholder="Enter your name"/>
                                            { (isFullnameValid && isFullnameChanged) && <button onClick={this.saveFullname} >SAVE</button> }
                                        </div>
                                        <span className="error-input-message"></span>
                                    </div>
                                    <div style={{ marginBottom : "20px" }} className="form-group">
                                        <label className="custom-label block">Email</label>
                                        <div style={{ display : "flex"}}>
                                            <input onChange={this.onEmailChange} defaultValue={userProfile.hasOwnProperty('emailAddress') ? userProfile.emailAddress : ""} id="emailAddress" name="emailAddress" style={{ width: "100%" }} className="custom-input" type="text" placeholder="Enter your email address"/>
                                            { (isEmailValid && isEmailChanged) && <button onClick={this.saveEmail} >SAVE</button> }
                                        </div>
                                        <span className="error-input-message"></span>
                                    </div>
                                    <div style={{ marginBottom : "0" }} className="form-group">
                                        <label className="custom-label block">Password</label>
                                        <input onClick={this.onPasswordClick} defaultValue={"Your masked password"} id="password" name="password" style={{ width: "100%" }} className="custom-input" type="password" placeholder="Enter your password"/>
                                    </div>
                                </div>
                                <div style={{ flex : '1', paddingLeft : "32px" }}>
                                    <div style={{ marginBottom : "20px" }} className="form-group">
                                        <label className="custom-label block">Your country</label>
                                        <div style={{ display : "flex" }}>
                                            <select onChange={this.onCountryChange} placeholder="Select here" className="custom-select form-control" id="country" name="country">
                                                <option value="">Select here</option>
                                                {
                                                    Object.keys(myCountryCodesObject).map( (data, i) => (
                                                        <option selected={userProfile.country === data} key={i} value={data}>{data}</option>
                                                    ))
                                                }
                                            </select>
                                            { (isCountryValid && isCountryChanged) && <button onClick={this.saveCountry} >SAVE</button> }
                                        </div>
                                    </div>
                                    <label className="custom-label block">Phone number</label>
                                    <div style={{ marginBottom : "0" }} className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">{phoneCode}</span>
                                        </div>
                                        <input onFocus={this.onPhoneNumberFocus} onChange={this.onPhoneNumberChange} aria-describedby="basic-addon1" defaultValue={userProfile.hasOwnProperty('phoneNumber') ? userProfile.phoneNumber : ""} id="phoneNumber" name="phoneNumber" style={{ width: "100%", flex : "1" }} className="custom-input" type="text" placeholder="Enter your phone number"/>
                                        { (isPhoneNumberValid && isPhoneNumberChanged) && <button onClick={this.savePhoneNumber} >SAVE</button> }
                                    </div>
                                    <span className="error-input-message"></span>
                                </div>
                            </div>
                        </div>
                        {
                            isMessageModalActive && 
                            <Message 
                                closeMessageModal={this.closeMessageModal} 
                                isOpened={isMessageModalActive} 
                                header={messageModalHeader} 
                                subHeader={messageModalSubHeader}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                            />
                                
                        }
                        {
                            isReauthenticateModalActive && 
                            <Reauthenticate
                                isOpened={isReauthenticateModalActive}
                                authSubmit={this.authSubmit}
                                authChange={this.authChange}
                                errorMessage={errorMessage}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                                closeReAuthModal={this.closeReAuthModal}
                            />
                        }
                        {
                            isPasswordChangeModalActive && 
                            <NewPassword
                                onPasswordChange={this.onPasswordChange}
                                onPasswordConfirmChange={this.onPasswordConfirmChange}
                                onSavePasswordChange={this.onSavePasswordChange}
                                isOpened={isPasswordChangeModalActive}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                                closeNewPasswordModal={this.closeNewPasswordModal}
                            />
                        }
                        {
                            isProcessing &&
                            <SpinnerModal 
                                isOpened={isProcessing}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                            />
                        }
                        {
                            isLoggingOut &&
                            <SpinnerModal
                                isOpened={isLoggingOut}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                            />
                        }
                    </div>
                }
            </div>
        )


        
    }

}

const mapStateToProps = (state) => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        modalInstances : state.app.modalInstances,
        isLoggingOut : state.auth.isLoggingOut
    }
}


export default compose(
    withRouter,
    connect(mapStateToProps, {
        uploadUserImage,
        updateCurrentUser,
        checkForDuplicateEmail,
        updateUserEmail,
        updateUserPassword,
        signOutUser,
        reAuthenticateUser,
        addModal,
        popModal
    })
)(Index)