import React, { Component } from 'react'
import moment from 'moment'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

export class Message extends Component {

    render () {
        const { lastOpenedDate, message, auth, activeConversation } = this.props;
        
        let other = null;

        if(activeConversation!==null && activeConversation!==undefined){
            other = Object.keys(activeConversation.lastOpenedDates).find( f => f!==auth.uid);
        }

        let seen;
      
        if(activeConversation!==undefined){
            if(message.dateAdded.seconds !== undefined){
                if(activeConversation.lastOpenedDates[other]!==undefined){
                    if(message.dateAdded.seconds<=activeConversation.lastOpenedDates[other].seconds){
                        seen = <span style={{ fontSize : "12px", color : "#E6E4E8", marginLeft : "5px" }}><img src={require('../../images/icon-seen.png')} /> Seen</span>
                    }
                }
                // if(lastOpenedDate!==null && lastOpenedDate.seconds!== undefined){
                //     if(message.dateAdded.seconds<=lastOpenedDate.seconds){
                //         seen = <span style={{ fontSize : "12px", color : "#E6E4E8", marginLeft : "5px" }}><img src={require('../../images/icon-seen.png')} /> Seen</span>
                //     }
                // }
            }
    
            if(message.dateAdded._seconds !== undefined){
    
                if(activeConversation.lastOpenedDates[other]!==undefined){
                    if(message.dateAdded.seconds<=activeConversation.lastOpenedDates[other]._seconds){
                        seen = <span style={{ fontSize : "12px", color : "#E6E4E8", marginLeft : "5px" }}><img src={require('../../images/icon-seen.png')} /> Seen</span>
                    }
                }
    
                // if(lastOpenedDate!==null && lastOpenedDate._seconds!== undefined){
                //     if(message.dateAdded._seconds<=lastOpenedDate._seconds){
                //         seen = <span style={{ fontSize : "12px", color : "#E6E4E8", marginLeft : "5px" }}><img src={require('../../images/icon-seen.png')} /> Seen</span>
                //     }
                // }
            }
        }
        
        // if(message.dateAdded.seconds !== undefined){
        //     if(lastOpenedDate!== null && lastOpenedDate.seconds!== undefined){
        //         if(message.dateAdded.seconds<=lastOpenedDate.seconds){
        //             seen = <span style={{ fontSize : "12px", color : "#E6E4E8", marginLeft : "5px" }}><img src={require('../../images/icon-seen.png')} /> Seen</span>
        //         }
        //     }
        // }

        // if(message.dateAdded._seconds !== undefined){
        //     if(lastOpenedDate!== null && lastOpenedDate._seconds!== undefined){
        //         if(message.dateAdded._seconds<=lastOpenedDate._seconds){
        //             seen = <span style={{ fontSize : "12px", color : "#E6E4E8", marginLeft : "5px" }}><img src={require('../../images/icon-seen.png')} /> Seen</span>
        //         }
        //     }
        // }
        
        let messageStyle = {
            background : "#E6E4E8",
            color : "#414042"
        }

        let senderAvatarStyle = {
            marginLeft: "10px"
        }

        let flexDirection = {
            flexDirection : "row-reverse"
        }

        let messageFlexDirection = {
            flexDirection : "row"
        }
        let chatAvatar = "";
        if(auth.uid!==message.sentBy){
            messageStyle = {
                background : "#22C9C7",
                color : "#FFF"
            }

            flexDirection = {
                flexDirection : "row"
            }

            messageFlexDirection = {
                flexDirection : "row-reverse"
            }

            senderAvatarStyle = {
                marginRight : "10px"
            }
            chatAvatar = message.senderImage
        }else{
            chatAvatar = message.senderImage
        }

        return (
            <div className="message-item" style={flexDirection}>
                <div className="message-sender-avatar">
                    <img style={senderAvatarStyle} src={ chatAvatar === "" ? require('../../images/default-user.jpg') : chatAvatar }/>
                </div>
                <div className="message-details" >
                    <div className="message-bubble" style={messageStyle}>
                        <p>{message.text}</p>
                        <ul className="message-files" >
                        {
                            message.attachedFiles.length!==0  &&
                            message.attachedFiles.map( (data,i) => (
                                <li key={i}>
                                    <a style={{ color : auth.uid!==message.sentBy ? "#FFFFF" : "#414042" }} download target="_blank" href={data.downloadUrl} className="message-file"> <img style={{ width: "10px", height : "10px", marginRight : "10px" }} src={require('../../images/icon-task-file.png')}/>{data.fileName}</a>
                                </li>
                            ))
                           
                        }
                        </ul>   
                      
                    </div>
                    <div className="message-date" style={ message.sentBy===auth.uid ? { float : "right"} : { float : "left" }}>
                        <span style={{ marginRight : "30px" }}>{ moment.unix(message.dateAdded.seconds).format("hh:mm , MMM D, YYYY") }</span>
                        { message.sentBy===auth.uid && seen }
                    </div>
                </div>
               
            </div>
           
        )

    }

}

export default Message
