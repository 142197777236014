import {
    RELOAD_NOTIFICATIONS,
    REMOVE_NOTIFICATION
} from './types'

export const watchTranslatorNotification = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    const unsubscribeWatchTranslatorNotificationListener = firestore.collection('notifications')
        .where('recipient','==',firebase.auth().currentUser.uid)
        .where('notificationType','==',6)
        .where('isViewed','==',false)
        .onSnapshot( querySnapshot => {
            querySnapshot.docChanges().forEach( change => {
                if(change.type==='added'){
                    dispatch({
                        type : RELOAD_NOTIFICATIONS,
                        payload : {
                            notification : change.doc.data(),
                            notificationRef : change.doc
                        }
                    })
                }
            })
        })

    return unsubscribeWatchTranslatorNotificationListener;
}

export const removeNotification = (data) => (dispatch, getState, {getFirestore, getFirebase }) => {

    const firestore = getFirestore();

    let notifRef = firestore.collection('notifications').doc(data)
    notifRef.set({
        isViewed : true
    }, { merge : true})

    dispatch({
        type : REMOVE_NOTIFICATION
    })

}