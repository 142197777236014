import {
    GET_ESCALATIONS,
    GET_ESCALATIONS_SUCCESS,
    GET_ESCALATIONS_ERROR,
    GET_ESCALATIONS_PAGINATE_SUCCESS,
    CLEAR_ESCALATIONS_REF,
    LOAD_MORE_ESCALATIONS,
    LOAD_MORE_ESCALATIONS_SUCCESS,
    LOAD_MORE_ESCALATIONS_ERROR
} from '../actions/types'

const initState = {
    isGettingEscalations : false,
    isLoadingMoreEscalations : false,
    escalationLastRef : null,
    escalations : [],
    escalationsRef : [],
}

const escalationReducer = ( state = initState , action ) => {

    switch(action.type){
        case GET_ESCALATIONS:
            return{
                ...state,
                isGettingEscalations : true
            }
        case GET_ESCALATIONS_SUCCESS:
            return{
                ...state,
                isGettingEscalations : false,
                escalations : [...action.payload.escalations],
                escalationsRef : [...action.payload.escalationsRef],
                escalationLastRef : action.payload.escalationsRef.length===0 ? null : action.payload.escalationsRef[action.payload.escalationsRef.length-1],
            }
        case LOAD_MORE_ESCALATIONS:
            return {
                ...state,
                isLoadingMoreEscalations : true,
            }
        case LOAD_MORE_ESCALATIONS_SUCCESS:
            return {
                ...state,
                isLoadingMoreEscalations : false,
                escalations :  [...state.escalations, ...action.payload.escalations],
                escalationsRef : [...state.escalationsRef, ...action.payload.escalationsRef],
                escalationLastRef : action.payload.escalationsRef.length===0 ? state.escalationsRef[state.escalationsRef.length-1] : action.payload.escalationsRef[action.payload.escalationsRef.length-1],
            }
        case LOAD_MORE_ESCALATIONS_ERROR:
            return {
                ...state,
                isLoadingMoreEscalations : false
            }
        case CLEAR_ESCALATIONS_REF:
            return{
                ...state,
                escalationLastRef : null
            }
        default:
            return state;
    }

}

export default escalationReducer;