import React, { Component } from 'react'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export class DateFilter extends Component {

    render(){

        const { dateFrom, dateTo } = this.props;
        
        return(
            <div className="dashboard-date-range-filter">
               
                <div style={{ display : "block"}}>
                    <p>Date ranges</p>
                    <div style={{ display : "flex" }}>
                        <div style={{ flex : "1" }}>
                            <DatePicker className={`date-picker`} style={{ width: "100%" }} showYearDropdown showMonthDropdown name="date_from" id="date_from" onChange={this.props.onDateFromChange} selected={dateFrom} placeholderText="-" />
                        </div>
                        <span style={{ marginLeft: "10px" }}>to</span>
                        <div style={{ flex : "1" }}>
                            <DatePicker minDate={dateFrom} className={`date-picker`} style={{ width: "100%" }} showYearDropdown showMonthDropdown name="date_to" id="date_to" onChange={this.props.onDateToChange} selected={dateTo} placeholderText="-" />
                        </div>
                    </div>
                </div>
                <div style={{ display : "block", marginLeft : "25px" }}>
                    <p>Select period</p>
                    <button onClick={ () => this.props.applyPeriod(30)} className="date-range-buttons">Last 30 days</button>
                    <button onClick={ () => this.props.applyPeriod(60)} className="date-range-buttons">Last 60 days</button>
                    <button onClick={ () => this.props.applyPeriod(90)} className="date-range-buttons">Last 90 days</button>
                    <button onClick={this.props.applyFilter} className="date-range-buttons-apply">APPLY FILTER</button>
                </div>
            </div>
        )

    }

}

export default DateFilter