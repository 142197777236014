import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import moment from 'moment'

const renderStatus = (status) => {
    switch(status) {
        case "inProgress":
            return <span><img style={{ width: "8px", height :  "8px", marginRight : "8px" }} src={require('../../../images/icon-inprogress.png')}/>In Progress</span>
        case "completed":
            return <span><img style={{ width: "8px", height :  "8px", marginRight : "8px" }} src={require('../../../images/icon-completed.png')}/>Completed</span>
        case "forReview":
            return <span><img style={{ width: "8px", height :  "8px", marginRight : "8px" }} src={require('../../../images/icon-inprogress.png')}/>On review</span>
        case "paused":
            return <span><img style={{ width: "8px", height :  "8px", marginRight : "8px" }} src={require('../../../images/icon-archived.png')}/>Paused</span>
        case "stopped":
            return <span><img style={{ width: "8px", height :  "8px", marginRight : "8px" }} src={require('../../../images/icon-archived.png')}/>Stopped</span>
        case "declined":
            return <span><img style={{ width: "8px", height :  "8px", marginRight : "8px" }} src={require('../../../images/icon-archived.png')}/>Declined</span>
        default:
            return <span><img style={{ width: "8px", height :  "8px", marginRight : "8px" }} src={require('../../../images/icon-new.png')}/>New</span>
    }

}

const taskTypeColor = (type) => {
    switch(type){
        case "presentation":
            return { background: "#EA5895" }
        case "essay":
            return { background : "#7E57C2" }
        default:
            return { background : "#29B6F6" }
    }
}

const renderAdditionalServices = (services) => {

    let servicesList = [];

    
    if(services!==undefined){

        services.map( (data) => {

            if(data==="diamond"){
                servicesList.push(<span style={{ fontSize : "12px", marginRight: "10px" }}><img style={{ width: "30px", height: "30px" }} src={require('../../../images/icon-diamond.png')}/></span>)
            }
    
            if(data==="express"){
                servicesList.push(<span style={{ fontSize : "12px", marginRight: "10px" }}><img style={{ width: "30px", height: "30px" }} src={require('../../../images/icon-express.png')}/></span>)
            }
        })
        
    }
    
    return servicesList;
}

export class List extends Component {

    scrollToBottom = () => {
        const { taskList } = this.refs;
        const scrollHeight = taskList.scrollHeight;
        const height = taskList.clientHeight;
        const maxScrollTop = scrollHeight - height;
        ReactDOM.findDOMNode(taskList).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    openTaskDetailsModal = (data) => {
        this.props.openTaskDetailsModal(data)
    }


    render(){

        const { isLoadingMoreTasks, tasks, showLoadMore } = this.props;

        return (
        
            <div className="div-holder">
                <div className="task-table-div">
                    <table>
                        <thead>
                            <tr>
                                <th>ORDER #</th>
                                <th>STATUS</th>
                                <th>FINISH DATE</th>
                                <th>TASK TYPE</th>
                                <th>TASK NAME</th>
                                <th>TASK PRICE</th>
                                <th>SERVICES</th>
                                <th style={{ width: "70px" }}>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            tasks.map( (data, i) => (
                                <tr>
                                    <td  onClick={ () => this.openTaskDetailsModal(data) }>{ data.orderID }</td>
                                    <td  onClick={ () => this.openTaskDetailsModal(data) }>{ renderStatus(data.status) }</td>
                                    <td  onClick={ () => this.openTaskDetailsModal(data) }>{ moment.unix(data.deadline._seconds).format('MMM DD, YYYY') }</td>
                                    <td  onClick={ () => this.openTaskDetailsModal(data) }><span style={taskTypeColor(data.taskType)} className="task-type">{data.taskType}</span></td>
                                    <td  onClick={ () => this.openTaskDetailsModal(data) }>{data.title}</td>
                                    <td  onClick={ () => this.openTaskDetailsModal(data) }><span className="task-price">${data.price}</span></td>
                                    <td  onClick={ () => this.openTaskDetailsModal(data) }>{ renderAdditionalServices(data.additionalServices) }</td>
                                    <td style={{ position : "relative" }}>
                                        <div class="btn-group" style={{ position : "absolute", top : "10px", right : "15px" }}>
                                            <button type="button" class="btn btn-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            . . .
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right custom-dropdown-right">
                                            <button class="dropdown-item custom-dropdown-item" type="button">Decline task</button>
                                            <button class="dropdown-item custom-dropdown-item" type="button">Problem? Get help</button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
                {
                    (tasks.length!==0 && showLoadMore) &&
                    isLoadingMoreTasks ? <div style={{ paddingTop : "15px" }}>
                        <div class="custom-get-more-spinner spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div> : <div className="text-center" style={{ marginTop : "15px" }}>
                        <button onClick={this.props.loadMore} className="load-more-button">LOAD MORE</button>
                    </div>
                }
            </div>
           
        )

    }

}

export default List;
