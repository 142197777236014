import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'
import $ from 'jquery'

import {
    getConversations,
    loadMoreConversations,
    checkConversation,
    updateConversation,
    clearActiveConversation,
    setActiveConversation,
    removeConversation,
    loadConversationMessages,
    createConversation,
    createConversationMessage,
    createConversationMessageAM,
    watchConversationMessages,
    watchConversation,
    uploadMessageFiles,
    searchConversations,
    getAndSetActiveConversation,
    clearConversationAndMessages,
    watchConversations,
    updateConversationLastOpenedDate,
    setChatActiveTab
} from '../../actions/conversationAction'

import { 
    updateTask, 
    setEscalation,
    getTaskDetails
} from '../../actions/taskAction'

import {
    updateUserLastOpenedConversationDate
} from '../../actions/authAction'

import {
    updateTranslatorAvailability
} from '../../actions/userAction'

import ConversationWindow from './Conversation';
import ConversationList from './ConversationList';
import SpinnerModal from "../Spinner/SpinnerModal";
import EscalateTaskModal from '../Tasks/Modals/EscalateTask';
import CompleteTaskModal from '../Tasks/Modals/CompleteTask';


import './chat.css'

export class Index extends Component {

    constructor(){
        super();
        this.state = {
            isProcessing : false,
            selectedConversation : null,
            isPauseTaskModalActive : false,
            isStopTaskModalActive : false,
            isEscalateTaskModalActive : false,
            isCompleteTaskModalActive : false,
            selectedTask : null,
            search : ``,
            isAuthenticated : false,
            activeTab : "customer",
            conversation : null
        }
    }

    componentWillUnmount(){
       const { auth } = this.props;

       if(auth.uid){
        this.props.updateUserLastOpenedConversationDate();
        this.props.clearActiveConversation();
        this.watchConversation && this.watchConversation()
        this.watchConversations && this.watchConversations()
        this.watchConversationMessages && this.watchConversationMessages()
       }
       
    }

    async componentDidMount () {
        
        const { auth, userProfile } = this.props;
        
        if(userProfile.hasOwnProperty('fullName')){

            let tab;
            let recipient;
            let task;
          
            if(auth.uid){

                this.props.updateUserLastOpenedConversationDate();

                tab = this.props.history.location.state=== undefined ? null : this.props.history.location.state.tab;
                recipient = this.props.history.location.state === undefined ? null : this.props.history.location.state.recipient;
                task = this.props.history.location.state === undefined ? null : this.props.history.location.state.task;
              
                if(recipient!==null){
              
                    let result = await this.props.checkConversation({

                        userType : tab,
                        taskUid : task!==null ? task.uid : "",
                        recipient : recipient
                    })

                    if(result.code===404){
    
                        let memberData = []
                        let memberTypes = {}
                        let members = []

                        memberData = {
                            [auth.uid] : {
                                fullName : userProfile.fullName,
                                imageUrl : userProfile.imageUrl !== undefined ? userProfile.imageUrl : "",
                                userType : userProfile.type
                            },
                            [result[tab].uid] : {
                                fullName : result[tab].fullName,
                                imageUrl : result[tab].imageUrl !== undefined ? result[tab].imageUrl : "",
                                userType : result[tab].type
                            }
                        }
        
                        members = [
                            auth.uid,
                            result[tab].uid
                        ]
            
                        memberTypes = {
                            [tab] : true,
                            [userProfile.type] : true
                        }
            
                        await this.props.createConversation({
                            members : members,
                            memberData : memberData,
                            memberTypes : memberTypes,
                            lastMessageSentText : "",
                            lastMessageSentBy : "",
                            lastMessageSentByUserType : "",
                            task : task!== null ? task.uid : "",
                            taskTitle : task!== null ? task.title : "",
                            initiatedBy : auth.uid
                        })
                    }
                }
            }

            if(tab===null){
                if(userProfile.type==="accountManager" || userProfile.type==="operator"){
                    tab = "customer"
                }

                if(userProfile.type==="superuser"){
                    tab = "accountManager"
                }
            }
            
            this.props.setChatActiveTab(tab)
            this.setState({
                isAuthenticated : true,
                activeTab : tab,
                conversation : this.props.activeConversation
            }, async () => {
             
                await this.loadConversations(tab)
                if(this.state.conversation!==null)
                    this.setActiveConversation(this.state.conversation)
            })
        }
        
        this.watchConversations = this.props.watchConversations();
    }

    async componentDidUpdate(prevProps, prevState){

        if(prevProps.userProfile!==this.props.userProfile){

            let tab;
            let recipient;
            let task;
            const { userProfile, auth } = this.props;

            if(auth.uid){
                tab = this.props.history.location.state=== undefined ? null : this.props.history.location.state.tab;
                recipient = this.props.history.location.state === undefined ? null : this.props.history.location.state.recipient;
                task = this.props.history.location.state === undefined ? null : this.props.history.location.state.task;
                // if(recipient!==null){
              
                //     let result = await this.props.checkConversation({
                //         userType : tab,
                //         taskUid : task.uid,
                //         recipient : recipient
                //     })

                //     if(result.code===404){
    
                //         let memberData = []
                //         let memberTypes = {}
                //         let members = []
        
                //         memberData = {
                //             [auth.uid] : {
                //                 fullName : userProfile.fullName,
                //                 imageUrl : userProfile.imageUrl !== undefined ? userProfile.imageUrl : ""
                //             },
                //             [result[tab].uid] : {
                //                 fullName : result[tab].fullName,
                //                 imageUrl : result[tab].imageUrl !== undefined ? result[tab].imageUrl : ""
                //             }
                //         }
        
                //         members = [
                //             auth.uid,
                //             result[tab].uid
                //         ]
            
                //         memberTypes = {
                //             [tab] : true,
                //             [userProfile.type] : true
                //         }
            
                //         await this.props.createConversation({
                //             members : members,
                //             memberData : memberData,
                //             memberTypes : memberTypes,
                //             lastMessageSentText : "",
                //             lastMessageSentBy : "",
                //             lastMessageSentByUserType : "",
                //             task : task.uid,
                //             initiatedBy : auth.uid,
                //             lastOpenedDates : []
                //         })
                //     }
                // }
            }

            if(tab===null){
                
                if(userProfile.type==="accountManager" || userProfile.type==="operator"){
                    tab = "customer"
                }

                if(userProfile.type==="superuser"){
                    tab = "accountManager"
                }
            }
        
            this.setState({
                isAuthenticated : true,
                activeTab : tab,
                conversationUid : this.props.activeConversation !== null ? this.props.activeConversation.uid : ""
            }, () => {
                this.loadConversations(tab)
            })
        }

    }

    loadConversations =  (userType) => {
        
        this.props.clearConversationAndMessages();
        this.setState({
            activeTab : userType
        }, async () => {
            await this.props.getConversations({
                recipient : userType
            });

            const { conversationUid } = this.state;

        })   
    }

    loadMoreConversations = (userType) => {
        this.props.loadMoreConversations({
            recipient : this.state.activeTab
        });
    }

    setActiveConversation = (conversation) => {
        this.setState({
            selectedTask : { uid : conversation.task }
        }, async () =>{
            
            let result = await this.props.setActiveConversation(conversation);

            await this.props.loadConversationMessages(result.data);
            this.watchConversation && this.watchConversation()
            this.watchConversationMessages && this.watchConversationMessages()
            this.watchConversation = this.props.watchConversation(conversation.uid)
            this.watchConversationMessages = this.props.watchConversationMessages(conversation.uid)
            
        })
    }

    getAndSetActiveConversation = async (conversationUid) => {

        let result = await this.props.getAndSetActiveConversation(conversationUid);

        this.setState({
            selectedTask : { uid: result.data.task}
        }, async () => {
            await this.props.loadConversationMessages(result.data);
            this.watchConversation && this.watchConversation()
            this.watchConversationMessages && this.watchConversationMessages()
            this.watchConversation = this.props.watchConversation(conversationUid)
            this.watchConversationMessages = this.props.watchConversationMessages(conversationUid)
            
        })

    }

    loadConversationMessages = async () => {
        const { activeConversation } = this.props;

        await this.props.loadConversationMessages(activeConversation);
        this.watchConversationMessages && this.watchConversationMessages()
        this.watchConversationMessages = this.props.watchConversationMessages(activeConversation.uid)
    }

    openPauseTaskModal = (task) => {
        this.setState({
            isPauseTaskModalActive : true,
            selectedTask : {...task}
        })
    }

    closePauseTaskModal = () => {
        this.setState({
            isPauseTaskModalActive : false,
            selectedTask : null
        })
    }

    pauseTask = async (pauseData) => {
        const { selectedTask } = this.state;
        const { activeConversation } = this.props;

        let data = {
            uid : selectedTask.uid,
            isArchived : true,
            pauseReason : pauseData.pauseReason,
            dateToContinue : pauseData.dateToContinue,
            status : "paused"
        }

        this.setState( {
            isProcessing : true
        }, async () => {
            let result = await this.props.updateTask(data);

            this.props.removeConversation(activeConversation)
            this.props.clearActiveConversation()
            
            this.setState({
                isPauseTaskModalActive : false,
                isProcessing : false
            })

        })  
    }

    openEscalateTaskModal = (task) => {
        this.setState({
            isEscalateTaskModalActive : true,
            selectedTask : {...task}
        })
    }

    closeEscalateTaskModal = () => {
        this.setState({
            isEscalateTaskModalActive : false,
            selectedTask : null
        })
    }

    escalateTask = async (escalateData) => {
        const { selectedTask } = this.state;

        let data = {
            taskUid : selectedTask.uid,
            escalationLetter : escalateData.escalationLetter,
            escalationReason : escalateData.escalationReason,
            operator : ""
        }

        this.setState( {
            isProcessing : true
        }, async () => {
            let result = await this.props.setEscalation(data);

            this.setState({
                isEscalateTaskModalActive : false,
                isProcessing : false
            })
        })
    }

    openStopTaskModal = (task) => {
        this.setState({
            isStopTaskModalActive : true,
            selectedTask : {...task}
        })
    }

    closeStopTaskModal = () => {
        this.setState({
            isStopTaskModalActive : false,
            selectedTask : null
        })
    }

    stopTask = async (stopData) => {
        const { selectedTask } = this.state;
        const { activeConversation } = this.props;

        let data = {
            uid : selectedTask.uid,
            isArchived : true,
            stopReason : stopData.stopReason,
            status : "stopped"
        }

        this.setState( {
            isProcessing : true
        }, async () => {
            let result = await this.props.updateTask(data);

            this.props.removeConversation(activeConversation)
            this.props.clearActiveConversation()

            this.setState({
                isStopTaskModalActive : false,
                isProcessing : false
            })
        })
    }

    openCompleteTaskModal = (task) => {
        this.setState({
            isCompleteTaskModalActive : true,
            selectedTask : {...task}
        })
    }

    closeCompleteTaskModal = () => {
        this.setState({
            isCompleteTaskModalActive : false,
            selectedTask : null
        })
    }

    completeTask = async () => {
        const { selectedTask } = this.state;
        
        let data = {
            uid : selectedTask.uid,
            status : "completed"
        }

        this.setState( {
            isProcessing : true
        }, async () => {
            await this.props.updateTask(data);
            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isCompleteTaskModalActive : false
                }, () => {
                    this.props.updateTranslatorAvailability({
                        translator : selectedTask.assignedTranslator,
                        taskUid : selectedTask.uid
                    })
                })
            }, 500)
        })
    }

    sendMessage = async (data) => {
        
        const { activeConversation, auth, userProfile } = this.props;
        const { selectedTask } = this.state;

        if(data.message || data.files.length!==0) {

            let recipientType = Object.keys(activeConversation.memberTypes).find( data => data!== userProfile.type)
            let recipientUid = activeConversation.members.find( data => data!== userProfile.uid);
            let recipientName = activeConversation.memberData[recipientUid].fullName;
            let recipientImage = activeConversation.memberData[recipientUid].imageUrl;

            if(data.files.length!==0){

                let result = await this.props.uploadMessageFiles(data.files, activeConversation.uid);

                let messageData = {
                    conversation : activeConversation.uid,
                    text : data.message,
                    attachedFiles : [...result],
                    sentBy : auth.uid,
                    senderType : userProfile.type,
                    senderName : userProfile.fullName,
                    senderImage : userProfile.imageUrl,
                    recipient : recipientUid,
                    recipientType : recipientType,
                    recipientName : recipientName,
                    recipientImage : recipientImage,
                    members : activeConversation.members
                }
                
                this.props.createConversationMessage(messageData)
            }else{
                let messageData = {
                    conversation : activeConversation.uid,
                    text : data.message,
                    attachedFiles : [],
                    sentBy : auth.uid,
                    senderType : userProfile.type,
                    senderName : userProfile.fullName,
                    senderImage : userProfile.imageUrl,
                    recipient : recipientUid,
                    recipientType : recipientType,
                    recipientName : recipientName,
                    recipientImage : recipientImage,
                    members : activeConversation.members
                }
                
                this.props.createConversationMessage(messageData)
            }
        }
    }

    searchConversations = (data) => {

        this.setState({
            search : data
        }, () => {

            const { search, activeTab } = this.state;

            this.props.searchConversations({
                search : search,
                activeTab : activeTab,
                userType : this.props.userProfile.type
            })
           
        })   
    }

    setChatActiveTab = (tab) => {
        this.props.setChatActiveTab(tab)
    }

    render(){

        const { isLoadingMoreConversations, isSearchingContacts, isSearchingMessages, searchedMessages, searchedContacts,
            isGettingMessages, isGettingConversations, conversations, 
            activeConversation, userProfile, auth, isLoggingOut , lastOpenedDate} = this.props;

        const {
            isAuthenticated,
            isProcessing,
            isPauseTaskModalActive, 
            isStopTaskModalActive, 
            isCompleteTaskModalActive,
            isEscalateTaskModalActive, 
            selectedTask,
            activeTab
        } = this.state;

        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }
        
        if(!auth.uid){
            return <Redirect to="/"/>
        }

        return (
            <div>
               <div className="chat-main">
                    <ConversationList 
                        setChatActiveTab={this.setChatActiveTab}
                        loadMoreConversations={this.loadMoreConversations}
                        isLoadingMoreConversations={isLoadingMoreConversations}
                        activeTab={activeTab}
                        searchConversations={this.searchConversations} 
                        setActiveConversation={this.setActiveConversation} 
                        getAndSetActiveConversation={this.getAndSetActiveConversation}
                        userProfile={userProfile} 
                        loadConversations={this.loadConversations} 
                        activeConversation={activeConversation} 
                        conversations={conversations} 
                        isGettingConversations={isGettingConversations}
                        isSearchingMessages={isSearchingMessages}
                        searchedContacts={searchedContacts}
                        isSearchingContacts={isSearchingContacts}
                        searchedMessages={searchedMessages}
                        />
                    <ConversationWindow 
                        loadConversationMessages={this.loadConversationMessages}
                        lastOpenedDate={lastOpenedDate}
                        isGettingMessages={isGettingMessages}
                        userProfile={userProfile} 
                        activeConversation={activeConversation}
                        openPauseTaskModal={this.openPauseTaskModal}
                        openStopTaskModal={this.openStopTaskModal}
                        openEscalateTaskModal={this.openEscalateTaskModal}
                        openCompleteTaskModal={this.openCompleteTaskModal}
                        sendMessage={this.sendMessage}
                        updateConversationLastOpenedDate={this.props.updateConversationLastOpenedDate}
                        />
                </div>
                {
                    isProcessing &&
                    <SpinnerModal 
                        isOpened={isProcessing}
                    />
                }
                {
                    isLoggingOut && 
                    <SpinnerModal
                        isOpened={isLoggingOut}
                    />
                }
                {
                    isEscalateTaskModalActive && <EscalateTaskModal userProfile={userProfile} escalateTask={this.escalateTask} closeEscalateTaskModal={this.closeEscalateTaskModal} isOpened={isEscalateTaskModalActive}/>
                }
                {
                    isCompleteTaskModalActive && <CompleteTaskModal completeTask={this.completeTask} closeCompleteTaskModal={this.closeCompleteTaskModal} task={selectedTask} isOpened={isCompleteTaskModalActive}/>
                }
            </div>
        )
    }

}


const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        activeConversation : state.conversation.activeConversation,
        lastOpenedDate : state.conversation.lastOpenedDate,
        isGettingConversations : state.conversation.isGettingConversations,
        isLoadingMoreConversations : state.conversation.isLoadingMoreConversations,
        conversations : state.conversation.conversations,
        groupedMessages : state.conversation.groupedMessages,
        isSearchingContacts : state.conversation.isSearchingContacts,
        searchedContacts : state.conversation.searchedContacts,
        isSearchingMessages : state.conversation.isSearchingMessages,
        searchedMessages : state.conversation.searchedMessages,
        isLoggingOut : state.auth.isLoggingOut
    }
}


export default compose(
    withRouter,
    connect( mapStateToProps, {
        checkConversation,
        updateConversation,
        loadMoreConversations,
        createConversation,
        getConversations,
        clearActiveConversation,
        setActiveConversation,
        updateTask,
        setEscalation,
        removeConversation,
        loadConversationMessages,
        createConversationMessage,
        createConversationMessageAM,
        watchConversationMessages,
        watchConversation,
        uploadMessageFiles,
        searchConversations,
        getTaskDetails,
        getAndSetActiveConversation,
        clearConversationAndMessages,
        updateUserLastOpenedConversationDate,
        watchConversations,
        updateConversationLastOpenedDate,
        setChatActiveTab,
        updateTranslatorAvailability
    })
)(Index)