import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'
import { declineTask, declineAssignedTask, getNewTasks, loadMoreNewTasks, filterTasks, watchNewTasks, clearLastRef, updateTask, hireTranslator } from '../../../actions/taskAction'
import { updateUserLastOpenedNewTasksDate, updateCurrentUser, setUserPresence, updateAccountManagerTasksAssigned } from '../../../actions/authAction'
import { setEscalation } from '../../../actions/escalationAction';
import { updateUser } from '../../../actions/userAction';
import NewTaskList from './List';
import SpinnerModal from '../../Spinner/SpinnerModal';
import HireTranslatorModal from '../Modals/HireTranslator';
import MessagePromptModal from '../Modals/MessagePrompt';
import ConfirmationModal from '../Modals/Confirmation';
import TranslatorInviteModal from '../Modals/TranslatorInvite';
import DeclineTaskModal from '../Modals/DeclineTask';
import EscalateTaskModal from '../Modals/EscalateTask';
import '../task.css'
import $ from 'jquery'

export class Index extends Component {

    constructor(){
        super();
        this.state = {
            filterType : "all",
            loadMore : false,
            isEmpty : false,
            isProcessing : false,
            isHireTranslatorModalActive : false,
            isDeclineTaskModalActive : false,
            isEscalateTaskModalActive : false,
            isTranslatorInviteModalActive : false,
            isMessagePromptModalActive : false,
            isConfirmationModalActive : false,
            confirmationHeader : "",
            messagePromptText : "",
            selectedTask : {},
            selectedTranslator : null,
            isAuthenticated : false
        }
    }
    
    async componentDidUpdate(prevProps){

        if(prevProps.typeFilter!==this.props.typeFilter){
            this.loadNewTasks();
        }

        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true
            })
        }

    }

    loadNewTasks = () => {
        this.props.getNewTasks();
    }

    loadMoreNewTasks = () => {
        this.props.loadMoreNewTasks();
    }

    componentDidMount(){
        const { auth, userProfile } = this.props;

        if(auth.uid){
            this.props.setUserPresence({
                uid: auth.uid
            });
            this.loadNewTasks();
           
            this.props.updateUserLastOpenedNewTasksDate();
            this.unsubscribeWatchTaskListener = this.props.watchNewTasks();
        }

        if(userProfile.hasOwnProperty('fullName')){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    componentWillUnmount(){
        //this.unsubscribeWatchTaskListener && this.unsubscribeWatchTaskListener();
    }

    filterByType = (type) => {
        this.setState({
            filterType : type
        }, async () => {
            const { filterType } = this.state;
            await this.props.filterTasks(filterType);
        })
    }

    openHireTranslatorModal = (task) => {
        this.setState({
            isHireTranslatorModalActive : true,
            selectedTask : {...task}
        })
    }

    closeHireTranslatorModal = () => {
        this.setState({
            isHireTranslatorModalActive : false,
            selectedTask : {}
        })
    }

    openDeclineTaskModal = (task) => {
        this.setState({
            isDeclineTaskModalActive : true,
            selectedTask : {...task}
        })
    }

    closeDeclineTaskModal = () => {
        this.setState({
            isDeclineTaskModalActive : false,
            selectedTask : {}
        })
    }

    declineTask = async (declineData) => {
        const { selectedTask } = this.state;
        const { auth } = this.props;

        let data = {
            uid : selectedTask.uid,
            isArchived : true,
            status : "declined",
            deleteAssignedTranslatorField : true,
            declineReason : declineData.declineReason
        }

        this.setState( {
            isProcessing : true
        }, async () => {
            await this.props.declineTask(data);
            await this.props.clearLastRef();
            this.props.getNewTasks();

            this.setState({
                isDeclineTaskModalActive : false,
                isProcessing : false
            })
         
        })
    }

    openEscalateTaskModal = (task) => {
        this.setState({
            isEscalateTaskModalActive : true,
            selectedTask : {...task}
        })
    }

    closeEscalateTaskModal = () => {
        this.setState({
            isEscalateTaskModalActive : false,
            selectedTask : {}
        })
    }

    escalateTask = async (escalateData) => {
        const { selectedTask } = this.state;
        const { auth } = this.props;

        let data = {
            taskUid : selectedTask.uid,
            escalationLetter : escalateData.escalationLetter,
            escalationReason : escalateData.escalationReason,
            operator : "OPERATOR"
        }

        this.setState( {
            isProcessing : true
        }, async () => {
            await this.props.setEscalation(data);
            await this.props.clearLastRef();
            this.props.getNewTasks();

            this.setState({
                isEscalateTaskModalActive : false,
                isProcessing : false
            })
        })
    }

        
    openConfirmationModal = (data) => {
        this.setState({
            isConfirmationModalActive : true,
            confirmationHeader : `Invite ${data.name} for ${data.task} ?`,
            selectedTranslator : data.translator
        })
    }
    

    closeConfirmationModal = () => {
        this.setState({
            isConfirmationModalActive : false,
            confirmationHeader : "",
            selectedTask : null,
            selectedTranslator : null
        })
    }

    confirm = () => {
        const { selectedTranslator, selectedTask } = this.state;

        this.hireTranslator({
            translator : selectedTranslator,
            task : selectedTask
        })
        
    }

    hireTranslator = async (data) => {

        const { task, translator } = data;
        const { auth } = this.props;

        this.setState({
            isProcessing : true
        }, async () => {
            
            let result = await this.props.hireTranslator({
                uid : task.uid,
                assignedTranslator : translator.uid,
                accountManager : auth.uid
            })

            if(result.code!==500){
                if(result.code===1062){
                    this.setState({
                        isProcessing : false,
                        isConfirmationModalActive : false,
                        isMessagePromptModalActive : true,
                        isHireTranslatorModalActive : false,
                        messagePromptText : result.message,
                        
                    })
                }else{
                    const translatorName = translator.fullName.split(",");
                    let name =  translatorName[0] + " " + translatorName[1]

                    this.props.updateAccountManagerTasksAssigned();

                    this.setState({
                        isProcessing : false,
                        isConfirmationModalActive : false,
                        isHireTranslatorModalActive : false,
                        selectedTranslatorName : name,
                        isTranslatorInviteModalActive : true
                    })
                }

                $('.modal-backdrop')[0]!== undefined && $('.modal-backdrop')[0].remove()
            }

            
        })

    }

    reloadList = () => {

        this.setState({
            isMessagePromptModalActive : false,
            messagePromptText : "",
        }, () => {
            this.loadNewTasks();
        })

    }

    closeTranslatorInviteModal = () => {
        this.setState({
            isTranslatorInviteModalActive : false
        }, () => {
            this.props.clearLastRef();
            this.loadNewTasks();
        })
    }

    unAssignTask = (task, translator) => {

        this.setState({
            isProcessing : true
        }, async () => {

            await this.props.declineAssignedTask({
                task: task,
                translator : translator
            })

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isTranslatorInviteModalActive : false
                }, async () =>{
                    await this.props.clearLastRef();
                    this.loadNewTasks();
                })
            }, 1500)

        })

    }

    
    openChat = (userType, recipient, task) => {
        this.props.history.push({
            pathname : "/chat",
            state : {
                tab : userType,
                task : task,
                recipient : recipient
            }
        })

    }

    render() {

        const { isLoggingOut, isLoadingMoreTasks,  auth, userProfile, typeFilter, isGettingTasks, tasks } = this.props;
        const { selectedTranslatorName, confirmationHeader, isConfirmationModalActive, isMessagePromptModalActive, messagePromptText, isAuthenticated, isProcessing, isEscalateTaskModalActive, isHireTranslatorModalActive, isDeclineTaskModalActive, isTranslatorInviteModalActive, selectedTask, selectedTranslator } = this.state;

        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }
        
        if(!auth.uid && !auth.emailVerified){
            return <Redirect to="/" />
        }else{
            if(isAuthenticated){
                if(userProfile.type!=="accountManager"){
                    return "NOT ALLOWED";
                }
            }
        }

        return (
            <div className="div-holder">
                {
                    isAuthenticated && 
                    <div className="div-holder">
                        <div style={{ display : "flex"}}>
                            <div style={{ flex : "1", textAlign : "left" }}>
                                <p className="module-header">Tasks List</p>
                            </div>
                            <div style={{ flex : "1", textAlign : "right" }}>
                                <ul className="type-filters">
                                    <li style={{ paddingTop: "10px",  marginRight: "15px", width : "100px" }}><span style={{ fontSize : "12px", color : "#414042"}}>Filter by type:</span></li>
                                    <li onClick={ () => this.filterByType('all')} style={{ marginRight: "15px" }}><span style={ typeFilter==="all" ? { background : '#7AB7B6', color : "white"} : {} } className="type-filter">All</span></li>
                                    <li onClick={ () => this.filterByType('presentation')} style={{ marginRight: "15px" }}><span style={ typeFilter==="presentation" ? { background : '#7AB7B6', color : "white"} : {} } className="type-filter">Presentation</span></li>
                                    <li onClick={ () => this.filterByType('essay')} style={{ marginRight: "15px" }}><span style={ typeFilter==="essay" ? { background : '#7AB7B6', color : "white"} : {} } className="type-filter">Essay</span></li>
                                    <li onClick={ () => this.filterByType('dissertation')} style={{ marginRight: "15px" }}><span style={ typeFilter==="dissertation" ? { background : '#7AB7B6', color : "white"} : {} } className="type-filter">Dissertation</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="">
                            {
                                isGettingTasks ?
                                <div className="d-flex justify-content-center">
                                    <div style={{ height: "5rem", width: "5rem" }} className="custom-spinner-border spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                tasks.length!==0 ?  
                                <NewTaskList  
                                    isLoadingMoreTasks={isLoadingMoreTasks}
                                    openChat={this.openChat}
                                    loadMoreNewTasks={this.loadMoreNewTasks} 
                                    openDeclineTaskModal={this.openDeclineTaskModal}
                                    openEscalateTaskModal={this.openEscalateTaskModal}
                                    openHireTranslatorModal={this.openHireTranslatorModal}
                                    tasks={tasks}
                                    /> : 
                                ""
                            }
                        </div>
                        {
                            isProcessing && 
                            <SpinnerModal
                                isOpened={isProcessing}
                            />
                        }
                        {
                            isLoggingOut &&
                            <SpinnerModal
                                isOpened={isLoggingOut}
                            />
                        }
                        {
                            isHireTranslatorModalActive &&
                            <HireTranslatorModal 
                                hireTranslator={this.hireTranslator}
                                task={selectedTask}
                                closeHireTranslatorModal={this.closeHireTranslatorModal}
                                openConfirmationModal={this.openConfirmationModal}
                                isOpened={isHireTranslatorModalActive}
                            />
                        }
                        {
                            isMessagePromptModalActive &&
                            <MessagePromptModal
                                header={messagePromptText}
                                btnOk={"OK"}
                                onOk={this.reloadList}
                                isOpened={isMessagePromptModalActive}
                            />
                        }
                        {
                            isConfirmationModalActive &&
                            <ConfirmationModal
                                isOpened={isConfirmationModalActive}
                                closeConfirmationModal={this.closeConfirmationModal}
                                confirm={this.confirm}
                                header={confirmationHeader}
                                btnYes="YES"
                                btnCancel="NO"
                            />
                        }
                        {
                            isEscalateTaskModalActive && <EscalateTaskModal userProfile={userProfile} escalateTask={this.escalateTask} closeEscalateTaskModal={this.closeEscalateTaskModal} isOpened={isEscalateTaskModalActive}/>
                        }
                        {
                            isDeclineTaskModalActive && <DeclineTaskModal declineTask={this.declineTask} closeDeclineTaskModal={this.closeDeclineTaskModal} isOpened={isDeclineTaskModalActive}/>
                        }
                        {
                            isTranslatorInviteModalActive && <TranslatorInviteModal openChat={this.openChat} unAssignTask={this.unAssignTask} task={selectedTask} translator={selectedTranslator} translatorName={selectedTranslatorName} closeTranslatorInviteModal={this.closeTranslatorInviteModal} isOpened={isTranslatorInviteModalActive} />
                        }
                    </div>
                }
            </div>
        )   
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        tasks : state.task.newTasks,
        isGettingTasks : state.task.isGettingTasks,
        isLoadingMoreTasks : state.task.isLoadingMoreTasks,
        isLoggingOut : state.auth.isLoggingOut,
        typeFilter : state.task.typeFilter,
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { 
        setEscalation, 
        updateTask, 
        hireTranslator,
        clearLastRef, 
        getNewTasks, 
        loadMoreNewTasks,
        filterTasks, 
        watchNewTasks, 
        updateUserLastOpenedNewTasksDate,
        updateUser,
        updateCurrentUser,
        declineAssignedTask,
        setUserPresence,
        updateAccountManagerTasksAssigned,
        declineTask
    })
)(Index)

