import React, { Component } from 'react'
import moment from 'moment';
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import {
    addModal,
    popModal
} from '../../../../actions/appAction';

const TASK_TYPES = [
    "Accounting & Consulting",
    "Admin Support",
    "Customer Service",
    "Data Science and Analytics",
    "Engineering and Architecture",
    "Design & Creative",
    "IT & Engineering",
    "Legal",
    "Sales & Marketing",
    "Translation",
    "Web, Mobile, & Software Development",
    "Writing",
    "Medical",
]

const YEARS_OF_EXPERIENCE = [
    "Less than 1 year",
    "1-3 years",
    "3-5 years",
    "More than 5 years",
]

export class AcceptCandidate extends Component{

    constructor(){
        super();
        this.state = {
            selectedCandidate : null,
            modalZ : 1050
        }

    }

    async componentDidMount(){

        const { isOpened, candidate } = this.props;
        
        if(isOpened){
            
            $('#acceptCandidateModal').modal({backdrop:'static', keyboard:false});
            $('#acceptCandidateModal').modal('show');
            this.props.addModal('right-modal')
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("acd-bd");

        }
    }

     componentWillUnmount(){
        $('#acceptCandidateModal').modal('hide');
        $('body').removeClass('modal-open')
        this.props.popModal()
    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="right-modal");
            if(modalInstance!==undefined){
                $('.acd-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }

        }
    }

    render() {

        const { candidate } = this.props;
        const { modalZ } = this.state;

        return(
            <div style={{ zIndex : `${modalZ}` }} className="modal right-modal fade" id="acceptCandidateModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header" style={{ position: "relative", paddingBottom : "50px" }}>
                            <div class="btn-group" style={{ position : "absolute", top : "20px", right : "15px" }}>
                                <button type="button" class="btn btn-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                . . .
                                </button>
                                <div class="dropdown-menu dropdown-menu-right custom-dropdown-right">
                                </div>
                            </div>
                            <p onClick={this.props.closeAcceptCandidateModal}><img style={{ cursor: "pointer", width: "10px", height : "15px", marginRight: "15px"}} src={require('../../../../images/back.png')}/>Details</p>
                        </div>
                        <div  className="modal-body" style={{ overflowY : "auto" }}>
                            <div className="candidate-details" style={{ position :"relative" }}>
                                <div style={{ borderTop : "1px solid #DFDFE0", marginLeft : "30px", marginRight : "30px", paddingTop : "30px", textAlign : "left" }}>
                                    <div className="form-group">
                                        <label className="custom-label block">Select specialty</label>
                                        <select placeholder="Select specialty" class="custom-select form-control" id="country" name="country">
                                            <option value="">Select specialty</option>
                                            {
                                                TASK_TYPES.map( (data, i) => (
                                                    <option selected={candidate.specialty === data} key={i} value={data}>{data}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="custom-label block">Select years of experience</label>
                                        <select placeholder="Select years of experience" class="custom-select form-control" id="country" name="country">
                                            <option value="">Select years of experience</option>
                                            {
                                                YEARS_OF_EXPERIENCE.map( (data, i) => (
                                                    <option selected={candidate.yearsOfExperience === data} key={i} value={data}>{data}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <button className="accept-candidate-btn" onClick={ () => this.props.acceptCandidate(candidate)}>ACCEPT CANDIDATE</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(AcceptCandidate)