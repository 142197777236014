import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import moment from 'moment'

const renderName = (fullName) => {

    let firstName = fullName.split(",")[1]!==undefined ? fullName.split(",")[1].trim() : "";
    let lastName = fullName.split(",")[0];

    return `${firstName} ${lastName}`

}

export class List extends Component {

    scrollToBottom = () => {
        const { taskList } = this.refs;
        const scrollHeight = taskList.scrollHeight;
        const height = taskList.clientHeight;
        const maxScrollTop = scrollHeight - height;
        ReactDOM.findDOMNode(taskList).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    render(){

        const { isGettingMoreCustomers, customers, showLoadMore } = this.props;

        return (
        
            <div className="div-holder">
                <div className="customer-table-div">
                    <table>
                        <thead>
                            <tr>
                                <th>CUSTOMER</th>
                                <th style={{ textAlign : "center" }}>HOW MANY TASKS</th>
                                <th>ON SERVICE SINCE</th>
                                <th style={{ textAlign : "center" }}>HOW MUCH SPENT</th>
                                <th style={{ width: "70px" }}>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            customers.map( (data, i) => (
                                <tr>
                                   <td><img style={{ width: "36px", height : "36px", borderRadius : "100%", marginRight : "15px" }} src={ data.imageUrl !== "" ? data.imageUrl : require('../../../images/default-user.jpg')}/>{ renderName(data.fullName) }</td>
                                    <td style={{ textAlign : "center" }}>{data.numberOfTasksCreated}</td>
                                    <td>{ data.hasOwnProperty('dateRegistered') ? moment.unix(data.dateRegistered._seconds).format('MMMM DD, YYYY') : ""  }</td>
                                    <td style={{ textAlign : "center" }}>${data.hasOwnProperty('amountSpent') ? data.amountSpent.toFixed(2) : 0}</td>
                                    <td style={{ position : "relative" }}>
                                        <div class="btn-group" style={{ position : "absolute", top : "10px", right : "15px" }}>
                                            <button type="button" class="btn btn-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            . . .
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right custom-dropdown-right">
                                            { data.status!=="disabled" && <button onClick={ () => this.props.openBlockCustomerModal(data) } class="dropdown-item custom-dropdown-item" type="button">Block Customer</button> }
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
                {
                    (customers.length!==0) &&
                    isGettingMoreCustomers ? <div style={{ paddingTop : "15px" }}>
                        <div class="custom-get-more-spinner spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div> : <div className="text-center" style={{ marginTop : "15px" }}>
                        <button onClick={this.props.loadMore} className="load-more-button">LOAD MORE</button>
                    </div>
                }
            </div>
           
        )

    }

}

export default List;
