import {
    GET_ADMIN_CONFIG,
    GET_ADMIN_CONFIG_SUCCESS,
    GET_ADMIN_CONFIG_ERROR,
} from './types';


export const getAdminConfig = () => async (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : GET_ADMIN_CONFIG
    })  

    const firestore = getFirestore();

    let adminConfig = {};

    await firestore.collection('adminConfig').get()
        .then( querySnapshots => {

            querySnapshots.forEach( doc => {
                adminConfig = {
                    ...adminConfig,
                    [doc.id] : {
                        ...doc.data()
                    }
                }
            })

            dispatch({
                type : GET_ADMIN_CONFIG_SUCCESS,
                payload : {
                    data : {
                        ...adminConfig
                    }
                }
            })

        })

    



}

export const updateAdminConfig = (uid, data) => async (dispatch, getState, {getFirestore, getFirebase }) => {

    const firestore = getFirestore();
    
    let adminConfigRef = firestore.collection('adminConfig').doc(uid)

    adminConfigRef.set({
        ...data
    }, { merge : true })
    .then( () => {
    })
    .catch( error => {
    })


}