import React, { Component } from 'react'
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'
import axios from 'axios'

import {
    testFiles
} from '../../actions/appAction'

import {
    testCallable
} from '../../actions/authAction'

export class Index extends Component {

    constructor(){
        super();
        this.state = {
            files : null
        }
    }

    onSubmit = () => {
        this.props.testFiles(this.state.files)
    }

    handleFiles = (e) => {
        this.setState({
            files : e.target.files
        })
    }

    doCallableFx = async () => {

        // axios.post('https://us-central1-tarjemle-445ea.cloudfunctions.net/testCallable', { data : "TEST" }, { "Headers" : { "Content-type" : "application/json" }})
        //     .then( result => {
        //         console.log(result)
        //     })
        //     .catch( error => {
        //         console.log(error)
                
        //     })

        let result = await this.props.testCallable();
    }

    doHttpFx = () => {
        axios.get('https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/h-testcallable')
            .then( result => {
            })
            .catch( error => {
                console.log(error)
                
            })
    }
    

    render (){

        return <div>
            <input onChange={this.handleFiles} type="file"/>
            <button onClick={this.onSubmit}>TEST</button>
            <button onClick={this.doCallableFx}>TEST CALLABLE</button>
            <button onClick={this.doHttpFx}>TEST HTTP</button>
        </div>


    }

}

export default compose(
    withRouter,
    connect(null, { 
        testFiles,
        testCallable
    })
)(Index)