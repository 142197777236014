import {
    ADD_MODAL,
    POP_MODAL,
    LOGOUT_SUCCESS
} from '../actions/types'

const initState = {
    currentZindex : 1050,
    modalInstances : []
}

const appReducer = (state = initState, action) => {

    let newZindex;

    switch(action.type){
        case ADD_MODAL: 
            newZindex = state.currentZindex + 20;
            state.modalInstances.push({
                modal_class : action.payload.modalClass,
                z_index : newZindex
            });
            return{
                ...state,
                currentZindex : newZindex,
                modalInstances: [...state.modalInstances]
            }
        case POP_MODAL:
            newZindex = state.currentZindex - 20;
            state.modalInstances.pop();
            return{
                ...state,
                currentZindex : newZindex,
                modalInstances : [...state.modalInstances]
            }
        case LOGOUT_SUCCESS:
            return initState;
        default:
            return state;
    }

}

export default appReducer