import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, Redirect , withRouter } from 'react-router-dom';
import { signInUser, signOutUser, setUserPresence } from '../../actions/authAction';
import { getAllUser } from '../../actions/userAction';
import MessageModal from './Modals/MessageModal';
import SpinnerModal from '../Spinner/SpinnerModal'
import './login.css';

export class Login extends Component {

    state = {
        email : "",
        password : "",
        isLoggingIn : false,
        errorMessage : "",
        isLoginModalActive : false,
        loginModalHeader : "",
        loginModalSubHeader : "",
        loginModalLink : "",
        isProcessing : false,
        rememberMe : false,
    }

    onChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    onChecked = (e) => {
        this.setState({
            [e.target.name] : e.target.checked
        })
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { email, password } = this.state

        if(email==="" && password===""){
            this.setState({
                errorMessage : "Please complete all fields to continue."
            })
        }else{
            this.setState({
                isLoggingIn : true,
                errorMessage : ""
            }, () => {

                const { rememberMe } = this.state;

                this.props.signInUser(email,password, rememberMe)
                    .then( (result) => {
                        const { userProfile, auth } = this.props;
                        if(result.code==="auth/success"){
                            this.props.setUserPresence({
                                uid : auth.uid
                            });
                            if(result.data.type==="superuser"){
                                this.props.history.push('/dashboard')
                            }

                            if(result.data.type==="accountManager"){
                                this.props.history.push('/dashboard')
                            }

                            if(result.data.type==="operator"){
                                this.props.history.push('/tasks/escalated')
                            }
                        }

                        this.setState({
                            isLoggingIn : false
                        })
                    })
            })
        }
    }

    render () {

        const { isLoggingIn, errorMessage, isLoginModalActive, loginModalHeader, loginModalSubHeader, loginModalLink } = this.state;
        const { authError, auth, userProfile } = this.props;
        
        let emailErrorMessage = "";
        let emailErrorClass = "";
        let passwordErrorClass = "";
        let passwordErrorMessage = "";

        if(auth.uid) {
            if(userProfile.type==="accountManager"){
                if(auth.emailVerified){
                    return <Redirect to="/dashboard"/>
                }
            }

            if(userProfile.type==="operator"){
                if(auth.emailVerified){
                    return <Redirect to="/tasks/escalated"/>
                }
            }

            if(userProfile.type==="superuser"){
                return <Redirect to="/dashboard"/>
            }

            return "";
        }else{
            if(authError!==null){
                const { code } = authError;
    
                if(code==="auth/invalid-email"){
                    emailErrorMessage = "Incorrect email format";
                    emailErrorClass = "error-input"
                }
    
                if(code==="auth/user-not-found"){
                    emailErrorMessage = "No account found. Please verify your email address";
                    emailErrorClass = "error-input"
                }
    
                if(code==="auth/wrong-password"){
                    passwordErrorMessage = "Incorrect password. Please try again";
                    passwordErrorClass = "error-input"
                }
    
                if(code==="auth/unverified-email"){
                    emailErrorMessage = authError.message
                    emailErrorClass = "error-input"
                }

                if(code==="auth/user-disabled"){
                    emailErrorMessage = authError.message
                    emailErrorClass = "error-input"
                }
    
            }
    
            if(errorMessage!==""){
                emailErrorClass = "error-input"
                passwordErrorClass = "error-input"
            }
    
            const loginHeader = {
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "48px",
                lineHeight: "64px",
                color: "#414042",
                marginBottom : "40px"
            }
    
           
            return (
                <div class="login-page">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12"></div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                       
                            <p style={loginHeader}>Log in</p>
                            <form>
                                <div className="form-group">
                                    <label className="custom-label block">Log in</label>
                                    <input onChange={this.onChange} name="email" style={{ width: "100%" }} className={`custom-input ${emailErrorClass}`} type="text" placeholder="Enter here"/>
                                    <span class="error-input-message">{ emailErrorMessage }</span>
                                </div>
                                <div className="form-group">
                                    <label className="custom-label block">Password</label>
                                    <input onChange={this.onChange} name="password" style={{ width: "100%" }} className={`custom-input ${passwordErrorClass}`} type="password" placeholder="Enter here"/>
                                    <span class="error-input-message">{ passwordErrorMessage }</span>
                                </div>
                                <div style={{ marginTop : "15px" }}>
                                        <label className="align-left checkbox-container">Remember me
                                        <input onClick={this.onChecked} name="rememberMe" type="checkbox"/>
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <button style={{ width: "100%" }} disabled={isLoggingIn} onClick={this.onSubmit} className="custom-btn">{ isLoggingIn ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "LOGIN"}</button>
                                <span class="error-input-message block mt-3">{ errorMessage }</span>
                            </form>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12"></div>
                    </div>
                    
                </div>
            )
        }

        
        
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        authError : state.auth.authError
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { signInUser, signOutUser, getAllUser, setUserPresence })
)(Login)