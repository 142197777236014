import React, { Component } from 'react'
import moment from 'moment'

 
export class Item extends Component {
    

    render(){

        const { candidate } = this.props;
        
        const candidateName = candidate.fullName.split(",");

        
        return (
            <div className="candidate-item">
                <div className="candidate-item-left">
                    <div style={{ position: "relative"}}>
                        <img className="display-photo" src={candidate.imageUrl===""?require('../../../images/default-user.jpg'):candidate.imageUrl}/>
                    </div>
                    <div className="main-details">
                        <span className="cd-name">{candidateName[1]+' '+candidateName[0]}</span>
                    </div>
                    <div className="sub-details">
                        <div style={{ flex : "1" }}>
                            <span className="cd-label">
                                Specialty
                            </span>
                            <span className="cd-value">
                                {candidate.specialty}
                            </span>
                        </div>
                        <div style={{ flex : "1" }}>
                            <span className="cd-label">
                                Experience
                            </span>
                            <span className="cd-value">
                                {candidate.yearsOfExperience}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="candidate-item-right">
                    <div className="task-details">
                        <p>{candidate.aboutYourself}</p>
                    </div>
                    <div className="candidate-cv">
                        <a href={candidate.resumeUrl} download className="download-cv"> <img style={{ width: "13px", height : "13px", marginRight : "10px" }} src={require('../../../images/icon-download.png')}/>DOWNLOAD CV</a>
                    </div>
                </div>
                <div className="candidate-actions">
                    <button onClick={ () => this.props.openAcceptCandidateModal(candidate)} className="accept-candidate">ACCEPT</button>
                    <button onClick={ () => this.props.openDeclineCandidateModal(candidate)} className="decline-candidate">DECLINE</button>
                </div>
            </div>
        )

    }

}

export default Item;