import React, { Component } from 'react'
import ConversationItem from './ConversationItem';
import SearchInput from './SearchInput';
import SearchedContact from './SearchedContact';
import SearchedMessage from './SearchedMessage';
import $ from 'jquery'

export class ConversationList extends Component {

    constructor(){
        super();
        this.state = {
            search : ``,
            facetFilters : []
        }
    }

    componentDidMount(){

        const { activeConversation } = this.props;
        if(activeConversation!==null){
            this.setActiveConversation(activeConversation)
        }

    }

    searchConversations = (keyword) => {
        this.setState({
            search : keyword
        }, () => {
            if(keyword.trim()!==""){
                this.props.searchConversations(keyword);
            }
        })
    }

    loadConversation = async (type) => {
        await this.props.loadConversations(type)
    }

    changeTab = (recipient) => {
        $('.chat-search-input').val("");
        this.setState({
            search : ""
        })
        this.props.loadConversations(recipient)
        this.props.setChatActiveTab(recipient);
    }

    setActiveConversation = (activeConversation) => {
        this.props.setActiveConversation(activeConversation)
        $('.conversation-body')[0].scrollTop = $('.conversation-body')[0].scrollHeight;
    }

    render(){

        const { isLoadingMoreConversations, isSearchingContacts, isSearchingMessages, searchedContacts, searchedMessages, userProfile, activeConversation, isGettingConversations, conversations } = this.props;

        const { search } = this.state;
        const { activeTab } = this.props;

        return (
            <div className="user-list">
                <div style={{ marginLeft : "20px", marginRight : "20px", borderBottom : "1px solid #E6E4E8" }}>
                    {
                        (userProfile.hasOwnProperty('fullName') && userProfile.type==="accountManager") &&
                            <div className="conv-list-tabs">
                                <ul className="nowrap-nav nav nav-tabs nav-fill nav-header" role="tablist" style={{ marginTop : "5px !important" }}>
                                    <li onClick={ () => this.changeTab('customer') } className="nav-item" >
                                        <a className={`nav-link ${activeTab==="customer" ? "active" : ""}`} id="customer-tab" data-toggle="tab" href="#customer" role="tab" aria-controls="customer" aria-selected={ activeTab === "customer" }>Customer</a>
                                    </li>
                                    <li onClick={ () => this.changeTab('translator') } className="nav-item">
                                        <a className={`nav-link ${activeTab==="translator" ? "active" : ""}`} id="translator-tab" data-toggle="tab" href="#translator" role="tab" aria-controls="translator" aria-selected={ activeTab === "translator" }>Translator</a>
                                    </li>
                                    <li onClick={ () => this.changeTab('operator') } className="nav-item">
                                        <a className={`nav-link ${activeTab==="operator" ? "active" : ""}`} id="operator-tab" data-toggle="tab" href="#operator" role="tab" aria-controls="operator" aria-selected={ activeTab === "operator" }>Operator</a>
                                    </li>
                                    <li onClick={ () => this.changeTab('superuser') } className="nav-item">
                                        <a className={`nav-link ${activeTab==="superuser" ? "active" : ""}`} id="superuser-tab" data-toggle="tab" href="#superuser" role="tab" aria-controls="superuser" aria-selected={ activeTab === "superuser" }>Super user</a>
                                    </li>                    
                                </ul>
                            </div>
                    }
                    {
                        (userProfile.hasOwnProperty('fullName') && userProfile.type==="superuser") &&
                            <div className="conv-list-tabs">
                                <ul className="nowrap-nav nav nav-tabs nav-fill nav-header" role="tablist" style={{ marginTop : "5px !important" }}>
                                    <li onClick={ () => this.changeTab('accountManager') } className="nav-item" >
                                        <a className={`nav-link ${activeTab==="accountManager" ? "active" : ""}`} id="accountManager-tab" data-toggle="tab" href="#accountManager" role="tab" aria-controls="accountManager" aria-selected={ activeTab === "accountManager" }>Account Manager</a>
                                    </li>
                                    <li onClick={ () => this.changeTab('operator') } className="nav-item">
                                        <a className={`nav-link ${activeTab==="operator" ? "active" : ""}`} id="operator-tab" data-toggle="tab" href="#operator" role="tab" aria-controls="operator" aria-selected={ activeTab === "operator" }>Operator</a>
                                    </li>                
                                </ul>
                            </div>
                    }
                    {
                        (userProfile.hasOwnProperty('fullName') && userProfile.type==="operator") &&
                        <div className="conv-list-tabs">
                            <ul className="nowrap-nav nav nav-tabs nav-fill nav-header" role="tablist" style={{ marginTop : "5px !important" }}>
                                <li onClick={ () => this.changeTab('customer') } className="nav-item" >
                                    <a className={`nav-link ${activeTab==="customer" ? "active" : ""}`} id="customer-tab" data-toggle="tab" href="#customer" role="tab" aria-controls="customer" aria-selected={ activeTab === "customer" }>Customer</a>
                                </li>
                                <li onClick={ () => this.changeTab('translator') } className="nav-item">
                                    <a className={`nav-link ${activeTab==="translator" ? "active" : ""}`} id="translator-tab" data-toggle="tab" href="#translator" role="tab" aria-controls="translator" aria-selected={ activeTab === "translator" }>Translator</a>
                                </li>
                                <li onClick={ () => this.changeTab('accountManager') } className="nav-item">
                                    <a className={`nav-link ${activeTab==="accountManager" ? "active" : ""}`} id="accountManager-tab" data-toggle="tab" href="#accountManager" role="tab" aria-controls="accountManager" aria-selected={ activeTab === "accountManager" }>Account Manager</a>
                                </li>
                                <li onClick={ () => this.changeTab('superuser') } className="nav-item">
                                    <a className={`nav-link ${activeTab==="superuser" ? "active" : ""}`} id="superuser-tab" data-toggle="tab" href="#superuser" role="tab" aria-controls="superuser" aria-selected={ activeTab === "superuser" }>Super user</a>
                                </li>                    
                            </ul>
                        </div>
                    }
                </div>
                <SearchInput disabled={isGettingConversations} searchConversations={this.searchConversations}/>
                {
                    isGettingConversations ?
                    <div className="custom-spinner-border spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    :
                    search === "" ?
                    conversations.length!==0 ?
                    conversations.map( ( data, i) => (
                        <ConversationItem key={i} setActiveConversation={this.props.setActiveConversation} userProfile={userProfile} activeConversation={activeConversation} conversation={data} />
                    ))
                    :
                    <p style={{ margin : "5px 20px", fontSize : "12px", textAlign: "center", color : "#7D7C7F" }}>No messages.</p>
                    : 
                    isSearchingContacts && isSearchingMessages ?
                    <div className="custom-spinner-border spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    : 
                    <div>
                        <div style={{ marginLeft : "20px", marginRight : "20px", borderBottom : "1px solid #E6E4E8" }}>
                            <p className="align-left" style={{ marginBottom: "5px", fontSize : "12px", color : "#7D7C7F" }}>Contacts:</p>
                        </div>
                        {
                            searchedContacts.length===0 ?
                            <p style={{ margin : "5px 20px", fontSize : "12px", textAlign: "center", color : "#7D7C7F" }}>No result(s) found.</p>
                            :
                            searchedContacts.map( ( data, i) => (
                                <SearchedContact userProfile={userProfile} getAndSetActiveConversation={this.props.getAndSetActiveConversation} key={i} searched={data} />
                            ))
                        }
                        <div style={{ marginLeft : "20px", marginRight : "20px", borderBottom : "1px solid #E6E4E8" }}>
                            <p className="align-left" style={{ marginBottom: "5px", fontSize : "12px",  color : "#7D7C7F" }}>Messages:</p>
                        </div>
                        {
                            searchedMessages.length===0 ?
                            <p style={{ margin : "5px 20px", fontSize : "12px", textAlign: "center", color : "#7D7C7F" }}>No result(s) found.</p> :
                            searchedMessages.map( ( data, i) => (
                                <SearchedMessage getAndSetActiveConversation={this.props.getAndSetActiveConversation} key={i} searched={data} />
                            ))
                        }
                    </div>
                }
                <div className="text-center">
                {
                    isLoadingMoreConversations ? <div style={{ paddingTop : "15px" }}>
                        <div class="custom-get-more-spinner spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div> : (!isGettingConversations && !isSearchingContacts && conversations.length!==0) && <div className="text-center" style={{ marginTop : "15px" }}>
                        <button onClick={this.props.loadMoreConversations} className="load-more-button">LOAD MORE</button>
                    </div>
                }
                </div>                   
            </div>
        )

    }

}


export default ConversationList