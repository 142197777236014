export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const CHECK_FOR_DUPLICATE_EMAIL = "CHECK_FOR_DUPLICATE_EMAIL"; 
export const CHECK_FOR_DUPLICATE_EMAIL_RESULT = "CHECK_FOR_DUPLICATE_EMAIL_RESULT";
export const LOGIN = "LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const VERIFY = "VERIFY";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VALIDATE_EMAIL_ERROR = "VALIDATE_EMAIL_ERROR"
export const VALIDATE_EMAIL_SUCCESS = "VALIDATE_EMAIL_SUCCESS"
export const SEND_PASSWORD_RESET = "SEND_PASSWORD_RESET"
export const SEND_PASSWORD_RESET_SUCCESS = "SEND_PASSWORD_RESET_SUCCESS"
export const SEND_PASSWORD_RESET_ERROR = "SEND_PASSWORD_RESET_ERROR"
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR"

export const GET_AUTH_USER_DATA = "GET_AUTH_USER_DATA"
export const GET_AUTH_USER_DATA_ERROR = "GET_AUTH_USER_DATA_ERROR"
export const GET_AUTH_USER_DATA_SUCCESS = "GET_AUTH_USER_DATA_SUCCESS"


/**
 * TASKS
 */

export const GET_NEW_TASKS = "GET_NEW_TASKS"
export const GET_NEW_TASKS_SUCCESS = "GET_NEW_TASKS_SUCCESS"
export const GET_NEW_TASKS_ERROR = "GET_NEW_TASKS_ERROR"
export const LOAD_MORE_NEW_TASKS = "LOAD_MORE_NEW_TASKS"
export const LOAD_MORE_NEW_TASKS_SUCCESS = "LOAD_MORE_NEW_TASKS_SUCCESS"
export const LOAD_MORE_NEW_TASKS_ERROR = "LOAD_MORE_NEW_TASKS_ERROR"

export const GET_INPROGRESS_TASKS = "GET_INPROGRESS_TASKS"
export const GET_INPROGRESS_TASKS_SUCCESS = "GET_INPROGRESS_TASKS_SUCCESS"
export const GET_INPROGRESS_TASKS_ERROR = "GET_INPROGRESS_TASKS_ERROR"
export const LOAD_MORE_INPROGRESS_TASKS = "LOAD_MORE_INPROGRESS_TASKS"
export const LOAD_MORE_INPROGRESS_TASKS_SUCCESS = "LOAD_MORE_INPROGRESS_TASKS_SUCCESS"
export const LOAD_MORE_INPROGRESS_TASKS_ERROR = "LOAD_MORE_INPROGRESS_TASKS_ERROR"

export const GET_ARCHIVED_TASKS = "GET_ARCHIVED_TASKS"
export const GET_ARCHIVED_TASKS_SUCCESS = "GET_ARCHIVED_TASKS_SUCCESS"
export const GET_ARCHIVED_TASKS_ERROR = "GET_ARCHIVED_TASKS_ERROR"
export const LOAD_MORE_ARCHIVED_TASKS = "LOAD_MORE_ARCHIVED_TASKS"
export const LOAD_MORE_ARCHIVED_TASKS_SUCCESS = "LOAD_MORE_ARCHIVED_TASKS_SUCCESS"
export const LOAD_MORE_ARCHIVED_TASKS_ERROR = "LOAD_MORE_ARCHIVED_TASKS_ERROR"
export const CLEAR_ARCHIVED_TASKS_LAST_REF = "CLEAR_ARCHIVED_TASKS_LAST_REF"

export const GET_ALL_TASKS = "GET_ALL_TASKS" 
export const GET_ALL_TASKS_SUCCESS = "GET_ALL_TASKS_SUCCESS" 
export const GET_ALL_TASKS_ERROR = "GET_ALL_TASKS_ERROR" 
export const LOAD_MORE_TASKS = "LOAD_MORE_TASKS" 
export const LOAD_MORE_TASKS_SUCCESS = "LOAD_MORE_TASKS_SUCCESS" 
export const LOAD_MORE_TASKS_ERROR = "LOAD_MORE_TASKS_ERROR" 

export const GET_TASK_CUSTOMER = "GET_TASK_CUSTOMER"
export const GET_TASK_CUSTOMER_ERROR = "GET_TASK_CUSTOMER_ERROR"
export const GET_TASK_CUSTOMER_SUCCESS = "GET_TASK_CUSTOMER_SUCCESS"

export const GET_TASK_TRANSACTIONS = "GET_TASK_TRANSACTIONS"
export const GET_TASK_TRANSACTIONS_ERROR = "GET_TASK_TRANSACTIONS_ERROR"
export const GET_TASK_TRANSACTIONS_SUCCESS = "GET_TASK_TRANSACTIONS_SUCCESS"

export const GET_TASK_TRANSLATOR = "GET_TASK_TRANSLATOR"
export const GET_TASK_TRANSLATOR_SUCCESS = "GET_TASK_TRANSLATOR_SUCCESS"
export const GET_TASK_TRANSLATOR_ERROR = "GET_TASK_TRANSLATOR_ERROR"

export const UPDATE_TASK = "UPDATE_TASK"
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS"
export const UPDATE_TASK_ERROR = "UPDATE_TASK_ERROR"

/**
 * CONVERSATION
 */
export const CHECK_CONVERSATION = "CHECK_CONVERSATION" 
export const CHECK_CONVERSATION_SUCCESS = "CHECK_CONVERSATION_SUCCESS" 
export const CHECK_CONVERSATION_ERROR = "CHECK_CONVERSATION_ERROR" 
export const UPDATE_CONVERSATION = "UPDATE_CONVERSATION"
export const UPDATE_CONVERSATIONS_SUCCESS = "UPDATE_CONVERSATIONS_SUCCESS"
export const UPDATE_CONVERSATION_ERROR = "UPDATE_CONVERSATION_ERROR"
export const CREATE_CONVERSATION = "CREATE_CONVERSATION"
export const CREATE_CONVERSATION_SUCCESS = "CREATE_CONVERSATION_SUCCESS"
export const CREATE_CONVERSATION_ERROR = "CREATE_CONVERSATION_ERROR"
export const GET_CONVERSATIONS = "GET_CONVERSATIONS" 
export const GET_CONVERSATIONS_SUCCESS = "GET_CONVERSATIONS_SUCCESS" 
export const GET_CONVERSATIONS_ERROR = "GET_CONVERSATIONS_ERROR"
export const LOAD_MORE_CONVERSATIONS = "LOAD_MORE_CONVERSATIONS"
export const LOAD_MORE_CONVERSATIONS_SUCCESS = "LOAD_MORE_CONVERSATIONS_SUCCESS"
export const LOAD_MORE_CONVERSATIONS_ERROR = "LOAD_MORE_CONVERSATIONS_ERROR"
export const GET_CONVERSATION_MESSAGES = "GET_CONVERSATION_MESSAGES"
export const GET_CONVERSATION_MESSAGES_SUCCESS = "GET_CONVERSATION_MESSAGES_SUCCESS"
export const GET_CONVERSATION_MESSAGES_ERROR = "GET_CONVERSATION_MESSAGES_ERROR"
export const GET_CONVERSATION_MESSAGES_PAGINATE = "GET_CONVERSATION_MESSAGES_PAGINATE"
export const GET_CONVERSATION_MESSAGES_PAGINATE_SUCCESS = "GET_CONVERSATION_MESSAGES_PAGINATE_SUCCESS"
export const GET_CONVERSATION_MESSAGES_PAGINATE_ERROR = "GET_CONVERSATION_MESSAGES_PAGINATE_ERROR"
export const CLEAR_ACTIVE_CONVERSATION = "CLEAR_ACTIVE_CONVERSATION"
export const CREATE_CONVERSATION_MESSAGE = "CREATE_CONVERSATION_MESSAGE"
export const CREATE_CONVERSATION_MESSAGE_SUCCESS = "CREATE_CONVERSATION_MESSAGE_SUCCESS"
export const CREATE_CONVERSATION_MESSAGE_ERROR = "CREATE_CONVERSATION_MESSAGE_ERROR"
export const RELOAD_CONVERSATION_MESSAGES = "RELOAD_CONVERSATION_MESSAGES"
export const SET_ACTIVE_CONVERSATION = "SET_ACTIVE_CONVERSATION"
export const RELOAD_ACTIVE_CONVERSATION = "RELOAD_ACTIVE_CONVERSATION"
export const REMOVE_ACTIVE_CONVERSATION = "REMOVE_ACTIVE_CONVERSATION"
export const REMOVE_ACTIVE_CONVERSATION_SUCCESS = "REMOVE_ACTIVE_CONVERSATION_SUCCESS"
export const REMOVE_ACTIVE_CONVERSATION_ERROR = "REMOVE_ACTIVE_CONVERSATION_ERROR"
export const SEND_MESSAGE = "SEND_MESSAGE"
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS"
export const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR"
export const SEARCH_CONTACTS = "SEARCH_CONTACTS" 
export const SEARCH_CONTACTS_SUCCESS = "SEARCH_CONTACTS_SUCCESS" 
export const SEARCH_CONTACTS_ERROR = "SEARCH_CONTACTS_ERROR"
export const SEARCH_MESSAGES = "SEARCH_MESSAGES" 
export const SEARCH_MESSAGES_SUCCESS = "SEARCH_MESSAGES_SUCCESS" 
export const SEARCH_MESSAGES_ERROR = "SEARCH_MESSAGES_ERROR" 
export const DISPLAY_MESSAGE_BADGE = "DISPLAY_MESSAGE_BADGE"
export const RESET_MESSAGE_BADGE = "RESET_MESSAGE_BADGE" 
export const WATCH_CONVERSATIONS = "WATCH_CONVERSATIONS"
export const RELOAD_CONVERSATIONS = "RELOAD_CONVERSATIONS" 

export const ADD_MODAL = "ADD_MODAL"
export const POP_MODAL = "POP_MODAL"

export const CLEAR_ESCALATIONS_REF = "CLEAR_ESCALATIONS_REF"
export const GET_ESCALATIONS = "GET_ESCALATIONS"
export const GET_ESCALATIONS_ERROR = "GET_ESCALATIONS_ERROR"
export const GET_ESCALATIONS_SUCCESS = "GET_ESCALATIONS_SUCCESS"
export const LOAD_MORE_ESCALATIONS = "LOAD_MORE_ESCALATIONS"
export const LOAD_MORE_ESCALATIONS_SUCCESS = "LOAD_MORE_ESCALATIONS_SUCCESS"
export const LOAD_MORE_ESCALATIONS_ERROR = "LOAD_MORE_ESCALATIONS_ERROR"
export const GET_ESCALATIONS_PAGINATE_SUCCESS = "GET_ESCALATIONS_PAGINATE_SUCCESS"

export const GET_USER_CUSTOMERS = "GET_USER_CUSTOMERS"
export const GET_USER_CUSTOMERS_SUCCESS = "GET_USER_CUSTOMERS_SUCCESS"
export const GET_USER_CUSTOMERS_ERROR = "GET_USER_CUSTOMERS_ERROR"
export const GET_USER_CUSTOMERS_PAGINATE = "GET_USER_CUSTOMERS_PAGINATE"
export const GET_USER_CUSTOMERS_PAGINATE_SUCCESS = "GET_USER_CUSTOMERS_PAGINATE_SUCCESS"
export const GET_USER_CUSTOMERS_PAGINATE_ERROR = "GET_USER_CUSTOMERS_PAGINATE_ERROR"

export const GET_USER_TRANSLATORS = "GET_USER_TRANSLATORS"
export const GET_USER_TRANSLATORS_SUCCESS = "GET_USER_TRANSLATORS_SUCCESS"
export const GET_USER_TRANSLATORS_ERROR = "GET_USER_TRANSLATORS_ERROR"
export const GET_USER_TRANSLATORS_PAGINATE = "GET_USER_TRANSLATORS_PAGINATE"
export const GET_USER_TRANSLATORS_PAGINATE_SUCCESS = "GET_USER_TRANSLATORS_PAGINATE_SUCCESS"
export const GET_USER_TRANSLATORS_PAGINATE_ERROR = "GET_USER_TRANSLATORS_PAGINATE_ERROR"

export const GET_USER_CANDIDATES = "GET_USER_CANDIDATES"
export const GET_USER_CANDIDATES_SUCCESS = "GET_USER_CANDIDATES_SUCCESS"
export const GET_USER_CANDIDATES_ERROR = "GET_USER_CANDIDATES_ERROR"
export const GET_USER_CANDIDATES_PAGINATE = "GET_USER_CANDIDATES_PAGINATE"
export const GET_USER_CANDIDATES_PAGINATE_SUCCESS = "GET_USER_CANDIDATES_PAGINATE_SUCCESS"
export const GET_USER_CANDIDATES_PAGINATE_ERROR = "GET_USER_CANDIDATES_PAGINATE_ERROR"

export const GET_USER_ACCOUNT_MANAGERS = "GET_USER_ACCOUNT_MANAGERS"
export const GET_USER_ACCOUNT_MANAGERS_SUCCESS = "GET_USER_ACCOUNT_MANAGERS_SUCCESS"
export const GET_USER_ACCOUNT_MANAGERS_ERROR = "GET_USER_ACCOUNT_MANAGERS_ERROR"
export const GET_USER_ACCOUNT_MANAGERS_PAGINATE = "GET_USER_ACCOUNT_MANAGERS_PAGINATE"
export const GET_USER_ACCOUNT_MANAGERS_PAGINATE_SUCCESS = "GET_USER_ACCOUNT_MANAGERS_PAGINATE_SUCCESS"
export const GET_USER_ACCOUNT_MANAGERS_PAGINATE_ERROR = "GET_USER_ACCOUNT_MANAGERS_PAGINATE_ERROR"

export const GET_USER_OPERATORS = "GET_USER_OPERATORS"
export const GET_USER_OPERATORS_SUCCESS = "GET_USER_OPERATORS_SUCCESS"
export const GET_USER_OPERATORS_ERROR = "GET_USER_OPERATORS_ERROR"
export const GET_USER_OPERATORS_PAGINATE = "GET_USER_OPERATORS_PAGINATE"
export const GET_USER_OPERATORS_PAGINATE_SUCCESS = "GET_USER_OPERATORS_PAGINATE_SUCCESS"
export const GET_USER_OPERATORS_PAGINATE_ERROR = "GET_USER_OPERATORS_PAGINATE_ERROR"

export const GET_TASK_STATISTICS = "GET_TASK_STATISTICS"
export const GET_TASK_STATISTICS_SUCCESS = "GET_TASK_STATISTICS_SUCCESS"
export const GET_TASK_STATISTICS_ERROR = "GET_TASK_STATISTICS_ERROR"

export const GET_USER_STATISTICS = "GET_USER_STATISTICS"
export const GET_USER_STATISTICS_SUCCESS = "GET_USER_STATISTICS_SUCCESS"
export const GET_USER_STATISTICS_ERROR = "GET_USER_STATISTICS_ERROR"

export const GET_REQUESTS_STATISTICS = "GET_REQUESTS_STATISTICS"
export const GET_REQUESTS_STATISTICS_SUCCESS = "GET_REQUESTS_STATISTICS_SUCCESS"
export const GET_REQUESTS_STATISTICS_ERROR = "GET_REQUESTS_STATISTICS_ERROR"

export const GET_EARNINGS_STATISTICS = "GET_EARNINGS_STATISTICS"
export const GET_EARNINGS_STATISTICS_SUCCESS = "GET_EARNINGS_STATISTICS_SUCCESS"
export const GET_EARNINGS_STATISTICS_ERROR = "GET_EARNINGS_STATISTICS_ERROR"

export const GET_TASK_STATUS_STATISTICS = "GET_TASK_STATUS_STATISTICS"
export const GET_TASK_STATUS_STATISTICS_SUCCESS = "GET_TASK_STATUS_STATISTICS_SUCCESS"
export const GET_TASK_STATUS_STATISTICS_ERROR = "GET_TASK_STATUS_STATISTICS_ERROR"

export const GET_TASK_TYPE_STATISTICS = "GET_TASK_TYPE_STATISTICS"
export const GET_TASK_TYPE_STATISTICS_SUCCESS = "GET_TASK_TYPE_STATISTICS_SUCCESS"
export const GET_TASK_TYPE_STATISTICS_ERROR = "GET_TASK_TYPE_STATISTICS_ERROR"

export const GET_TRANSLATOR_STATISTICS = "GET_TRANSLATOR_STATISTICS"
export const GET_TRANSLATOR_STATISTICS_SUCCESS = "GET_TRANSLATOR_STATISTICS_SUCCESS"
export const GET_TRANSLATOR_STATISTICS_ERROR = "GET_TRANSLATOR_STATISTICS_ERROR"

export const GET_CUSTOMER_STATISTICS = "GET_CUSTOMER_STATISTICS"
export const GET_CUSTOMER_STATISTICS_SUCCESS = "GET_CUSTOMER_STATISTICS_SUCCESS"
export const GET_CUSTOMER_STATISTICS_ERROR = "GET_CUSTOMER_STATISTICS_ERROR"

export const GET_TRANSLATOR_COUNT_STATISTICS = "GET_TRANSLATOR_COUNT_STATISTICS"
export const GET_TRANSLATOR_COUNT_STATISTICS_SUCCESS = "GET_TRANSLATOR_COUNT_STATISTICS_SUCCESS"
export const GET_TRANSLATOR_COUNT_STATISTICS_ERROR = "GET_TRANSLATOR_COUNT_STATISTICS_ERROR"

export const GET_CUSTOMER_COUNT_STATISTICS = "GET_CUSTOMER_COUNT_STATISTICS"
export const GET_CUSTOMER_COUNT_STATISTICS_SUCCESS = "GET_CUSTOMER_COUNT_STATISTICS_SUCCESS"
export const GET_CUSTOMER_COUNT_STATISTICS_ERROR = "GET_CUSTOMER_COUNT_STATISTICS_ERROR"

export const SET_REPORT_DATE_FILTER = "SET_REPORT_DATE_FILTER"

export const BLOCK_CUSTOMER = "BLOCK_CUSTOMER" 
export const BLOCK_CUSTOMER_SUCCESS = "BLOCK_CUSTOMER_SUCCESS" 
export const BLOCK_CUSTOMER_ERROR = "BLOCK_CUSTOMER_ERROR" 


export const FIRE_USER = "FIRE_USER" 
export const FIRE_USER_SUCCESS = "FIRE_USER_SUCCESS" 
export const FIRE_USER_ERROR = "FIRE_USER_ERROR" 

export const GET_EMAIL_VERIFICATION_CONTENT = "GET_EMAIL_VERIFICATION_CONTENT"
export const GET_EMAIL_VERIFICATION_CONTENT_SUCCESS = "GET_EMAIL_VERIFICATION_CONTENT_SUCCESS"
export const GET_EMAIL_VERIFICATION_CONTENT_ERROR = "GET_EMAIL_VERIFICATION_CONTENT_ERROR"

export const GET_ADMIN_CONFIG = "GET_ADMIN_CONFIG" 
export const GET_ADMIN_CONFIG_SUCCESS = "GET_ADMIN_CONFIG_SUCCESS" 
export const GET_ADMIN_CONFIG_ERROR = "GET_ADMIN_CONFIG_ERROR" 

export const RELOAD_NOTIFICATIONS = "RELOAD_NOTIFICATIONS"
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION"
export const SET_TO_VIEWED = "SET_TO_VIEWED"
export const SET_TO_VIEWED_SUCCESS = "SET_TO_VIEWED_SUCCESS"
export const SET_TO_VIEWED_ERROR = "SET_TO_VIEWED_ERROR"

export const CLEAR_NEW_TASKS_COUNT = "CLEAR_NEW_TASKS_COUNT"
export const SET_CHAT_ACTIVE_TAB = "SET_CHAT_ACTIVE_TAB"