import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import moment from 'moment';

import '../task.css'
import {
    exactMath
} from 'exact-math'

const renderTaskStatus = (status) => {

    if(status==="new"){
        return <p className="task-status"><span style={{ marginRight: "10px" }}><img style={{ width: "10px", height: "10px" }} src={require('../../../images/icon-new.png')}/></span>In progress</p>
    }

    if(status==="inProgress"){
        return <p className="task-status"><span style={{ marginRight: "10px" }}><img style={{ width: "10px", height: "10px" }} src={require('../../../images/icon-inprogress.png')}/></span>In progress</p>
    }

    if(status==="forReview"){
        return <p className="task-status"><span style={{ marginRight: "10px" }}><img style={{ width: "10px", height: "10px" }} src={require('../../../images/icon-review.png')}/></span>For review</p>
    }

    if(status==="completed"){
        return <p className="task-status"><span style={{ marginRight: "10px" }}><img style={{ width: "10px", height: "10px" }} src={require('../../../images/icon-completed.png')}/></span>Completed</p> 
    }

}

const taskTypeColor = (type) => {
    switch(type){
        case "presentation":
            return { background: "#EA5895" }
        case "essay":
            return { background : "#7E57C2" }
        default:
            return { background : "#29B6F6" }
    }
}

const renderAdditionalServices = (services) => {

    let servicesList = [];

    if(services.length==1){
        services.map( (data) => {

            if(data==="diamond"){
                servicesList.push(<span style={{ fontSize : "12px", marginRight: "10px" }}><img style={{ width: "30px", height: "30px" }} src={require('../../../images/icon-diamond.png')}/>&nbsp;&nbsp;&nbsp;High priority task</span>)
            }
    
            if(data==="express"){
                servicesList.push(<span style={{ fontSize : "12px", marginRight: "10px" }}><img style={{ width: "30px", height: "30px" }} src={require('../../../images/icon-express.png')}/>&nbsp;&nbsp;&nbsp;Express service</span>)
            }
        })
    }else{
        services.map( (data) => {

            if(data==="diamond"){
                servicesList.push(<span style={{ fontSize : "12px", marginRight: "10px" }}><img style={{ width: "30px", height: "30px" }} src={require('../../../images/icon-diamond.png')}/></span>)
            }
    
            if(data==="express"){
                servicesList.push(<span style={{ fontSize : "12px", marginRight: "10px" }}><img style={{ width: "30px", height: "30px" }} src={require('../../../images/icon-express.png')}/></span>)
            }
        })
    
    }

    return servicesList;


}

export class Item extends Component {

    openPauseTaskModal = (task) => {
        this.props.openPauseTaskModal(task);
    }

    openStopTaskModal = (task) => {
        this.props.openStopTaskModal(task);
    }

    openEscalateTaskModal = (task) => {
        this.props.openEscalateTaskModal(task);
    }

    openTaskDetailsModal = (task) => {
        this.props.openTaskDetailsModal(task);
    }

    openChat = (userType, recipient, task) => {
        this.props.openChat(userType, recipient, task)
    }

    render(){

        const { task } = this.props;

        let dateAdded = task.dateAdded.seconds === undefined ? task.dateAdded._seconds : task.dateAdded.seconds;
        let deadline = task.deadline.seconds === undefined ? task.deadline._seconds : task.deadline.seconds;
        
        let d = new Date();
        let dateAddedBar = new Date(moment.unix(dateAdded))
        let deadlineBar = new Date(moment.unix(deadline))
        let timelineBar = moment(deadlineBar).diff(dateAddedBar, 'days')
        let daysDiffBar = moment(deadlineBar).diff(d,'days')
        
        let passedDueStyle = {};
        let progressBarClasses = "progress-bar custom-progress-bar";
        let progressBarStyle = {}

        if(moment.unix(deadline).diff(moment(new Date()), 'days')<=0){
         
            passedDueStyle = {
                color : "#FF6666"
            }

            progressBarClasses = "progress-bar custom-progress-bar-danger"
        }

        if(daysDiffBar<=0){
            progressBarStyle.width = "100%"
        }else{
            progressBarStyle.width = 100 - ((daysDiffBar/timelineBar) * 100) +"%"
        }
        
        return (
            <div className="task-item">
                <div className="row">
                    <div style={{ borderRight: "1px solid #E9E9EA"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">

                        <div className="row pl-2">
                            <div className="col">
                                {renderTaskStatus(task.status)}
                            </div>
                            <div className="col">
                                <p className="task-status" style={{ textAlign : "left" }}><span style={{ marginRight: "10px" }}><img style={{ width: "10px", height: "10px" }} src={ task.remainingAmount!==0 ? require('../../../images/icon-unpaid.png') : require('../../../images/icon-paid.png')}/></span>{task.remainingAmount!==0 ? "50% Paid" : "100% Paid"}</p>
                            </div>
                        </div>
                        <div className="row pl-2">
                            <div className="col">
                                <span className="field-label">Task created</span>
                                <span className="field-value">{ moment.unix(dateAdded).format("Y/M/DD")}</span>
                            </div>
                            <div className="col">
                                <span className="field-label">Deadline</span>
                                <span className="field-value" style={passedDueStyle}>{ moment.unix(deadline).format("Y/M/DD")}</span>
                            </div>
                        </div>
                        <div className="row pl-2 mt-4">
                            <div className="col">
                                <span className="field-label">Words number</span>
                                <span className="field-value">{task.numberOfWords}</span>
                            </div>
                            <div className="col">
                                <span className="field-label">Days to deadline</span>
                                <span className="field-value" style={passedDueStyle}>{ moment.unix(deadline).diff(moment(new Date()), 'days')}</span>
                            </div>
                        </div>

                    </div>
                    <div style={{ borderRight: "1px solid #E9E9EA", position : "relative"}}  className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="row pl-2">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <span style={taskTypeColor(task.taskType)} className="task-type">{task.taskType}</span>
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                <p className="task-title">{task.title}</p>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right">
                            <span style={{ fontSize : "13px", color: "#676A6C" }}>Order#:<span style={{ fontSize: ".80rem", color: "black" }}>{task.orderID}</span></span>
                            </div>
                        </div>
                        <div className="row pl-2">
                            <div className="col">
                                <p className="task-description"><span style={{ color : "#7e7e7e" }}>Description: &nbsp;</span>{task.detailedInstructions}</p>
                            </div>
                        </div>
                        <div style={{ display : "flex" }}>
                            <div>
                                <span style={{ marginBottom: "0", fontSize : "16px", fontWeight : "bold", color : "#414042" }}>Work in progress</span>
                            </div>
                            <div style={{ flex : "1", marginLeft : "13px" }}>
                                <div className="progress mt-1 custom-progress">
                                    <div className={progressBarClasses} aria-valuenow={daysDiffBar} aria-valuemin={0} aria-valuemax={timelineBar} style={progressBarStyle}></div>
                                </div>
                            </div>
                            <div style={{ marginLeft : "20px", float : "right", textAlign : "right" }}>
                                { renderAdditionalServices(task.additionalServices) }
                            </div>
                        </div>
                    </div>
                    <div style={{ position :"relative" }} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <div class="btn-group" style={{ position : "absolute", top : "-7px", right : "15px" }}>
                            <button type="button" class="btn btn-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            . . .
                            </button>
                            <div class="dropdown-menu dropdown-menu-right custom-dropdown-right">
                            { (task.paymentStatus==="fullyPaid" && task.status==="forReview") && <button onClick={ () => this.props.openCompleteTaskModal(task)} class="dropdown-item custom-dropdown-item" type="button">Complete Task</button> }
                            <button class="dropdown-item custom-dropdown-item" type="button">Start the chat</button>
                            <button onClick={ () => this.props.openDeclineTaskModal(task)} class="dropdown-item custom-dropdown-item" type="button">Stop task</button>
                            <button onClick={ () => this.props.openEscalateTaskModal(task)} class="dropdown-item custom-dropdown-item" type="button">Problem? Get help</button>
                            </div>
                        </div>
                        <span style={{ fontSize:"20px", color : "#464646" }}>Price</span>
                        <p  className="task-remaining-amount">${ Number.isInteger(task.price) ? parseFloat(task.price).toFixed(2) : task.price }</p>
                        {
                            task.remainingAmount!== 0 &&
                            <div>
                                <span style={{ fontSize:"16px", color : "#464646" }}>Remaining amount</span>
                                <p style={{ fontSize : "16px" }} className="task-remaining-amount">${ Number.isInteger(task.remainingAmount) ? parseFloat(task.remainingAmount).toFixed(2) : task.remainingAmount }</p>
                            </div>
                        }
                        <button onClick={ () => this.openChat('translator', task.assignedTranslator, task) } className="task-give-feedback">TRANSLATOR'S CHAT</button>
                        <button onClick={ () => this.openChat('customer', task.customer, task) } className="task-give-feedback" style={{ marginTop : "10px"}}>CUSTOMER'S CHAT</button>
                    </div>
                </div>


            </div>
        )

    }

}

export default Item;
