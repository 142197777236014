import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { signOutUser, loggingOutUser, setUserPresence } from '../../actions/authAction';
import { watchNewTasksCount, clearNewTasksCount } from '../../actions/taskAction';
import { updateUserLastOpenedNewTasksDate } from '../../actions/authAction'
import { watchNewMessages, resetMessageBadge } from '../../actions/conversationAction'
import {
    watchTranslatorNotification,
    removeNotification
} from '../../actions/notificationAction'

import{
    TranslatorNotification
} from '../Modals/TranslatorNotification';

import './header.css';

import {
    popModal
} from '../../actions/appAction'

export class LoggedInLinks extends Component {

    componentDidMount(){

        const { auth } = this.props;

        if(auth.uid){
            this.unsubscribeWatchNewMessages = this.props.watchNewMessages();
            this.unsubscribeWatchTranslatorNotificationListener = this.props.watchTranslatorNotification();
        }
    
    }

    componentWillUnmount(){
        this.unsubscribeWatchNewMessages && this.unsubscribeWatchNewMessages();
        this.unsubscribeWatchTranslatorNotificationListener && this.unsubscribeWatchTranslatorNotificationListener();
        this.unsubscribeWatchTaskCountListener && this.unsubscribeWatchTaskCountListener();
    }

    closeMessageModal = (uid) => {
        this.props.removeNotification(uid)
    }

    componentDidUpdate(prevProps){
        
        if(this.props.userProfile!==prevProps.userProfile){
            this.unsubscribeWatchTaskCountListener = this.props.watchNewTasksCount();
            this.unsubscribeWatchNewMessages &&  this.unsubscribeWatchNewMessages()
            if(this.props.location.pathname!=="/chat"){
                setTimeout( () => {
                    this.unsubscribeWatchNewMessages = this.props.watchNewMessages();
                }, 3000 )
            }
        }

        if(this.props.location!==prevProps.location){
            if(this.props.location.pathname==="/chat"){
                this.unsubscribeWatchNewMessages &&  this.unsubscribeWatchNewMessages()
            }else{
                if(this.props.auth.uid){
                    setTimeout( () => {
                        this.unsubscribeWatchNewMessages = this.props.watchNewMessages();
                    }, 3000 )
                }
            }
        }
    }

    updateUserLastOpenedNewTasksDate = () => {
        const { userProfile } = this.props;

        if(userProfile.uid!==undefined){
            this.props.clearNewTasksCount();
            this.props.updateUserLastOpenedNewTasksDate();
            this.unsubscribeWatchTaskCountListener && this.unsubscribeWatchTaskCountListener();
            this.unsubscribeWatchTaskCountListener = this.props.watchNewTasksCount();
        }
    }


    signOutUser = async () => {
        const { auth } = this.props;
        
        this.props.loggingOutUser();
        setTimeout( () => {
            this.unsubscribeWatchNewMessages &&  this.unsubscribeWatchNewMessages()
            this.props.signOutUser(); 
        }, 2000)
    }

    render () {

        const { auth, userType, newTaskCount, userProfile, hasNewMessage } = this.props;
        let links = null;

        const { notifications } = this.props;

        switch(userType){
            case "accountManager":
            case "superuser":
                    links = <ul className="navbar-nav ml-auto">
                    <li className="header-nav-item nav-item">
                        <Link className="header-nav-link nav-link" to="/dashboard">Dashboard</Link>
                    </li>
                    <li className="header-nav-item nav-item">
                        {
                            userType==="accountManager" &&
                            <div className="dropdown">
                                <a className="header-nav-link nav-link" href="#" data-toggle="dropdown">Tasks</a>
                                <ul className="dropdown-menu custom-dropdown-menu" role="menu" aria-labelledby="imageDropdown">
                                    <li onClick={this.updateUserLastOpenedNewTasksDate} role="presentation"><Link to="/tasks/new" role="menuitem">New Tasks { (newTaskCount>0) ? <span className="badge-count">{newTaskCount}</span> : "" } </Link></li>
                                    <li role="presentation"><Link to="/tasks/in_progress" role="menuitem">In progress tasks</Link></li>
                                </ul>
                            </div>
                        }
                        {
                            userType==="superuser" &&
                            <div className="dropdown">
                                <Link onClick={this.updateUserLastOpenedNewTasksDate} className="header-nav-link nav-link" to="/tasks">Tasks</Link>
                            </div>
                        }
                    </li>
                    <li className="header-nav-item nav-item">
                        {
                            userType==="superuser" && 
                            <div className="dropdown">
                                <a className="header-nav-link nav-link" href="#" data-toggle="dropdown">Users <img style={{ marginLeft : "15px", width : "15px", height : "15px" }} src={require('../../images/icon-dropdown.png')}/></a>
                                <ul style={{ left : "-70px" }} className="dropdown-menu custom-dropdown-menu" role="menu" aria-labelledby="imageDropdown">
                                    <li role="presentation"><Link to="/users/customers" role="menuitem">Customers</Link></li>
                                    <li role="presentation"><Link to="/users/translators" role="menuitem">Translators</Link></li>
                                    <li role="presentation"><Link to="/users/account_managers" role="menuitem">Account managers</Link></li>
                                    <li role="presentation"><Link to="/users/operators" role="menuitem">Operators</Link></li>
                                </ul>
                            </div>
                        }
                        {
                            userType==="accountManager" && 
                            <div className="dropdown">
                                <a className="header-nav-link nav-link" href="#" data-toggle="dropdown">Users <img style={{ marginLeft : "15px", width : "15px", height : "15px" }} src={require('../../images/icon-dropdown.png')}/></a>
                                <ul style={{ left : "-70px" }} className="dropdown-menu custom-dropdown-menu" role="menu" aria-labelledby="imageDropdown">
                                    <li role="presentation"><Link to="/users/candidates" role="menuitem">Candidates</Link></li>
                                    <li role="presentation"><Link to="/users/translators" role="menuitem">Translators</Link></li>
                                </ul>
                            </div>
                        }
                    </li>
                    <li className="header-nav-item nav-item">
                        <a className="header-nav-link nav-link" href="#"><img style={{ width: "60%" }} src={ require('../../images/icon-notifications.png') }/></a>
                    </li>
                    <li className="header-nav-item nav-item">
                        <Link onClick={this.props.resetMessageBadge} className="header-nav-link nav-link" to="/chat">
                            <div style={{ position : "relative" }}><img style={{ width: "60%" }} src={ require('../../images/icon-messages.png') }/> { hasNewMessage && <img style={{ width: "10px", position : "absolute", top : "2px", right : "0" }} src={require('../../images/icon-archived.png')}/> }</div>
                        </Link>
                    </li>
                    <li className="header-nav-item nav-item">
                        <div className="dropdown">
                            <a href="#" id="imageDropdown" data-toggle="dropdown"><img className="avatar" src={ userProfile.imageUrl ? userProfile.imageUrl : require('../../images/default-user.jpg') }/></a>
                            <ul style={{ left : "-125px" }} className="dropdown-menu custom-dropdown-menu" role="menu" aria-labelledby="imageDropdown">
                                <li role="presentation"><Link role="menuitem" tabindex="-1" to="/profile">Profile</Link></li>
                                { userType==="superuser" && <li role="presentation"><Link role="menuitem" tabindex="-1" to="/invite_employee">Invite Employee</Link></li> }
                                { userType==="superuser" && <li role="presentation"><Link role="menuitem" tabindex="-1" to="/config">Admin Configuration</Link></li> }
                                <li role="presentation"><Link role="menuitem" tabindex="-1" to="/tasks/archived">Archive Tasks</Link></li>
                                <li role="presentation" className="divider"></li>
                                <li role="presentation"><a role="menuitem" tabindex="-1" href="#" onClick={this.signOutUser}>Logout</a></li>
                            </ul>
                        </div>
                    </li>
                </ul>    
                break;
            case "operator":
                    links = <ul className="navbar-nav ml-auto">
                    {/* <li className="header-nav-item nav-item">
                        <a className="header-nav-link nav-link" href="#">Dashboard</a>
                    </li> */}
                    <li className="header-nav-item nav-item">
                        <Link className="header-nav-link nav-link" to="/tasks/escalated">Tasks</Link>
                    </li>
                    <li className="header-nav-item nav-item" >
                        <a className="header-nav-link nav-link" href="#"><img style={{ width: "60%" }} src={ require('../../images/icon-notifications.png') }/></a>
                    </li>
                    <Link style={{ marginRight : "40px"}} onClick={this.props.resetMessageBadge} className="header-nav-link nav-link" to="/chat">
                        <div style={{ position : "relative" }}><img style={{ width: "60%" }} src={ require('../../images/icon-messages.png') }/> { hasNewMessage && <img style={{ width: "10px", position : "absolute", top : "2px", right : "0" }} src={require('../../images/icon-archived.png')}/> }</div>
                    </Link>
                    <li className="header-nav-item nav-item">
                        <div className="dropdown">
                            <a href="#" id="imageDropdown" data-toggle="dropdown"><img className="avatar" src={ userProfile.imageUrl ? userProfile.imageUrl : require('../../images/default-user.jpg') }/></a>
                            <ul style={{ left : "-125px" }} className="dropdown-menu custom-dropdown-menu" role="menu" aria-labelledby="imageDropdown">
                                <li role="presentation"><Link role="menuitem" tabindex="-1" to="/profile">Profile</Link></li>
                                <li role="presentation"><a role="menuitem" tabindex="-1" href="#">Archive Tasks</a></li>
                                <li role="presentation" className="divider"></li>
                                <li role="presentation"><a role="menuitem" tabindex="-1" href="#" onClick={this.signOutUser}>Logout</a></li>
                            </ul>
                        </div>
                        
                    </li>
                </ul>
                break;
            default:
               break;
            
        }
    
        return (
            <div className="collapse navbar-collapse navbar-dark" id="navbarNav">
                { links }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        newTaskCount : state.task.newTaskCount,
        hasNewMessage : state.conversation.hasNewMessage,
        notifications : state.app.notifications
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { 
        signOutUser, 
        loggingOutUser,
        watchNewTasksCount,
        updateUserLastOpenedNewTasksDate,
        setUserPresence,
        watchNewMessages,
        resetMessageBadge,
        clearNewTasksCount,
        watchTranslatorNotification,
        removeNotification
    })
)(LoggedInLinks)

