import React, { Component } from 'react'
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import {
    addModal,
    popModal
} from '../../../../actions/appAction';

import '../candidates.css'

export class DeclineCandidate extends Component {

    constructor(){
        super();
        this.state={
            declineReason : "",
            isReasonEmpty : false,
            modalZ : 1050
        }
    }

    componentDidMount(){

        const { isOpened } = this.props;

        if(isOpened){
            $('#declineCandidateModal').modal({backdrop:'static', keyboard:false});
            $('#declineCandidateModal').modal('show');
            this.props.addModal('decline-candidate-modal')
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("dc-bd");
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="decline-candidate-modal");
            if(modalInstance!==undefined){
                $('.dc-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }


    componentWillUnmount(){
        $('#declineCandidateModal').modal('hide');
        this.props.popModal()
    }

    onInputChange = (e) => {

        if(e.target.value.trim()!==""){
            this.setState({
                isReasonEmpty : false
            })
        }

        this.setState({
            declineReason : e.target.value
        })

    }

    declineTask = () => {

        const { declineReason } = this.state;

        if(declineReason===""){
            this.setState({
                isReasonEmpty: true
            })
        }else{
            this.props.declineCandidate({
                declineReason : declineReason
            })
        }

    }

    render () {

        const { modalZ } = this.state;
        const { header, btnYes, btnCancel, isReasonEmpty } = this.props;

        return (
            <div style={{ zIndex : `${modalZ}` }} className="decline-candidate-modal modal fade modal-opaque" id="declineCandidateModal" tabIndex="-1" role="dialog" aria-labelledby="helpTaskModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            
                        </div>
                        <div className="modal-body">
                            <p className="decline-candidate-header">{header}</p>
                            <div className="form-group" style ={{ textAlign : "left" }}>
                            <input style={{ width: "100%" }} onChange={this.onInputChange} id="decline_reason" name="decline_reason" style={{ width: "100%" }} className={`custom-input ${ isReasonEmpty ? "error-input" : ""}`} type="text" placeholder="Enter the reason for declining the applicant"/>  
                            </div>
                            <div className="text-center" style={{ display : "flex" }}>
                                <button className="btn-ok" onClick={this.props.confirm} style={{ flex : "1" }}>{btnYes}</button>    
                                <button className="btn-cancel" onClick={this.props.closeDeclineCandidateModal} style={{ flex : "1" }}>{btnCancel}</button>    
                            </div>                            
                        </div>
                    
                    </div>
                </div>
            </div>
        )

    }


}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(DeclineCandidate)