import React, { Component } from 'react'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export class DateFilter extends Component {

    render(){

        const { dateFrom, dateTo } = this.props;
        
        return(
            <div className="date-range-filter">
                <span>Date ranges</span>
                <div style={{ flex : "1" }}>
                    <DatePicker className={`date-picker`} style={{ width: "100%" }} showYearDropdown showMonthDropdown name="date_from" id="date_from" onChange={this.props.onDateFromChange} selected={dateFrom} placeholderText="-" />
                </div>
                <span style={{ marginLeft: "10px" }}>to</span>
                <div style={{ flex : "1" }}>
                    <DatePicker minDate={dateFrom} className={`date-picker`} style={{ width: "100%" }} showYearDropdown showMonthDropdown name="date_to" id="date_to" onChange={this.props.onDateToChange} selected={dateTo} placeholderText="-" />
                </div>
            </div>
        )

    }

}

export default DateFilter