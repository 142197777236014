import {
    ADD_MODAL,
    POP_MODAL
} from './types'

import axios from 'axios'

export const addModal = (data) => (dispatch) => {

    dispatch({
        type : ADD_MODAL,
        payload : {
            modalClass : data
        }
    })

}

export const popModal = (data) => (dispatch) => {

    dispatch({
        type : POP_MODAL,
        payload : {
            modalClass : data
        }
    })

}

export const testFiles = (files) => ( dispatch, getState, { getFirebase, getFirestore }) => {
    
    const firebase = getFirebase();
    return new Promise( async (resolve, reject) => {
        for(let i=0; i<files.length; i++){
            firebase.storage().ref(`test_files/${files[i].name}`).put(files[i])
            .on('state_changed', (snapshot) => {
            },
            (error) =>{
                console.log(error);
            },
            async () => {
                firebase.storage().ref(`test_files`).child(files[i].name).getDownloadURL().then( url => {
                    resolve(url)
                })
            });
        }
    })
}