import React, { Component } from 'react'
import _ from 'lodash/'
import './users.css'

export class SearchInput extends Component{
    
    searchUsers = _.debounce( async (keyword) => {
        
        this.props.searchUsers(keyword);

    }, 800)


    render (){

        return (
            <div className="users-search">
                <input onKeyUp={(e) => this.searchUsers(e.target.value)} placeholder="Search" className="users-search-input"/>
                <img src={require('../../images/icon-search.png')}/>
            </div>
        )

    }

}

export default SearchInput