import React, { Component } from 'react'
import moment from 'moment'
import $ from 'jquery';

const taskTypeColor = (type) => {
    switch(type){
        case "presentation":
            return { background: "#EA5895" }
        case "essay":
            return { background : "#7E57C2" }
        default:
            return { background : "#29B6F6" }
    }
}

export class TranslatorInvite extends Component {

    constructor(){
        super();
    }

    componentDidMount(){

        const { isOpened, task } = this.props;

        if(isOpened){
            $('#translatorInviteModal').modal({backdrop:'static', keyboard:false});
            $('#translatorInviteModal').modal('show');
        }

    }

    componentWillUnmount(){
        $('#translatorInviteModal').modal('hide');
        $('.modal-backdrop').slice(0,1).remove();
    }

    render(){
        const { task, translator, translatorName } = this.props;

        let dateAdded = task.dateAdded.seconds === undefined ? task.dateAdded._seconds : task.dateAdded.seconds;
        let deadline = task.deadline.seconds === undefined ? task.deadline._seconds : task.deadline.seconds;
       
        return (
            <div className="hired-translator-modal modal fade modal-opaque" id="translatorInviteModal" tabIndex="-1" role="dialog" aria-labelledby="forgotPasswordModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button onClick={this.props.closeTranslatorInviteModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span style={{ fontWeight: "300", fontSize: "70px" }} aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="text-header">Invite translator</p>
                            <p className="text-sub-header">You have invited {translatorName} to the task</p>
                            <div className="hired-translator-details task-item">
                                <div style={{ display : "flex" }}>
                                    <div style={{ flex : "1" }}><span style={taskTypeColor(task.taskType)} className="task-type">{task.taskType}</span></div>
                                    <div style={{ textAlign: "right" }}><span style={{ fontSize : "14px", color: "#676A6C" }}>Order#:<span style={{ fontSize: ".80rem", color: "black" }}>{task.orderID}</span></span></div>
                                </div>
                                <p style={{ marginTop: "16px" }} className="task-title">{task.title}</p>
                                <div style={{ display : "flex", marginTop : "24px" }}>
                                    <div style={{ marginRight : "24px"}}>
                                        <span className="field-label">Task created</span>
                                        <span className="field-value">{ moment.unix(dateAdded).format("Y/M/DD")}</span>
                                    </div>
                                    <div style={{ flex : "1"}}>
                                        <span className="field-label">Deadline</span>
                                        <span className="field-value">{ moment.unix(deadline).format("Y/M/DD")}</span>
                                    </div>
                                    <div style={{ flex : "1"}}>
                                        <span className="field-label">Translator</span>
                                        <span className="field-value">{translatorName}</span>
                                    </div>
                                </div>
                                <div style={{ display : "flex", marginTop : "24px" }}>
                                    <div style={{ marginRight : "24px"}}>
                                        <span className="field-label">Words number</span>
                                        <span className="field-value">{task.numberOfWords}</span>
                                    </div>
                                    <div style={{ flex : "1"}}>
                                        <span className="field-label">Days to deadline</span>
                                        <span className="field-value">{ moment.unix(deadline).diff(moment(new Date()), 'days')}</span>
                                    </div>
                                </div>
                                <div className="text-center" style={{ display : "flex", marginTop : "56px" }}>
                                    <button onClick={ () => this.props.unAssignTask(task, translator.uid) } className="btn-decline">DECLINE INVITATION</button>    
                                    <button onClick={ () => this.props.openChat('translator',translator.uid,task)} className="btn-chat">START THE CHAT</button>    
                                </div>     
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default TranslatorInvite