import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/database';
import 'firebase/analytics';

var firebaseConfig = {
    apiKey: process.env.REACT_APP_ENV=="production " ? process.env.REACT_APP_PROD_FB_API_KEY : process.env.REACT_APP_DEV_FB_API_KEY,
    authDomain:  process.env.REACT_APP_ENV=="production " ? process.env.REACT_APP_PROD_FB_AUTH_DOMAIN : process.env.REACT_APP_DEV_FB_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_ENV=="production " ? process.env.REACT_APP_PROD_FB_DB_URL : process.env.REACT_APP_DEV_FB_DB_URL,
    projectId: process.env.REACT_APP_ENV=="production " ? process.env.REACT_APP_PROD_FB_PROJECT_ID : process.env.REACT_APP_DEV_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_ENV=="production " ? process.env.REACT_APP_PROD_FB_STORAGE_BUCKET : process.env.REACT_APP_DEV_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_ENV=="production " ? process.env.REACT_APP_PROD_FB_MESSAGING_SENDER_ID : process.env.REACT_APP_DEV_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ENV=="production " ? process.env.REACT_APP_PROD_FB_APP_ID : process.env.REACT_APP_DEV_FB_APP_ID,
    measurementId: process.env.REACT_APP_ENV=="production " ? process.env.REACT_APP_PROD_FB_MEASUREMENT_ID : process.env.REACT_APP_DEV_FB_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
  