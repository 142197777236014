import {
    GET_NEW_TASKS,
    GET_NEW_TASKS_SUCCESS,
    GET_NEW_TASKS_ERROR,
    LOAD_MORE_NEW_TASKS,
    LOAD_MORE_NEW_TASKS_SUCCESS,
    LOAD_MORE_NEW_TASKS_ERROR,
    GET_INPROGRESS_TASKS,
    GET_INPROGRESS_TASKS_SUCCESS,
    GET_INPROGRESS_TASKS_ERROR,
    LOAD_MORE_INPROGRESS_TASKS,
    LOAD_MORE_INPROGRESS_TASKS_SUCCESS,
    LOAD_MORE_INPROGRESS_TASKS_ERROR,
    GET_ARCHIVED_TASKS,
    GET_ARCHIVED_TASKS_SUCCESS,
    GET_ARCHIVED_TASKS_ERROR,
    LOAD_MORE_ARCHIVED_TASKS,
    LOAD_MORE_ARCHIVED_TASKS_SUCCESS,
    LOAD_MORE_ARCHIVED_TASKS_ERROR,
    GET_ALL_TASKS,
    GET_ALL_TASKS_SUCCESS,
    GET_ALL_TASKS_ERROR,
    LOAD_MORE_TASKS,
    LOAD_MORE_TASKS_SUCCESS,
    LOAD_MORE_TASKS_ERROR,
    CLEAR_NEW_TASKS_COUNT
} from '../actions/types'


const initState = {
    isGettingTasks : false,
    isLoadingMoreTasks : false,
    typeFilter : "all",
    tasks : [],
    tasksTotalRecord : 0,
    tasksFilteredRecord : 0,
    tasksCurrentPage : 0,
    tasksPageCount : 0,
    newTasks : [],
    newTasksRef : [],
    newTasksLastRef : null,
    taskLastRef : null,
    newTaskCount : 0,
    inProgressTasks : [],
    inProgressTasksRef : [],
    inProgressTasksLastRef : null,
    inProgressTaskCount : 0,
    archivedTasks : [],
    archivedTasksRef : [],
    archivedTasksLastRef : []
}

const inProgressStat = [
    "inProgress",
    "forReview"
]

const taskReducer = ( state = initState , action ) => {
    switch(action.type){
        case GET_ALL_TASKS:
            return {
                ...state,
                isGettingTasks : true
            }
        case GET_ALL_TASKS_SUCCESS:
            return {
                ...state,
                isGettingTasks : false,
                tasks : Object.keys(action.payload.data).length!==0 ?  [...action.payload.data.hits] : [],
                tasksTotalRecord : Object.keys(action.payload.data).length!==0 ? action.payload.data.nbHits : 0,
                tasksFilteredRecord : Object.keys(action.payload.data).length!== 0 ? action.payload.data.hits.length : 0,
                tasksCurrentPage : Object.keys(action.payload.data).length!==0 ? action.payload.data.page : 0,
                tasksPageCount : Object.keys(action.payload.data).length!==0 ? action.payload.data.nbPages : 0
            }
        case LOAD_MORE_TASKS:
            return{
                ...state,
                isLoadingMoreTasks : true
            }
        case LOAD_MORE_TASKS_SUCCESS:
            return {
                ...state,
                isLoadingMoreTasks : false,
                tasks : Object.keys(action.payload.data).length!==0 ? [...state.tasks, ...action.payload.data.hits] : [...state.tasks],
                asksTotalRecord : Object.keys(action.payload.data).length!==0 ? action.payload.data.nbHits : 0,
                tasksFilteredRecord : Object.keys(action.payload.data).length!== 0 ? action.payload.data.hits.length : 0,
                tasksCurrentPage : Object.keys(action.payload.data).length!==0 ? action.payload.data.page : 0,
                tasksPageCount : Object.keys(action.payload.data).length!==0 ? action.payload.data.nbPages : 0
            }
        case "FILTER_TASKS":
            return {
                ...state,
                newTasks : [],
                newTasksRef : [],
                inProgressTasks : [],
                inProgressTasksRef : [],
                taskLastRef : null,
                typeFilter : action.payload.type
            }
        case GET_NEW_TASKS:
            return {
                ...state,
                isGettingTasks : true,
                newTaskCount : 0
            }
        case GET_NEW_TASKS_SUCCESS:
           
            return {
                ...state,
                newTasks :  [...action.payload.tasks],
                newTasksRef : [...action.payload.tasksRef],
                newTasksLastRef : action.payload.tasksRef.length===0 ? state.newTasksRef[state.newTasksRef.length-1] : action.payload.tasksRef[action.payload.tasksRef.length-1],
                isGettingTasks : false
            }
        case GET_NEW_TASKS_ERROR:
            return{
                ...state,
                isGettingTasks : false
            }
        case LOAD_MORE_NEW_TASKS:
            return{
                ...state,
                isLoadingMoreTasks : true
            }
        case LOAD_MORE_NEW_TASKS_SUCCESS:
            return{
                ...state,
                isLoadingMoreTasks : false,
                newTasks :  [...state.newTasks, ...action.payload.tasks],
                newTasksRef : [...state.newTasksRef, ...action.payload.tasksRef],
                newTasksLastRef : action.payload.tasksRef.length===0 ? state.newTasksRef[state.newTasksRef.length-1] : action.payload.tasksRef[action.payload.tasksRef.length-1],
            }
        case LOAD_MORE_NEW_TASKS_ERROR:
            return{
                ...state,
                isLoadingMoreTasks : false
            }
        case GET_INPROGRESS_TASKS:
            return {
                ...state,
                isGettingTasks : true
            }
        case GET_INPROGRESS_TASKS_SUCCESS:
            return {
                ...state,
                inProgressTasks :  [ ...action.payload.tasks],
                inProgressTasksRef : [ ...action.payload.tasksRef],
                inProgressTasksLastRef : action.payload.tasksRef.length===0 ? state.inProgressTasksRef[state.inProgressTasksRef.length-1] : action.payload.tasksRef[action.payload.tasksRef.length-1],
                isGettingTasks : false
            }
        case GET_INPROGRESS_TASKS_ERROR:
            return{
                ...state,
                isGettingTasks : false
            }
        case LOAD_MORE_INPROGRESS_TASKS:
            return{
                ...state,
                isLoadingMoreTasks : true
            }
        case LOAD_MORE_INPROGRESS_TASKS_SUCCESS:
            return{
                ...state,
                isLoadingMoreTasks : false,
                inProgressTasks :  [...state.inProgressTasks, ...action.payload.tasks],
                inProgressTasksRef : [...state.inProgressTasksRef, ...action.payload.tasksRef],
                inProgressTasksLastRef : action.payload.tasksRef.length===0 ? state.inProgressTasksRef[state.inProgressTasksRef.length-1] : action.payload.tasksRef[action.payload.tasksRef.length-1],
            }
        case LOAD_MORE_INPROGRESS_TASKS_ERROR:
            return{
                ...state,
                isLoadingMoreTasks : false
            }
        case "RELOAD_NEW_TASKS":

            /**
             * This case is for checking newly assigned tasks
             * to the logged in translator
             */
            let newData = action.payload.task;
            let newCount = state.newTaskCount;

            let taskIndex = state.newTasks.findIndex( task => task.uid===newData.uid )
            if(taskIndex===-1){
                    
                // Check if the task is not yet accepted by the translator
                // so we add to the 
                if(newData.status==="new" && (newData.paymentStatus==="fullyPaid" || newData.paymentStatus==="prepaid")){
                    if(state.newTasks.length%3==0){
                        state.newTasks.pop();
                    }
                    state.newTasks.unshift(action.payload.task)
                    if(state.newTasks.length%3==0){
                        state.newTasksRef.pop();
                    }
                    state.newTasksRef.unshift(action.payload.taskRef)
                    newCount = newCount + 1
                }

            }else{
                // Check if the tasks is removed/archived by the customer
                if(newData.isDeleted || newData.isArchived || newData.status!=="new"){
                    state.newTasks.splice(taskIndex, 1);
                    state.newTasksRef.splice(taskIndex, 1);
                    newCount = newCount - 1
                }else{
                    state.newTasks[taskIndex] = newData
                }
            }

            return {
                ...state,
                typeFilter : "all",
                newTasks : [...state.newTasks],
                newTasksRef : [...state.newTasksRef],
                newTaskCount : newCount,
                newTasksLastRef : state.newTasksRef[state.newTasksRef.length-1],
                isGettingTasks : false
            }
        case "RELOAD_NEW_TASK":

            let updatedCount = state.newTaskCount;
            let updatedData = action.payload.task;
            let updatedTaskIndex = state.newTasks.findIndex( (data) => data.uid===updatedData.uid )
           
            // Check if the tasks is removed/archived by the customer
            if(updatedData.isDeleted || updatedData.isArchived || updatedData.status!=="new"){
                state.newTasks.splice(updatedTaskIndex, 1);
                state.newTasksRef.splice(updatedTaskIndex, 1);
                updatedCount = updatedCount - 1
               
            }else{
                if(updatedTaskIndex===-1){
                    if(updatedData.status==="new" && (updatedData.paymentStatus==="fullyPaid" || updatedData.paymentStatus==="prepaid")){
                        
                        if(state.newTasks.length%3==0){
                            state.newTasks.pop();
                        }
                        state.newTasks.unshift(updatedData)
                        
                        if(state.newTasks.length%3==0){
                            state.newTasksRef.pop();
                        }
                        state.newTasksRef.unshift(action.payload.taskRef)
                        updatedCount = updatedCount + 1
                    }
                }else{
                    state.newTasks[updatedTaskIndex] = updatedData
                }

                
            }

            return{
                ...state,
                newTasks : [...state.newTasks],
                newTasksRef : [...state.newTasksRef],
                taskLastRef : state.newTasksRef[state.newTasksRef.length-1],
                newTaskCount : updatedCount,
                isGettingTasks : false
            }
        case "RELOAD_INPROGRESS_TASK":

            let updatedInProgressData = action.payload.task;
            let updatedInProgressTaskIndex = state.inProgressTasks.findIndex( (data) => data.uid===updatedInProgressData.uid )
            
            // Check if the tasks is removed/archived by the customer
            if(updatedInProgressData.isDeleted || updatedInProgressData.isArchived || inProgressStat.indexOf(updatedInProgressData.status)==-1){
                state.inProgressTasks.splice(updatedInProgressTaskIndex, 1);
                state.inProgressTasksRef.splice(updatedInProgressTaskIndex, 1);
            }else{
                if(updatedInProgressTaskIndex===-1){
                    if(updatedInProgressData.status==="inProgress" || updatedInProgressData.status==="forReview"){
                        
                        if(state.inProgressTasks.length%3==0){
                            state.inProgressTasks.pop();
                        }
                        state.inProgressTasks.unshift(updatedInProgressData)
                        
                        if(state.inProgressTasks.length%3==0){
                            state.inProgressTasksRef.pop();
                        }
                        state.inProgressTasksRef.unshift(action.payload.taskRef)
                    }
                }else{
                    state.inProgressTasks[updatedInProgressTaskIndex] = updatedInProgressData
                }

                
            }

            return{
                ...state,
                inProgressTasks : [...state.inProgressTasks],
                inProgressTasksRef : [...state.inProgressTasksRef],
                inProgressTasksLastRef : state.inProgressTasksRef[state.inProgressTasksRef.length-1],
                isGettingTasks : false
            }
        case "CLEAR_LAST_REF":
            return{
                ...state,
                newTasksLastRef : null,
                inProgressTasksLastRef : null,
                isGettingTasks : false
            }
        case GET_ARCHIVED_TASKS:
            return {
                ...state,
                isGettingTasks : true
            }
        case GET_ARCHIVED_TASKS_SUCCESS:
            return {
                ...state,
                isGettingTasks : false,
                archivedTasks : [...action.payload.tasks],
                archivedTasksRef : [...action.payload.tasksRef],
                archivedTasksLastRef : action.payload.tasksRef.length===0 ? null : action.payload.tasksRef[action.payload.tasksRef.length-1],
            }
        case LOAD_MORE_ARCHIVED_TASKS:
            return {
                ...state,
                isLoadingMoreTasks : true
            }
        case LOAD_MORE_ARCHIVED_TASKS_SUCCESS:
            return {
                ...state,
                isLoadingMoreTasks : false,
                archivedTasks :  [...state.archivedTasks, ...action.payload.tasks],
                archivedTasksRef : [...state.archivedTasksRef, ...action.payload.tasksRef],
                archivedTasksLastRef : action.payload.tasksRef.length===0 ? state.archivedTasksRef[state.archivedTasksRef.length-1] : action.payload.tasksRef[action.payload.tasksRef.length-1],
            }
        case CLEAR_NEW_TASKS_COUNT:
            return {
                ...state,
                newTaskCount : 0
            }
        default:
            return state;
    }
}

export default taskReducer;