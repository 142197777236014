import React, { Component } from 'react'
import _ from 'lodash/'

export class SearchInput extends Component{
    
    searchTasks = _.debounce( async (e, keyword) => {

        this.props.searchTasks(keyword);

    }, 800)


    render (){

        return (
            <div className="tasks-search">
                <input onKeyUp={(e) => this.searchTasks(e, e.target.value)} placeholder="Search" className="tasks-search-input"/>
                <img src={require('../../../images/icon-search.png')}/>
            </div>
        )

    }

}

export default SearchInput