import React, { Component } from 'react'
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import {
    addModal,
    popModal
} from '../../../actions/appAction';

export class EscalateTaskModal extends Component {

    constructor(){
        super();
        this.state = {
            escalationReason : "",
            escalationLetter : "",
            showOtherInput : false,
            isReasonEmpty : false,
            isLetterEmpty : false,
            modalZ : 1050
        }
    }

    componentDidMount(){
       
        const { isOpened } = this.props;
        
        if(isOpened){
            $('#escalateTaskModal').modal({backdrop:'static', keyboard:false});
            $('#escalateTaskModal').modal('show');
            this.props.addModal('escalate-task-modal')
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("et-bd");
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="escalate-task-modal");
            if(modalInstance!==undefined){
                $('.et-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }


    componentWillUnmount(){
        $('#escalateTaskModal').modal('hide');
        this.props.popModal()
    }

    selectReason = (e) => {
        let text = $(e.target).parent('.checkbox-container').text();

        if(text==="Other"){
            this.setState({
                showOtherInput : true,
                escalationReason : ""
            })
        }else{
            this.setState({
                escalationReason : text,
                showOtherInput : false,
                isReasonEmpty : false
            })
        }

        
    }

    onInputChange = (e) => {

        if(e.target.name==="escalationLetter"){
            if(e.target.value.trim()!==""){
                this.setState({
                    isLetterEmpty : false
                })
            }
        }

        if(e.target.name==="escalationReason"){
            if(e.target.value.trim()!==""){
                this.setState({
                    isReasonEmpty : false
                })
            }
        }

        this.setState({
            [e.target.name] : e.target.value
        })

    }

    escalateTask = () => {

        const { escalationReason, escalationLetter } = this.state;

        if(escalationReason.trim()===""){
            this.setState({
                isReasonEmpty : true
            })
        }else if(escalationLetter.trim()===""){
            this.setState({
                isLetterEmpty : true
            })
        }else{
            this.props.escalateTask({
                escalationReason : escalationReason,
                escalationLetter : escalationLetter
            })
        }

    }

    render(){
        const { escalationReason, showOtherInput, isReasonEmpty, isLetterEmpty, modalZ } = this.state;
        const { userProfile } = this.props;

        return (
            <div style={{ zIndex : `${modalZ}` }} className="escalate-task-modal modal fade modal-opaque" id="escalateTaskModal" tabIndex="-1" role="dialog" aria-labelledby="helpTaskModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button onClick={this.props.closeEscalateTaskModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span style={{ fontWeight: "300", fontSize: "35px" }} aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="text-header">Problem? Get Help!</p>
                            <p style={{ fontWeight : "bold", textAlign : "left", marginLeft : "30px", marginTop : "50px" }}>Please indicate the reason for appeal</p>
                            
                            <div className="form-group">
                                <ul className="reason-list">
                                    <li>
                                        <label className="checkbox-container">The problem with the translator
                                            <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkbox-container">Technical problem
                                            <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkbox-container">File transfer problem
                                            <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkbox-container">Other
                                            <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div className="form-group" style ={{ textAlign : "left", paddingLeft : "30px" }}>
                            { showOtherInput && <input style={{ width: "90%" }} onChange={this.onInputChange} id="escalationReason" name="escalationReason" style={{ width: "100%" }}  className={`custom-input ${ isReasonEmpty ? "error-input" : ""}`} type="text" placeholder="Please write a reason"/> }  
                            <div><p style={{ marginTop : "10px" }}>Write a letter</p><input style={{ width: "90%" }} onChange={this.onInputChange} id="escalationLetter" name="escalationLetter" style={{ width: "100%" }}  className={`custom-input ${ isReasonEmpty ? "error-input" : ""}`} type="text" placeholder="Please write a reason"/></div>
                            </div>
                            <div className="text-center">
                                <button onClick={this.escalateTask} style={{ width: "192px" , border :"none", background : "#22C9C7", borderRadius : "8px", padding: "16px 20px", fontSize : "16px", fontWeight : "bold", textTransform : "uppercase", textAlign : "center", color : "#FAFAFA", marginTop : "30px" }}>SEND APPEAL</button>    
                            </div>                            
                        </div>
                    
                    </div>
                </div>
            </div>
            
        )

    }

}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(EscalateTaskModal)