import React, { Component } from 'react'
import ReactDOM from 'react-dom';

import Item from './Item';

export class List extends Component {

    render(){

        const { isLoadingMoreTasks, tasks } = this.props;
        return (
            
            <div className="" ref="archivedTaskList">
                {
                    tasks.map( (data, i) => <Item key={data.uid} task={data}/> )
                }       
                <div className="text-center">
                {
                    isLoadingMoreTasks ? <div style={{ paddingTop : "15px" }}>
                        <div class="custom-get-more-spinner spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div> : <div className="text-center" style={{ marginTop : "15px" }}>
                        <button onClick={this.props.loadMoreArchivedTasks} className="load-more-button">LOAD MORE</button>
                    </div>
                }
                </div>        
            </div>
            
           

        )

    }

}

export default List;
