import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'
import { watchInProgressTasks, loadMoreInProgressTasks, getInProgressTasks, filterTasks, clearLastRef, updateTask } from '../../../actions/taskAction'
import { setUserPresence } from '../../../actions/authAction'
import { updateTranslatorAvailability } from '../../../actions/userAction'
import { setEscalation } from '../../../actions/escalationAction';
import InProgressTaskList from './List';
import SpinnerModal from '../../Spinner/SpinnerModal';
import DeclineTaskModal from '../Modals/DeclineTask';
import EscalateTaskModal from '../Modals/EscalateTask';
import '../task.css'

import CompleteTaskModal from '../Modals/CompleteTask';

export class Index extends Component {

    constructor(){
        super();
        this.state = {
            filterType : "all",
            loadMore : false,
            isEmpty : false,
            isProcessing : false,
            isCompleteTaskModalActive : false,
            isDeclineTaskModalActive : false,
            isEscalateTaskModalActive : false,
            selectedTask : {},
            isAuthenticated : false
        }
    }
    
    componentDidUpdate(prevProps){

        if(prevProps.typeFilter!==this.props.typeFilter){
            this.loadInProgressTasks();
        }

        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true
            })
        }

    }

    loadInProgressTasks = () => {
        this.props.getInProgressTasks();
    }

    loadMoreInProgressTasks = () => {
        this.props.loadMoreInProgressTasks();
    }

    componentWillUnmount(){
        this.unsubscribeWatchTaskListener();
    }

    async componentDidMount(){

        const { auth, userProfile } = this.props;

        if(auth.uid){
            this.props.setUserPresence({
                uid: auth.uid
            });
            
            await this.props.filterTasks("all");
            this.loadInProgressTasks();
            this.unsubscribeWatchTaskListener = this.props.watchInProgressTasks();
        }

        if(userProfile.hasOwnProperty('fullName')){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    filterByType = (type) => {
        this.setState({
            filterType : type
        }, async () => {
            const { filterType } = this.state;
            await this.props.filterTasks(filterType);
        })
    }

    openCompleteTaskModal = (task) => {
        this.setState({
            isCompleteTaskModalActive : true,
            selectedTask : {...task}
        })
    }

    closeCompleteTaskModal = () => {
        this.setState({
            isCompleteTaskModalActive : false,
            selectedTask : {}
        })
    }

    completeTask = async () => {
        const { selectedTask } = this.state;
        
        let data = {
            uid : selectedTask.uid,
            status : "completed",
            assignedTranslator : selectedTask.assignedTranslator
        }

        this.setState( {
            isProcessing : true
        }, async () => {
            await this.props.updateTask(data);
            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isCompleteTaskModalActive : false
                }, () => {
                    this.loadInProgressTasks();
                })
            }, 500)

            setTimeout( () => {
                this.props.updateTranslatorAvailability({
                    translator : selectedTask.assignedTranslator,
                    taskUid : selectedTask.uid
                })
            }, 2000)
        })
    }

    openDeclineTaskModal = (task) => {
        this.setState({
            isDeclineTaskModalActive : true,
            selectedTask : {...task}
        })
    }

    closeDeclineTaskModal = () => {
        this.setState({
            isDeclineTaskModalActive : false,
            selectedTask : {}
        })
    }

    declineTask = async (declineData) => {
        const { selectedTask } = this.state;
        const { auth } = this.props;

        let data = {
            uid : selectedTask.uid,
            isArchived : true,
            status : "declined",
            deleteAssignedTranslatorField : true,
            declineReason : declineData.declineReason
        }

        this.setState( {
            isProcessing : true
        }, async () => {
            await this.props.updateTask(data);
            
            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isDeclineTaskModalActive : false
                }, () => {
                    this.loadInProgressTasks();
                })
            }, 500)
         
        })
    }

    openEscalateTaskModal = (task) => {
        this.setState({
            isEscalateTaskModalActive : true,
            selectedTask : {...task}
        })
    }

    closeEscalateTaskModal = () => {
        this.setState({
            isEscalateTaskModalActive : false,
            selectedTask : {}
        })
    }

    escalateTask = async (escalateData) => {
        const { selectedTask } = this.state;
        const { auth } = this.props;

        let data = {
            taskUid : selectedTask.uid,
            escalationLetter : escalateData.escalationLetter,
            escalationReason : escalateData.escalationReason,
            operator : "OPERATOR"
        }

        this.setState( {
            isProcessing : true
        }, async () => {
            await this.props.setEscalation(data);
            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isEscalateTaskModalActive : false
                }, () => {
                    this.loadInProgressTasks();
                })
            }, 500)
        })
    }

    openChat = (userType, recipient, task) => {

        this.props.history.push({
            pathname : "/chat",
            state : {
                tab : userType,
                task : task,
                recipient : recipient
            }
        })

    }

    render() {

        const { isLoggingOut, isLoadingMoreTasks, auth, userProfile, typeFilter, isGettingTasks, tasks } = this.props;
        const { isAuthenticated, isProcessing, isEscalateTaskModalActive, isDeclineTaskModalActive, isCompleteTaskModalActive, selectedTask } = this.state;

        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }
        
        if(!auth.uid && !auth.emailVerified){
            return <Redirect to="/" />
        }else{
            if(isAuthenticated){
                if(userProfile.type!=="accountManager"){
                    return "NOT ALLOWED";
                }
            }
        }

        return (
            <div className="div-holder">
                {
                    isAuthenticated &&
                    <div className="div-holder">
                        <div style={{ display : "flex"}}>
                            <div style={{ flex : "1", textAlign : "left" }}>
                                <p className="module-header">Ongoing tasks</p>
                            </div>
                            <div style={{ flex : "1", textAlign : "right" }}>
                                <ul className="type-filters">
                                    <li style={{ paddingTop: "10px",  marginRight: "15px", width : "100px" }}><span style={{ fontSize : "12px", color : "#414042"}}>Filter by type:</span></li>
                                    <li onClick={ () => this.filterByType('all')} style={{ marginRight: "15px" }}><span style={ typeFilter==="all" ? { background : '#7AB7B6', color : "white"} : {} } className="type-filter">All</span></li>
                                    <li onClick={ () => this.filterByType('presentation')} style={{ marginRight: "15px" }}><span style={ typeFilter==="presentation" ? { background : '#7AB7B6', color : "white"} : {} } className="type-filter">Presentation</span></li>
                                    <li onClick={ () => this.filterByType('essay')} style={{ marginRight: "15px" }}><span style={ typeFilter==="essay" ? { background : '#7AB7B6', color : "white"} : {} } className="type-filter">Essay</span></li>
                                    <li onClick={ () => this.filterByType('dissertation')} style={{ marginRight: "15px" }}><span style={ typeFilter==="dissertation" ? { background : '#7AB7B6', color : "white"} : {} } className="type-filter">Dissertation</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="">
                            {
                               isGettingTasks ?
                               <div className="d-flex justify-content-center">
                                   <div style={{ height: "5rem", width: "5rem" }} className="custom-spinner-border spinner-border" role="status">
                                       <span className="sr-only">Loading...</span>
                                   </div>
                               </div>
                               :
                                tasks.length!==0 ?  
                                <InProgressTaskList  
                                    openChat={this.openChat}
                                    openCompleteTaskModal={this.openCompleteTaskModal}
                                    openDeclineTaskModal={this.openDeclineTaskModal}
                                    openEscalateTaskModal={this.openEscalateTaskModal}
                                    loadMoreInProgressTasks={this.loadMoreInProgressTasks} 
                                    isLoadingMoreTasks={isLoadingMoreTasks}
                                    tasks={tasks}
                                    /> : 
                                ""
                            }
                        </div>
                        {
                            isProcessing && 
                            <SpinnerModal
                                isOpened={isProcessing}
                            />
                        }
                        {
                            isLoggingOut && 
                            <SpinnerModal
                                isOpened={isLoggingOut}
                            />
                        }
                        {
                            isCompleteTaskModalActive && <CompleteTaskModal completeTask={this.completeTask} closeCompleteTaskModal={this.closeCompleteTaskModal} task={selectedTask} isOpened={isCompleteTaskModalActive}/>
                        }
                        {
                            isEscalateTaskModalActive && <EscalateTaskModal userProfile={userProfile} escalateTask={this.escalateTask} closeEscalateTaskModal={this.closeEscalateTaskModal} isOpened={isEscalateTaskModalActive}/>
                        }
                        {
                            isDeclineTaskModalActive && <DeclineTaskModal declineTask={this.declineTask} closeDeclineTaskModal={this.closeDeclineTaskModal} isOpened={isDeclineTaskModalActive}/>
                        }
                    </div>
                }
                
            </div>
        )   
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        tasks : state.task.inProgressTasks,
        isGettingTasks : state.task.isGettingTasks,
        isLoadingMoreTasks : state.task.isLoadingMoreTasks,
        typeFilter : state.task.typeFilter,
        isLoggingOut : state.auth.isLoggingOut
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { updateTranslatorAvailability, setUserPresence, watchInProgressTasks, setEscalation, loadMoreInProgressTasks, getInProgressTasks, filterTasks, clearLastRef, updateTask })
)(Index)

