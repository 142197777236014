import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import $ from 'jquery';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import {
    addModal,
    popModal
} from '../../actions/appAction'

export class TranslatorNotification extends Component {
    
    constructor(){
        super();
        this.state = {
            modalZ : 1050
        }
    }

    componentDidMount(){
       
        const { isOpened,id } = this.props;
        
        if(isOpened){
           $(`#translatorNotificationModal-${id}`).modal({backdrop:'static', keyboard:false});
           $(`#translatorNotificationModal-${id}`).modal('show');
            if(this.props.addModal){
                this.props.addModal(`translator-notification-modal-${id}`)
            }
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass(`tn-${id}-bd`);
        }

    }

    componentDidUpdate(prevProps){
        const { id } = this.props;
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class===`translator-notification-modal-${id}`);
            if(modalInstance!==undefined){
                $(`tn-${id}-bd`).css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }

    componentWillUnmount(){
        const { id } = this.props;
        $(`#translatorNotificationModal-${id}`).modal('hide');
        if(this.props.popModal){
            this.props.popModal()
        }
        $('body').removeClass('modal-open');
    }

    render(){

        const { opened, header, subHeader, confirmText, link, id } = this.props;
        const { modalZ } = this.state;

        return (
            <div style={{ zIndex : `${modalZ}` }} className="translator-notification-modal modal fade" id={`translatorNotificationModal-${id}`} tabIndex="-1" role="dialog" aria-labelledby="translatorNotificationModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <p className="text-header">{ header }</p>
                            <p className="text-sub-header">{ subHeader }</p>
                            <button id="modal-closer" style={{ cursor : "pointer", border : "none" }} onClick={ () => this.props.closeMessageModal(id) } className="ok">{ confirmText }</button>
                        </div>
                       
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(TranslatorNotification)