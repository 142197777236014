import React, { Component } from 'react'
import ConversationHeader from './ConversationHeader'
import ConversationBody from './ConversationBody'
import ConversationFooter from './ConversationFooter'

export class Messages extends Component {

    droppableRef = React.createRef();

    constructor(){
        super();
        this.state = {
            dragging : false,
            droppedFiles : []
        }
    }

    componentDidMount() {
        this.dragCounter = 0
        let div = this.droppableRef.current
        div.addEventListener('dragenter', this.handleDragIn)
        div.addEventListener('dragleave', this.handleDragOut)
        div.addEventListener('dragover', this.handleDrag)
        div.addEventListener('drop', this.handleDrop)
    }
    
    componentWillUnmount() {
        let div = this.droppableRef.current
        div.removeEventListener('dragenter', this.handleDragIn)
        div.removeEventListener('dragleave', this.handleDragOut)
        div.removeEventListener('dragover', this.handleDrag)
        div.removeEventListener('drop', this.handleDrop)
    }

    handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({dragging: true})
        }
    }

    handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter--
        if (this.dragCounter > 0) return
        this.setState({dragging: false})
    }

    handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({drag: false})
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            // this.props.handleDrop(e.dataTransfer.files)

            let files = [];
            for(let x=0; x < e.dataTransfer.files.length; x++){
                let file = e.dataTransfer.files[x]
                file.from = "drop"
                files.push(file)
            }

            this.setState({

                droppedFiles : [...this.state.droppedFiles, ...files]
            }, () => {
                e.dataTransfer.clearData()
                this.dragCounter = 0    
            })
        }
    }

    clearDroppedFiles = () => {
        this.setState({
            droppedFiles : []
        })
    }

    removeToDroppedFileList = (key) => {
        const { droppedFiles } = this.state;
        let newFiles = droppedFiles;

        newFiles.splice(key,1);
        this.setState({
            droppedFiles : [...newFiles]
        })

    }

    updateUserOpenedDate = () => {

        const { activeConversation, userProfile } = this.props;
        this.props.updateConversationLastOpenedDate({ uid : activeConversation.uid });

    }


    render(){

        const { activeConversation, userProfile, isGettingMessages, lastOpenedDate } = this.props;
        const { droppedFiles } = this.state;

        return (
            <div ref={this.droppableRef} className="conversation-box">
                <ConversationHeader 
                    userProfile={userProfile}
                    activeConversation={activeConversation}
                    openPauseTaskModal={this.props.openPauseTaskModal}
                    openStopTaskModal={this.props.openStopTaskModal}
                    openEscalateTaskModal={this.props.openEscalateTaskModal}
                    openCompleteTaskModal={this.props.openCompleteTaskModal}
                    />
                <ConversationBody lastOpenedDate={lastOpenedDate} loadConversationMessages={this.props.loadConversationMessages} activeConversation={activeConversation} />
                <ConversationFooter updateUserOpenedDate={this.updateUserOpenedDate} removeToDroppedFileList={this.removeToDroppedFileList} clearDroppedFiles={this.clearDroppedFiles} droppedFiles={droppedFiles} activeConversation={activeConversation} sendMessage={this.props.sendMessage} />
            </div>
        )

    }

}

export default Messages