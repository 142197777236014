import React, { Component } from 'react'
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import {
    addModal,
    popModal
} from '../../../../actions/appAction';

export class FireOperator extends Component {

    constructor(){
        super();
        this.state = {
            disabledReason : "",
            showOtherInput : false,
            isReasonEmpty : false,
            modalZ : 1050
        }
    }

    componentDidMount(){
        
        const { isOpened } = this.props;
        
        if(isOpened){
            $('#fireOperatorModal').modal({backdrop:'static', keyboard:false});
            $('#fireOperatorModal').modal('show');
            this.props.addModal('fire-operator-modal')
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("fo-bd");
        }


    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="fire-operator-modal");
            if(modalInstance!==undefined){
                $('.fo-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }


    componentWillUnmount(){
        $('#fireOperatorModal').modal('hide');
        this.props.popModal()
    }

    selectReason = (e) => {
        let text = $(e.target).parent('.checkbox-container').text();

        if(text==="Other"){
            this.setState({
                showOtherInput : true,
                disabledReason : ""
            })
        }else{
            this.setState({
                disabledReason : text,
                showOtherInput : false,
                isReasonEmpty : false
            })
        }
    }

    onInputChange = (e) => {

        if(e.target.value.trim()!==""){
            this.setState({
                isReasonEmpty : false
            })
        }

        this.setState({
            disabledReason : e.target.value
        })

    }

    fireOperator = () => {

        const { disabledReason } = this.state;

        if(disabledReason===""){
            this.setState({
                isReasonEmpty: true
            })
        }else{
            this.props.fireOperator({
                disabledReason : disabledReason
            })
        }

    }

    render(){
        const { showOtherInput, isReasonEmpty, modalZ } = this.state;
        
        return (
            <div style={{ zIndex : `${modalZ}` }}  className="fire-operator-modal modal fade modal-opaque" id="fireOperatorModal" tabIndex="-1" role="dialog" aria-labelledby="helpTaskModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button onClick={this.props.closeFireOperatorModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span style={{ fontWeight: "300", fontSize: "70px" }} aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="text-header">Fire Operator</p>
                            <p style={{ fontWeight : "bold", textAlign : "left", marginLeft : "30px", marginTop : "50px" }}>Please select a reason for operator firing</p>
                            
                            <div className="form-group">
                                <ul className="reason-list">
                                    <li>
                                        <label className="checkbox-container">Work quality is too poor
                                            <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkbox-container">Too long to communicate
                                            <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkbox-container">Unavailable for a long time
                                            <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkbox-container">Other
                                            <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div className="form-group" style ={{ textAlign : "left", paddingLeft : "30px" }}>
                            { showOtherInput && <input style={{ width: "90%" }} onChange={this.onInputChange} id="other_reason" name="other_reason" style={{ width: "100%" }} className={`custom-input ${ isReasonEmpty ? "error-input" : ""}`} type="text" placeholder="Please write a reason"/> }  
                            </div>
                            <div className="text-center">
                                <button onClick={this.fireOperator} style={{ width: "50%" , border :"none", background : "#22C9C7", borderRadius : "8px", padding: "16px 20px", fontSize : "16px", fontWeight : "bold", textTransform : "uppercase", textAlign : "center", color : "#FAFAFA", marginTop : "30px" }}>FIRE OPERATOR</button>    
                            </div>                            
                        </div>
                    
                    </div>
                </div>
            </div>
            
        )

    }

}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(FireOperator)