import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoggedInLinks from './LoggedInLinks';
import {
    watchTranslatorNotification,
    removeNotification
} from '../../actions/notificationAction'
import {
    addModal,
    popModal
} from '../../actions/appAction'

import{
    TranslatorNotification
} from '../Modals/TranslatorNotification';
import './header.css';

export class Header extends Component {

    constructor(){
        super();
        this.state = {
            translatorNotifications : []
        }
    }

    componentDidMount(){
        const { auth } = this.props;

       
    }

    componentDidUpdate(prevProps){
        if(prevProps.notifications!==this.props.notifications){
        }
    }

    closeMessageModal = (uid) => {
        this.props.removeNotification(uid)
    }

    render() {

        const { notifications } = this.props;
        const { auth, userProfile } = this.props;
        let links = "";

        if(userProfile.type==="superuser"){
            links = auth.uid ? <LoggedInLinks userType={userProfile.type}/> : ""
        }else{
            links = auth.uid && auth.emailVerified ? <LoggedInLinks userType={userProfile.type}/> : ""
        }
    
        return (
            <div>
                <nav className="navbar navbar-expand-lg fixed-top">
                    <img className="navbar-logo" src={ require('../../images/tarjemle-logo.png') } />
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="line"></span> 
                        <span className="line"></span> 
                        <span className="line"></span>
                    </button>
                    { links }
                </nav>
                {
                    notifications.map( (data,i ) => (
                        <TranslatorNotification
                            key={i}
                            id={data.uid}
                            isOpened={true}
                            header={data.body}
                            subHeader={JSON.parse(data.data).message}
                            confirmText={"OK"}
                            closeMessageModal={this.closeMessageModal}
                            addModal={this.props.addModal}
                            popModal={this.props.popModal}
                        />
                    ))
                }
               
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        notifications : state.notification.notifications
    }
}

export default connect(mapStateToProps, {
    watchTranslatorNotification,
    addModal,
    popModal,
    removeNotification
})(Header)
