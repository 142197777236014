import {
    GET_TASK_STATISTICS,
    GET_TASK_STATISTICS_SUCCESS,
    GET_TASK_STATISTICS_ERROR,

    GET_USER_STATISTICS,
    GET_USER_STATISTICS_SUCCESS,
    GET_USER_STATISTICS_ERROR,

    GET_REQUESTS_STATISTICS,
    GET_REQUESTS_STATISTICS_SUCCESS,
    GET_REQUESTS_STATISTICS_ERROR,

    GET_EARNINGS_STATISTICS,
    GET_EARNINGS_STATISTICS_SUCCESS,
    GET_EARNINGS_STATISTICS_ERROR,
    
    GET_TASK_STATUS_STATISTICS,
    GET_TASK_STATUS_STATISTICS_SUCCESS,
    GET_TASK_STATUS_STATISTICS_ERROR,
    GET_TASK_TYPE_STATISTICS,
    GET_TASK_TYPE_STATISTICS_SUCCESS,
    GET_TASK_TYPE_STATISTICS_ERROR,

    GET_TRANSLATOR_STATISTICS,
    GET_TRANSLATOR_STATISTICS_SUCCESS,
    GET_TRANSLATOR_STATISTICS_ERROR,
    GET_TRANSLATOR_COUNT_STATISTICS,
    GET_TRANSLATOR_COUNT_STATISTICS_SUCCESS,
    GET_TRANSLATOR_COUNT_STATISTICS_ERROR,

    SET_REPORT_DATE_FILTER
} from './types'

import moment from 'moment'
import momentRandom from 'moment-random'

export const setReportDateFilter = (data) => dispatch => {
    dispatch({
        type : SET_REPORT_DATE_FILTER,
        payload : {
            dateFrom : data.dateFrom,
            dateTo : data.dateTo
        }
    })
}

export const getTasksForStatistics = () => (dispatch, getState, { getFirebase, getFirestore }) => {

    dispatch({
        type : GET_TASK_STATISTICS
    })

    const firestore = getFirestore();
    const firebase = getFirebase();
    const reportState = getState().report;

    const reportDateFilter = reportState.reportDateFilter;

    let tasksRef = firestore.collection('tasks').
                    where('isDeleted','==',false)

    tasksRef = tasksRef.orderBy('dateAdded')

    if(reportDateFilter!==null){
        tasksRef = tasksRef.where('dateAdded','>=',moment(reportDateFilter.dateFrom).startOf('day').toDate()).where('dateAdded','<=',moment(reportDateFilter.dateTo).endOf('day').toDate())
    }

    tasksRef.get().then( querySnapshots => {

        let taskList = [];

        querySnapshots.forEach( doc => {
            taskList.push(doc.data())
        })

        dispatch({
            type : GET_TASK_STATISTICS_SUCCESS,
            payload : {
                taskList : taskList
            }
        })

    }).catch( error => {
        console.log(error)
        dispatch({
            type : GET_TASK_STATISTICS_ERROR
        })
    })
}

export const getUserStatistics = () => (dispatch, getState, { getFirebase, getFirestore }) => {

    dispatch({
        type : GET_USER_STATISTICS
    })

    const firestore = getFirestore();
    const firebase = getFirebase();
    const reportState = getState().report;

    const reportDateFilter = reportState.reportDateFilter;

    let usersRef = firestore.collection('users').
                    where('isDeleted','==',false)

    usersRef = usersRef.orderBy('dateRegistered')
    if(reportDateFilter!==null){
        usersRef = usersRef.where('dateRegistered','>=',moment(reportDateFilter.dateFrom).startOf('day').toDate()).where('dateRegistered','<=',moment(reportDateFilter.dateTo).endOf('day').toDate())
    }

    usersRef.get().then( querySnapshots => {

        let userList = [];

        querySnapshots.forEach( doc => {
            userList.push(doc.data())
        })
        
        dispatch({
            type : GET_USER_STATISTICS_SUCCESS,
            payload : {
                userList : userList
            }
        })

    }).catch( error => {
        console.log(error)
        dispatch({
            type : GET_USER_STATISTICS_ERROR
        })
    })    


}

export const getRequestStatistics = () => (dispatch, getState, { getFirebase, getFirestore }) => {

    dispatch({
        type : GET_REQUESTS_STATISTICS
    })

    const firestore = getFirestore();
    const firebase = getFirebase();
    const reportState = getState().report;

    const reportDateFilter = reportState.reportDateFilter;

    let escalationsRef = firestore.collection('escalations')

    escalationsRef = escalationsRef.orderBy('dateAdded')

    if(reportDateFilter!==null){
        escalationsRef = escalationsRef.where('dateAdded','>=',moment(reportDateFilter.dateFrom).startOf('day').toDate()).where('dateAdded','<=',moment(reportDateFilter.dateTo).endOf('day').toDate())
    }

    escalationsRef.get()
        .then( async (querySnapshots) => {

            let escalationList = [];
            let escalationRefList = [];

            for(let a=0; a<querySnapshots.docs.length; a++){
                let taskDetails;

                let taskRef = firestore.collection('tasks').doc(querySnapshots.docs[a].data().task);

                await taskRef.get()
                    .then( async (tQuerySnapshot) => {
                        taskDetails = tQuerySnapshot.data()
                        
                        let escalatorList = [];

                        Object.keys(querySnapshots.docs[a].data().escalatedBy).map( async (data) => {

                            let userRef = firestore.collection('users').doc(data)
                            
                            await userRef.get()
                                .then( (uQuerySnapshot) => {
                                    escalatorList.push({
                                        user : uQuerySnapshot.data(),
                                        escalationReason : querySnapshots.docs[a].data().escalationReason[data],
                                        escalationLetter : querySnapshots.docs[a].data().escalationLetter[data]
                                    })
                                })
                        })

                        escalationList.push({
                            escalation : querySnapshots.docs[a].data(),
                            task : taskDetails,
                            escalators : escalatorList
                        })

                        escalationRefList.push(querySnapshots.docs[a]);
                    })
            }
            
            dispatch({
                type : GET_REQUESTS_STATISTICS_SUCCESS,
                payload : {
                    escalations : escalationList,
                    escalationsRef : escalationRefList
                }
            })

        })


}

export const getEarningStatistics = () => (dispatch, getState, { getFirebase, getFirestore }) => {

    dispatch({
        type : GET_EARNINGS_STATISTICS
    })

    const firestore = getFirestore();
    const firebase = getFirebase();
    const reportState = getState().report;

    const reportDateFilter = reportState.reportDateFilter;

    let transactionsRef = firestore.collection('transactions')

    transactionsRef = transactionsRef.orderBy('dateAdded')

    if(reportDateFilter!==null){
        transactionsRef = transactionsRef.where('dateAdded','>=',moment(reportDateFilter.dateFrom).startOf('day').toDate()).where('dateAdded','<=',moment(reportDateFilter.dateTo).endOf('day').toDate())
    }

    transactionsRef.get().then( querySnapshots => {

        let earningList = [];

        querySnapshots.forEach( doc => {
            earningList.push(doc.data())
        })

        dispatch({
            type : GET_EARNINGS_STATISTICS_SUCCESS,
            payload : {
                earningList : earningList
            }
        })

    }).catch( error => {
        console.log(error)
        dispatch({
            type : GET_EARNINGS_STATISTICS_ERROR
        })
    })


}


