import React, { Component } from 'react'
import TranslatorItem from './Item'

export class List extends Component {

    constructor(){
        super();
    }

    render(){

        const { translators, task } = this.props;

        return (
            <div className="" ref="translatorRefList">
                {
                    translators.map( (data, i) => <TranslatorItem openConfirmationModal={this.props.openConfirmationModal} hireTranslator={this.props.hireTranslator} loadTranslators={this.props.loadTranslators} task={task} key={data.uid} translator={data}/> )
                }

                <div className="text-center">
                    <button onClick={this.props.loadMore} className="load-more-button">LOAD MORE</button>
                </div>
            </div>
        )

    }

}

export default List;