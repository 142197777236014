import React, { Component } from 'react'
import _ from 'lodash/'
import './chat.css'

export class SearchInput extends Component{
    
    searchConversations = _.debounce( async (keyword) => {
        
        this.props.searchConversations(keyword);

    }, 800)


    render (){

        return (
            <div className="chat-search">
                <input disabled={this.props.disabled} onKeyUp={(e) => this.searchConversations(e.target.value)} placeholder="Search" className="chat-search-input"/>
                <img src={require('../../images/icon-search.png')}/>
            </div>
        )

    }

}

export default SearchInput