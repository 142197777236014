import React, { Component } from 'react';
import $ from 'jquery';
import moment from 'moment'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import { 
    getAllTasks
} from '../../../actions/taskAction'
import {
    setUserPresence
} from '../../../actions/authAction'
import SpinnerModal from '../../Spinner/SpinnerModal'
import TaskDetailsModal from '../Modals/TaskDetails'

import './task-main-list.css'

import TaskList from './List';
import SearchInput from './SearchInput';
import Filter from './Filter';
import DateFilter from './DateFilter';

const renderStatus = (status) => {
    switch(status){
        case "In progress":
            return "inProgress";
        case "On review":
            return "forReview";
        case "Completed":
            return "completed";
        case "Paused":
            return "paused";
        case "Stopped":
            return "stopped";
        case "Declined":
            return "declined";
        default:
            return "new";
    }
}

const renderTaskType = (type) => {
    switch(type){
        case "All":
            return "all";
        case "Essay":
            return "essay";
        case "Dissertation":
            return "dissertation";
        default:
            return "presentation";
    }
}

const renderService = (service) => {
    switch(service){
        case "All":
            return "all";
        case "High priority task":
            return "diamond";
        case "Express service":
            return "express";
        default:
            return "certified"
    }
}

export class Index extends Component {

    constructor(){
        super();
        this.state = {
            isTaskDetailsModalActive : false,
            selectedTask : null,
            activeTab : "customer",
            isProcessing : false,
            isAuthenticated : false,
            search : ``,
            facetFilters : [],
            filters : "",
            statFilter : [
                "status:new",
                "status:inProgress",
                "status:forReview",
                "status:paused",
                "status:stopped",
                "status:declined",
                "status:completed"
            ],
            typeFilter :[
                "taskType:essay",
                "taskType:presentation",
                "taskType:dissertation"
            ],
            typeAll : true,
            servFilter :[
                "additionalServices:diamond",
                "additionalServices:express",
                "additionalServices:certified"
            ],
            servAll : true,
            dateFilter: [],
            dateTo : null,
            dateFrom : null,
            hitsPerPage : 10,
            openFilter : false,
            showLoadMore : true,
            isAuthenticated : false
        }
    }

    componentDidMount(){
        const { auth, userProfile } = this.props;
        
        if(auth.uid){

            this.props.setUserPresence({
                uid : auth.uid
            })

            const { search , facetFilters, servFilter, typeFilter, statFilter,dateFilter } = this.state;

            let newFacetFilters = [];
            servFilter.length!==0 && newFacetFilters.push(servFilter)
            typeFilter.length!==0 && newFacetFilters.push(typeFilter)
            statFilter.length!==0 && newFacetFilters.push(statFilter)
            dateFilter.length!==0 && newFacetFilters.push(dateFilter)

            this.setState({
                facetFilters : newFacetFilters
            }, () => {
                
                this.loadTasks({
                    search : search,
                    facetFilters : newFacetFilters
                });
            })
        }

        if(userProfile.hasOwnProperty('fullName')){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    loadTasks = (data) => {

        const { hitsPerPage } = this.state;

        let loadData = {
            ...data,
            sortBy : "dateAdded",
            hitsPerPage : hitsPerPage
        }

        this.props.getAllTasks(loadData);
    }

    componentDidUpdate(prevProps){
        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    openTaskDetailsModal = (task, activeTab = "customer") => {
        
        this.setState({
            isTaskDetailsModalActive : true,
            selectedTask : {...task},
            activeTab : activeTab
        })
    }

    closeTaskDetailsModal = () => {
        this.setState({
            isTaskDetailsModalActive : false,
            selectedTask : {}
        })
    }

    searchTasks = (data) => {

        const { tasksTotalRecord } = this.props;

        if(data.trim()===""){
            this.setState({
                showLoadMore : true,
                hitsPerPage : 10
            })
        }else{
            this.setState({
                showLoadMore : false,
                hitsPerPage : tasksTotalRecord
            })
        }


        this.setState({
            search : data            
        }, () => {

            const { search, facetFilters } = this.state;
            
            this.loadTasks({
                search : search,
                facetFilters : facetFilters
            });
            
        })
    }

    toggleFilter = () => {

        const { openFilter, servFilter, typeFilter, statFilter, facetFilters, dateFilter } = this.state;
  
        if(openFilter){
            
            let newFacetFilters = [];
            servFilter.length!==0 && newFacetFilters.push(servFilter)
            typeFilter.length!==0 && newFacetFilters.push(typeFilter)
            statFilter.length!==0 && newFacetFilters.push(statFilter)
            dateFilter.length!==0 && newFacetFilters.push(dateFilter)

            this.setState({
                openFilter : false,
                facetFilters : newFacetFilters
            }, () => {
                
                const { search, facetFilters, filters } = this.state;

                this.loadTasks({
                    search : search,
                    facetFilters : facetFilters,
                    filters: filters
                });

            })

        }else{
            this.setState({
                openFilter : true
            })
        }

    }

    selectFilter = (e) => {

        const { servFilter, typeFilter, statFilter } = this.state;

        let text = $(e.target).parent('.checkbox-container').text();
        let filterClass = $(e.target).parent('.checkbox-container').data('filter')

        if(filterClass==="service"){

            text = renderService(text);

            if(e.target.checked){
                if(text!=="all"){
                    servFilter.push(`additionalServices:${text}`);
                }else{
                    this.setState({
                        servAll : true
                    })
                    servFilter.push(`additionalServices:express`);
                    servFilter.push(`additionalServices:diamond`);
                    servFilter.push(`additionalServices:certified`);
                }
            }else{
                $(e.target).prop('checked',false)
                if(text!=="all"){
                    let i = servFilter.indexOf(`additionalServices:${text}`);
                    if(i!==-1){
                        servFilter.splice(i,1)
                    }
                }else{
                    this.setState({
                        servAll : false
                    })
                }
            }
            this.setState({
                servFilter : [...servFilter]
            })
            
        }

        if(filterClass==="task-type"){
            
            text = renderTaskType(text)

            if(e.target.checked){
                if(text!=="all"){
                    typeFilter.push(`taskType:${text}`);
                }else{
                    this.setState({
                        typeAll : true
                    })
                    typeFilter.push(`taskType:essay`);
                    typeFilter.push(`taskType:dissertation`);
                    typeFilter.push(`taskType:presentation`);                    
                }
            }else{
                $(e.target).prop('checked',false)
                if(text!=="all"){
                    let i = typeFilter.indexOf(`taskType:${text}`);
                    if(i!==-1){
                        typeFilter.splice(i,1)
                    }
                }else{
                    this.setState({
                        typeAll : false
                    })
                }
            }
            this.setState({
                typeFilter : [...typeFilter]
            })

        }

        if(filterClass==="status"){

            text = renderStatus(text)

            if(e.target.checked){
                statFilter.push(`status:${text}`);
            }else{
                $(e.target).prop('checked',false)
                let i = statFilter.indexOf(`status:${text}`);
                if(i!==-1){
                    statFilter.splice(i,1)
                }
            }
            this.setState({
                statFilter : [...statFilter]
            })
        }
    }

    setDateFilter = () => {
        
        const { dateFrom, dateTo } = this.state;

        if(dateFrom!==null && dateTo!==null){
            this.setState({
                filters : `dateAdded._seconds:${moment(dateFrom).startOf('day').unix()} TO ${moment(dateTo).endOf('day').unix()}`
            }, () => {

                const { search, facetFilters, filters } = this.state;

                this.loadTasks({
                    search : search,
                    facetFilters : facetFilters,
                    filters : filters
                });

            })
        }else{
            this.setState({
                filters : ""
            },  () => {
                const { search, facetFilters, filters } = this.state;

                this.loadTasks({
                    search : search,
                    facetFilters : facetFilters,
                    filters : filters
                });
            })
        }  

    
    }

    loadMore = () => {

        const { tasksCurrentPage, tasksPageCount } = this.props;
        const { search, facetFilters, filters } = this.state;

        let newPageNumber = tasksCurrentPage + 1;

        if(newPageNumber <= tasksPageCount ){
            this.loadTasks({
                search : search,
                facetFilters : facetFilters,
                page : newPageNumber,
                filters : filters
            })   
        }
    }

    onDateFromChange = (date) => {
        if(date!==undefined){
            this.setState({
                dateFrom : date
            }, () => {
                this.setDateFilter();
            })
        }
    }

    onDateToChange = (date) =>{
        if(date!==undefined){
            this.setState({
                dateTo : date
            }, () => {
                this.setDateFilter();
            })
        }
    }



    render(){

        const { isLoadingMoreTasks, isLoggingOut, auth, userProfile, isGettingTasks, tasksTotalRecord } = this.props;
        const { servAll, typeAll, dateTo, dateFrom, showLoadMore, isAuthenticated, selectedTask, isTaskDetailsModalActive, activeTab, openFilter, dateFilter, typeFilter, servFilter, statFilter } = this.state;

        const { tasks } = this.props;
        
        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }
        
        if(!auth.uid){
            return <Redirect to="/" />
        }else{
            if(isAuthenticated){
                if(userProfile.type!=="superuser"){
                    return "NOT ALLOWED";
                }
            }
        }

        return (
            <div className="div-holder">
                {
                    isAuthenticated &&
                    <div className="div-holder">
                        <div style={{ display : "flex"}}>
                            <div style={{ flex : "1", textAlign : "left" }}>
                                <p className="module-header">Tasks list</p>
                            </div>
                            <div style={{ display : "flex", float : "right" }}>
                                <SearchInput searchTasks={this.searchTasks} />
                                <DateFilter onDateToChange={this.onDateToChange} onDateFromChange={this.onDateFromChange} dateTo={dateTo} dateFrom={dateFrom} />
                                <div className="task-dropdown-filter">
                                    <button onClick={this.toggleFilter} className="filter-tasks">FILTER</button>
                                    {
                                        openFilter && <Filter 
                                            selectFilter={this.selectFilter} 
                                            servFilter={servFilter}
                                            typeFilter={typeFilter}
                                            statFilter={statFilter}
                                            typeAll={typeAll}
                                            servAll={servAll}
                                            toggleFilter={this.toggleFilter}
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            isGettingTasks ?
                            <div className="d-flex justify-content-center">
                                <div style={{ height: "5rem", width: "5rem" }} className="custom-spinner-border spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            tasksTotalRecord!== 0 ?
                            <TaskList 
                                openTaskDetailsModal={this.openTaskDetailsModal}
                                loadMore={this.loadMore}
                                isLoadingMoreTasks={isLoadingMoreTasks}
                                showLoadMore={showLoadMore}
                                tasks={tasks}
                            />
                            :
                            <div className="filter-no-translator">
                                <img src={require('../../../images/no-translator.png')}/>
                                <p className="filter-no-translator-text">There is not a single task that matches the conditions of <br/>the request, try changing the filters or dates</p>
                                <Link to="/"><button className="filter-no-translator-button">NOTIFY ACCOUNT MANAGER</button></Link>
                            </div>
                           
                        }
                        {
                            isTaskDetailsModalActive && 
                            <TaskDetailsModal 
                                activeTab={activeTab}
                                task={selectedTask}
                                closeTaskDetailsModal={this.closeTaskDetailsModal}
                                isOpened={isTaskDetailsModalActive}
                            />
                        }
                        {
                            isLoggingOut && 
                            <SpinnerModal
                                isOpened={isLoggingOut}
                            />
                        }
                    </div>
                }
                
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        isGettingTasks : state.task.isGettingTasks,
        isLoadingMoreTasks : state.task.isLoadingMoreTasks,
        tasks : state.task.tasks,
        tasksTotalRecord : state.task.tasksTotalRecord,
        tasksFilteredRecord : state.task.tasksFilteredRecord,
        tasksCurrentPage : state.task.tasksCurrentPage,
        tasksPageCount : state.task.tasksPageCount,
        isLoggingOut : state.auth.isLoggingOut
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, {   
        getAllTasks,
        setUserPresence
    })
)(Index)