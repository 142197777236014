import React, { Component } from 'react';
import { compose } from 'redux';
import $ from 'jquery';
import './index.css';
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Header from './components/Header/Header';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Index';
import NewTasks from './components/Tasks/New/Index';
import InProgressTasks from './components/Tasks/InProgress/Index';
import TranslatorsIndex from './components/Users/Translators/Index';
import CandidatesIndex from './components/Users/Candidates/Index';
import AccountManagersIndex from './components/Users/AccountManagers/Index';
import OperatorsIndex from './components/Users/Operators/Index';
import CustomersIndex from './components/Users/Customers/Index';
import ArchivedTasksIndex from './components/Tasks/Archived/Index';
import ChatIndex from './components/Chat/Index';
import TasksIndex from './components/Tasks/MainList/Index';
import EscalationIndex from './components/Tasks/Escalated/Index';
import ProfileIndex from './components/Profile/Index';
import InviteIndex from './components/Users/Invite/Index'
import InviteSignup from './components/Users/Invite/InviteSignup'
import CustomEmailVerification from './components/Users/Invite/CustomEmailVerification'
import AdminConfigIndex from './components/Config/Index';
import TestIndex from './components/Test/Index'
import {
  setUserPresence
} from './actions/authAction'

export class App extends Component {

  componentDidMount(){
    const { auth } = this.props;
    this.props.setUserPresence({
      uid : auth.uid
    })

    $(document).on('keydown',".translator-notification-modal", function ( e ) {
      var key = e.which || e.keyCode;
      if (key == 27) {
        $($('.translator-notification-modal')[$('.translator-notification-modal').length-1]).find('.modal-content .modal-body #modal-closer').click(); // <----use the DOM click this way!!!
      }
    });
  }

  render(){

    const { userProfile } = this.props;

    return (
      <div className="app">
        <Header />
        <div className="content">
          <Switch>
            <Route path="/" exact component={Login}/>
            <Route path="/dashboard" exact component={Dashboard}/>
            <Route path="/tasks" exact component={TasksIndex}/>
            <Route path="/tasks/new" exact component={NewTasks}/>
            <Route path="/tasks/in_progress" exact component={InProgressTasks}/>
            <Route path="/users/translators" exact component={TranslatorsIndex}/>
            <Route path="/users/candidates" exact component={CandidatesIndex}/>
            <Route path="/users/account_managers" exact component={AccountManagersIndex}/>
            <Route path="/users/operators" exact component={OperatorsIndex}/>
            <Route path="/users/customers" exact component={CustomersIndex}/>
            <Route path="/tasks/archived" exact component={ArchivedTasksIndex}/>
            <Route path="/tasks/escalated" exact component={EscalationIndex}/>
            <Route path="/chat" exact component={ChatIndex}/>
            <Route path="/profile" exact component={ProfileIndex} />
            <Route path="/invite_employee" exact component={InviteIndex} />
            <Route path="/invite/:token" exact component={InviteSignup} />
            <Route path="/custom_email_verification" exact component={CustomEmailVerification} />
            <Route path="/config" exact component={AdminConfigIndex} />
            <Route path="/test" exact component={TestIndex} />
          </Switch>
        </div>
        <span className="version">v1.0.2</span>
      </div>
    )
  }

  
}

const mapStateToProps = state => {
  return {
    auth : state.firebase.auth,
    userProfile : state.firebase.profile,
    modalInstances : state.app.modalInstances
  }
}

export default compose(
  connect(mapStateToProps, { setUserPresence })
)(App)
