import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import moment from 'moment'

const renderStatus = (status) => {
    switch(status) {
        case "inProgress":
            return <span><img style={{ width: "8px", height :  "8px", marginRight : "8px" }} src={require('../../../images/icon-inprogress.png')}/>In Progress</span>
        case "completed":
            return <span><img style={{ width: "8px", height :  "8px", marginRight : "8px" }} src={require('../../../images/icon-completed.png')}/>Completed</span>
        case "forReview":
            return <span><img style={{ width: "8px", height :  "8px", marginRight : "8px" }} src={require('../../../images/icon-inprogress.png')}/>On review</span>
        case "paused":
            return <span><img style={{ width: "8px", height :  "8px", marginRight : "8px" }} src={require('../../../images/icon-archived.png')}/>Paused</span>
        case "stopped":
            return <span><img style={{ width: "8px", height :  "8px", marginRight : "8px" }} src={require('../../../images/icon-archived.png')}/>Stopped</span>
        case "declined":
            return <span><img style={{ width: "8px", height :  "8px", marginRight : "8px" }} src={require('../../../images/icon-archived.png')}/>Declined</span>
        default:
            return <span>New</span>
    }

}

const taskTypeColor = (type) => {
    switch(type){
        case "presentation":
            return { background: "#EA5895" }
        case "essay":
            return { background : "#7E57C2" }
        default:
            return { background : "#29B6F6" }
    }
}

const renderEscalator = (type) => {
    switch(type){
        case "accountManager":
            return "Account M.";
        case "translator":
            return "Translator";
        case "customer":
            return "Customer"
        default:
            return "";
    }
}

export class List extends Component {

    scrollToBottom = () => {
        const { taskList } = this.refs;
        const scrollHeight = taskList.scrollHeight;
        const height = taskList.clientHeight;
        const maxScrollTop = scrollHeight - height;
        ReactDOM.findDOMNode(taskList).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    openTaskDetailsModal = (data) => {
        this.props.openTaskDetailsModal(data)
    }

    render(){

        const { escalations, isLoadingMoreEscalations } = this.props;

        return (
        
            <div className="div-holder">
                <div className="task-table-div">
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: "200px" }}>ORDER #</th>
                                <th style={{ width: "150px" }}>STATUS</th>
                                <th style={{ width: "150px" }}>TASK TYPE</th>
                                <th style={{ width: "300px" }}>TASK NAME</th>
                                <th style={{ width: "150px" }}>REASON</th>
                                <th style={{ width: "250px" }}>ESCALATOR</th>
                                <th >&nbsp;</th>
                                <th style={{ width: "150px" }}>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            escalations.map( (data, i) => (
                                <tr >
                                    <td onClick={ () => this.openTaskDetailsModal(data) }>{ data.task.orderID }</td>
                                    <td onClick={ () => this.openTaskDetailsModal(data) }>{ renderStatus(data.task.status) }</td>
                                    <td onClick={ () => this.openTaskDetailsModal(data) }><span style={taskTypeColor(data.task.taskType)} className="task-type">{data.task.taskType}</span></td>
                                    <td onClick={ () => this.openTaskDetailsModal(data) }>{ data.task.title }</td>
                                    <td style={{ fontWeight : "bold" }}>
                                        {
                                            data.escalators.map( (dataEscalator, i) => (
                                                <p style={{ width: "130px", overflow : "hidden", whiteSpace : "nowrap", textOverflow : "ellipsis" }}>{dataEscalator.escalationReason}</p> 
                                            ))
                                        } 
                                    </td>
                                    <td onClick={ () => this.openTaskDetailsModal(data) }>
                                        {
                                            data.escalators.map( (dataEscalator, i) => (
                                                <div style={{ position : "relative", display: "flex", marginBottom : "15px" }}>
                                                    <img title={dataEscalator.user.fullName} style={{ borderRadius : "100%", width: "28px", height : "28px", marginRight : "10px" }} src={ dataEscalator.user.imageUrl === "" ? require('../../../images/default-user.jpg') : dataEscalator.user.imageUrl} />
                                                    <img style={{ width: "8px", height : "8px", position : "absolute", bottom : "0", left : "20px" }} src={ dataEscalator.user.presence==="offline" ? require('../../../images/icon-offline.png') : require('../../../images/icon-completed.png')}/>
                                                    <span>{renderEscalator(dataEscalator.user.type)}</span>
                                                </div>  
                                            ))
                                        } 
                                    </td>  
                                    <td onClick={ () => this.openTaskDetailsModal(data) }>
                                        <div>
                                            {
                                                data.escalators.map( (dataEscalator, i) => (
                                                    <div style={{ marginBottom : "15px" }}>
                                                        <img onClick={ () => this.props.openChat(dataEscalator.user.type, dataEscalator.user.uid, data.task)} style={{ width: "28px", height : "23px", marginTop : "5px", marginRight : "15px" }} src={ dataEscalator.user.presence==="offline" ? require('../../../images/icon-messages-offline.png') : require('../../../images/icon-messages.png') }/>
                                                    </div>
                                                ))
                                            } 
                                        </div>
                                    </td>
                                    <td style={{ position : "relative"}}>
                                        <div className="btn-group" style={{ position : "absolute", top : "10px", right : "15px" }}>
                                            <button type="button" className="btn btn-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            . . .
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right custom-dropdown-right">
                                            <button className="dropdown-item custom-dropdown-item" type="button">Task details</button>
                                            <button onClick={ () => this.props.resolveRequest(data)} className="dropdown-item custom-dropdown-item" type="button">Resolve request</button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
                {
                    escalations.length!==0 && 
                    isLoadingMoreEscalations ? <div style={{ paddingTop : "15px" }}>
                        <div class="custom-get-more-spinner spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div> : <div className="text-center" style={{ marginTop : "15px" }}>
                        <button onClick={this.props.loadMoreEscalations} className="load-more-button">LOAD MORE</button>
                    </div>
                }
            </div>
           
        )

    }

}

export default List;
