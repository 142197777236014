import React, { Component } from 'react'
import moment from 'moment'

const renderRating = (count) => {

    let rating = [];
    
    let ratingCt =0;

    for(let i=0; i<count; i++){
        rating.push(<img src={require('../../../images/icon-rating.png')}/>)
        ratingCt++;
    }
    
    for(let i=ratingCt; i<5; i++){
        rating.push(<img src={require('../../../images/icon-rating-disabled.png')}/>)
    }

    return rating;

}
 
export class Item extends Component {
    
    openConfirmationModal = (translator) =>{
        const { task } = this.props;

        const translatorName = translator.fullName.split(",");

        this.props.openConfirmationModal({
            name : translatorName[0] + " " + translatorName[1],
            translator : translator,
            task : task.title
        })
    }

    render(){

        const { translator, task } = this.props;
        
        const translatorName = translator.fullName.split(",");
        
        let lastActiveAt = translator.hasOwnProperty('lastActiveAt') ? translator.lastActiveAt===null ? "offline" : moment.unix(translator.lastActiveAt._seconds).fromNow() : "offline" ;
        
        return (
            <div className="translator-item">
                <div className="translator-item-left">
                    <div style={{ position: "relative"}}>
                        <img className="display-photo" src={translator.imageUrl===""?require('../../../images/default-user.jpg'):translator.imageUrl}/>
                        <img style={{ width: "12px", height : "12px", position : "absolute", top : "50px", left : "40px" }} src={ translator.presence==="offline" ? require('../../../images/icon-offline.png') : require('../../../images/icon-completed.png')}/>
                    </div>
                    <div className="main-details">
                        <span className="tr-name">{translatorName[1]+' '+translatorName[0]}</span>
                        <span className={` ${translator.presence==="online" ? "tr-presence-online" : "tr-presence-offline"}`}>{translator.presence==="online"? "Online" : lastActiveAt}</span>
                        <span className="tr-rating">{renderRating(Math.trunc(translator.rating))}</span>
                    </div>
                </div>
                <div className="translator-item-right">
                    <div className="translator">
                        <div className="details">
                            <div style={{ flex : "1" }}>
                                <span className="tr-label">
                                    Specialty
                                </span>
                                <span className="tr-value">
                                    {translator.specialty}
                                </span>
                            </div>
                            <div style={{ flex : "1" }}>
                                <span className="tr-label">
                                    Status
                                </span>
                                <span className="tr-value">
                                    {translator.status}
                                </span>
                            </div>
                            <div style={{ flex : "1" }}>
                                <span className="tr-label">
                                    Experience
                                </span>
                                <span className="tr-value">
                                    {translator.yearsOfExperience}
                                </span>
                            </div>
                        </div>
                        <div className="actions">
                            { task && <button onClick={ () => this.openConfirmationModal(translator)} className="hire-translator op-btn">INVITE TRANSLATOR</button> }
                            { task && <div class="btn-group" style={{ position : "absolute", top : "1px", right : "-40px" }}>
                                <button type="button" class="btn btn-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                . . .
                                </button>
                                <div class="dropdown-menu dropdown-menu-right custom-dropdown-right">
                                <button class="dropdown-item custom-dropdown-item" type="button">Start the chat</button>
                                <button class="dropdown-item custom-dropdown-item" type="button">Problem? Get Help</button>
                                <button class="dropdown-item custom-dropdown-item" type="button">Fire Translator</button>
                                </div>
                            </div> }
                            { !task && <div class="btn-group" style={{ position : "absolute", top : "1px", right : "-40px" }}>
                                <button type="button" class="btn btn-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                . . .
                                </button>
                                <div class="dropdown-menu dropdown-menu-right custom-dropdown-right">
                                <button class="dropdown-item custom-dropdown-item" type="button">Start the chat</button>
                                <button onClick={ () => this.props.openFireTranslatorModal(translator)} class="dropdown-item custom-dropdown-item" type="button">Fire Translator</button>
                                </div>
                            </div> }
                        </div>
                    </div>
                    <div className="task-details">
                        <p>{translator.aboutYourself}</p>
                    </div>
                </div>
            </div>
        )

    }

}

export default Item;