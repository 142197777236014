import React, { Component } from 'react';
import $ from 'jquery';
import _ from 'lodash/'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'
import {
    getEmailVerificationContents,
    saveEmailVerificationContents,
} from '../../actions/userAction';

import {
    getAdminConfig,
    updateAdminConfig
} from '../../actions/adminConfigAction'

import SpinnerModal from '../Spinner/SpinnerModal'
import MessageModal from '../Modals/Message';
import ViewEmailVerificationModal from './Modals/ViewEmailVerification'

import './config.css'

export class Index extends Component {

    constructor(){
        super();
        this.state = {
            isAuthenticated : false,
            isProcessing : false,
            verificationTextEn : "",
            verificationTextAr : "",
            verificationTitleEn : "",
            verificationTitleAr : "",
            btnTextEn : "VERIFY ACCOUNT",
            btnTextAr : "تأكيد الحساب",
            isViewEmailVerificationModalActive : false,
            paymentEmail : "",
            isProduction : false,
            taskPrices : {}
        }
    }

    componentDidMount(){

        const { auth , userProfile } = this.props;

        if(auth.uid){
            
            this.setState({
                isAuthenticated : true
            }, async () => {
                this.props.getAdminConfig();
            })
        }

        if(userProfile.hasOwnProperty('fullName')){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true
            })
        }

        if(prevProps.adminConfig!==this.props.adminConfig){
            if(Object.keys(this.props.adminConfig).length!==0){
                const { adminConfig } = this.props;
                this.setState({
                    verificationTextAr : adminConfig.emailVerification.content.ar,
                    verificationTextEn : adminConfig.emailVerification.content.en,
                    verificationTitleAr : adminConfig.emailVerification.title.ar,
                    verificationTitleEn : adminConfig.emailVerification.title.en,
                    paymentEmail : adminConfig.emailPayments.emailAddress,
                    isProduction : adminConfig.paymentAPI.isProduction,
                    taskPrices : adminConfig.taskPrices
                }, () => {

                    $('input[name="isProduction"][value="'+adminConfig.paymentAPI.isProduction+'"]').prop('checked',true)

                })
            }
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    openViewVerificationModal = () => {
        this.setState({
            isViewEmailVerificationModalActive : true
        })
    }

    closeViewVerificationModal = () => {
        this.setState({
            isViewEmailVerificationModalActive : false
        })
    }

    saveContent = () => {
        const { verificationTextAr, verificationTextEn } = this.state;
        if(verificationTextAr.trim()!=="" && verificationTextEn.trim()!==""){
            this.setState({
                isProcessing : true
            }, async () => {
                
                this.props.updateAdminConfig("emailVerification",{
                    content : {
                        en : verificationTextEn,
                        ar : verificationTextAr
                    }
                })

                setTimeout(() => {
                    this.setState({
                        isProcessing : false
                    })
                }, 800)
               
            })
        }
    }

    paymentEmailOnChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    savePaymentEmail = () => {
        
        const { paymentEmail } = this.state;

        let regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;

        if(paymentEmail.trim()!==""){

            if(regExp.test(paymentEmail)){
                $('#paymentEmail').next('span.error-input-message').text("")

                this.setState({
                    isProcessing : true
                }, async () => {
                    
                    this.props.updateAdminConfig("emailPayments",{
                        emailAddress : paymentEmail
                    })

                    setTimeout(() => {
                        this.setState({
                            isProcessing : false
                        })
                    }, 800)
                
                })
            }else{
                $('#paymentEmail').next('span.error-input-message').text("Please enter a valid email address.")
            }
        }else{
            $('#paymentEmail').next('span.error-input-message').text("Please enter a valid email address.")
        }
    }

    selectEnv = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    saveEnv = () => {

        const { isProduction } = this.state;

        this.setState({
            isProcessing : true
        }, async () => {
            
            this.props.updateAdminConfig("paymentAPI",{
                isProduction : isProduction==="true" ? true : false
            })

            setTimeout(() => {
                this.setState({
                    isProcessing : false
                })
            }, 800)
           
        })
    }

    render(){

        const { isProcessing, isAuthenticated } = this.state;
        const { isLoggingOut, auth, userProfile } = this.props;

        const { isViewEmailVerificationModalActive, btnTextEn, btnTextAr, verificationTitleEn, verificationTitleAr, verificationTextAr, verificationTextEn } = this.state;
        const { paymentEmail } = this.state;
        const { isProduction } = this.state;

        const { adminConfig } = this.props;

        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }
        
        if(!auth.uid && !auth.emailVerified){
            return <Redirect to="/" />
        }else{
            if(isAuthenticated){
                if(userProfile.type!=="superuser"){
                    return "NOT ALLOWED";
                }
            }
        }

        return (
            <div className="div-holder">
                <div className="div-holder" style={{ paddingBottom : "20px", borderBottom : "1px solid #e4e4e4" }}>
                    <div style={{ display : "flex"}}>
                        <div style={{ flex : "1", textAlign : "left" }}>
                            <p className="module-header">Custom Email Verification</p>
                        </div>
                        <div style={{ display : "flex", float : "right" }}>
                            <button  onClick={this.openViewVerificationModal} className="view-btn">View Email</button>
                            <button onClick={this.saveContent} className="save-btn">Save</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label className="custom-create-task-label block">Body of the email ( EN )</label>
                                <textarea value={verificationTextEn} onChange={this.onChange} style={{ whiteSpace : "pre-line", resize : "none", width: "100%" }} name="verificationTextEn" id="emailBody" rows={10} placeholder="Enter body of the email here" className="custom-input"></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label className="custom-create-task-label block">Body of the email ( AR )</label>
                                <textarea value={verificationTextAr} onChange={this.onChange} style={{ whiteSpace : "pre-line", resize : "none", width: "100%" }} name="verificationTextAr" id="emailBody" rows={10} placeholder="Enter body of the email here" className="custom-input"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="div-holder">
                    <div style={{ display : "flex"}}>
                        <div style={{ flex : "1", textAlign : "left" }}>
                            <p className="module-header">Payments</p>
                        </div>
                        <div style={{ display : "flex", float : "right" }}>
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label className="custom-create-task-label block">Payment Email</label>
                                <input onChange={this.paymentEmailOnChange} defaultValue={paymentEmail} className="custom-input" style={{ width : "100%" }} name="paymentEmail" id="paymentEmail" placeholder="Enter email here" />
                                <span className="error-input-message" style={{ display : "block" }}></span>
                                <button onClick={this.savePaymentEmail} style={{ marginTop : "15px"}} className="save-btn">Save</button>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label className="custom-create-task-label block">Payment API</label>
                                <label className="checkbox-container">For Production
                                    <input name="isProduction" value={true} onClick={this.selectEnv} className="reason" type="radio"/>
                                    <span className="checkmark"></span>
                                </label>
                                <label className="checkbox-container">For Development
                                    <input name="isProduction" value={false} onClick={this.selectEnv} className="reason" type="radio"/>
                                    <span className="checkmark"></span>
                                </label>
                                <button onClick={this.saveEnv} style={{ marginTop : "15px"}} className="save-btn">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isViewEmailVerificationModalActive &&
                    <ViewEmailVerificationModal
                        isOpened={isViewEmailVerificationModalActive}
                        closeViewVerificationModal={this.closeViewVerificationModal}
                        header={ {en : verificationTitleEn, ar : verificationTitleAr} }
                        body={{ en : verificationTextEn, ar : verificationTextAr}}
                        btn={{ en : btnTextEn, ar : btnTextAr }}
                    />
                }
                {
                    isLoggingOut && 
                    <SpinnerModal
                        isOpened={isLoggingOut}
                    />
                }
                {
                    isProcessing && 
                    <SpinnerModal
                        isOpened={isProcessing}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        isLoggingOut : state.auth.isLoggingOut,
        adminConfig : state.adminConfig.adminConfig
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, {
        getEmailVerificationContents,
        saveEmailVerificationContents,
        getAdminConfig,
        updateAdminConfig
    })
)(Index)