import React, { Component } from 'react'
import _ from 'lodash/'
import './modal-translator-search.css'

export class SearchInput extends Component{
    
    searchTranslators = _.debounce( async (keyword) => {
        
        this.props.searchTranslators(keyword);

    }, 800)


    render (){

        return (
            <div className="translator-modal-search">
                <input onKeyUp={(e) => this.searchTranslators(e.target.value)} placeholder="Search" className="translator-modal-search-input"/>
                <img src={require('../../../../images/icon-search.png')}/>
            </div>
        )

    }

}

export default SearchInput