import React, { Component } from 'react'

export class ConversationHeader extends Component {

    constructor(){
        super();
    }

    render(){

        const {  activeConversation, userProfile } = this.props;

        let imageUrl;
        let userName;
        let recipient;

        if( activeConversation!== null ){
            Object.keys(activeConversation.memberData).map( i=> {
                if(i!==userProfile.uid){
                    recipient = activeConversation.memberData[i]
                }
            })
    
            imageUrl = recipient.imageUrl === "" ? require('../../images/default-user.jpg') : recipient.imageUrl
            userName = recipient.fullName.split(",");
        }

        return (
            <div className="conversation-header">
                <div className="recipient-details">
                    { activeConversation &&  <img className="display-photo" src={imageUrl}/> }
                    <div className="details">
                        <span className="username">{activeConversation !== null ? userName[1]+' '+userName[0] : ""}</span>
                        <span className="task-title">{activeConversation !== null ? activeConversation.taskTitle : "" }</span>
                    </div>
                </div>
                <div className="recipient-actions">
                    {
                        (activeConversation && userProfile.type!=="superuser") && <div class="btn-group">
                            {
                                (activeConversation.hasOwnProperty('taskObject') && activeConversation.taskObject.status!=="completed") &&
                                <button type="button" class="btn btn-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                . . .
                                </button>
                            }
                            <div class="dropdown-menu dropdown-menu-right custom-dropdown-right">
                            { (userProfile.type ==="accountManager" && (activeConversation.hasOwnProperty('taskObject') && activeConversation.taskObject.status==="forReview" && activeConversation.taskObject.paymentStatus==="fullyPaid")) && <button onClick={ () => this.props.openCompleteTaskModal(activeConversation.taskObject)} class="dropdown-item custom-dropdown-item" type="button">Complete task</button> }
                            { activeConversation.hasOwnProperty('taskObject') && <button onClick={ () => this.props.openEscalateTaskModal(activeConversation.taskObject)} class="dropdown-item custom-dropdown-item" type="button">Problem? Get help</button>}
                            </div>
                        </div>
                    }
                    {/* {
                        activeConversation && <div className="dropdown dropdown-menu-right">
                            <a href="javascript:void(0)" id="imageDropdown" data-toggle="dropdown">. . .</a>
                            <ul className="dropdown-menu chat-dropdown-menu" role="menu" aria-labelledby="imageDropdown">
                                <li role="presentation"><a role="menuitem" tabindex="-1" href="#">Pause task</a></li>
                                <li role="presentation"><a role="menuitem" tabindex="-1" href="#">Stop task</a></li>
                                <li role="presentation"><a role="menuitem" tabindex="-1" href="#">Problem? Get help</a></li>
                            </ul>
                        </div>
                    } */}
                </div>
            </div>
        )

    }

}

export default ConversationHeader