import React, { Component } from 'react';
import $ from 'jquery';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SearchInput from '../SearchInput';
import { Redirect, withRouter, Link } from 'react-router-dom'
import { getAllTranslators, updateUser, fireUser, deleteCandidate, updateAll } from '../../../actions/userAction'
import { declineAssignedTask, hireTranslator, clearLastRef } from '../../../actions/taskAction'

import TranslatorList from './List';
import Filter from './Filter';
import SpinnerModal from '../../Spinner/SpinnerModal'
import TranslatorInviteModal from '../../Tasks/Modals/TranslatorInvite';
import ConfirmationModal from '../../Tasks/Modals/Confirmation'
import MessagePromptModal from '../../Tasks/Modals/MessagePrompt'
import FireTranslatorModal from './Modals/FireTranslator';
import DeclineCandidateModal from '../Candidates/Modals/DeclineCandidate'
import AcceptCandidateModal from '../Candidates/Modals/AcceptCandidate'
import MessageModal from '../../Modals/Message'
import SUList from './SUList'

import './translators.css'

const specialties = [
    "Accounting & Consulting",
    "Admin Support",
    "Customer Service",
    "Data Science and Analytics",
    "Design & Creative",
    "Engineering and Architecture",
    "IT & Engineering",
    "Legal",
    "Sales & Marketing",
    "Translation",
    "Web, Mobile, & Software Development",
    "Writing",
    "Medical"
]

const yearsOfExperience = [
    "Less than 1 year",
    "1-3 years",
    "3-5 years",
    "More than 5 years"
]

const status = [
    "available",
    "notAvailable",
    "pending"
]

export class Index extends Component{

    constructor(){
        super();
        this.state = {
            selectedTask : null,
            search : ``,
            facetFilters : [
                ["type:translator"],
                ["status:-disabled"]
            ],
            specFilter : [
                "specialty:Accounting & Consulting",
                "specialty:Admin Support",
                "specialty:Customer Service",
                "specialty:Data Science and Analytics",
                "specialty:Engineering and Architecture",
                "specialty:Design & Creative",
                "specialty:IT & Engineering",
                "specialty:Legal",
                "specialty:Sales & Marketing",
                "specialty:Translation",
                "specialty:Web, Mobile, & Software Development",
                "specialty:Writing",
                "specialty:Medical",
            ],
            expFilter : [
                "yearsOfExperience:Less than 1 year",
                "yearsOfExperience:1-3 years",
                "yearsOfExperience:3-5 years",
                "yearsOfExperience:More than 5 years",
            ],
            ratingFilter : [
                "rating:0",
                "rating:1",
                "rating:2",
                "rating:3",
                "rating:4",
                "rating:5",
            ],
            statFilter : [
                "status:available",
            ],
            openFilter : false,
            isProcessing : false,
            searchString : "",
            specialty : [],
            experience : [],
            status : [],
            rating : 5,
            pageNumber : 0,
            isTranslatorInviteModalActive : false,
            isConfirmationModalActive : false,
            isMessagePromptModalActive : false,
            messagePromptText : "",
            selectedTranslator : "",
            toFireTranslator : null,
            isFireTranslatorModalActive : false,
            showLoadMore : true,
            isDeclineCandidateModalActive : false,
            toDeclineTranslator : null,
            isAcceptCandidateModalActive : false,
            toAcceptTranslator : null,
            isMessageModalActive : false,
            messageHeader : "",
            messageSubHeader : ""
        }
    }

    openConfirmationModal = (data) => {

        const { selectedTask } = this.state;

        this.setState({
            isConfirmationModalActive : true,
            confirmationHeader : `HIRE ${data.name} for ${selectedTask.title} ?`,
            selectedTranslator : data.translator
        })
    }

    closeConfirmationModal = () => {
        this.setState({
            isConfirmationModalActive : false,
            confirmationHeader : "",
            selectedTranslator : null
        })
    }

    confirm = () => {
        const { selectedTranslator, selectedTask } = this.state;
        const {  auth } = this.props;

        this.hireTranslator({
            translator : selectedTranslator,
            task : selectedTask
        })
        
    }

    componentDidMount(){
        const { auth } = this.props;
        
        if(auth.uid){
            let selectedTask = this.props.history.location.state=== undefined ? null : this.props.history.location.state.task;
            
            let newFacetFilters = [];
            let newStatFilter = [];

            if(selectedTask===null){

                newFacetFilters = [
                    ["type:translator"],
                    ["status:-disabled"]
                ]

                newStatFilter = [
                    "status:available",
                    "status:notAvailable",
                    "status:pending"
                ]

            }else{
                
                newFacetFilters = [
                    ["type:translator"],
                    ["status:available"]
                ]

                newStatFilter = [
                    "status:available",
                ]
            }

            this.setState({
                selectedTask : selectedTask,
                facetFilters : newFacetFilters,
                statFilter : newStatFilter
            }, () => {

                const { search , facetFilters } = this.state;

                this.loadTranslators({
                    search : search,
                    facetFilters : facetFilters
                });
            })
          
        }
    }

    loadTranslators = (data) => {

        data.sortBy = "fullName";
        this.props.getAllTranslators(data);

    }

    searchTranslators = (data) => {

        const { translatorsTotalRecord } = this.props;

        if(data.trim()===""){
            this.setState({
                showLoadMore : true
            })
        }else{
            this.setState({
                showLoadMore : false
            })
        }

        this.setState({
            search : data
        }, () => {

            const { search, facetFilters } = this.state;

            this.loadTranslators({
                search : search,
                facetFilters : facetFilters
            });
        })
    }

    toggleFilter = () => {

        const { openFilter, specFilter, expFilter, statFilter, ratingFilter, facetFilters } = this.state;

        if(openFilter){
            
            let newFacetFilters = [];
            specFilter.length!==0 && newFacetFilters.push(specFilter)
            expFilter.length!==0 && newFacetFilters.push(expFilter)
            statFilter.length!==0 && newFacetFilters.push(statFilter)
            ratingFilter.length!==0 && newFacetFilters.push(ratingFilter)

            this.setState({
                openFilter : false,
                facetFilters : newFacetFilters
            }, () => {
                
                const { search, facetFilters } = this.state;

                this.loadTranslators({
                    search : search,
                    facetFilters : facetFilters
                });

            })

        }else{
            this.setState({
                openFilter : true
            })
        }

    }

    selectFilter = (e) => {

        const { specFilter, ratingFilter, expFilter, statFilter } = this.state;

        let text = $(e.target).parent('.checkbox-container').text();
        let filterClass = $(e.target).parent('.checkbox-container').data('filter')

        if(filterClass==="specialty"){
            if(e.target.checked){
                specFilter.push(`specialty:${text}`);
            }else{
                $(e.target).prop('checked',false)
                let i = specFilter.indexOf(`specialty:${text}`);
                if(i!==-1){
                    specFilter.splice(i,1)
                }
            }
            this.setState({
                specFilter : [...specFilter]
            })
            
        }

        if(filterClass==="exp"){
            
            if(e.target.checked){
                expFilter.push(`yearsOfExperience:${text}`);
            }else{
                $(e.target).prop('checked',false)
                let i = expFilter.indexOf(`yearsOfExperience:${text}`);
                if(i!==-1){
                    expFilter.splice(i,1)
                }
            }
            this.setState({
                expFilter : [...expFilter]
            })

        }

        if(filterClass==="status"){
            if(text==="Available"){
                text = "available"
            }

            if(text==="Not Available"){
                text = "notAvailable"
            }

            if(text==="Pending"){
                text = "pending"
            }

            if(e.target.checked){
                statFilter.push(`status:${text}`);
            }else{
                $(e.target).prop('checked',false)
                let i = statFilter.indexOf(`status:${text}`);
                if(i!==-1){
                    statFilter.splice(i,1)
                }
            }
            this.setState({
                statFilter : [...statFilter]
            })
        }
    }

    setRatingFilter = (value) => {
        
        let rFilter = [];

        for(let i=0; i<=value; i++){
            rFilter.push(`rating: ${i}`)
        }

        this.setState({
            rating : value,
            ratingFilter : [...rFilter]
        })
    
    }

    loadMore = () => {

        const { translatorsCurrentPage, translatorsPageCount } = this.props;
        const { search, facetFilters } = this.state;

        let newPageNumber = translatorsCurrentPage + 1;

        if(newPageNumber <= translatorsPageCount ){
            this.loadTranslators({
                search : search,
                facetFilters : facetFilters,
                page : newPageNumber
            })   
        }
    }

    hireTranslator = async (data) => {

        const { selectedTask } = this.state;

        if(selectedTask.hasOwnProperty('invitedTranslators')){
            if(selectedTask.invitedTranslators.indexOf(data.uid)===-1){
                const { auth } = this.props;

                this.setState({
                    isProcessing : true
                }, async () => {

                    let result = await this.props.hireTranslator({
                        uid : selectedTask.uid,
                        assignedTranslator : data.translator.uid,
                        accountManager : auth.uid
                    })
    
                    if(result.code!==500){
                        if(result.code===1062){
                            this.setState({
                                isProcessing : false,
                                isConfirmationModalActive : false,
                                isMessagePromptModalActive : true,
                                isHireTranslatorModalActive : false,
                                messagePromptText : result.message,
                                
                            })
                        }else{
                            const translatorName = data.translator.fullName.split(",");
                            let name =  translatorName[0] + " " + translatorName[1]
                
                            this.setState({
                                isProcessing : false,
                                selectedTranslator : name,
                                isTranslatorInviteModalActive : true,
                                isConfirmationModalActive : false,
                                selectedTask : {...result.data}
                            })
                        }

                        $('.modal-backdrop')[0]!== undefined && $('.modal-backdrop')[0].remove()
                    }
                
                })
            }else{
            }
        }else{
            const { auth } = this.props;

            this.setState({
                isProcessing : true
            }, async () => {

                let result = await this.props.hireTranslator({
                    uid : selectedTask.uid,
                    assignedTranslator : data.translator.uid,
                    accountManager : auth.uid
                })

                if(result.code!==500){
                    if(result.code===1062){
                        this.setState({
                            isProcessing : false,
                            isConfirmationModalActive : false,
                            isMessagePromptModalActive : true,
                            isHireTranslatorModalActive : false,
                            messagePromptText : result.message,
                            
                        })
                    }else{
                        const translatorName = data.translator.fullName.split(",");
                        let name =  translatorName[0] + " " + translatorName[1]
            
                        this.setState({
                            isProcessing : false,
                            selectedTranslator : name,
                            isTranslatorInviteModalActive : true,
                            isConfirmationModalActive : false,
                            selectedTask : {...result.data}
                        })
                    }

                    $('.modal-backdrop')[0]!== undefined && $('.modal-backdrop')[0].remove()
                }

            })
        }
    }

    closeTranslatorInviteModal = () => {
        this.setState({
            isTranslatorInviteModalActive : false
        })
    }

    unAssignTask = (task, translator) => {
        this.setState({
            isProcessing : true
        }, async () => {

            let result = await this.props.declineAssignedTask({
                task: task,
                translator : translator
            })

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isTranslatorInviteModalActive : false,
                    selectedTask : {...result.data}
                })
            }, 1500)

        })

    }

    openChat = (recipient, task) => {
        this.props.history.push({
            pathname : "/chat",
            state : {
                tab : "translator",
                task : task,
                recipient : recipient
            }
        })
    }

    backToTasks = () => {
        this.props.history.push("/tasks/new");
    }

    openFireTranslatorModal = (translator) => {
        this.setState({
            toFireTranslator : translator,
            isFireTranslatorModalActive : true
        })
    }

    closeFireTranslatorModal = () => {
        this.setState({
            toFireTranslator : null,
            isFireTranslatorModalActive : false
        })
    }

    fireTranslator = (data) => {
        
        const { toFireTranslator } = this.state;

        this.setState({
            isProcessing : true
        }, () => {

            this.props.fireUser({
                uid : toFireTranslator.uid,
                status : "disabled",
                disabledReason : data.disabledReason
            })

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isFireTranslatorModalActive : false,
                    toFireTranslator : null,
                    isMessageModalActive : true,
                    messageHeader : "",
                    messageSubHeader : "Translator has been fired."
                }, () => {

                    const { search , facetFilters } = this.state;

                    this.loadTranslators({
                        search : search,
                        facetFilters : facetFilters
                    });

                })
            }, 3000)

        })

    }

    openDeclineCandidateModal = (translator) => {
        this.setState({
            toDeclineTranslator : translator,
            isDeclineCandidateModalActive : true
        })
    }

    closeDeclineCandidateModal = () => {
        this.setState({
            toDeclineTranslator : null,
            isDeclineCandidateModalActive : false
        })
    }

    openAcceptCandidateModal = (translator) => {
        this.setState({
            toAcceptTranslator : translator,
            isAcceptCandidateModalActive : true
        })
    }

    closeAcceptCandidateModal = () => {
        this.setState({
            toAcceptTranslator : null,
            isAcceptCandidateModalActive : false
        })
    }

    confirmDeclineCandidate = () => {
        
        const { toDeclineTranslator } = this.state;

        this.setState({
            isProcessing : true
        }, () => {

            this.props.deleteCandidate(toDeclineTranslator)

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isDeclineCandidateModalActive : false,
                    toDeclineTranslator : null,
                    isMessageModalActive : true,
                    messageHeader : "",
                    messageSubHeader : "Translator has been decline."
                })
            }, 3000)

        })

    }

    acceptCandidate = () => {

        const { toAcceptTranslator } = this.state;

        this.setState({
            isProcessing : true
        }, () => {

            this.props.updateUser({
                uid : toAcceptTranslator.uid,
                status : "available"
            })

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isAcceptCandidateModalActive : false,
                    toAcceptTranslator : null,
                    isMessageModalActive : true,
                    messageHeader : "",
                    messageSubHeader : "Translator has been accepted."
                })
            }, 3000)

        })

    }

    closeMessageModal = () => {
        this.setState({
            isMessageModalActive : false,
            messageHeader : "",
            messageSubHeader : ""
        }, () => {
            const { search , facetFilters } = this.state;

            this.loadTranslators({
                search : search,
                facetFilters : facetFilters
            });
        })
    }


    render(){

        const { isMessagePromptModalActive, messagePromptText, confirmationHeader, isConfirmationModalActive, selectedTranslator, isTranslatorInviteModalActive, isProcessing, selectedTask, openFilter, rating, specFilter, expFilter, statFilter } = this.state;
        const { isLoggingOut, translators, auth, userProfile } = this.props;

        const { isGettingTranslators, isGettingMoreTranslators, translatorsTotalRecord } = this.props;
        const { isFireTranslatorModalActive, toFireTranslator } = this.state;
        const { isDeclineCandidateModalActive, toDeclineTranslator } = this.state;
        const { isAcceptCandidateModalActive, toAcceptTranslator } = this.state;

        const { isMessageModalActive, messageHeader, messageSubHeader } = this.state;

        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }
        
        if(!auth.uid && !auth.emailVerified){
            return <Redirect to="/" />
        }

        return (
            <div>
                <div style={{ display : "flex"}}>
                    <div style={{ flex : "1", textAlign : "left" }}>
                        <p className="module-header">Translators List</p>
                    </div>
                    <div style={{ display : "flex", float : "right" }}>
                        <SearchInput searchUsers={this.searchTranslators} />
                        <div className="translator-dropdown-filter">
                            <button onClick={this.toggleFilter} className="filter-translators">FILTER</button>
                            {
                                openFilter && <Filter 
                                    selectFilter={this.selectFilter} 
                                    setRatingFilter={this.setRatingFilter} 
                                    rating={rating}
                                    specFilter={specFilter}
                                    expFilter={expFilter}
                                    statFilter={statFilter}
                                    toggleFilter={this.toggleFilter}
                                    selectedTask={selectedTask}
                                    />
                            }
                        </div>
                    </div>
                </div>
                {
                    userProfile.type==="superuser" &&
                    <div>
                        {   
                            isGettingTranslators ?
                            <div className="d-flex justify-content-center">
                                <div style={{ height: "5rem", width: "5rem" }} className="custom-spinner-border spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            translatorsTotalRecord!== 0 ?
                            <SUList 
                                openDeclineCandidateModal={this.openDeclineCandidateModal}
                                openAcceptCandidateModal={this.openAcceptCandidateModal}
                                isGettingMoreTranslators={isGettingMoreTranslators}
                                loadMore={this.loadMore}
                                openFireTranslatorModal={this.openFireTranslatorModal}
                                translators={translators}
                            /> : ""
                        }
                        {
                            isDeclineCandidateModalActive &&
                            <DeclineCandidateModal
                                isOpened={isDeclineCandidateModalActive}
                                btnYes={"OK"}
                                btnCancel={"CANCEL"}
                                header={"Decline Candidate"}
                                confirm={this.confirmDeclineCandidate}
                                candidate={toDeclineTranslator}
                                closeDeclineCandidateModal={this.closeDeclineCandidateModal}
                            />
                        }
                        {
                            isAcceptCandidateModalActive &&
                            <AcceptCandidateModal
                                isOpened={isAcceptCandidateModalActive}
                                candidate={toAcceptTranslator}
                                acceptCandidate={this.acceptCandidate}
                                closeAcceptCandidateModal={this.closeAcceptCandidateModal}
                            />
                        }
                    </div>
                }
                {
                    userProfile.type==="accountManager" &&
                    <div className="">
                        {
                            translators.length!==0 ?  
                            <TranslatorList  
                                openChat={this.openChat}
                                openConfirmationModal={this.openConfirmationModal}
                                hireTranslator={this.hireTranslator}
                                loadMore={this.loadMore} 
                                translators={translators}
                                selectedTask={selectedTask}
                                openFireTranslatorModal={this.openFireTranslatorModal}
                                /> : 
                            <div className="filter-no-translator">
                                <img src={require('../../../images/no-translator.png')}/>
                                <p className="filter-no-translator-text">We did not find anyone, try changing filters or</p>
                                <Link to="/users/candidates"><button className="filter-no-translator-button">CHECK THE LIST OF CANDIDATES</button></Link>
                            </div>
                        }
                    </div>
                }
                {
                    isProcessing &&
                    <SpinnerModal
                        isOpened={isProcessing}
                    />
                }
                {
                    isLoggingOut &&
                    <SpinnerModal
                        isOpened={isLoggingOut}
                    />
                }
                {
                    isTranslatorInviteModalActive && <TranslatorInviteModal unAssignTask={this.unAssignTask} task={selectedTask} translator={selectedTranslator} closeTranslatorInviteModal={this.closeTranslatorInviteModal} isOpened={isTranslatorInviteModalActive} />
                }
                {
                    isConfirmationModalActive &&
                    <ConfirmationModal
                        isOpened={isConfirmationModalActive}
                        closeConfirmationModal={this.closeConfirmationModal}
                        confirm={this.confirm}
                        header={confirmationHeader}
                        btnYes="YES"
                        btnCancel="NO"
                    />
                }
                {
                    isMessagePromptModalActive &&
                    <MessagePromptModal
                        header={messagePromptText}
                        btnOk={"OK"}
                        onOk={this.backToTasks}
                        isOpened={isMessagePromptModalActive}
                    />
                }
                {
                    isFireTranslatorModalActive &&
                    <FireTranslatorModal 
                        closeFireTranslatorModal={this.closeFireTranslatorModal}
                        isOpened={isFireTranslatorModalActive}
                        translator={toFireTranslator}
                        fireTranslator={this.fireTranslator}
                    />
                }
                {
                    isMessageModalActive &&
                    <MessageModal 
                        isOpened={isMessageModalActive}
                        closeMessageModal={this.closeMessageModal}
                        header={messageHeader}
                        subHeader={messageSubHeader}
                    />
                }
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        isGettingTranslators : state.user.isGettingTranslators,
        isGettingMoreTranslators : state.user.isGettingMoreTranslators,
        translators : state.user.translators,
        translatorsTotalRecord : state.user.translatorsTotalRecord,
        translatorsFilteredRecord : state.user.translatorsFilteredRecord,
        translatorsCurrentPage : state.user.translatorsCurrentPage,
        translatorsPageCount : state.user.translatorsPageCount,
        isLoggingOut : state.auth.isLoggingOut
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, {   
        getAllTranslators,
        hireTranslator,
        declineAssignedTask,
        clearLastRef,
        updateUser,
        deleteCandidate,
        updateAll,
        fireUser
    })
)(Index)
