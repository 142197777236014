import React, { Component } from 'react'
import BeautyStars from 'beauty-stars';

const statuses = [
    "inProgress",
    "forReview",
    "completed",
    "paused",
    "stopped",
    "declined",
    "new"
]

const renderStatus = (status) => {
    switch(status){
        case "inProgress":
            return "In progress";
        case "forReview":
            return "On review";
        case "completed":
            return "Completed";
        case "paused":
            return "Paused";
        case "stopped":
            return "Stopped";
        case "declined":
            return "Declined";
        default:
            return "New";
    }
}

const taskTypes = [
    "essay",
    "dissertation",
    "presentation",
]

const renderTaskType = (type) => {
    switch(type){
        case "essay":
            return "Essay";
        case "dissertation":
            return "Dissertation";
        default:
            return "Presentation";
    }
}

const services = [
    "express",
    "diamond",
    "certified"
]

const renderService = (service) => {
    switch(service){
        case "diamond":
            return "High priority task";
        case "express":
            return "Express service";
        default:
            return "Certified translator"
    }
}


export class Filter extends Component {

    componentDidMount(){
        document.addEventListener('mousedown',this.outOfBoundsClick, false);
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown',this.outOfBoundsClick, false);
    }

    outOfBoundsClick = (e) => {
        if(this.filter_node.contains(e.target)){
            return
        }
        this.props.toggleFilter();
    }

    render() {

        const { typeAll, servAll , statFilter, typeFilter, servFilter } = this.props;
        
        return (
            <div style={{ minWidth: "510px", left : "-400px" }} className="task-floating-filter" ref={filter_node=>this.filter_node = filter_node}>
                <div className="filters" style={{ maxWidth : "150px" }}>
                    <div className="filter-options" style={{ marginLeft: "0 !important"}}>
                        <span className="filter-options-header">Status</span>
                        <div>
                            <ul className="filter-option-list">
                                {
                                    statuses.map( (data, i) => (
                                        <li>
                                            <label data-filter="status" className="checkbox-container">{renderStatus(data)}
                                                <input checked={statFilter.indexOf(`status:${data}`)!==-1 ? true : false } name="filter" onClick={this.props.selectFilter} className="filter" type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="filters" style={{ maxWidth : "150px" }}>
                    <div className="filter-options">
                        <span className="filter-options-header">Task type</span>
                        <div>
                            <ul className="filter-option-list">
                                <li>
                                    <label data-filter="task-type" className="checkbox-container">All
                                        <input checked={typeAll} name="filter" onClick={this.props.selectFilter} className="filter" type="checkbox"/>
                                        <span className="checkmark"></span>
                                    </label>
                                </li>
                                {
                                    taskTypes.map( (data, i) => (
                                        <li>
                                            <label data-filter="task-type" className="checkbox-container">{renderTaskType(data)}
                                                <input checked={typeFilter.indexOf(`taskType:${data}`)!==-1 ? true : false } name="filter" onClick={this.props.selectFilter} className="filter" type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="nonflex-filters" style={{ maxWidth : "150px" }}>
                    <div className="filter-options">
                        <span className="filter-options-header">Services</span>
                        <div>
                            <ul className="filter-option-list">
                                <li>
                                    <label data-filter="service" className="checkbox-container">All
                                        <input checked={servAll} name="filter" onClick={this.props.selectFilter} className="filter" type="checkbox"/>
                                        <span className="checkmark"></span>
                                    </label>
                                </li>
                                {
                                    services.map( (data, i) => (
                                        <li>
                                            <label data-filter="service" className="checkbox-container">{renderService(data)}
                                                <input checked={servFilter.indexOf(`additionalServices:${data}`)!==-1 ? true : false } name="filter" onClick={this.props.selectFilter} className="filter" type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>  
        )
    }
}

export default Filter